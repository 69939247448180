import fetch from 'cross-fetch'

import i18n from '@app/i18n/config'

const BASE_URL = import.meta.env.VITE_STRAPI_API_URL

export async function strapiFetcher(endpoint: string) {
  const resp = await fetch(`${BASE_URL}${endpoint}`, { headers: { 'x-locale': i18n.language } })

  const { data, meta, error } = await resp.json()

  if (!resp.ok) {
    throw error || new Error('Unable to fetch')
  }

  return { data, meta }
}
