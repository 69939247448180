import { useTranslation } from 'react-i18next'

import { Sex } from '@app/types'

export const usePatientSexTranslationKeys = (sex?: Sex): string => {
  const { t } = useTranslation()

  if (!sex) return ''

  const patientSex = {
    male: t('common.patient.sex.male'),
    female: t('common.patient.sex.female'),
  }[sex.toLowerCase()]

  return patientSex || ''
}
