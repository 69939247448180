import { CssBaseline, ThemeProvider } from '@mui/material'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { OuterErrorBoundary } from '@app/components/ErrorBoundary'
import { AuthProvider } from '@app/context/auth'
import { CalendarTimeFocusProvider } from '@app/context/calendar-time-focus'
import { ConfigProvider } from '@app/context/config'
import { ConsultationCancellationProvider } from '@app/context/consultation-cancellation'
import { ConsultationProgressProvider } from '@app/context/consultation-progress'
import { ErraticPatientsProvider } from '@app/context/erratic-patients'
import { LocalesProvider } from '@app/context/locales'
import { MonitoringProvider } from '@app/context/monitoring'
import { NotificationsProvider } from '@app/context/notifications'

import { PatientSearchResultsProvider } from './context/patient-search-results'
import { AppRoutes } from './routes'
import theme from './theme'

import './style.css'

function App() {
  return (
    <React.StrictMode>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <OuterErrorBoundary>
          <BrowserRouter>
            <AuthProvider>
              <ConfigProvider>
                <LocalesProvider>
                  <NotificationsProvider>
                    <ConsultationProgressProvider>
                      <ErraticPatientsProvider>
                        <CalendarTimeFocusProvider>
                          <ConsultationCancellationProvider>
                            <MonitoringProvider>
                              <PatientSearchResultsProvider>
                                <AppRoutes />
                              </PatientSearchResultsProvider>
                            </MonitoringProvider>
                          </ConsultationCancellationProvider>
                        </CalendarTimeFocusProvider>
                      </ErraticPatientsProvider>
                    </ConsultationProgressProvider>
                  </NotificationsProvider>
                </LocalesProvider>
              </ConfigProvider>
            </AuthProvider>
          </BrowserRouter>
        </OuterErrorBoundary>
      </ThemeProvider>
    </React.StrictMode>
  )
}

export default App
