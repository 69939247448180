import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CodeForm } from '@app/forms/Code'
import { EmailAndPasswordForm } from '@app/forms/EmailAndPassword'
import { useLogin } from '@app/hooks/use-login'

export const LoginPage: React.FC = () => {
  const { t } = useTranslation()
  const { onEmailAndPasswordSubmit, onResendCode, onCodeSubmit, onForceLogin, maskedPhone, token } = useLogin()

  return (
    <Box
      component="main"
      minHeight="100%"
      py={12}
      px={2}
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      flex={1}
      sx={{
        // This background is a placeholder, so leaving here for now
        background: 'linear-gradient(67.48deg, #454674 4.37%, #4448AB 99.84%)',
      }}
    >
      <Box bgcolor="white" p={4} maxWidth="420px" width="100%" sx={{ borderRadius: 2 }}>
        {token ? (
          <CodeForm
            onSubmit={onCodeSubmit}
            onResend={onResendCode}
            onForceLogin={onForceLogin}
            maskedPhone={maskedPhone}
            heading={t('pages.login.code.heading')}
            headingProps={{ component: 'h1', variant: 'h2', mb: 4 }}
          />
        ) : (
          <>
            <EmailAndPasswordForm
              onSubmit={onEmailAndPasswordSubmit}
              onForceLogin={onForceLogin}
              heading={t('pages.login.emailAndPassword.heading')}
              headingProps={{ component: 'h1', variant: 'h2', mb: 4 }}
              submitLabel={t('pages.login.emailAndPassword.submit')}
            />
          </>
        )}
      </Box>
    </Box>
  )
}
