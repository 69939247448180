import { Box, Button, Grid, Stack, SvgIcon, Typography } from '@mui/material'
import { StackTypeMap } from '@mui/material/Stack/Stack'
import { Baby, SuitcaseSimple } from 'phosphor-react'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Card } from '@app/components/Card'
import { usePatientSexTranslationKeys } from '@app/hooks/use-patient-sex-translation-keys'
import { Patient } from '@app/types'
import { calculateAge } from '@app/util/calculate-age'
import { parseOpenEMRDate } from '@app/util/date'

type PatientDescriptionItemProps = {
  label: string
} & StackTypeMap['props']

const PatientDescriptionItem: React.FC<PatientDescriptionItemProps> = ({ label, children, ...rest }) => {
  return (
    <Stack direction="column" spacing={0.5} {...rest}>
      <Typography component="dt" variant="body2" fontWeight="bold" sx={{ color: 'gray.900', minWidth: '3rem' }}>
        {label}
      </Typography>
      <Typography component="dd" variant="body2" sx={{ color: 'gray.800', fontSize: 12 }}>
        {children}
      </Typography>
    </Stack>
  )
}

const PatientB2BCompanyInfo: React.FC<{ companyName?: string }> = ({ companyName }) => {
  const { t } = useTranslation()

  if (!companyName) return null

  return (
    <Box
      width={'100%'}
      sx={{
        bgcolor: 'blue.50',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 1,
        padding: 1,
      }}
    >
      <SuitcaseSimple />
      <Typography component="dd" variant="body2" sx={{ lineHeight: '18px', ml: 1, color: 'gray.800', fontSize: 12 }}>
        {t('common.patient.b2bCompanyInfo', { companyName })}
      </Typography>
    </Box>
  )
}

type PersonalInformationDetailsProps = {
  patient: Patient
}

export const PersonalInformationDetails: React.FC<PersonalInformationDetailsProps> = ({ patient }) => {
  const { t } = useTranslation()

  const patientSex = usePatientSexTranslationKeys(patient.sex)

  return (
    <Grid item style={{ flexGrow: 1 }}>
      <Card sx={{ p: 2 }}>
        <Stack spacing={1}>
          <Typography variant="subtitle1" component="h2" sx={{ mb: 1, fontSize: 16 }}>
            {t('pages.emr.personalInformation')}
          </Typography>

          <Box
            sx={{
              color: 'gray.800',
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridAutoFlow: 'row',
              columnGap: 1,
              rowGap: 1,
            }}
          >
            <PatientDescriptionItem data-testid="patient-cardoId" label={t('common.patient.id')}>
              {patient.cardoId}
            </PatientDescriptionItem>
            <PatientDescriptionItem data-testid="patient-sex" label={t('common.patient.sexLabel')}>
              {patientSex}
            </PatientDescriptionItem>
            <PatientDescriptionItem data-testid="patient-dateOfBirth" label={t('common.patient.age')}>
              {calculateAge(patient.dateOfBirth)}
            </PatientDescriptionItem>
            <PatientDescriptionItem label={t('common.patient.dob')}>
              {t('dates.long', { date: parseOpenEMRDate(patient.dateOfBirth) })}
            </PatientDescriptionItem>
          </Box>

          {patient.genderInfo ? (
            <Box mt="0.75rem">
              <PatientDescriptionItem label={t('common.patient.genderInfo')}>
                {patient.genderInfo}
              </PatientDescriptionItem>
            </Box>
          ) : null}

          {patient.holder ? (
            <Box p={1} bgcolor="blue.50" borderRadius={1}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <SvgIcon component={Baby} fontSize="inherit" inheritViewBox />
                <Typography fontSize={12}>
                  <Trans i18nKey="common.patient.dependantTo" values={{ holderName: patient.holder.legalName }}>
                    <Button
                      component={Link}
                      to={`/patients/${patient.holder.emrId}`}
                      sx={{
                        color: 'inherit',
                        display: 'inline',
                        fontSize: 12,
                        fontWeight: 'normal',
                        lineHeight: 'inherit',
                        p: 0,
                        textDecoration: 'underline',
                        verticalAlign: 'baseline',
                      }}
                    />
                  </Trans>
                </Typography>
              </Stack>
            </Box>
          ) : null}

          <PatientB2BCompanyInfo companyName={patient.b2bCompany?.companyName} />
        </Stack>
      </Card>
    </Grid>
  )
}
