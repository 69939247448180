import { format as F, formatDistanceStrict, setDefaultOptions } from 'date-fns'
import { enUS as enDateLocale, es as esDateLocale, ptBR as ptDateLocale } from 'date-fns/locale'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from './en/translation.json'
import es from './es/translation.json'
import pt from './pt/translation.json'

type Locales = Record<string, { name: string; display: string; translations: object }>

export const dateLocales: Record<string, Locale> = {
  en: enDateLocale,
  es: esDateLocale,
  pt: ptDateLocale,
  'en-US': enDateLocale,
  'es-MX': esDateLocale,
  'pt-BR': ptDateLocale,
}

export const OPEN_EMR_DATE_FORMAT = 'yyyy-MM-dd'

const I18N_DEBUG = import.meta.env.VITE_I18N_DEBUG === 'true'

const supportedLocales: Locales = {
  en: {
    name: 'English',
    display: '**English**',
    translations: en,
  },
  es: {
    name: 'Spanish',
    display: '**Spanish**',
    translations: es,
  },
  pt: {
    name: 'Portuguese',
    display: '**Portuguese**',
    translations: pt,
  },
} as const

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: I18N_DEBUG ? false : 'en',
    debug: I18N_DEBUG,
    resources: supportedLocales,
    defaultNS: 'translations',
    nonExplicitSupportedLngs: true,
    missingKeyNoValueFallbackToKey: I18N_DEBUG,
    interpolation: {
      escapeValue: false,
      format: (value, format = 'P', lng) => {
        if (!value) return ''

        if (format === 'price') {
          return Intl.NumberFormat(lng, {
            style: 'currency',
            currency: 'GBP',
          }).format(value)
        }

        if (format === 'relativeTime') {
          const [date, baseDate] = value
          return formatDistanceStrict(date, baseDate)
        }

        // else it's a date
        const d = new Date(value)
        const dateLocale = lng ? dateLocales[lng] : undefined

        return F(d, format, { locale: dateLocale })
      },
    },
    react: {
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
    },
  })

// ensure the document lang is kept up to date
i18n.on('languageChanged', lang => {
  setDefaultOptions({ locale: dateLocales[i18n.resolvedLanguage] })
  document.documentElement.setAttribute('lang', lang)
})

export default i18n
