import { useState } from 'react'

import { useAuth } from '@app/context/auth'
import { useConfig } from '@app/context/config'
import * as Auth from '@app/lib/auth'
import { SUCCESS, Success } from '@app/lib/success'

export type OnEmailPasswordSubmit = (input: {
  email: string
  password: string
}) => Promise<Success | { kind: 'max-session-reached'; maxSessionsToken: string }>
export type OnCodeSubmit = (input: {
  code: string
}) => Promise<Success | { kind: 'max-session-reached'; maxSessionsToken: string }>
export type OnForceLogin = (maxSessionsToken: string) => Promise<void>
export type OnResendCode = () => Promise<void>

export function useLogin() {
  const { config } = useConfig()
  const auth = useAuth()
  const [token, setToken] = useState('')
  const [maskedPhone, setMaskedPhone] = useState('')

  const onEmailAndPasswordSubmit: OnEmailPasswordSubmit = async input => {
    const resp = await Auth.login.callback(input.email, input.password)

    const mfaRquired = config.enableMfa || Auth.isMfaPending(resp.data?.accessToken)
    if (resp.kind === 'error') {
      if (!config.enableMfa && resp.status === 429 && resp.error.maxSessionsToken) {
        return { kind: 'max-session-reached', maxSessionsToken: resp.error.maxSessionsToken }
      }
      throw new Error('Error submitting email and password')
    } else {
      if (!mfaRquired && resp.data && resp.data.accessToken) {
        // Login completed since MFA is disabled
        auth.saveTokens(resp.data)
        return SUCCESS
      }

      if (!resp.data.accessToken || !resp.data.maskedPhone) {
        throw new Error('Error submitting email and password')
      }

      // Proceed with MFA code validation
      setToken(resp.data.accessToken)
      setMaskedPhone(resp.data.maskedPhone)
      return SUCCESS
    }
  }

  const onCodeSubmit: OnCodeSubmit = async input => {
    const resp = await Auth.login.mfa(input.code, token)
    if (resp.kind === 'error') {
      if (resp.status === 429 && resp.error.maxSessionsToken) {
        return { kind: 'max-session-reached', maxSessionsToken: resp.error.maxSessionsToken }
      }

      throw new Error('Failed to login')
    }

    auth.saveTokens(resp.data)
    return SUCCESS
  }

  const onForceLogin: OnForceLogin = async (maxSessionsToken: string) => {
    const resp = await Auth.login.forceLogin(maxSessionsToken)

    if (resp.kind === 'error') {
      console.error('🔴 forceLogin error:', resp.error)
      const errorMessage = typeof resp.error === 'string' ? resp.error : resp.error.message
      throw new Error(errorMessage)
    }

    auth.saveTokens(resp.data)
  }

  const onResendCode: OnResendCode = async () => {
    const { kind, data } = await Auth.mfa.resendViaSMS(token)
    if (kind === 'error' || !data?.accessToken) {
      throw new Error('Error resending token')
    }
    setToken(data.accessToken)
  }

  return {
    onEmailAndPasswordSubmit,
    onCodeSubmit,
    onResendCode,
    onForceLogin,
    maskedPhone,
    token,
  }
}
