import { useCallback, useEffect, useState } from 'react'
import useSwr from 'swr'

import fetcher from '@app/lib/fetcher'
import { FileInfo } from '@app/types'

const THIRTY_SECONDS = 30 * 1000

export function useSymptomsDocuments(id: number | null) {
  const [symptomsDocuments, setSymptomsDocuments] = useState<FileInfo[]>([])
  const [newUploadedDocuments, setNewUploadedDocuments] = useState<number>(0)
  const [newDocumentsHaveBeenLoaded, setNewDocumentsHaveBeenLoaded] = useState<boolean>(false)

  const key = id ? `/consultations/${id}/document` : null

  const { data, error } = useSwr<FileInfo[]>(key, {
    fetcher: fetcher.api,
    refreshInterval: THIRTY_SECONDS,
  })

  useEffect(() => {
    if (error) {
      console.error('🔴 Error getting symptoms documents', error)
    }
  }, [error])

  const newDocumentsHaveBeenSaw = useCallback(() => {
    setNewDocumentsHaveBeenLoaded(false)
  }, [])

  useEffect(() => {
    setSymptomsDocuments(_prevState => {
      if (!data) return []
      if (_prevState.length < data.length) {
        setNewUploadedDocuments(data.length - _prevState.length)
        setNewDocumentsHaveBeenLoaded(true)
        return [..._prevState, ...data.slice(_prevState.length - data.length)]
      } else {
        setNewUploadedDocuments(0)
        return _prevState
      }
    })
  }, [data])

  return {
    symptomsDocuments,
    newUploadedDocuments,
    isLoading: !error && !data,
    newDocumentsHaveBeenLoaded,
    newDocumentsHaveBeenSaw,
  }
}
