import { Avatar, Button, Box, Typography, MenuItem, Divider, Menu } from '@mui/material'
import { styled } from '@mui/material/styles'
import { User, Sliders, VideoCamera, Microphone, Phone, MicrophoneSlash } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { zeroPad } from 'react-countdown'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'

import { Card } from '@app/components/Card'
import { colors } from '@app/theme/color'
import { Patient } from '@app/types'

import { CallStatus } from './PhoneCall'

const CustomMenu = styled(Menu)({
  '& .MuiPaper-root': {
    backgroundColor: colors.blue['700'],
    color: 'white',
    borderRadius: '8px',
    marginTop: '30px',
    padding: '16px',
  },
})

const CustomButton = styled(Button)({
  boxShadow: 'none',
})

type PatientCallPlayerProps = {
  patient: { legalName: Patient['legalName'] }
  muted: boolean
  status: CallStatus
  minutes: number
  seconds: number
  onHangUp: VoidFunction
  onMute: VoidFunction
  onChangeInputDevice: (id: string) => void
  onChangeOutputDevice: (id: string) => void
}

export const PatientCallPlayer: React.FC<PatientCallPlayerProps> = ({
  patient,
  muted,
  status,
  onHangUp,
  onMute,
  minutes,
  seconds,
  onChangeInputDevice,
  onChangeOutputDevice,
}) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [inputDevices, setInputDevices] = useState<MediaDeviceInfo[]>([])
  const [outputDevices, setOutputDevices] = useState<MediaDeviceInfo[]>([])

  const openSettings = (e: { currentTarget: React.SetStateAction<HTMLElement | null> }): void => {
    setAnchorEl(e.currentTarget)
  }

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const input: MediaDeviceInfo[] = []
      const output: MediaDeviceInfo[] = []

      devices.forEach(d => {
        if (d.kind === 'audioinput') {
          input.push(d)
        } else if (d.kind === 'audiooutput') {
          output.push(d)
        }
      })

      setInputDevices(input)
      setOutputDevices(output)
    })
  }, [])

  const callStatusLabel = status ? t(`call.statuses.${status}`) : ''

  return (
    <Draggable>
      <Card
        sx={{
          backgroundColor: 'blue.800',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          maxWidth: '480px',
          position: 'fixed',
          right: 0,
          top: 0,
          margin: '50px 80px 0 0',
          zIndex: 2,
        }}
      >
        <Box display="flex" flexBasis="45%" alignItems="center">
          <Avatar sx={{ marginRight: 1.5, bgcolor: 'blue.600' }}>
            <User />
          </Avatar>
          <Box>
            <Typography>{patient.legalName}</Typography>
            <Typography fontWeight={700}>{callStatusLabel}</Typography>
            {status === CallStatus.answered ? (
              <Typography fontWeight={700}>
                {zeroPad(minutes)}:{zeroPad(seconds)}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box flexBasis="55%" display="flex" justifyContent="space-around">
          <Box display="flex" flexDirection="column" alignItems="center" data-testid="settings">
            <CustomButton
              disabled
              variant="contained"
              sx={{
                borderRadius: 8,
                backgroundColor: 'blue.700',
                minWidth: '8px',
                '&:hover': {
                  backgroundColor: 'blue.300',
                },
              }}
              onClick={openSettings}
            >
              <Sliders size={8} style={{ scale: '2' }} />
            </CustomButton>
            <Typography fontSize={10} marginTop="8px">
              {t('call.controls.settings')}
            </Typography>
          </Box>
          <CustomMenu open={!!anchorEl} anchorEl={anchorEl} onClick={() => setAnchorEl(null)}>
            <MenuItem disabled sx={{ color: 'blue.200' }}>
              {t('call.controls.microphone')}
            </MenuItem>
            {inputDevices.map(d => (
              <MenuItem key={d.deviceId} sx={{ fontSize: 12 }} onClick={() => onChangeInputDevice(d.deviceId)}>
                {d.label}
              </MenuItem>
            ))}
            <Divider />
            <MenuItem disabled sx={{ color: 'blue.200' }}>
              {t('call.controls.speaker')}
            </MenuItem>
            {outputDevices.map(d => (
              <MenuItem key={d.deviceId} sx={{ fontSize: 12 }} onClick={() => onChangeOutputDevice(d.deviceId)}>
                {d.label}
              </MenuItem>
            ))}
          </CustomMenu>
          <Box display="flex" flexDirection="column" alignItems="center" data-testid="video">
            <CustomButton
              disabled
              variant="contained"
              sx={{
                borderRadius: 8,
                backgroundColor: 'blue.700',
                minWidth: '8px',
                '&:hover': {
                  backgroundColor: 'blue.300',
                },
              }}
            >
              <VideoCamera size={8} style={{ scale: '2' }} />
            </CustomButton>
            <Typography fontSize={10} marginTop="8px">
              {t('call.controls.video')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" data-testid={muted ? 'unmute' : 'mute'}>
            <CustomButton
              disabled={status !== CallStatus.answered}
              variant="contained"
              sx={{
                borderRadius: 8,
                backgroundColor: 'blue.700',
                minWidth: '8px',
                '&:hover': {
                  backgroundColor: 'blue.300',
                },
              }}
              onClick={onMute}
            >
              {muted ? (
                <MicrophoneSlash size={8} style={{ scale: '2' }} />
              ) : (
                <Microphone size={8} style={{ scale: '2' }} />
              )}
            </CustomButton>
            <Typography fontSize={10} marginTop="8px">
              {muted ? t('call.controls.unmute') : t('call.controls.mute')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" data-testid="end-call">
            <CustomButton
              disabled={![CallStatus.answered, CallStatus.started, CallStatus.ringing].includes(status)}
              variant="contained"
              sx={{
                borderRadius: 8,
                backgroundColor: 'error.main',
                minWidth: '8px',
              }}
              onClick={onHangUp}
            >
              <Phone size={8} style={{ rotate: '135deg', scale: '2' }} />
            </CustomButton>
            <Typography fontSize={10} marginTop="8px">
              {t('call.controls.endCall')}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Draggable>
  )
}
