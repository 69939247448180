import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, SvgIcon } from '@mui/material'
import { CaretLeft } from 'phosphor-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { AppLoading } from '@app/components/AppLoading'
import { ActiveConsultation } from '@app/components/Consultation/ActiveConsultation'
import { CancelConsultationModal } from '@app/components/Consultation/common/CancelConsultationModal/CancelConsultationModal'
import { ScheduledConsultationDateAndDuration } from '@app/components/Consultation/common/ScheduledConsultationDateAndDuration'
import { ConsultationFeedbackAlerts } from '@app/components/ConsultationFeedback/ConsultationFeedbackAlerts'
import { ConsultationFeedbackMissingAlerts } from '@app/components/ConsultationFeedback/ConsultationFeedbackMissingAlert'
import { ConsultationFeedbackModal } from '@app/components/ConsultationFeedback/ConsultationFeedbackModal'
import { ErrorModal } from '@app/components/ErrorModal/ErrorModal'
import { Page } from '@app/components/Page'
import { useAuth } from '@app/context/auth'
import { useConsultationCancellation } from '@app/context/consultation-cancellation'
import { ConsultationFeedbackProvider } from '@app/context/consultation-feedback'
import { PatientFileStatus, PatientFileStatusProvider } from '@app/context/patient-file-status'
import { NewPrescriptionProvider } from '@app/context/use-new-prescriptions'
import { useConsultation } from '@app/hooks/use-consultation'
import { useHistoryPush } from '@app/hooks/use-history-push'
import { useHistoryReplace } from '@app/hooks/use-history-replace'
import { useUnloadDialog } from '@app/hooks/use-unload-dialog'
import { lock, unlock } from '@app/lib/patients'
import { ErrorCodes } from '@app/lib/request'
import { cancelConsultation } from '@app/lib/schedule'
import { ConsultationType } from '@app/types'
import { isAdHocConsultationLockedByMe } from '@app/util/lock'

/**
 * Consultation from the queue (on demand) or a scheduled consultation.
 */
export const ActiveConsultationPage: React.FC = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const previousLocationPathname: string | undefined = useLocation().state?.previousLocationPathname
  const { consultation, hasError, isLoading } = useConsultation(Number(id))
  const { profile } = useAuth()
  const [isLocked, setIsLocked] = useState(false)
  const [blockHistoryWarning, setBlockHistoryWarning] = useState(consultation?.type === ConsultationType.SCHEDULED)
  const [isCancellingConsultation, setIsCancellingConsultation] = useState(false)
  const [errorCancelling, setErrorCancelling] = useState(false)
  const { setShowCancellationModal } = useConsultationCancellation()

  const handleUnlock = useCallback(() => {
    if (!consultation) return

    unlock(consultation.patientId)
  }, [consultation?.patientId, isLocked])

  const previousUrl = useMemo(() => {
    if (!previousLocationPathname) {
      return '/'
    }

    return previousLocationPathname
  }, [consultation?.startTime, consultation?.type, previousLocationPathname])

  const handleCloseErrorModal = () => {
    setErrorCancelling(false)
  }

  const handleCancel = useCallback(async () => {
    if (!consultation) return

    try {
      await cancelConsultation(consultation.id)
    } catch (e) {
      setErrorCancelling(true)
    }
    handleUnlock()

    setShowCancellationModal(true)
    navigate(previousUrl)
  }, [consultation, handleUnlock])

  useEffect(() => {
    if (!consultation) return

    if (consultation.type === ConsultationType.ON_DEMAND) {
      const isLockedByMe = isAdHocConsultationLockedByMe(consultation, profile?.id)
      setIsLocked(!isLockedByMe)
    }

    if (consultation.type === ConsultationType.SCHEDULED) {
      lock(consultation.patientId).then(resp => {
        const isLockedByMe = resp.kind === 'success' || resp.error?.code === ErrorCodes.ALREADY_LOCKED
        return setIsLocked(!isLockedByMe)
      })

      return () => {
        handleUnlock()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultation?.patient.lockedById])

  useUnloadDialog(t('consultationFeedback.unloadFeedbackConfirmation'), !!consultation)

  useHistoryPush(t('consultationFeedback.unloadFeedbackConfirmation'), blockHistoryWarning && !isCancellingConsultation)

  useHistoryReplace(t('consultationFeedback.unloadFeedbackConfirmation'))

  return (
    <NewPrescriptionProvider>
      <Page>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mb={1.5}>
          <Button
            component={Link}
            to={previousUrl}
            startIcon={<SvgIcon component={CaretLeft} inheritViewBox size={24} />}
            sx={{ color: 'gray.900', p: 0, fontWeight: 700, fontSize: '1.25rem', mr: 3 }}
          >
            {consultation?.patient.legalName}
          </Button>
          {consultation &&
          consultation.type === ConsultationType.SCHEDULED &&
          consultation.startTime &&
          consultation.endTime ? (
            <>
              <ScheduledConsultationDateAndDuration startTime={consultation.startTime} endTime={consultation.endTime} />
              <LoadingButton
                variant={'outlined'}
                onClick={() => setIsCancellingConsultation(true)}
                size="medium"
                style={{ padding: '8px 16px', fontWeight: 700 }}
                data-test="consultation-cancel-button"
              >
                {t('pages.consultation.cancel')}
              </LoadingButton>
            </>
          ) : null}
        </Box>
        {hasError ? <Alert severity="error">{t('pages.consultation.error')}</Alert> : null}
        {isLoading ? <AppLoading /> : null}
        {!isLoading && consultation && profile ? (
          <PatientFileStatusProvider value={isLocked ? PatientFileStatus.LOCKED : PatientFileStatus.UNLOCKED}>
            <ConsultationFeedbackProvider consultationId={consultation.id}>
              <ActiveConsultation consultation={consultation} onBlockHistoryWarning={setBlockHistoryWarning} />
              <ConsultationFeedbackModal />
              <ConsultationFeedbackAlerts />
              <ConsultationFeedbackMissingAlerts />
            </ConsultationFeedbackProvider>
          </PatientFileStatusProvider>
        ) : null}

        <CancelConsultationModal
          isOpen={isCancellingConsultation}
          onConfirm={handleCancel}
          onClose={() => setIsCancellingConsultation(false)}
        />
        <ErrorModal
          isOpen={errorCancelling}
          handleClose={handleCloseErrorModal}
          title={t('pages.emr.cancelConsultation.errorModal.title')}
          description={t('pages.emr.cancelConsultation.errorModal.description')}
        />
      </Page>
    </NewPrescriptionProvider>
  )
}
