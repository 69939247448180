import { Box, BoxProps } from '@mui/material'
import * as React from 'react'

type Props = Omit<BoxProps, 'width' | 'height' | 'bgcolor' | 'borderRadius'> & {
  size: number
  backgroundColor: string
}

/**
 * A circle to place icons inside. Centers its children vertically and horizontally by default.
 */
export function Circle({ size, backgroundColor, children, ...rest }: React.PropsWithChildren<Props>) {
  return (
    <Box
      width={size}
      height={size}
      bgcolor={backgroundColor}
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      {children}
    </Box>
  )
}
