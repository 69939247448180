import { Box, FormHelperText, InputBase, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import React, { FC, useState, useEffect, useMemo, createRef, ChangeEvent, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'

export function isNum(n: string) {
  return !!n.trim() && !isNaN(+n)
}

interface CodeInputProps {
  count: number
  autoFocus?: boolean
  required?: boolean
  errorMessage?: string
  onChange?: (value: string) => void
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

export const CodeInput: FC<CodeInputProps> = ({
  count,
  autoFocus = false,
  errorMessage,
  required,
  onChange,
  onBlur,
}) => {
  const { t } = useTranslation()
  const [values, setValue] = useState<string[]>(Array(count).fill(''))
  const refs = useMemo(() => Array.from({ length: count }).map(() => createRef<HTMLInputElement>()), [count])

  useEffect(() => {
    if (autoFocus) {
      refs[0].current?.focus()
    }
  }, [refs, autoFocus])

  const handleKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Backspace' && index > 0) {
      if (values[index] === '') {
        refs[index - 1].current?.focus()
      }
    }
  }

  const handleChange = (index: number) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value
    const newValues = [...values]
    newValues.splice(index, 1, value)
    setValue(newValues)

    onChange && onChange(newValues.join(''))

    if (index < count - 1) {
      refs[index + 1].current?.focus()
      return
    }
  }

  const handlePaste = (index: number) => (e: React.ClipboardEvent) => {
    e.preventDefault()

    const lengthRemaining = count - index
    const pastedData = e.clipboardData.getData('text/plain').slice(0, lengthRemaining)
    const newValues = [...values]
    newValues.splice(index, lengthRemaining, ...pastedData)

    setValue(newValues)
    onChange && onChange(newValues.join(''))

    refs[count - 1].current?.focus()
  }

  return (
    <>
      <Box component="fieldset" border={0} p={0}>
        <Typography component="legend" sx={visuallyHidden}>
          {t('inputs.code.group')}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          {values.map((value, index) => {
            return (
              <InputBase
                key={`i_${index}`}
                type="text"
                inputRef={refs[index]}
                data-test={`mfa-input-${index}`}
                inputProps={{
                  maxLength: 1,
                  'aria-label': t('inputs.code.label', {
                    number: index + 1,
                  }),
                  'aria-required': required,
                  'aria-describedby': errorMessage ? 'code-error-message' : '',
                  sx: { textAlign: 'center', p: 0 },
                }}
                value={value}
                onChange={handleChange(index)}
                onBlur={onBlur}
                onKeyDown={handleKeyDown(index)}
                onPaste={handlePaste(index)}
                sx={{
                  ml: index === 0 ? 0 : 1,
                  flexShrink: 1,
                  height: 64,
                }}
              />
            )
          })}
        </Box>
      </Box>
      {errorMessage ? (
        <FormHelperText id="code-error-message" sx={{ ml: 0, mt: 1 }} error>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </>
  )
}

export default CodeInput
