import { Alert, Divider, Stack, SvgIcon, Typography } from '@mui/material'
import { ArrowClockwise } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Consultation } from '@app/types'

import { AppLoading } from '../AppLoading'

import { QueueConsultationCard } from './QueueConsultationCard'

type QueueConsultationListProps = {
  consultations: Consultation[] | null
  disabled?: boolean
  title?: string
  isLoading?: boolean
  hasAssigned?: boolean
  hasError?: boolean
  fetchedAt?: string
  highlightConsultationId?: number
  onRefresh: VoidFunction
}

export const QueueConsultationList: React.FC<QueueConsultationListProps> = ({
  consultations,
  title,
  isLoading,
  hasAssigned,
  hasError,
  fetchedAt,
  highlightConsultationId,
  onRefresh,
}) => {
  const { t } = useTranslation()

  if (!hasError && !isLoading && !consultations?.length) {
    return null
  }

  return (
    <Stack direction="column" spacing={3}>
      {title ? (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="body2" fontWeight="700" color="gray.700">
            {title}
          </Typography>
          <Divider sx={{ flex: 1 }} />
          {!!fetchedAt && (
            <>
              <SvgIcon component={ArrowClockwise} inheritViewBox aria-hidden="true" fontSize="inherit" />
              <Typography variant="body2" color="gray.700">
                {t('pages.queue.lastFetched', { time: t('dates.shortTime', { date: fetchedAt }) })}
              </Typography>
            </>
          )}
        </Stack>
      ) : null}
      {isLoading ? (
        <AppLoading />
      ) : hasError ? (
        <Alert sx={{ alignItems: 'center' }} severity="error">
          {t('pages.queue.error')}
        </Alert>
      ) : (
        <Stack component="ul" direction="column" spacing={2} sx={{ listStyleType: 'none', p: 0 }}>
          {consultations?.map(consultation => (
            <QueueConsultationCard
              key={`consultation-${consultation.id}`}
              component="li"
              disabled={hasAssigned}
              highlight={consultation.id === highlightConsultationId}
              onRefresh={onRefresh}
              {...consultation}
            />
          ))}
        </Stack>
      )}
    </Stack>
  )
}
