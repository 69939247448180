import { yupResolver } from '@hookform/resolvers/yup'
import { SvgIcon } from '@mui/material'
import React, { Fragment, useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { ReactComponent as Virus } from '@app/assets/healthicons/virus.svg'
import { useConsultationMedicalHistoryNonPathologicalState } from '@app/components/Consultation/state/use-consultation-medical-history-non-pathological-state'
import { DescriptionList } from '@app/components/EMR/common/DescriptionList'
import { MedicalHistoryEditableText } from '@app/components/EMR/common/MedicalHistoryEditableText'
import { NonPathologicalPersonalHistory } from '@app/types'

import { SMALL_MAX_SIZE, MEDIUM_MAX_SIZE } from '../const'

export function useMedicalHistoryNonPathologicalSchema() {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object({
        nonPathologicalPersonalHistory: yup.object({
          bloodType: yup
            .string()
            .trim()
            .nullable()
            .notRequired()
            .transform((curr, orig) => (orig === '' ? undefined : curr))
            .max(SMALL_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: SMALL_MAX_SIZE })),
          familyHistoryDiseases: yup
            .string()
            .trim()
            .nullable()
            .notRequired()
            .transform((curr, orig) => (orig === '' ? undefined : curr))
            .max(MEDIUM_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: MEDIUM_MAX_SIZE })),
          obstetricsAndGynecologicalHistory: yup
            .string()
            .trim()
            .nullable()
            .notRequired()
            .transform((curr, orig) => (orig === '' ? undefined : curr))
            .max(SMALL_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: SMALL_MAX_SIZE })),
          socialConditions: yup
            .string()
            .trim()
            .nullable()
            .notRequired()
            .transform((curr, orig) => (orig === '' ? undefined : curr))
            .max(MEDIUM_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: MEDIUM_MAX_SIZE })),
        }),
      }),
    [t]
  )
}

type MedicalHistoryNonPathologicalProps = {
  defaultValues?: NonPathologicalPersonalHistory
}

export const MedicalHistoryNonPathological: React.FC<MedicalHistoryNonPathologicalProps> = ({ defaultValues }) => {
  const { t } = useTranslation()
  const { setNonPathologicalState, setFormIsValid } = useConsultationMedicalHistoryNonPathologicalState()

  const nonPathologicalSchema = useMedicalHistoryNonPathologicalSchema()
  const methods = useForm<NonPathologicalPersonalHistory>({
    resolver: yupResolver(nonPathologicalSchema),
    defaultValues,
    mode: 'onTouched',
  })

  const formValues = useWatch({
    control: methods.control,
  })

  useEffect(() => {
    setNonPathologicalState(defaultValues)
    methods.trigger()
  }, [defaultValues])

  useEffect(() => {
    setFormIsValid(methods.formState.isValid)
  }, [methods.formState.isValid])

  useEffect(() => {
    if (methods.formState.isValidating || !methods.formState.isDirty || !methods.formState.isValid) return

    setNonPathologicalState(formValues)
  }, [formValues, methods.formState.isDirty, methods.formState.isValid, methods.formState.isValidating])

  return (
    <>
      <DescriptionList.Divider
        label={t('pages.emr.medicalHistory.nonPathologicalPersonalHistory.title')}
        icon={() => <SvgIcon component={Virus} inheritViewBox />}
      />

      <MedicalHistoryEditableText
        name={'familyHistoryDiseases'}
        key={'familyHistoryDiseases'}
        control={methods.control}
        setValue={methods.setValue}
      >
        {t('pages.emr.medicalHistory.nonPathologicalPersonalHistory.familyHistoryDiseases')}
      </MedicalHistoryEditableText>

      <MedicalHistoryEditableText
        name={'socialConditions'}
        key={'socialConditions'}
        control={methods.control}
        setValue={methods.setValue}
      >
        {t('pages.emr.medicalHistory.nonPathologicalPersonalHistory.socialConditions')}
      </MedicalHistoryEditableText>

      <MedicalHistoryEditableText
        name={'bloodType'}
        key={'bloodType'}
        control={methods.control}
        setValue={methods.setValue}
      >
        {t('pages.emr.medicalHistory.nonPathologicalPersonalHistory.bloodType')}
      </MedicalHistoryEditableText>

      <MedicalHistoryEditableText
        name={'obstetricsAndGynecologicalHistory'}
        key={'obstetricsAndGynecologicalHistory'}
        control={methods.control}
        setValue={methods.setValue}
      >
        {t('pages.emr.medicalHistory.nonPathologicalPersonalHistory.obstetricsAndGynecologicalHistory')}
      </MedicalHistoryEditableText>
    </>
  )
}
