import { useCallback } from 'react'
import { proxy, subscribe } from 'valtio'

import { useConsultationAdditionalInformationState } from '@app/components/Consultation/state/use-consultation-additional-information-state'
import { useConsultationJournalState } from '@app/components/Consultation/state/use-consultation-journal-state'
import { useConsultationMedicalHistoryState } from '@app/components/Consultation/state/use-consultation-medical-history-state'
import { useConsultationPastPrescriptionsState } from '@app/components/Consultation/state/use-consultation-past-presscriptions-state'
import { useConsultationPrescriptionsState } from '@app/components/Consultation/state/use-consultation-prescriptions-state'
import { useConsultationSensitiveNotesState } from '@app/components/Consultation/state/use-consultation-sensitive-notes-state'
import { JournalData } from '@app/types'

interface State {
  journalData: JournalData
  formStatus: {
    isValid: boolean
  }
}

const emptyJournal: JournalData = {
  additionalInformation: {
    curp: undefined,
    address: undefined,
    weight: undefined,
    height: undefined,
  },
  medicalHistory: {
    allergies: { drugAllergies: [], hypersensitivities: undefined, otherWarning: undefined },
    nonPathologicalPersonalHistory: {
      familyHistoryDiseases: undefined,
      socialConditions: undefined,
      bloodType: undefined,
      obstetricsAndGynecologicalHistory: undefined,
    },
    pathologicalPersonalHistory: {
      previousDiseases: [],
      previousMedication: [],
      previousSurgeries: [],
      currentDiseases: [],
      currentMedication: [],
      currentSurgeries: [],
    },
  },
  journal: {
    currentSituation: undefined,
    status: undefined,
    assessment: undefined,
    diagnosis: [],
    plannedActions: undefined,
  },
  sensitiveNotes: undefined,
  prescriptions: [],
  pastPrescriptions: [],
}

const state = proxy<State>({
  journalData: {
    prescriptions: [],
  },
  formStatus: {
    isValid: true,
  },
})

const initialiseState = () => {
  state.journalData = { ...emptyJournal }
  state.formStatus.isValid = true
}

export const useConsultationState = () => {
  const { state: journalState } = useConsultationJournalState()
  const { state: sensitiveNotesState } = useConsultationSensitiveNotesState()
  const { state: additionalInformationState } = useConsultationAdditionalInformationState()
  const { state: prescriptionsState } = useConsultationPrescriptionsState()
  const { state: pastPrescriptionsState } = useConsultationPastPrescriptionsState()
  const { state: medicalHistoryState, subscribeAllStates: subscribeMedicalState } = useConsultationMedicalHistoryState()

  const isFormValid = useCallback(() => {
    return (
      medicalHistoryState.formStatus.isValid &&
      journalState.formStatus.isValid &&
      sensitiveNotesState.formStatus.isValid &&
      additionalInformationState.formStatus.isValid
    )
  }, [
    additionalInformationState.formStatus.isValid,
    journalState.formStatus.isValid,
    medicalHistoryState.formStatus.isValid,
    sensitiveNotesState.formStatus.isValid,
  ])

  const subscribeAllStates = useCallback(() => {
    const unsubscribeJournalState = subscribe(journalState, () => {
      state.journalData.journal = journalState.journal
      state.formStatus.isValid = isFormValid()
    })

    const unsubscribeSensitiveNotesState = subscribe(sensitiveNotesState, () => {
      state.journalData.sensitiveNotes = sensitiveNotesState.sensitiveNotes
      state.formStatus.isValid = isFormValid()
    })

    const unsubscribeAdditionalInformationState = subscribe(additionalInformationState, () => {
      state.journalData.additionalInformation = additionalInformationState.additionalInformation
      state.formStatus.isValid = isFormValid()
    })

    const unsubscribePrescriptionsState = subscribe(prescriptionsState, () => {
      state.journalData.prescriptions = prescriptionsState.prescriptions
      state.formStatus.isValid = isFormValid()
    })

    const unsubscribePastPrescriptionsState = subscribe(pastPrescriptionsState, () => {
      state.journalData.pastPrescriptions = pastPrescriptionsState.pastPrescriptions
      state.formStatus.isValid = isFormValid()
    })

    const unsubscribeMedicalHistoryState = subscribeMedicalState(() => {
      state.journalData.medicalHistory = medicalHistoryState.medicalHistory
      state.formStatus.isValid = isFormValid()
    })

    return () => {
      unsubscribeMedicalHistoryState()
      unsubscribeJournalState()
      unsubscribeSensitiveNotesState()
      unsubscribeAdditionalInformationState()
      unsubscribePrescriptionsState()
      unsubscribePastPrescriptionsState()
    }
  }, [])

  return { state, initialiseState, isFormValid, subscribeAllStates }
}
