import React from 'react'

export enum MonitoringTab {
  ON_DEMAND_CONSULTATIONS = 'ON_DEMAND_CONSULTATIONS',
  SCHEDULED_CONSULTATIONS = 'SCHEDULED_CONSULTATIONS',
}

export enum MonitoringTable {
  CONSULTATIONS = 'CONSULTATIONS',
  CLINICIANS = 'CLINICIANS',
}

type ContextType = {
  tab: MonitoringTab
  table: MonitoringTable
  setTab: React.Dispatch<React.SetStateAction<MonitoringTab>>
  setTable: React.Dispatch<React.SetStateAction<MonitoringTable>>
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
}

const MonitoringContext = React.createContext<ContextType | undefined>(undefined)

export const MonitoringProvider = ({ children }: { children: React.ReactNode }) => {
  const [tab, setTab] = React.useState<MonitoringTab>(MonitoringTab.ON_DEMAND_CONSULTATIONS)
  const [table, setTable] = React.useState<MonitoringTable>(MonitoringTable.CONSULTATIONS)
  const [searchValue, setSearchValue] = React.useState('')
  const [date, setDate] = React.useState(new Date())
  return (
    <MonitoringContext.Provider
      value={{
        tab,
        table,
        setTab,
        setTable,
        date,
        setDate,
        searchValue,
        setSearchValue,
      }}
    >
      {children}
    </MonitoringContext.Provider>
  )
}

export const useMonitoring = () => {
  const context = React.useContext(MonitoringContext)
  if (!context) {
    throw new Error('useCalendarTimeFocus must be used within a CalendarTimeFocusProvider')
  }
  return context
}
