import { Alert, Grid, ListItem, ListItemButton, SvgIcon, Typography } from '@mui/material'
import { XCircle } from 'phosphor-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useConfig } from '@app/context/config'
import { useErraticPatients } from '@app/context/erratic-patients'
import { usePatientSexTranslationKeys } from '@app/hooks/use-patient-sex-translation-keys'
import { EMRPatient } from '@app/types'
import { parseOpenEMRDate } from '@app/util/date'

type SearchPatientRowProps = EMRPatient

function getButtonStyles(error: boolean) {
  if (error) {
    return { borderColor: 'error.main', borderWidth: 1, borderStyle: 'solid', pb: 0 }
  }

  return { px: 3, py: 4 }
}

export const SearchPatientRow: React.FC<SearchPatientRowProps> = item => {
  const emptyItemPlaceholder = '-'

  const { t } = useTranslation()
  const { config } = useConfig()
  const navigate = useNavigate()
  const patientSex = usePatientSexTranslationKeys(item.sex)
  const { patientIds } = useErraticPatients()
  const isErratic = patientIds.includes(item.id)

  const itemDate = useMemo(() => {
    const dateText = item.dateOfBirth ? parseOpenEMRDate(item.dateOfBirth) : undefined
    return dateText ? t('dates.long', { date: dateText }) : emptyItemPlaceholder
  }, [item.dateOfBirth])

  // We should somehow be able to decide this, but either we choose an existing partner config
  // variable that is as close as possible to the desired semantics or we introduce one more
  // partner config variable to control the visibility of the CPF column
  const showMigratedMessage = !!config.partnerConfig.isMemed
  const shouldDisplayCpf = !!config.partnerConfig.cpfValidationEnabled
  const showErrorAlert = isErratic || item.blocked

  const buttonStyles = getButtonStyles(showErrorAlert)

  const patientErrorMessage = isErratic
    ? t('pages.consultation.modal.signAndShareError')
    : showMigratedMessage
    ? t('pages.consultation.modal.migrationBlockedPatient')
    : t('pages.consultation.modal.blockedPatient')

  function navigateToPatientDetail(id: string) {
    navigate(`/patients/${id}`, { state: { previousLocationPathname: '/patients' } })
  }

  const alertStyles = {
    width: '100%',
    backgroundColor: '#FEF2F2',
    borderRadius: 0,
    color: 'error.main',
    mt: 4,
    py: 0,
    fontWeight: 'bold',
    border: 'none',
  }

  return (
    <ListItem
      component="div"
      role="row"
      key={item.emrId}
      disablePadding
      sx={{ mt: 2 }}
      data-testid="search-results-item"
      data-test="search-results-item"
    >
      <ListItemButton
        sx={{ bgcolor: 'white', px: 0, py: 4, flexDirection: 'column', overflow: 'hidden', ...buttonStyles }}
        onClick={() => navigateToPatientDetail(item.emrId)}
      >
        <Grid alignItems="center" container spacing={2} sx={{ px: 3 }}>
          <Grid role="cell" item xs>
            <Typography fontWeight="bold" data-test="search-results-legalname">
              {item.legalName || emptyItemPlaceholder}
            </Typography>
          </Grid>

          <Grid role="cell" item xs>
            <Typography>{patientSex || emptyItemPlaceholder}</Typography>
          </Grid>

          <Grid role="cell" item xs>
            <Typography data-test="search-results-dob">{itemDate}</Typography>
          </Grid>

          <Grid role="cell" item xs>
            <Typography>{item.emrId}</Typography>
          </Grid>

          <Grid role="cell" item xs>
            <Typography data-test="search-results-phone">{item.phone || emptyItemPlaceholder}</Typography>
          </Grid>

          <Grid role="cell" item xs>
            <Typography data-test="search-results-email">{item.email || emptyItemPlaceholder}</Typography>
          </Grid>

          {shouldDisplayCpf ? (
            <Grid role="cell" item xs>
              <Typography data-test="search-results-cpf">{item.nationalId || emptyItemPlaceholder}</Typography>
            </Grid>
          ) : undefined}
        </Grid>

        {showErrorAlert ? (
          <Alert severity="error" icon={<SvgIcon component={XCircle} inheritViewBox />} sx={alertStyles}>
            {patientErrorMessage}
          </Alert>
        ) : null}
      </ListItemButton>
    </ListItem>
  )
}
