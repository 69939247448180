import { Diagnosis, JournalData, JournalState } from '@app/types'

export const sanitizeJournalTextField = (text: string | null | undefined): string | undefined => {
  return text === null || text === undefined || text === '' ? undefined : text
}

export const sanitizeJournalDiagnosis = (diagnosis?: Diagnosis[] | Diagnosis | null): Diagnosis[] | undefined => {
  if (diagnosis === null || diagnosis === undefined) {
    return undefined
  }

  if (Array.isArray(diagnosis) && (diagnosis?.length || 0) === 0) {
    return undefined
  }

  if (!Array.isArray(diagnosis)) {
    return [diagnosis]
  }

  return diagnosis
}

const sanitizePrescriptionDiagnosis = (diagnosis?: Diagnosis[] | Diagnosis): Diagnosis | undefined => {
  const sanitized = sanitizeJournalDiagnosis(diagnosis)

  return Array.isArray(sanitized) ? sanitized[0] : sanitized
}

export const sanitizeJournalData = (journal?: JournalState): JournalState | undefined => {
  return (
    journal && {
      currentSituation: sanitizeJournalTextField(journal.currentSituation),
      status: sanitizeJournalTextField(journal.status),
      assessment: sanitizeJournalTextField(journal.assessment),
      plannedActions: sanitizeJournalTextField(journal.plannedActions),
      diagnosis: sanitizeJournalDiagnosis(journal.diagnosis),
    }
  )
}

export const sanitizeJournalPrescriptions = (
  prescriptions: JournalData['prescriptions']
): JournalData['prescriptions'] => {
  if (!Array.isArray(prescriptions) || (prescriptions?.length || 0) === 0) {
    return []
  }

  return prescriptions.map(prescription => {
    const { id, label } = prescription.medication

    return {
      ...prescription,
      medication: { id, label },
      diagnosis: sanitizePrescriptionDiagnosis(prescription.diagnosis),
    }
  })
}
