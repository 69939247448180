import { useMemo } from 'react'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'

type UseAllergyListReturnType = { id: string; label: string }[]

export function useAllergyList(allergySearch: string): UseAllergyListReturnType {
  const queryParams = new URLSearchParams({
    search: allergySearch,
  })
  const { data: allergies } = useSWR<{ name: string }[]>(`/allergy?${queryParams}`, {
    fetcher: fetcher.api,
  })

  const formattedAllergies = useMemo(() => {
    return allergies?.map(({ name }) => ({ id: name, label: name }))
  }, [allergies])

  return formattedAllergies || []
}
