import { proxy } from 'valtio'

import { NonPathologicalPersonalHistory } from '@app/types'

interface State {
  nonPathologicalPersonalHistory?: NonPathologicalPersonalHistory
  formStatus: {
    isValid: boolean
  }
}

const state = proxy<State>({
  nonPathologicalPersonalHistory: {
    familyHistoryDiseases: undefined,
    socialConditions: undefined,
    bloodType: undefined,
    obstetricsAndGynecologicalHistory: undefined,
  },
  formStatus: {
    isValid: true,
  },
})

const setNonPathologicalState = (newState?: NonPathologicalPersonalHistory) => {
  state.nonPathologicalPersonalHistory = { ...state.nonPathologicalPersonalHistory, ...newState }
}

const setFormIsValid = (isValid: boolean) => {
  state.formStatus.isValid = isValid
}

export const useConsultationMedicalHistoryNonPathologicalState = () => {
  return {
    state,
    setNonPathologicalState,
    setFormIsValid,
  }
}
