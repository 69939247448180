import { useMemo } from 'react'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'
import { revalidateDataConfig } from '@app/lib/request'

const REFRESH_INTERVAL = 1000 * 15 // 15 seconds

export interface ClinicianResponse {
  id: number
  firstName: string | null
  lastName: string | null
  username: string
  phone: string
  categories: { name: string }[]
  clinicianData: {
    specialtyAndSubSpecialtyTitle: string
  }
  isOnline: boolean
  assignedPatient?: {
    id: number
    cardoId: string
  }
}

export interface CliniciansResponse {
  clinicians: ClinicianResponse[]
}

export function useClinicians() {
  const { data, error, isValidating } = useSWR<CliniciansResponse>(`/clinicians`, {
    fetcher: fetcher.api,
    refreshInterval: REFRESH_INTERVAL,
    ...revalidateDataConfig,
  })

  const cliniciansOnline = useMemo(() => {
    return data?.clinicians.filter(({ isOnline }) => isOnline) ?? []
  }, [data])

  const cliniciansCategories = useMemo(() => {
    return cliniciansOnline.reduce((categories: Record<string, number>, clinician) => {
      clinician.categories.forEach(({ name }) => {
        categories[name] = (categories[name] || 0) + 1
      })
      return categories
    }, {})
  }, [cliniciansOnline])

  return {
    hasError: !!error,
    isLoading: (!error && !data) || isValidating,
    data,
    clinicians: data?.clinicians,
    totalCliniciansOnline: cliniciansOnline.length,
    cliniciansCategories,
  }
}
