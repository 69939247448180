import { Stack, Typography } from '@mui/material'
import * as React from 'react'

interface Props {
  text: string
  backgroundColor: string
  icon: React.ReactElement
}

export function Pill({ text, backgroundColor, icon }: Props) {
  return (
    <Stack
      // Make pill width be it's content, instead of stretching to fill the container's width
      display="inline-flex"
      direction="row"
      alignItems="center"
      gap="8px"
      sx={{
        padding: '4px 8px',
        borderRadius: '8px',
        backgroundColor,
      }}
    >
      {icon}
      <Typography variant="body2">{text}</Typography>
    </Stack>
  )
}
