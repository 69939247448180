import { Box, Stack } from '@mui/material'
import * as React from 'react'

import { colors } from '@app/theme/color'

interface InfoBoxProps {
  caption: React.ReactElement
  title: string
  subTitles: string
}

export const InfoCard = (props: InfoBoxProps) => {
  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        width: '280px',
        height: '138px',
        padding: '16px',
        border: '1px solid rgba(44, 44, 44, 0.1)',
        borderRadius: '8px',
      }}
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
      data-testid="info-card"
    >
      <Box
        sx={{
          width: '40px',
          height: '40px',
          background: colors.blue[50],
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {props.caption}
      </Box>
      <Box>
        <Box
          sx={{
            fontWeight: '700',
            fontSize: '16px',
          }}
        >
          {props.title}
        </Box>
        <Box
          sx={{
            color: 'gray.700',
            fontWeight: '400',
            fontSize: '12px',
          }}
        >
          {props.subTitles}
        </Box>
      </Box>
    </Stack>
  )
}
