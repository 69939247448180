import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

const WORD_MIN_SIZE = 2
const SMALL_MAX_SIZE = 255

export function useMedicalHistorySelectSchema() {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup
        .string()
        .trim()
        .transform((curr, orig) => (orig === '' ? undefined : curr))
        .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
        .max(SMALL_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: SMALL_MAX_SIZE })),
    [t]
  )
}
