import { Box, Button, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import ErrorImage from '@app/assets/images/error.png'

import { Page } from '../Page'

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT

const SENTRY_TAG_BOUNDARY_KEY = 'errorBoundary'
const SENTRY_TAG_BOUNDARY_VALUE_INNER = 'inner'
const SENTRY_TAG_BOUNDARY_VALUE_OUTER = 'outer'

interface ErrorBoundaryProps {
  title: string
  subtitle: string
  resetButton: string
  /** Tag used to tell which error boundary the error occurred from. */
  boundaryTag: string
  children: ReactNode
}

function ErrorBoundary({ title, subtitle, resetButton, boundaryTag, children }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ componentStack, resetError }) => (
        <ErrorBoundaryContent
          title={title}
          subtitle={subtitle}
          resetButton={resetButton}
          stack={componentStack}
          onReset={resetError}
        />
      )}
      beforeCapture={scope => {
        scope.setTag(SENTRY_TAG_BOUNDARY_KEY, boundaryTag)
      }}
      showDialog={ENVIRONMENT === 'dev' || ENVIRONMENT === 'staging'}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export function InnerErrorBoundary({ children }: { children: ReactNode }) {
  const { t } = useTranslation()
  return (
    <ErrorBoundary
      title={t('errorScreen.inner.title')}
      subtitle={t('errorScreen.inner.subtitle')}
      resetButton={t('errorScreen.inner.button')}
      boundaryTag={SENTRY_TAG_BOUNDARY_VALUE_INNER}
    >
      {children}
    </ErrorBoundary>
  )
}

export function OuterErrorBoundary({ children }: { children: ReactNode }) {
  const { t } = useTranslation()
  return (
    <ErrorBoundary
      title={t('errorScreen.outer.title')}
      subtitle={t('errorScreen.outer.subtitle')}
      resetButton={t('errorScreen.outer.button')}
      boundaryTag={SENTRY_TAG_BOUNDARY_VALUE_OUTER}
    >
      {children}
    </ErrorBoundary>
  )
}

interface ErrorBoundaryContentProps {
  title: string
  subtitle: string
  resetButton: string
  stack: string
  onReset: () => void
}

function ErrorBoundaryContent({ title, subtitle, resetButton, stack, onReset }: ErrorBoundaryContentProps) {
  const { t } = useTranslation()

  return (
    <Page>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src={ErrorImage} alt={t('errorScreen.inner.title')} width={200} style={{ alignSelf: 'center' }} />
        <Typography variant="subtitle1" fontWeight={700} color="gray.700" mt={2}>
          {title}
        </Typography>
        <Typography variant="body2" color="gray.700" mt={0.5} mb={2}>
          {subtitle}
        </Typography>
        <Button variant="contained" disableElevation sx={{ fontWeight: '700' }} onClick={onReset}>
          {resetButton}
        </Button>
        <FormattedErrorStack stack={stack} />
      </Box>
    </Page>
  )
}

function FormattedErrorStack({ stack }: { stack: string }) {
  if (!import.meta.env.DEV) return null

  return (
    <Box sx={{ mt: 2 }}>
      <pre style={{ fontSize: 'smaller' }}>{stack}</pre>
      <small>
        <i>This error is not visible on production.</i>
      </small>
    </Box>
  )
}
