// import OT from '@opentok/client'
import { alpha, Avatar, Box, IconButton, SvgIcon, Typography } from '@mui/material'
import {
  ArrowsInSimple,
  ArrowsOut,
  Info,
  Microphone,
  MicrophoneSlash,
  PhoneDisconnect,
  VideoCamera,
  VideoCameraSlash,
} from 'phosphor-react'
import React, { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'
import { Resizable, ResizeCallbackData } from 'react-resizable'

import { DropdownButton } from '@app/components/TalkToPatient/DropdownButton'
import { useAuth } from '@app/context/auth'
import { useConsultationFeedback } from '@app/context/consultation-feedback'
import { useNotifications } from '@app/context/notifications'
import { useOpenTok } from '@app/hooks/use-opentok'
import {
  NetworkDisconnectedEvent,
  OpenTokEvent,
  SessionDisconnectReason,
  useOpenTokEvent,
} from '@app/hooks/use-opentok/use-opentok-event'
import { dispatchConsultationAction } from '@app/lib/consultation'
import { createVideoCall, getVideoCallToken } from '@app/lib/videoCalls'
import { colors } from '@app/theme/color'
import { ActionType, CallType, Consultation } from '@app/types'

import 'react-resizable/css/styles.css'

interface VideoCallProps {
  data: {
    consultation: {
      id: Consultation['id']
    }
    patient: {
      id: Consultation['patientId']
      legalName: Consultation['patient']['firstName']
    }
  }
  onDropdownClick?: VoidFunction
}

export const VideoCall: React.FC<VideoCallProps> = ({ data, onDropdownClick }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const defaultVideoBoxDimensions = {
    width: 400,
    height: 300,
  }

  const [videoBoxDimensions, setVideoBoxDimensions] = useState<{ width: number; height: number }>(
    defaultVideoBoxDimensions
  )

  const { addNotification } = useNotifications()
  const { t } = useTranslation()
  const { setCallType, setIsModalOpened } = useConsultationFeedback()

  const consultationId = data.consultation.id
  const videoBoxRef = useRef<HTMLDivElement>()

  const {
    isConnected,
    isConnecting,
    stream,
    publisher,
    connect,
    disconnect,
    session,
    subscribe,
    subscriber,
    publish,
    unpublish,
    setIsConnecting,
    toggleAudioEnabled,
    toggleVideoEnabled,
    unsubscribe,
  } = useOpenTok()

  const auth = useAuth()

  useOpenTokEvent(
    OpenTokEvent.SESSION_CONNECTED,
    () => {
      setCallType(CallType.VIDEO_CALL)
      dispatchConsultationAction(consultationId, { type: ActionType.VIDEO_CALL_STARTED })
    },
    session
  )

  useOpenTokEvent(
    OpenTokEvent.SESSION_DISCONNECTED,
    (event: NetworkDisconnectedEvent) => {
      if (event?.reason === SessionDisconnectReason.NETWORK_DISCONNECTED) {
        addNotification({
          autoHide: false,
          icon: Info,
          message: t('consultationFeedback.alertMessages.connectionIssueWarning'),
          severity: 'warning',
        })
        return dispatchConsultationAction(consultationId, { type: ActionType.VIDEO_CALL_FAILED })
      }
      if (event?.reason === SessionDisconnectReason.CLIENT_DISCONNECTED) {
        dispatchConsultationAction(consultationId, { type: ActionType.VIDEO_CALL_ENDED })
        return setIsModalOpened(true)
      }
    },
    session
  )

  useOpenTokEvent(
    OpenTokEvent.CONNECTION_DESTROYED,
    () => {
      handleDisconnect()
    },
    session
  )

  async function handleStartVideoCall() {
    if (!auth.profile?.id) {
      return addNotification({ message: t('videoCall.error') })
    }

    try {
      setIsConnecting(true)

      const { data: videoCallData } = await createVideoCall({
        consultationId,
        clinicianId: auth.profile.id,
        patientId: data.patient.id,
      })

      if (!videoCallData) {
        return addNotification({ message: t('videoCall.error'), severity: 'error' })
      }

      const { data: videoCallTokenData } = await getVideoCallToken(videoCallData.id)

      if (!videoCallTokenData) {
        return addNotification({ message: t('videoCall.error'), severity: 'error' })
      }

      await connect({ sessionId: videoCallTokenData.sessionId, token: videoCallTokenData.token })
    } catch (error) {
      setIsConnecting(false)
    }
  }

  function handleToggleFullScreen() {
    setIsExpanded(!isExpanded)
  }

  function handleResize(_: unknown, { size }: ResizeCallbackData) {
    if (isExpanded) return
    setVideoBoxDimensions(size)
  }

  function handleDisconnect() {
    if (publisher) {
      unpublish()
    }
    if (subscriber) {
      unsubscribe()
    }
    disconnect()
  }

  useEffect(() => {
    if (isConnected && auth.profile) {
      publish({ name: `${auth.profile?.firstName} ${auth.profile?.lastName}`.trim() })
    }
  }, [isConnected, publish, auth])

  useEffect(() => {
    if (subscriber) return
    if (stream) {
      subscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream]) // Do not add `subscriber` as a dependency, otherwise it creates a race condition with `handleDisconnect`

  return (
    <Box position="relative">
      <DropdownButton
        onButtonClick={handleStartVideoCall}
        onDropdownClick={onDropdownClick}
        disabled={isConnecting || isConnected}
      >
        <SvgIcon aria-hidden="true" weight="fill" component={VideoCamera} size={24} inheritViewBox sx={{ mr: 1 }} />
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {isConnected && stream
            ? t('videoCall.callInProgress')
            : isConnecting || isConnected
            ? t('videoCall.calling')
            : t('videoCall.start')}
        </Typography>
      </DropdownButton>

      {isConnected && (
        <Draggable
          disabled={isExpanded}
          cancel=".react-resizable-handle"
          position={isExpanded ? { x: 0, y: 0 } : undefined}
        >
          <Resizable
            width={videoBoxDimensions.width}
            height={videoBoxDimensions.height}
            onResize={handleResize}
            minConstraints={[defaultVideoBoxDimensions.width, defaultVideoBoxDimensions.height]}
            handle={null}
          >
            <Box
              ref={videoBoxRef}
              sx={{
                backgroundColor: 'blue.800',
                p: 2,
                borderRadius: 2,
                width: isExpanded ? '100%' : videoBoxDimensions.width,
                height: isExpanded ? '100%' : videoBoxDimensions.height || 'auto',
                position: 'fixed',
                zIndex: 9999,
                left: isExpanded ? 0 : '50%',
                top: isExpanded ? 0 : '92px', // Top aligned with the `Start video call` button
                overflow: 'hidden',
                ...(isExpanded
                  ? {}
                  : {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }),
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  ...(isExpanded
                    ? {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        p: 2,
                        zIndex: 999,
                      }
                    : {}),
                }}
                data-testid="video-call-box"
              >
                <Typography sx={{ color: 'gray.50' }}>{data.patient.legalName}</Typography>
                <Box sx={{ display: 'flex' }}>
                  <IconButton
                    aria-label={t(`videoCall.${isExpanded ? 'collapse' : 'expand'}`)}
                    onClick={handleToggleFullScreen}
                    sx={{ backgroundColor: 'blue.700', ml: 1 }}
                  >
                    <SvgIcon
                      aria-hidden="true"
                      component={isExpanded ? ArrowsInSimple : ArrowsOut}
                      size={24}
                      inheritViewBox
                      sx={{ color: 'white' }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  ...(isExpanded
                    ? {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        mt: 0,
                      }
                    : { flex: 1, pt: 2 }),
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'blue.700',
                    height: '100%',
                    width: '100%',
                    borderRadius: 2,
                    position: 'relative',
                    flex: 1,
                  }}
                >
                  {!stream && (
                    <Typography
                      sx={{
                        color: 'gray.50',
                        textAlign: 'center',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 2,
                      }}
                    >
                      {t('videoCall.waitingFor', {
                        name: data.patient.legalName,
                      })}
                    </Typography>
                  )}
                  <Box sx={{ width: '100%', height: '100%', borderRadius: 2, overflow: 'hidden' }}>
                    {stream && !stream.hasVideo && (
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'blue.100',
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: 'blue.200',
                            p: 5,
                            borderWidth: 1,
                            borderColor: 'blue.500',
                            borderStyle: 'solid',
                          }}
                        >
                          <Typography color="blue.500" fontSize="large" fontWeight="bold">
                            {data.patient.legalName[0]}
                            {data.patient.legalName.split(' ')[1]?.[0]}
                          </Typography>
                        </Avatar>
                      </Box>
                    )}
                    <Box
                      id="subscriber"
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 2,
                        overflow: 'hidden',
                        display: stream?.hasVideo ? 'block' : 'none',
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: 'blue.700',
                    height: '100%',
                    width: '100%',
                    flex: 1,
                    borderRadius: 2,
                    ml: 2,
                    ...(isExpanded
                      ? {
                          position: 'absolute',
                          bottom: 80,
                          right: 8,
                          width: 90,
                          height: 90,
                          mt: 0,
                        }
                      : {}),
                  }}
                >
                  {!publisher?.stream?.hasVideo && (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'blue.100',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        borderRadius: 2,
                        ...(isExpanded
                          ? {
                              borderColor: 'blue.500',
                              borderStyle: 'solid',
                              borderWidth: 1,
                            }
                          : {}),
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: 'blue.200',
                          p: 5,
                          borderWidth: 1,
                          borderColor: 'blue.500',
                          borderStyle: 'solid',
                        }}
                      >
                        <Typography color="blue.500" fontSize="large" fontWeight="bold">
                          {auth.profile?.firstName[0]}
                          {auth.profile?.lastName[0]}
                        </Typography>
                      </Avatar>
                    </Box>
                  )}
                  <Box
                    id="publisher"
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: 2,
                      overflow: 'hidden',
                      display: publisher?.stream?.hasVideo ? 'block' : 'none',
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  ...(isExpanded
                    ? {
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        p: 2,
                        backgroundColor: alpha(colors.blue[800], 0.8),
                      }
                    : {}),
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <IconButton
                    aria-label={t(`videoCall.${publisher?.stream?.hasAudio ? 'mute' : 'unmute'}`)}
                    onClick={() => toggleAudioEnabled()}
                    sx={{ backgroundColor: publisher?.stream?.hasAudio ? 'blue.500' : 'blue.700' }}
                  >
                    <SvgIcon
                      aria-hidden="true"
                      component={publisher?.stream?.hasAudio ? Microphone : MicrophoneSlash}
                      size={24}
                      inheritViewBox
                      sx={{ color: 'white' }}
                    />
                  </IconButton>
                  <IconButton
                    aria-label={t(`videoCall.${publisher?.stream?.hasVideo ? 'stopVideo' : 'startVideo'}`)}
                    onClick={() => toggleVideoEnabled()}
                    sx={{ backgroundColor: publisher?.stream?.hasVideo ? 'blue.500' : 'blue.700', ml: 1 }}
                  >
                    <SvgIcon
                      aria-hidden="true"
                      component={publisher?.stream?.hasVideo ? VideoCamera : VideoCameraSlash}
                      size={24}
                      inheritViewBox
                      sx={{ color: 'white' }}
                    />
                  </IconButton>
                </Box>
                <IconButton
                  data-testid="video-call-disconnect-button"
                  aria-label={t('videoCall.disconnect')}
                  onClick={handleDisconnect}
                  sx={{ backgroundColor: 'error.main', px: 3, borderRadius: 35 }}
                >
                  <SvgIcon
                    aria-hidden="true"
                    component={PhoneDisconnect}
                    size={24}
                    inheritViewBox
                    sx={{ color: 'white' }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Resizable>
        </Draggable>
      )}
    </Box>
  )
}

export default VideoCall
