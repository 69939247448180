import React, { useContext, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'

import { getLastDispatchedCallAction, getLastDispatchedCallType } from '@app/components/ConsultationFeedback/utils'
import { useConfig } from '@app/context/config'
import { NOTIFICATION_DURATION_MS } from '@app/context/notifications'
import { useConsultationActionList } from '@app/hooks/use-consultation-action-list'
import { dispatchConsultationAction, saveCallRating } from '@app/lib/consultation'
import { Action, ActionType, CallRating, CallType } from '@app/types'

type ContextType = {
  actionToDispatch: ActionType | null
  callType: CallType | null
  isFeedbackActionDispatched: boolean
  isModalOpened: boolean
  lastDispatchedCallAction: ActionType | null
  reversedActions: Action[]
  setActionToDispatch: React.Dispatch<React.SetStateAction<ActionType | null>>
  setCallType: React.Dispatch<React.SetStateAction<CallType | null>>
  setIsFeedbackActionDispatched: React.Dispatch<React.SetStateAction<boolean>>
  setIsModalOpened: React.Dispatch<React.SetStateAction<boolean>>
  setLastDispatchedCallAction: React.Dispatch<React.SetStateAction<ActionType | null>>
  setRating: React.Dispatch<React.SetStateAction<CallRating | null>>
}

export const ConsultationFeedbackContext = React.createContext<ContextType | undefined>(undefined)

type ConsultationFeedbackProviderProps = {
  consultationId: number | null
}

export const ConsultationFeedbackProvider: React.FC<ConsultationFeedbackProviderProps> = ({
  consultationId,
  children,
}) => {
  const [isFeedbackActionDispatched, setIsFeedbackActionDispatched] = useState(false)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [reversedActions, setReversedActions] = useState<Action[]>([])
  const [callType, setCallType] = useState<CallType | null>(null)
  const [lastDispatchedCallAction, setLastDispatchedCallAction] = useState<ActionType | null>(null)
  const [actionToDispatch, setActionToDispatch] = useState<ActionType | null>(null)
  const [rating, setRating] = useState<CallRating | null>(null)
  const { actions, isLoading } = useConsultationActionList(consultationId)
  const { config } = useConfig()

  useDebounce(
    async () => {
      if (!actionToDispatch || !consultationId || isModalOpened) return
      setActionToDispatch(null)
      setIsModalOpened(false)
      setLastDispatchedCallAction(actionToDispatch)
      setIsFeedbackActionDispatched(true)
      if (rating && rating?.rating !== 0 && callType === CallType.VIDEO_CALL) {
        await saveCallRating(consultationId, rating)
      }
      return dispatchConsultationAction(consultationId, { type: actionToDispatch })
    },
    NOTIFICATION_DURATION_MS,
    [actionToDispatch, consultationId, isModalOpened]
  )

  useEffect(() => {
    if (!config?.enableConsultationCallFeedback || !actions.length || isLoading) return

    // `actions` is sorted by `createdAt:desc` due to the Strapi result limitation
    // sort by createdAt:asc to ensure the last action in the array is the newest one
    const reversedActions = actions.sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt))

    setReversedActions(reversedActions)
    setCallType(getLastDispatchedCallType(reversedActions))
    setLastDispatchedCallAction(getLastDispatchedCallAction(reversedActions))
  }, [actions, config, isLoading, setCallType, setLastDispatchedCallAction])

  return (
    <ConsultationFeedbackContext.Provider
      value={{
        actionToDispatch,
        callType,
        isFeedbackActionDispatched,
        isModalOpened,
        lastDispatchedCallAction,
        reversedActions,
        setActionToDispatch,
        setCallType,
        setIsFeedbackActionDispatched,
        setIsModalOpened,
        setLastDispatchedCallAction,
        setRating,
      }}
    >
      {children}
    </ConsultationFeedbackContext.Provider>
  )
}

export const useConsultationFeedback = () => {
  const context = useContext(ConsultationFeedbackContext)

  if (!context) {
    throw new Error('useConsultationFeedback() must be wrapped inside ConsultationFeedbackProvider.')
  }

  return context
}
