import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'
import * as yup from 'yup'

import { ReactComponent as AllergiesImage } from '@app/assets/healthicons/allergies.svg'
import { useConsultationMedicalHistoryAllergiesState } from '@app/components/Consultation/state/use-consultation-medical-history-allergies-state'
import { DescriptionList } from '@app/components/EMR/common/DescriptionList'
import { MedicalHistoryEditableSelectionWithDates } from '@app/components/EMR/common/MedicalHistoryEditableSelectionWithDates'
import { MedicalHistoryEditableText } from '@app/components/EMR/common/MedicalHistoryEditableText'
import { useAllergyList } from '@app/hooks/use-allergy-list'
import { Allergies, Patient } from '@app/types'
import { parseOpenEMRDate } from '@app/util/date'

import { WORD_MIN_SIZE, SMALL_MAX_SIZE, MEDIUM_MAX_SIZE } from '../const'

import { useMedicalHistorySelectSchema } from './use-medical-history-select-schema'

export function useMedicalHistoryAllergiesSchema() {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object({
        drugAllergies: yup.array(
          yup.object({
            label: yup
              .string()
              .trim()
              .transform((curr, orig) => (orig === '' ? undefined : curr))
              .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
              .max(SMALL_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: SMALL_MAX_SIZE })),
          })
        ),
        hypersensitivities: yup
          .string()
          .trim()
          .nullable()
          .notRequired()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .max(MEDIUM_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: MEDIUM_MAX_SIZE })),
        otherWarning: yup
          .string()
          .trim()
          .nullable()
          .notRequired()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .max(MEDIUM_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: MEDIUM_MAX_SIZE })),
      }),
    [t]
  )
}

type MedicalHistoryAllergiesProps = {
  patient: Patient
  defaultValues?: Allergies
}

export const MedicalHistoryAllergies: React.FC<MedicalHistoryAllergiesProps> = ({ patient, defaultValues }) => {
  const { t } = useTranslation()
  const [allergySearch, setAllergySearch] = useState('')
  const allergies = useAllergyList(allergySearch)
  const patientDateOfBirth = useMemo(() => parseOpenEMRDate(patient.dateOfBirth), [patient.dateOfBirth])
  const medicalHistorySelectSchema = useMedicalHistorySelectSchema()
  const { state, setAllergiesState, setFormIsValid } = useConsultationMedicalHistoryAllergiesState()
  const allergiesSnapshot = useSnapshot(state)

  const allergiesSchema = useMedicalHistoryAllergiesSchema()
  const methods = useForm<Allergies>({
    resolver: yupResolver(allergiesSchema),
    defaultValues,
    mode: 'onTouched',
  })

  const formValues = useWatch({
    control: methods.control,
  })

  const drugAllergiesListController = useFieldArray({
    control: methods.control,
    name: 'drugAllergies',
  })

  useEffect(() => {
    setAllergiesState(defaultValues)
    methods.trigger()
  }, [defaultValues])

  useEffect(() => {
    setFormIsValid(methods.formState.isValid)
  }, [methods.formState.isValid])

  useEffect(() => {
    if (methods.formState.isValidating || !methods.formState.isDirty || !methods.formState.isValid) return

    setAllergiesState(formValues as Allergies)
  }, [formValues, methods.formState.isValid, methods.formState.isDirty, methods.formState.isValidating])

  return (
    <>
      <DescriptionList.Divider label={t('pages.emr.medicalHistory.allergies.title')} icon={AllergiesImage} />

      <MedicalHistoryEditableSelectionWithDates
        list={allergiesSnapshot.allergies?.drugAllergies as Allergies['drugAllergies']}
        updateItem={drugAllergiesListController.update}
        prependItem={drugAllergiesListController.prepend}
        removeItem={drugAllergiesListController.remove}
        key={'drugAllergies'}
        name={`drugAllergies`}
        control={methods.control}
        options={allergies}
        inputSchema={medicalHistorySelectSchema}
        onSearch={setAllergySearch}
        showAlert={true}
        dateValidation={{
          startDate: {
            min: patientDateOfBirth,
            max: new Date(),
          },
        }}
        firstDateOnly
        comments
      >
        {t('pages.emr.medicalHistory.allergies.drugAllergies')}
      </MedicalHistoryEditableSelectionWithDates>

      <MedicalHistoryEditableText
        name={'hypersensitivities'}
        key={'hypersensitivities'}
        control={methods.control}
        setValue={methods.setValue}
        showAlert={true}
      >
        {t('pages.emr.medicalHistory.allergies.hypersensitivities')}
      </MedicalHistoryEditableText>

      <MedicalHistoryEditableText
        name={'otherWarning'}
        key={'otherWarning'}
        control={methods.control}
        setValue={methods.setValue}
        showAlert={true}
      >
        {t('pages.emr.medicalHistory.allergies.otherWarning')}
      </MedicalHistoryEditableText>
    </>
  )
}
