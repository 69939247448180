import { alpha, Box, BoxProps } from '@mui/material'
import React from 'react'

import { colors } from '@app/theme/color'

export const Card: React.FC<BoxProps> = ({ children, sx, ...rest }) => {
  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: 2,
        py: 3,
        px: 2,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export const HeroCard: React.FC<BoxProps> = ({ children, sx, ...rest }) => {
  return (
    <Card
      sx={{
        border: '1px solid',
        borderColor: alpha(colors.gray['900'], 0.1),
        px: 4,
        pt: 6,
        pb: 4,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Card>
  )
}
