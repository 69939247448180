import { AlertColor } from '@mui/material'
import { Info } from 'phosphor-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useConsultationFeedback } from '@app/context/consultation-feedback'
import { Notification, useNotifications } from '@app/context/notifications'
import { ActionType } from '@app/types'

export const ConsultationFeedbackAlerts: React.FC = () => {
  const { t } = useTranslation()
  const { addNotification, handleAlertClose } = useNotifications()
  const { actionToDispatch, setActionToDispatch, setIsModalOpened } = useConsultationFeedback()

  const handleUndo = useCallback(() => {
    handleAlertClose()
    setActionToDispatch(null)
    setIsModalOpened(true)
  }, [handleAlertClose, setActionToDispatch, setIsModalOpened])

  const alertConfigs: { [key in ActionType]?: Notification } = useMemo(() => {
    const completedAlertConfig = {
      action: {
        title: t('common.undo'),
        onClick: handleUndo,
      },
      icon: Info,
      message: t('consultationFeedback.alertMessages.successfulConsultation'),
      severity: 'success' as AlertColor,
    }
    const cancelledAlertConfig = {
      action: {
        title: t('common.undo'),
        onClick: handleUndo,
      },
      icon: Info,
      message: t('consultationFeedback.alertMessages.closedConsultationWarning'),
      severity: 'warning' as AlertColor,
    }

    return {
      [ActionType.VIDEO_CALL_COMPLETED]: completedAlertConfig,
      [ActionType.PHONE_CALL_COMPLETED]: completedAlertConfig,
      [ActionType.VIDEO_CALL_CANCELLED]: cancelledAlertConfig,
      [ActionType.PHONE_CALL_CANCELLED]: cancelledAlertConfig,
    }
  }, [handleUndo, t])

  useEffect(() => {
    if (!actionToDispatch) return

    const { action, icon, message, severity } = alertConfigs[actionToDispatch] ?? {}

    if (!message) return

    return addNotification({ action, icon, message, severity })
  }, [actionToDispatch, addNotification, alertConfigs])

  return null
}
