import { useCallback } from 'react'
import { proxy, subscribe } from 'valtio'

import { useConsultationMedicalHistoryAllergiesState } from '@app/components/Consultation/state/use-consultation-medical-history-allergies-state'
import { useConsultationMedicalHistoryNonPathologicalState } from '@app/components/Consultation/state/use-consultation-medical-history-non-pathological-state'
import { useConsultationMedicalHistoryPathologicalState } from '@app/components/Consultation/state/use-consultation-medical-history-pathological-state'
import { Allergies, MedicalHistory } from '@app/types'

interface State {
  medicalHistory: MedicalHistory
  formStatus: {
    isValid: boolean
  }
}

const state = proxy<State>({
  medicalHistory: {
    allergies: [] as Allergies,
    nonPathologicalPersonalHistory: {
      familyHistoryDiseases: undefined,
      socialConditions: undefined,
      bloodType: undefined,
      obstetricsAndGynecologicalHistory: undefined,
    },
    pathologicalPersonalHistory: {
      previousDiseases: [],
      previousMedication: [],
      previousSurgeries: [],
      currentDiseases: [],
      currentMedication: [],
      currentSurgeries: [],
    },
  },
  formStatus: {
    isValid: true,
  },
})

export const useConsultationMedicalHistoryState = () => {
  const { state: allergiesState } = useConsultationMedicalHistoryAllergiesState()
  const { state: pathologicalState } = useConsultationMedicalHistoryPathologicalState()
  const { state: nonPathologicalState } = useConsultationMedicalHistoryNonPathologicalState()

  const isFormValid = useCallback(
    () =>
      allergiesState.formStatus.isValid &&
      pathologicalState.formStatus.isValid &&
      nonPathologicalState.formStatus.isValid,
    [allergiesState.formStatus.isValid, nonPathologicalState.formStatus.isValid, pathologicalState.formStatus.isValid]
  )

  const subscribeAllStates = useCallback((onUpdate: () => void) => {
    const unsubscribeAllergiesState = subscribe(allergiesState, () => {
      state.medicalHistory.allergies = { ...state.medicalHistory.allergies, ...allergiesState.allergies }
      state.formStatus.isValid = isFormValid()
      onUpdate()
    })

    const unsubscribePathologicalState = subscribe(pathologicalState, () => {
      state.medicalHistory.pathologicalPersonalHistory = {
        ...state.medicalHistory.pathologicalPersonalHistory,
        ...pathologicalState.pathologicalPersonalHistory,
      }
      state.formStatus.isValid = isFormValid()
      onUpdate()
    })

    const unsubscribeNonPathologicalState = subscribe(nonPathologicalState, () => {
      state.medicalHistory.nonPathologicalPersonalHistory = {
        ...state.medicalHistory.nonPathologicalPersonalHistory,
        ...nonPathologicalState.nonPathologicalPersonalHistory,
      }
      state.formStatus.isValid = isFormValid()
      onUpdate()
    })

    return () => {
      unsubscribeAllergiesState()
      unsubscribePathologicalState()
      unsubscribeNonPathologicalState()
    }
  }, [])

  return {
    state,
    subscribeAllStates,
  }
}
