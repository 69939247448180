import { useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'

export type UseDiagnosisListReturnType = { id: string; label: string }[]

const SEARCH_DEBOUNCE_TIMEOUT_MS = 500

export function useDiagnosisList(search: string): UseDiagnosisListReturnType {
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [diagnosisList, setDiagnosisList] = useState<UseDiagnosisListReturnType>([])
  const queryParams = new URLSearchParams({ search: debouncedSearch })

  useDebounce(() => setDebouncedSearch(search), SEARCH_DEBOUNCE_TIMEOUT_MS, [search])

  const { data, error, isValidating } = useSWR<{ code: string; description: string }[]>(`/diagnosis?${queryParams}`, {
    fetcher: fetcher.api,
    fallbackData: [],
  })
  const isLoading = (!error && !data) || isValidating

  useEffect(() => {
    if (isLoading || !data || search !== debouncedSearch) return

    const diagnosis = data.map(({ code, description }) => ({ id: code, label: `${code} ${description}` }))
    setDiagnosisList(diagnosis)
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  return diagnosisList
}
