import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Allergies } from '@app/assets/healthicons/allergies.svg'
import { ReactComponent as Virus } from '@app/assets/healthicons/virus.svg'
import { WarningTriangleIcon } from '@app/components/icons/WarningTriangleIcon'
import { useConfig } from '@app/context/config'
import { DateSelectionType, MedicalHistory, PrescriptionData } from '@app/types'

import { DescriptionList } from './DescriptionList'

type MedicalItemProps = {
  label: React.ReactNode
  value: string | Array<DateSelectionType & { comments?: string }> | undefined
}

export const MedicalItem: React.FC<MedicalItemProps> = ({ value, label }) => {
  const { t } = useTranslation()

  const getDateFrom = ({ approximateYear, dates }: DateSelectionType) => {
    const prefix = !dates.to ? t('common.at') : t(`common.from`)
    const format = approximateYear ? 'dates.year' : 'dates.long'

    return dates.from ? `${prefix.toLocaleLowerCase()} ${t(format, { date: new Date(dates.from) })}` : null
  }

  const getDateTo = ({ approximateYear, dates }: DateSelectionType) => {
    const format = approximateYear ? 'dates.year' : 'dates.long'
    return dates.to ? `${t(`common.to`).toLocaleLowerCase()} ${t(format, { date: new Date(dates.to) })}` : null
  }

  return (
    <DescriptionList.Item>
      <DescriptionList.Label>{label}</DescriptionList.Label>
      <DescriptionList.Body>
        {Array.isArray(value)
          ? value.map((item, i) => {
              return (
                <Typography key={i} data-test={`${item.label}-item-details`}>
                  {item.label}
                  {getDateFrom(item)} {getDateTo(item)}
                  {item.approximateYear && ` ${t('common.approximately').toLocaleLowerCase()}`}
                  {item.comments ? ` - ${t('common.notes')}: ${item.comments}` : ''}
                </Typography>
              )
            })
          : value}
      </DescriptionList.Body>
    </DescriptionList.Item>
  )
}

type MedicalHistoryDetailsProps = {
  showHeader?: boolean
  withBackground?: boolean
  medicalHistory: MedicalHistory
  prescriptions?: PrescriptionData[]
}

export const MedicalHistoryDetails: React.FC<MedicalHistoryDetailsProps> = ({
  withBackground,
  showHeader,
  medicalHistory,
  prescriptions,
}) => {
  const { t } = useTranslation()
  const { config } = useConfig()

  return (
    <>
      <DescriptionList>
        {showHeader ? (
          <DescriptionList.Header>
            <DescriptionList.Title>{t('pages.emr.medicalHistory.title')}</DescriptionList.Title>
          </DescriptionList.Header>
        ) : null}

        <DescriptionList.Items withBackground={withBackground}>
          {medicalHistory.allergies && (
            <>
              <DescriptionList.Divider label={t('pages.emr.medicalHistory.allergies.title')} icon={Allergies} />
              <MedicalItem
                label={
                  <>
                    {medicalHistory.allergies.drugAllergies?.length ? <WarningTriangleIcon /> : null}
                    {t('pages.emr.medicalHistory.allergies.drugAllergies')}
                  </>
                }
                value={medicalHistory.allergies.drugAllergies}
              />

              <MedicalItem
                label={
                  <>
                    {medicalHistory.allergies.hypersensitivities ? <WarningTriangleIcon /> : null}
                    {t('pages.emr.medicalHistory.allergies.hypersensitivities')}
                  </>
                }
                value={medicalHistory.allergies.hypersensitivities}
              />

              <MedicalItem
                label={
                  <>
                    {medicalHistory.allergies.otherWarning ? <WarningTriangleIcon /> : null}
                    {t('pages.emr.medicalHistory.allergies.otherWarning')}
                  </>
                }
                value={medicalHistory.allergies.otherWarning}
              />
            </>
          )}

          <DescriptionList.Divider
            label={t('pages.emr.medicalHistory.nonPathologicalPersonalHistory.title')}
            icon={Virus}
          />

          <MedicalItem
            label={t('pages.emr.medicalHistory.nonPathologicalPersonalHistory.familyHistoryDiseases')}
            value={medicalHistory.nonPathologicalPersonalHistory?.familyHistoryDiseases}
          />

          <MedicalItem
            label={t('pages.emr.medicalHistory.nonPathologicalPersonalHistory.socialConditions')}
            value={medicalHistory.nonPathologicalPersonalHistory?.socialConditions}
          />

          <MedicalItem
            label={t('pages.emr.medicalHistory.nonPathologicalPersonalHistory.bloodType')}
            value={medicalHistory.nonPathologicalPersonalHistory?.bloodType}
          />

          <MedicalItem
            label={t('pages.emr.medicalHistory.nonPathologicalPersonalHistory.obstetricsAndGynecologicalHistory')}
            value={medicalHistory.nonPathologicalPersonalHistory?.obstetricsAndGynecologicalHistory}
          />

          <DescriptionList.Divider
            label={t('pages.emr.medicalHistory.pathologicalPersonalHistory.title')}
            icon={Virus}
          />

          <MedicalItem
            label={t('pages.emr.medicalHistory.pathologicalPersonalHistory.previousDiseases')}
            value={medicalHistory.pathologicalPersonalHistory?.previousDiseases}
          />

          <MedicalItem
            label={t('pages.emr.medicalHistory.pathologicalPersonalHistory.previousMedication')}
            value={medicalHistory.pathologicalPersonalHistory?.previousMedication}
          />

          <MedicalItem
            label={t('pages.emr.medicalHistory.pathologicalPersonalHistory.previousSurgeries')}
            value={medicalHistory.pathologicalPersonalHistory?.previousSurgeries}
          />

          <MedicalItem
            label={t('pages.emr.medicalHistory.pathologicalPersonalHistory.currentDiseases')}
            value={medicalHistory.pathologicalPersonalHistory?.currentDiseases}
          />

          <MedicalItem
            label={t('pages.emr.medicalHistory.pathologicalPersonalHistory.currentMedication')}
            value={medicalHistory.pathologicalPersonalHistory?.currentMedication}
          />

          <MedicalItem
            label={t('pages.emr.medicalHistory.pathologicalPersonalHistory.currentSurgeries')}
            value={medicalHistory.pathologicalPersonalHistory?.currentSurgeries}
          />
          <Typography fontWeight="bold" mt={0}>
            {t('pages.emr.medicalHistory.pathologicalPersonalHistory.prescriptionsMadeByPartner', {
              partnerName: config.partnerName,
            })}
          </Typography>
          {prescriptions?.length ? (
            <Box sx={{ backgroundColor: 'gray.50' }} px={1} py={1} borderRadius={1} mt={1}>
              {prescriptions.map(() => (
                // TODO: implement the list of prescriptions here
                <></>
              ))}
            </Box>
          ) : null}
        </DescriptionList.Items>
      </DescriptionList>
    </>
  )
}
