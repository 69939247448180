import { Box, Button, SvgIcon } from '@mui/material'
import { CaretDown } from 'phosphor-react'
import React from 'react'

interface DropdownButtonProps {
  onButtonClick?: (e: React.MouseEvent) => void
  onDropdownClick?: (e: React.MouseEvent) => void
  disabled: boolean
  colorDisabled?: boolean
}

const Separator = () => <Box sx={{ width: '1px', height: 24, background: 'rgba(255, 255, 255, 0.6)' }} />

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  children,
  onButtonClick,
  onDropdownClick,
  disabled,
  colorDisabled,
}) => {
  const showDropdownButton = typeof onDropdownClick !== 'undefined'

  const backgroundColorStyle = colorDisabled
    ? {
        backgroundColor: 'grey.600',
        '&:hover': {
          backgroundColor: 'grey.600',
        },
      }
    : {}

  return (
    <Button
      variant="contained"
      color="success"
      sx={{ fontWeight: 'bold', p: 0, ...backgroundColorStyle }}
      component="div"
      role="group"
      tabIndex={-1}
      disabled={disabled}
      fullWidth
    >
      <Button
        variant="text"
        sx={{ py: 1, pr: showDropdownButton ? 1.5 : 2, color: 'white' }}
        onClick={onButtonClick}
        data-testid="talk-to-patient-use-selected-method-button"
      >
        {children}
      </Button>

      {showDropdownButton && (
        <Button
          variant="text"
          sx={{ color: 'white', py: 1, pl: 0, pr: 1, flexGrow: 0 }}
          onClick={onDropdownClick}
          data-testid="talk-to-patient-dropdown-button"
        >
          <Separator />
          <SvgIcon role="button" aria-hidden="true" component={CaretDown} size={24} inheritViewBox sx={{ ml: 1.5 }} />
        </Button>
      )}
    </Button>
  )
}
