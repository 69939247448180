import { StatusCodes } from 'http-status-codes'

import i18n from '@app/i18n/config'
import { getSessionId, getTraceId } from '@app/util/sentry'

// TODO: Extract `ErrorCodes` into a common library (already implemented in the backend repository)
export enum ErrorCodes {
  ALREADY_ACTIVE = 'ALREADY_ACTIVE',
  ALREADY_ASSIGNED = 'ALREADY_ASSIGNED',
  ALREADY_LOCKED = 'ALREADY_LOCKED',
  ALREADY_UNLOCKED = 'ALREADY_UNLOCKED',
  APP_ERROR = 'APP_ERROR',
  ASSIGNED_TO_ANOTHER_CLINICIAN = 'ASSIGNED_TO_ANOTHER_CLINICIAN',
  BAD_REQUEST = 'BAD_REQUEST',
  CAMPAIGN_NOT_ACTIVE = 'CAMPAIGN_NOT_ACTIVE',
  FAST_JWT_INVALID_SIGNATURE = 'FAST_JWT_INVALID_SIGNATURE',
  FORBIDDEN = 'FORBIDDEN',
  IN_ERRATIC_STATE = 'IN_ERRATIC_STATE',
  LOCKED_BY_ANOTHER_USER = 'LOCKED_BY_ANOTHER_USER',
  HAS_IN_PROGRESS_CONSULTATION = 'HAS_IN_PROGRESS_CONSULTATION',
  NOT_ACTIVE = 'NOT_ACTIVE',
  NOT_FOUND = 'NOT_FOUND',
  NOT_QUEUED = 'NOT_QUEUED',
  PATIENT_BLOCKED_FOR_EDITS = 'PATIENT_BLOCKED_FOR_EDITS',
  PAYMENT_APP_ERROR = 'PAYMENT_APP_ERROR',
  PAYMENT_BAD_REQUEST = 'PAYMENT_BAD_REQUEST',
  PAYMENT_GET_CARDS_ERROR = 'PAYMENT_GET_CARDS_ERROR',
  SESSION_LIMIT = 'SESSION_LIMIT',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UPLOAD_TOO_LARGE = 'UPLOAD_TOO_LARGE',
  USER_CONFLICT = 'USER_CONFLICT',
}

export type BackendError = {
  /** Eg 500 or 401 */
  statusCode: StatusCodes
  /** Eg "FAST_JWT_INVALID_SIGNATURE" or "UNAUTHORIZED". */
  code: ErrorCodes
  /** Eg "Internal Server Error" or "Unauthorized". */
  error: string
  /** Eg "The token signature is invalid." or "Unauthorized" */
  message: string
}

type Options = {
  method?: string
  body?: object | null
  headers?: Record<string, string>
  getToken?: () => Promise<string | null>
}

type ResponseSuccess<R> = {
  kind: 'success'
  status: number
  data: R
  error: null
}
type ResponseFailure<E> = {
  kind: 'error'
  status: number
  data: null
  error: E
}
type Res<R, E> = ResponseSuccess<R> | ResponseFailure<E>

type Req = <R = object, E = object>(endpoint: string, opts: Options) => Promise<Res<R, E>>

// We need to use this config to revalidate the data on certain occasions, due to the caching mechanism of SWR (v1.3.0).
// TODO: Re-evaluate this config once we upgrade to SWR v2.X.0, which has a new caching mechanism.
export const revalidateDataConfig = {
  revalidateOnFocus: false,
  revalidateOnReconnect: true,
  refreshWhenOffline: false,
  refreshWhenHidden: false,
  revalidateOnMount: true,
  revalidateIfStale: false,
}

async function getJson(response: Response) {
  if (response.status === StatusCodes.NO_CONTENT) return null

  try {
    return await response.json()
  } catch (err) {
    console.warn('🔴 Error parsing JSON from response.', err)
    return null
  }
}

const makeRequest: (baseUrl: string, options: { includeLocale?: boolean; sendTracingHeaders?: boolean }) => Req =
  (baseUrl: string, { includeLocale, sendTracingHeaders } = { sendTracingHeaders: false }) =>
  async (endpoint, opts) => {
    try {
      const hasBody = opts.body !== undefined

      const resp = await fetch(`${baseUrl}${endpoint}`, {
        method: opts.method || (hasBody ? 'POST' : 'GET'),
        body: JSON.stringify(opts.body),
        headers: {
          ...(hasBody && {
            'Content-Type': 'application/json',
          }),
          ...(opts?.getToken && {
            Authorization: `Bearer ${await opts.getToken()}`, // TODO: improve getToken thingie, should be able to pick session up automatically
          }),
          ...(includeLocale && { 'x-locale': i18n.language }),
          ...opts?.headers,
          ...(sendTracingHeaders && {
            'x-session-id': getSessionId(),
            'x-trace-id': getTraceId(),
            'x-app': 'clinician',
          }),
        },
      })
      const isError = resp.status >= 400
      const json = await getJson(resp)

      return {
        kind: isError ? 'error' : 'success',
        status: resp.status,
        data: isError ? null : json,
        error: isError ? json : null,
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error'
      return {
        kind: 'error',
        status: 500,
        data: null,
        // TODO error should be an instanceof Error, not a string
        error: errorMessage,
      }
    }
  }

export const apiRequest = makeRequest(import.meta.env.VITE_API_URL, { includeLocale: true, sendTracingHeaders: true })
