import { Box, Button, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import ErrorImage from '@app/assets/images/error.png'

export const ErrorResult = () => {
  const { t } = useTranslation()
  const handleRefresh = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <Stack spacing={1.5} alignItems="center" justifyContent="center" data-testid="empty-results" padding={'32px'}>
      <Box mt={4}>
        <img width={170} src={ErrorImage} alt="Error" />
      </Box>
      <Typography variant="h2" sx={{ color: 'gray.900', fontSize: '20px' }}>
        {t('errorScreen.inner.title')}
      </Typography>
      <Typography sx={{ color: 'gray.700', fontSize: '14px' }}>{t('errorScreen.inner.subtitle')}</Typography>
      <Button variant="contained" disableElevation sx={{ fontWeight: '700' }} onClick={handleRefresh}>
        {t('errorScreen.inner.button')}
      </Button>
    </Stack>
  )
}
