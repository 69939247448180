import React from 'react'
import { useTranslation } from 'react-i18next'

import { DescriptionList } from '@app/components/EMR/common/DescriptionList'
import { MediaGallery } from '@app/components/MediaGallery/MediaGallery'
import { MediaItem } from '@app/components/MediaGallery/MediaItem'
import { FileInfo } from '@app/types'

import { EmrCard } from './EmrCard'

interface Props {
  attachments: Array<FileInfo>
  withBackground?: boolean
  withCard?: boolean
}

export const EmrPatientFiles: React.FC<Props> = ({ attachments = [], withBackground, withCard }) => {
  const { t } = useTranslation()

  if (!attachments || attachments.length === 0) return null
  const content = (
    <DescriptionList>
      {withBackground && (
        <DescriptionList.Header>
          <DescriptionList.Title>{t('pages.emr.patientFiles')}</DescriptionList.Title>
        </DescriptionList.Header>
      )}
      <DescriptionList.Items withBackground={withBackground}>
        {!withBackground && (
          <DescriptionList.Item>
            <DescriptionList.Label>{t('pages.emr.patientFiles')}</DescriptionList.Label>
          </DescriptionList.Item>
        )}
        <DescriptionList.Item>
          <MediaGallery>
            {attachments.map((attachment, index) => (
              <MediaItem key={index} {...attachment} />
            ))}
          </MediaGallery>
        </DescriptionList.Item>
      </DescriptionList.Items>
    </DescriptionList>
  )

  if (withCard) {
    return <EmrCard>{content}</EmrCard>
  }

  return content
}
