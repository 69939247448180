import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import regex from '../util/regex'

export function usePasswordSchema() {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .required(t('forms.password.required'))
          .min(8, t('forms.password.min'))
          .test(
            'containsCriteria',
            t('forms.password.containsCriteria'),
            (value = '') =>
              [regex.lowerCaseLetters, regex.numbers, regex.upperCaseLetters, regex.specialCharacters].filter(rgx =>
                rgx.test(value)
              ).length > 2
          ),
        confirmPassword: yup
          .string()
          .required(t('forms.password.confirm.required'))
          .oneOf([yup.ref('password'), null], t('forms.password.confirm.matches')),
      }),
    [t]
  )
}
