import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const AppLoading: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={5}>
      <CircularProgress size={40} aria-label={t('common.loading')} />
      <Typography variant="body1">{t('common.loading')}</Typography>
    </Box>
  )
}
