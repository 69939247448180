import { Typography } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { ClinicianResponse, useClinicians } from '@app/hooks/use-clinicians'
import { useFeatureFlags } from '@app/hooks/use-feature-flags'
import { CustomTable, CustomTableCell, CustomTableRow } from '@app/pages/Monitoring/CustomTable'
import { TableBodySkeleton } from '@app/pages/Monitoring/CustomTable/TableBodySkeleton'
import { HeadCell } from '@app/pages/Monitoring/CustomTable/types'
import { SortingOrder } from '@app/pages/Monitoring/CustomTable/utils'
import { categoryHeader, nameHeader, phoneHeader, specialityHeader } from '@app/pages/Monitoring/tableRows'
import { fieldContains, formatPhoneNumber } from '@app/pages/Monitoring/utils'
import { colors } from '@app/theme/color'

export interface ClinicianRowData {
  id: number
  name: string
  phone: string
  specialty: string
  categories: string[]
}

const clinicianHeadCells: readonly HeadCell[] = [nameHeader, phoneHeader, specialityHeader, categoryHeader]

function mapRow(data: ClinicianResponse): ClinicianRowData {
  return {
    id: data.id,
    name: data.firstName ? `${data.firstName} ${data.lastName}` : data.username,
    phone: formatPhoneNumber(data.phone),
    specialty: data.clinicianData.specialtyAndSubSpecialtyTitle,
    categories: data.categories.map(category => category.name),
  }
}

export const ClinicianMonitoringTable = ({ searchFilter }: { searchFilter?: string }) => {
  const { featureFlags } = useFeatureFlags()
  const { data, clinicians, isLoading, hasError } = useClinicians()
  const [filteredRows, setFilteredRows] = useState<ClinicianRowData[]>([])

  useEffect(() => {
    if (!clinicians) return

    const filtered = clinicians
      .map(mapRow)
      .filter(
        (row: ClinicianRowData) =>
          !searchFilter ||
          fieldContains(row.name, searchFilter) ||
          fieldContains(row.phone, searchFilter) ||
          fieldContains(row.categories, searchFilter) ||
          fieldContains(row.specialty, searchFilter)
      )

    setFilteredRows(filtered)
  }, [clinicians, searchFilter])

  return (
    <CustomTable
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rows={filteredRows as ClinicianRowData[]}
      isLoading={!data && isLoading}
      hasError={hasError}
      noDataColSpan={6}
      ariaLabel={'monitoringCliniciansTable'}
      headCells={clinicianHeadCells}
      defaultOrder={SortingOrder.DESC}
      defaultOrderBy={'status'}
    >
      {data
        ? (visibleRows: ClinicianRowData[]) => {
            return visibleRows.map((row, index) => {
              return (
                <CustomTableRow key={row.id} position={index} data-testid="clinicians-table-row">
                  <CustomTableCell data-testid="clinicians-table-cell-name">
                    {featureFlags?.enableClinicianScheduling ? (
                      <Link
                        to={`/monitoring/clinicians/${row.id}`}
                        style={{ color: colors.gray[900], textDecoration: 'underline' }}
                      >
                        {row.name}
                      </Link>
                    ) : (
                      row.name
                    )}
                  </CustomTableCell>
                  <CustomTableCell data-testid="clinicians-table-cell-phone">{row.phone.split('-')[0]}</CustomTableCell>
                  <CustomTableCell>{row.specialty}</CustomTableCell>
                  <CustomTableCell>
                    {row.categories.map((category, index) => (
                      <Typography key={index} variant={'body2'}>
                        {category}
                      </Typography>
                    ))}
                  </CustomTableCell>
                </CustomTableRow>
              )
            })
          }
        : () => (!hasError ? <TableBodySkeleton rows={4} cols={6} /> : null)}
    </CustomTable>
  )
}
