import {
  List,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  ListProps,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import type { LinkProps as RouterLinkProps } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import { isOnlineSwitch, OnlineSwitch } from '@app/components/AppSidebar/OnlineSwitch'
import { useOnlineState } from '@app/hooks/use-online-state'

export const Menu: React.FC<ListProps> = ({ children, ...rest }) => (
  <Stack component={List} {...rest}>
    {children}
  </Stack>
)

function Icon({ icon, selected }: { icon: React.ElementType; selected?: boolean }) {
  return (
    <SvgIcon
      aria-hidden="true"
      component={icon}
      size={24}
      inheritViewBox
      sx={{ color: selected ? 'gray.900' : 'gray.700' }}
    />
  )
}

function Text({ children, selected }: { children: React.ReactNode; selected?: boolean }) {
  return (
    <Typography
      variant="body2"
      component="span"
      fontWeight={selected ? 'bold' : 'normal'}
      color={selected ? 'gray.900' : 'gray.700'}
    >
      {children}
    </Typography>
  )
}

function IconAndText({
  children,
  icon,
  selected,
}: {
  children: React.ReactNode
  icon: React.ElementType
  selected?: boolean
}) {
  return (
    <>
      <ListItemIcon sx={{ minWidth: 0, marginRight: 1 }}>
        <Icon icon={icon} selected={selected} />
      </ListItemIcon>
      <ListItemText>
        <Text selected={selected}>{children}</Text>
      </ListItemText>
    </>
  )
}

type MenuItemProps = ListItemButtonProps & {
  icon: React.ElementType
  component?: React.ElementType<any>
  href?: RouterLinkProps['to']
}

const ItemButton: React.FC<MenuItemProps> = ({ children, icon, selected, ...rest }) => (
  <ListItemButton selected={selected} sx={{ paddingY: 2 }} {...rest}>
    <IconAndText icon={icon} selected={selected}>
      {children}
    </IconAndText>
  </ListItemButton>
)

export const MenuItem: React.FC<MenuItemProps> = ({ href, ...rest }) => {
  return (
    <ListItem disablePadding>
      {href ? (
        <NavLink to={href} style={{ width: '100%' }}>
          {({ isActive }) => <ItemButton selected={isActive} {...rest} />}
        </NavLink>
      ) : (
        <ItemButton {...rest} />
      )}
    </ListItem>
  )
}

export function QueueMenuItem({ href, icon }: Required<Pick<MenuItemProps, 'href' | 'icon'>>) {
  const { t } = useTranslation()
  const { changeStatus, online } = useOnlineState()

  return (
    <ListItem disablePadding>
      <NavLink
        to={href}
        style={{ width: '100%' }}
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          if (isOnlineSwitch(event.target)) {
            event.preventDefault()
            changeStatus(!online)
          }
        }}
      >
        {({ isActive }) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            paddingTop={1.5}
            paddingBottom={1.5}
            // The following 2 props gives this Box have same CSS (background, hover...) than ItemButton
            component={ListItemButton}
            className={isActive ? 'Mui-selected' : undefined}
          >
            <Box display="flex" flexDirection="row" width="100%" alignItems="center">
              <IconAndText icon={icon} selected={isActive}>
                {t('common.sidebar.items.home')}
              </IconAndText>
            </Box>
            <OnlineSwitch />
          </Box>
        )}
      </NavLink>
    </ListItem>
  )
}
