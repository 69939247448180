import { Event, Session } from '@opentok/client'
import { useEffect } from 'react'

export enum SessionDisconnectReason {
  CLIENT_DISCONNECTED = 'clientDisconnected',
  FORCE_DISCONNECTED = 'forceDisconnected',
  NETWORK_DISCONNECTED = 'networkDisconnected',
}

export enum OpenTokEvent {
  ARCHIVE_STARTED = 'archiveStarted',
  ARCHIVE_STOPPED = 'archiveStopped',
  CONNECTION_CREATED = 'connectionCreated',
  CONNECTION_DESTROYED = 'connectionDestroyed',
  SESSION_CONNECTED = 'sessionConnected',
  SESSION_DISCONNECTED = 'sessionDisconnected',
  SESSION_RECONNECTED = 'sessionReconnected',
  SESSION_RECONNECTING = 'sessionReconnecting',
  SIGNAL = 'signal',
  STREAM_CREATED = 'streamCreated',
  STREAM_DESTROYED = 'streamDestroyed',
  STREAM_PROPERTY_CHANGED = 'streamPropertyChanged',
}

const events = [
  OpenTokEvent.ARCHIVE_STARTED,
  OpenTokEvent.ARCHIVE_STOPPED,
  OpenTokEvent.CONNECTION_CREATED,
  OpenTokEvent.CONNECTION_DESTROYED,
  OpenTokEvent.SESSION_CONNECTED,
  OpenTokEvent.SESSION_DISCONNECTED,
  OpenTokEvent.SESSION_RECONNECTED,
  OpenTokEvent.SESSION_RECONNECTING,
  OpenTokEvent.SIGNAL,
  OpenTokEvent.STREAM_CREATED,
  OpenTokEvent.STREAM_DESTROYED,
  OpenTokEvent.STREAM_PROPERTY_CHANGED,
] as const

export type NetworkDisconnectedEvent = Event<unknown, unknown> & {
  reason?: SessionDisconnectReason
}

export function useOpenTokEvent(
  type: OpenTokEvent,
  // No exported event type that I can use here
  callback: (event: Event<unknown, unknown>) => void,
  session: Session | null
) {
  const isEventTypeSupported = events.some(e => type.startsWith(e))
  if (!isEventTypeSupported) {
    throw new Error('The event type is not supported')
  }

  useEffect(() => {
    if (!session?.sessionId) return

    session.on(type, callback)
    return () => {
      session.off(type, callback)
    }
  }, [session, type, callback])
}
