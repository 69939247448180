import useSWRImmutable from 'swr/immutable'

import fetcher from '@app/lib/fetcher'

export type WorkingHours = {
  id: number
  day: string
  availability: Availability[]
}

export type ScheduleOverride = {
  date: Date
  availability: Availability[]
}

export type Availability = {
  id?: string
  label?: string
  start: string
  end: string
}

export interface Clinician {
  id: number
  firstName: string
  lastName: string
  phone: string
  professionTitle?: string
  specialtyAndSubSpecialtyTitle?: string
  categories?: string[]
  biography?: string
  qualifications?: string
  competences?: string
  profilePictureUrl?: string
  workingHours?: WorkingHours[]
  scheduleOverrides?: ScheduleOverride[]
}

export function useClinician(id?: number) {
  const { data, error, isValidating, mutate } = useSWRImmutable<Clinician>(id ? `/clinicians/${id}` : null, {
    fetcher: fetcher.api,
  })

  return {
    hasError: !!error,
    isLoading: (!error && !data) || isValidating,
    clinician: data,
    reload: mutate,
  }
}
