import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'
import { Action } from '@app/types'

export function useConsultationActionList(consultationId: number | null) {
  const { data, error, isValidating } = useSWR<{ actions: Action[] }>(
    consultationId ? `/consultations/${consultationId}/actions` : null,
    {
      fetcher: fetcher.api,
    }
  )
  return { actions: data?.actions ?? [], isLoading: isValidating || (!error && !data) }
}
