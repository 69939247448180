import { Skeleton } from '@mui/material'
import * as React from 'react'

import { CustomTableCell, CustomTableRow } from '@app/pages/Monitoring/CustomTable/CustomTable'

interface TableBodySkeletonProps {
  rows?: number
  cols?: number
  colWidth?: number
  colHeight?: number
}

export function TableBodySkeleton({ rows = 1, cols = 1, colWidth = 120, colHeight = 18 }: TableBodySkeletonProps) {
  const skeletonCols = Array(cols)
    .fill('')
    .map((_, i) => (
      <CustomTableCell key={i}>
        <Skeleton variant="rectangular" width={colWidth} height={colHeight} />
      </CustomTableCell>
    ))

  return (
    <>
      {Array(rows)
        .fill('')
        .map((_, i) => (
          <CustomTableRow key={i} position={i}>
            {skeletonCols}
          </CustomTableRow>
        ))}
    </>
  )
}
