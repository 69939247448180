import { createTheme } from '@mui/material/styles'

import { palette } from './color'
import { components } from './components'
import { typography } from './typography'

const theme = createTheme({
  components,
  palette,
  typography,
})

export default theme
