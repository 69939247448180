import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js'
import { X } from 'phosphor-react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PhoneCountryCode } from '@app/components/PhoneCountryCode/PhoneCountryCode'
import { useConfig } from '@app/context/config'
import { useFocusableError } from '@app/hooks/use-focusable-error'
import { usePatientSearchSchema } from '@app/schemas/use-patient-search'

export interface SearchPatientsFormValues {
  firstName: string
  lastName: string
  email: string
  nationalId: string
  phoneCountryCode: string
  phone: string
  dateOfBirth: {
    day: number | string
    month: number | string
    year: number | string
  }
  filter: string
}

interface SearchPatientsFormProps {
  onSubmit: (data: SearchPatientsFormValues) => void
  onClear: () => void
}

const thisYear = new Date().getFullYear()

export const normalizeNationalId = (value: string): string => {
  return value.replace(/[.-]/g, '')
}

export const SearchPatientsForm: React.FC<SearchPatientsFormProps> = ({ onSubmit, onClear }) => {
  const { t } = useTranslation()
  const { config } = useConfig()
  const { error, setError, errorRef } = useFocusableError()
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => inputRef.current?.focus(), [])

  const defaultValues = useMemo(
    () => ({
      filter: 'all',
      firstName: '',
      lastName: '',
      email: '',
      nationalId: '',
      phone: '',
      phoneCountryCode: getCountryCallingCode(config.countryCodes[0]),
      dateOfBirth: {
        day: '',
        month: '',
        year: '',
      },
    }),
    [config]
  )

  const searchSchema = usePatientSearchSchema()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(searchSchema),
  })

  const handlePhoneCountryCodeChange = useCallback(
    (evt: SelectChangeEvent<unknown>) => {
      setValue('phoneCountryCode', getCountryCallingCode(evt.target.value as CountryCode))
    },
    [setValue]
  )

  const handleClear = () => {
    reset(defaultValues, { keepIsSubmitted: false })
    onClear()
  }

  const handleFormSubmit: SubmitHandler<SearchPatientsFormValues> = async input => {
    setError('')

    try {
      onSubmit({
        ...input,
        nationalId: normalizeNationalId(input.nationalId),
      })
    } catch (err) {
      setError(t('forms.search.error'))
    }
  }

  return (
    <form data-testid="search-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography component="h2" variant="subtitle1">
            {t('forms.search.heading')}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ my: 2 }} ref={errorRef} tabIndex={-1}>
              {error}
            </Alert>
          )}

          {isSubmitSuccessful ? (
            <Button
              data-testid="clear-button"
              type="button"
              endIcon={<SvgIcon component={X} inheritViewBox />}
              onClick={handleClear}
              sx={{ m: -2 }}
            >
              {t('common.clear')}
            </Button>
          ) : null}
        </Stack>

        <Grid container columnSpacing={2} rowSpacing={4} sx={{ mt: 4 }}>
          <Grid item xs={4}>
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  inputProps={{
                    'data-testid': 'search-firstName',
                  }}
                  label={t('inputs.search.firstName')}
                  helperText={errors.firstName?.message}
                  error={!!fieldState.error}
                  inputRef={inputRef}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  inputProps={{
                    'data-testid': 'search-lastName',
                  }}
                  label={t('inputs.search.lastName')}
                  helperText={errors.lastName?.message}
                  error={!!fieldState.error}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <FormControl>
              <InputLabel>{t('inputs.search.dateOfBirth')}</InputLabel>
              <Stack direction="row" spacing={1}>
                <Controller
                  name="dateOfBirth.day"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      type="number"
                      sx={{ width: 80 }}
                      error={!!fieldState.error}
                      helperText={t('inputs.search.dateOfBirthDay')}
                      inputProps={{ min: 1, max: 31 }}
                      FormHelperTextProps={{
                        sx: { fontSize: '0.875rem', pl: 1 },
                      }}
                      data-test="search-dayOfBirth-input"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="dateOfBirth.month"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      type="number"
                      sx={{ width: 80 }}
                      error={!!fieldState.error}
                      helperText={t('inputs.search.dateOfBirthMonth')}
                      inputProps={{ min: 1, max: 12 }}
                      FormHelperTextProps={{
                        sx: { fontSize: '0.875rem', pl: 1 },
                      }}
                      data-test="search-monthOfBirth-input"
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="dateOfBirth.year"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      type="number"
                      sx={{ width: 120 }}
                      error={!!fieldState.error}
                      helperText={t('inputs.search.dateOfBirthYear')}
                      inputProps={{ min: 1900, max: thisYear }}
                      FormHelperTextProps={{
                        sx: { fontSize: '0.875rem', pl: 1 },
                      }}
                      data-test="search-yearOfBirth-input"
                      {...field}
                    />
                  )}
                />
              </Stack>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl error={'phone' in errors}>
              <InputLabel>{t('inputs.search.phoneNumber')}</InputLabel>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <InputBase
                    {...field}
                    error={!!fieldState.error}
                    data-test="search-phone-input"
                    startAdornment={
                      <InputAdornment position="start">
                        <PhoneCountryCode
                          sx={{ borderWidth: 0, borderRightWidth: '1px', borderRadius: 0 }}
                          countryCodes={config.countryCodes}
                          defaultValue={config.countryCodes[0]}
                          onChange={handlePhoneCountryCodeChange}
                        />
                      </InputAdornment>
                    }
                  />
                )}
              />
              <FormHelperText>{errors?.phone?.message}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="email"
                  label={t('inputs.search.email')}
                  helperText={errors.email?.message}
                  error={!!fieldState.error}
                  data-test="search-email-input"
                  inputProps={{
                    'data-testid': 'search-email-input',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="nationalId"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t('inputs.search.nationalId')}
                  helperText={errors.nationalId?.message}
                  error={!!fieldState.error}
                  data-test="search-national-id-input"
                />
              )}
            />
          </Grid>
        </Grid>

        <LoadingButton
          variant="contained"
          sx={{ mt: 6, alignSelf: 'center' }}
          type="submit"
          loading={isSubmitting}
          data-test="search-button"
        >
          {t('common.search')}
        </LoadingButton>
      </Stack>
    </form>
  )
}
