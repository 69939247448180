import React from 'react'

type ContextType = {
  showCancellationModal: boolean
  setShowCancellationModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ConsultationCancellationContext = React.createContext<ContextType | undefined>(undefined)

export const ConsultationCancellationProvider = ({ children }: { children: React.ReactNode }) => {
  const [showCancellationModal, setShowCancellationModal] = React.useState(false)
  return (
    <ConsultationCancellationContext.Provider
      value={{
        showCancellationModal,
        setShowCancellationModal,
      }}
    >
      {children}
    </ConsultationCancellationContext.Provider>
  )
}

export const useConsultationCancellation = () => {
  const context = React.useContext(ConsultationCancellationContext)
  if (!context) {
    throw new Error('useCalendarTimeFocus must be used within a CalendarTimeFocusProvider')
  }
  return context
}
