import { useCallback } from 'react'
import { snapshot } from 'valtio'

import { useConsultationMedicalHistoryPathologicalState } from '@app/components/Consultation/state/use-consultation-medical-history-pathological-state'
import { useConfig } from '@app/context/config'
import { DateSelectionType, PathologicalPersonalHistory, Prescription } from '@app/types'

export function useConsultationForm({
  setPrescriptions,
}: {
  setPrescriptions: (newPrescriptions: Prescription[]) => void
}) {
  const { config } = useConfig()
  const { state: pathologicalState, setPathologicalState } = useConsultationMedicalHistoryPathologicalState()

  const onSaveNewPrescriptions = useCallback(
    (newPrescriptions: Prescription[]) => {
      setPrescriptions(newPrescriptions)

      // Avoid the creation of a new medication when the prescription provider is Memed
      if (!config.displayCurrentMedication) {
        return
      }

      const currentMedication = (snapshot(pathologicalState).pathologicalPersonalHistory?.currentMedication ??
        []) as DateSelectionType[]

      const historicalCurrentMedications = currentMedication.filter(medication => !medication.uuid)

      newPrescriptions.forEach(prescription => {
        const date = prescription.medicalHistory?.date
        const stringifyDate = date instanceof Date ? date?.toISOString() : date
        // Vibe rule: no medication end date by default.
        const toDate = stringifyDate ?? null

        const medication: DateSelectionType = {
          id: prescription.medication.id,
          uuid: prescription.medication.uuid,
          label: prescription.medication.label,
          approximateYear: Boolean(prescription.medicalHistory?.approximateYear),
          dates: {
            from: new Date().toISOString(),
            to: toDate,
          },
        }

        historicalCurrentMedications.push(medication)
      })

      setPathologicalState({
        ...((snapshot(pathologicalState).pathologicalPersonalHistory ?? {}) as PathologicalPersonalHistory),
        currentMedication: historicalCurrentMedications,
      })
    },
    [config, pathologicalState, setPathologicalState, setPrescriptions]
  )

  return { onSaveNewPrescriptions }
}
