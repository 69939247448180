import { Action, ActionType, CallType } from '@app/types'

export const VIDEO_CALL_ACTIONS = [
  ActionType.VIDEO_CALL_STARTED,
  ActionType.VIDEO_CALL_FAILED,
  ActionType.VIDEO_CALL_ENDED,
]
export const VIDEO_FEEDBACK_ACTIONS = [
  ActionType.VIDEO_CALL_COMPLETED,
  ActionType.VIDEO_CALL_ATTEMPTED,
  ActionType.VIDEO_CALL_CANCELLED,
]
export const PHONE_CALL_ACTIONS = [
  ActionType.PHONE_CALL_STARTED,
  ActionType.PHONE_CALL_FAILED,
  ActionType.PHONE_CALL_ENDED,
]
export const PHONE_FEEDBACK_ACTIONS = [
  ActionType.PHONE_CALL_COMPLETED,
  ActionType.PHONE_CALL_ATTEMPTED,
  ActionType.PHONE_CALL_CANCELLED,
]

const getLastIndexPerAction = (actions: Action[]): { [key in ActionType]?: number } =>
  actions.reduce(
    (acc, action, i) => ({
      ...acc,
      [action.type]: i,
    }),
    {}
  )

export const getLastDispatchedCallAction = (actions: Action[]): ActionType | null => {
  const lastIndexPerAction = getLastIndexPerAction(actions)
  return [...VIDEO_CALL_ACTIONS, ...PHONE_CALL_ACTIONS, ...VIDEO_FEEDBACK_ACTIONS, ...PHONE_FEEDBACK_ACTIONS].reduce(
    (acc: ActionType | null, type) => {
      if (!acc && lastIndexPerAction[type]) return type
      if (acc && (lastIndexPerAction[type] ?? 0) > (lastIndexPerAction[acc] ?? 0)) return type
      return acc
    },
    null
  )
}

export const getLastDispatchedCallType = (actions: Action[]): CallType | null => {
  const lastDispatchedCallAction = getLastDispatchedCallAction(actions)

  if (!lastDispatchedCallAction) return null
  if ([...PHONE_CALL_ACTIONS, ...PHONE_FEEDBACK_ACTIONS].includes(lastDispatchedCallAction)) return CallType.PHONE_CALL
  if ([...VIDEO_CALL_ACTIONS, ...VIDEO_FEEDBACK_ACTIONS].includes(lastDispatchedCallAction)) return CallType.VIDEO_CALL
  return null
}

export const isConsultationFeedbackMissing = (actions: Action[]) => {
  const lastDispatchedCallAction = getLastDispatchedCallAction(actions)

  if (!lastDispatchedCallAction) return false
  return [...VIDEO_CALL_ACTIONS, ...PHONE_CALL_ACTIONS].includes(lastDispatchedCallAction)
}

export const isCallActionCancelled = (type: ActionType) =>
  type === ActionType.PHONE_CALL_CANCELLED || type === ActionType.VIDEO_CALL_CANCELLED
