import { EMRPatient } from '@app/types'

import { getToken } from './auth'
import { apiRequest } from './request'

type WatchlistResponse = Array<EMRPatient>

export const watchlist = {
  add(id: number) {
    return apiRequest('/clinician/watchlist', {
      body: { patientId: id },
      getToken,
    })
  },
  remove(watchlistId: number) {
    return apiRequest(`/clinician/watchlist/${watchlistId}`, {
      method: 'DELETE',
      getToken,
    })
  },
  async all({ sortOrder }: { sortOrder: 'asc' | 'desc' }) {
    const response = await apiRequest<WatchlistResponse>(`/clinician/watchlist?sort=${sortOrder}`, {
      getToken,
    })

    return response.data
  },
}
