import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'

import { useConfig } from '@app/context/config'

import fetcher from '../lib/fetcher'

export interface Locales {
  [k: string]: string | Locales
}

interface SupportedLocale {
  code: string
  name: string
}

type ContextType = {
  isLoading: boolean
  error?: string
  currentLanguage: string
  supportedLanguages?: SupportedLocale[]
  changeLanguage: (lang: string) => void
}

export const LocalesContext = React.createContext<ContextType | undefined>(undefined)

export const LocalesProvider: React.FC = ({ children }) => {
  const { i18n } = useTranslation()
  const { cache, mutate } = useSWRConfig()

  const { data: supportedLanguages } = useSWRImmutable<SupportedLocale[]>('/locales', {
    fetcher: fetcher.api,
  })

  const { config } = useConfig()

  const { data, error } = useSWRImmutable<Locales>(
    config.clinicianAppLanguage ? `/clinician/locales/${config.clinicianAppLanguage}` : null,
    {
      fetcher: fetcher.api,
    }
  )

  const clearSwrCache = useCallback(() => {
    for (const key of (cache as Map<string, any>).keys()) {
      mutate(key)
    }
  }, [cache, mutate])

  const changeLanguage = useCallback(
    async (lang: string) => {
      i18n.changeLanguage(lang)
      clearSwrCache()
    },
    [i18n, clearSwrCache]
  )

  useEffect(
    function addTranslations() {
      if (data) {
        i18n.addResourceBundle(i18n.language, 'translations', data, true, true)
      }
    },
    [data, i18n]
  )

  useEffect(() => {
    if (config.clinicianAppLanguage) {
      i18n.changeLanguage(config.clinicianAppLanguage)
    }
  }, [i18n, config.clinicianAppLanguage])

  const value = useMemo(() => {
    return { isLoading: !data && !error, error, currentLanguage: i18n.language, supportedLanguages, changeLanguage }
  }, [data, error, i18n, supportedLanguages, changeLanguage])

  return <LocalesContext.Provider value={value}>{children}</LocalesContext.Provider>
}

export const useLocales = () => {
  const context = useContext(LocalesContext)

  if (!context) {
    throw new Error('useLocales() must be wrapped inside LocalesProvider.')
  }

  return context
}
