import { IconButton, ButtonProps, useTheme } from '@mui/material'
import { X } from 'phosphor-react'
import React from 'react'

export const DialogCloseButton = (props: ButtonProps) => {
  const { spacing } = useTheme()
  const spacing2 = spacing(2)
  const spacingHalf = spacing(-0.5)

  return (
    <IconButton
      variant="text"
      sx={{
        position: 'absolute',
        color: 'gray.900',
        right: spacing2,
        top: spacing2,
        mt: spacingHalf,
        mr: spacingHalf,
      }}
      {...props}
    >
      <X />
    </IconButton>
  )
}
