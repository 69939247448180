import qs from 'qs'
import { useEffect, useMemo } from 'react'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'
import { SidebarLink, StrapiSideBarLink, StrapiError } from '@app/types'

type UseSideBarLinksReturnType = {
  links: SidebarLink[]
  error: StrapiError
}

function transform({ attributes, ...rest }: StrapiSideBarLink): SidebarLink {
  const { icon, name, url, ...restAttrs } = attributes
  return {
    ...rest,
    ...restAttrs,
    icon: transformIcon(icon),
    name,
    url,
  }

  function transformIcon(icon: StrapiSideBarLink['attributes']['icon']): SidebarLink['icon'] {
    return icon.data ? { ...icon.data.attributes } : null
  }
}

export function useSideBarLinks(): UseSideBarLinksReturnType {
  const query = qs.stringify(
    {
      populate: {
        icon: { fields: 'url' },
      },
    },
    { encodeValuesOnly: true }
  )

  const { data, error } = useSWR<{ data: StrapiSideBarLink[] }>(`/api/sidebar-links?${query}`, {
    fetcher: fetcher.strapi,
  })

  const transformedLinks = useMemo(() => {
    return data?.data.map(transform)
  }, [data])

  useEffect(() => {
    if (error) {
      console.warn('🔴 Error getting sidebar links', { error, data })
    }
  }, [error, data])

  return {
    links: transformedLinks || [],
    error,
  }
}
