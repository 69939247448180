import { MenuItem, Select, SelectProps } from '@mui/material'
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js'
import React from 'react'

interface PhoneCountryCodeProps extends SelectProps {
  countryCodes: string[]
}

export const PhoneCountryCode: React.FC<PhoneCountryCodeProps> = ({ countryCodes, ...props }) => {
  return (
    <Select {...props}>
      {countryCodes.map(code => {
        const countryPhoneCode = `+${getCountryCallingCode(code as CountryCode)}`
        return (
          <MenuItem key={code} value={code}>
            {countryPhoneCode}
          </MenuItem>
        )
      })}
    </Select>
  )
}
