import React from 'react'

export enum PatientFileStatus {
  UNLOCKED = 'UNLOCKED',
  LOCKED = 'LOCKED',
}

const PatientFileStatusContext = React.createContext<PatientFileStatus>(PatientFileStatus.UNLOCKED)

export const PatientFileStatusProvider = ({
  children,
  value,
}: {
  children: React.ReactNode
  value: PatientFileStatus
}) => {
  return <PatientFileStatusContext.Provider value={value}>{children}</PatientFileStatusContext.Provider>
}

export const usePatientFileStatus = () => {
  const context = React.useContext(PatientFileStatusContext)
  if (!context) {
    throw new Error('usePatientFileStatus must be used within a PatientFileStatusProvider')
  }
  return context
}
