import { zonedTimeToUtc } from 'date-fns-tz'

export const time = {
  ms: { Second: 1000, Minute: 1000 * 60, Hour: 1000 * 60 * 60 },
}

export const parseOpenEMRDate = (date: string): Date | undefined => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const parsedDate = new Date(date)

  if (Number.isNaN(parsedDate.getTime())) {
    console.debug('Error parsing patient date of birth', date)
    return undefined
  }

  return zonedTimeToUtc(date, timezone)
}
