import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import React from 'react'

type DatePickerInputProps = {
  date: Date
  handleSelectDate: (value: Date | null) => void
}

export const DatePickerInput = ({ date, handleSelectDate }: DatePickerInputProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker<Date>
        value={date}
        closeOnSelect={true}
        onChange={handleSelectDate}
        slotProps={{
          textField: {
            sx: {
              m: 1,
              width: '17ch',
              position: 'absolute',
              zIndex: '1',
              right: '300px',
              top: '-16px',
              '& .MuiOutlinedInput-root': {
                fontSize: '14px',
                borderRadius: '8px',
                backgroundColor: 'white',
                border: '1px solid #E3E3E3',
                height: '53px',
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  )
}
