import { Chip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DescriptionList } from '@app/components/EMR/common/DescriptionList'

import { EmrCard } from './EmrCard'

interface Props {
  productName: string
  withBackground?: boolean
  withCard?: boolean
}

export const EmrProduct: React.FC<Props> = ({ productName, withBackground, withCard }) => {
  const { t } = useTranslation()

  if (!productName) return null

  const content = (
    <DescriptionList>
      {!withBackground && (
        <DescriptionList.Items withBackground={withBackground}>
          <DescriptionList.Item>
            <DescriptionList.Label>{t('pages.emr.productLabel')} </DescriptionList.Label>
            <DescriptionList.Body>
              <Chip sx={{ mt: 0.5 }} label={productName} color="primary" variant="outlined" />
            </DescriptionList.Body>
          </DescriptionList.Item>
        </DescriptionList.Items>
      )}
    </DescriptionList>
  )

  if (withCard) {
    return <EmrCard>{content}</EmrCard>
  }

  return content
}
