import { proxy } from 'valtio'

import { Prescription } from '@app/types'
import { sanitizeJournalPrescriptions } from '@app/util/journal-data-formatting'

interface State {
  prescriptions: Prescription[]
}

const state = proxy<State>({
  prescriptions: [],
})

const setPrescriptionsState = (prescriptions: Prescription[]) => {
  state.prescriptions = sanitizeJournalPrescriptions(prescriptions)
}

export const useConsultationPrescriptionsState = () => {
  return {
    state,
    setPrescriptionsState,
  }
}
