import { addWeeks, endOfDay, startOfDay, subWeeks } from 'date-fns'
import * as React from 'react'

import { useClinicianEvents } from '@app/hooks/use-clinician-events'

/**
 * For the `Calendar` component, to load events to it and manage its date.
 */
export function useClinicianCalendarEvents(clinicianId: number) {
  const [navigationDate, setNavigationDate] = React.useState(new Date())
  const [eventBoundaries, setEventBoundaries] = React.useState({
    start: subWeeks(startOfDay(navigationDate), 4).getTime(),
    end: addWeeks(endOfDay(navigationDate), 4).getTime(),
  })

  const {
    events,
    isLoading: isLoadingEvents,
    reload,
  } = useClinicianEvents(clinicianId, eventBoundaries.start, eventBoundaries.end)

  React.useEffect(() => {
    const navigationTime = navigationDate.getTime()
    if (navigationTime < eventBoundaries.start) {
      const start = subWeeks(startOfDay(navigationDate), 4).getTime()
      setEventBoundaries({ start, end: eventBoundaries.end })
    }
    if (navigationTime > eventBoundaries.end) {
      const end = addWeeks(endOfDay(navigationDate), 4).getTime()
      setEventBoundaries({ start: eventBoundaries.start, end })
    }
  }, [eventBoundaries.end, eventBoundaries.start, navigationDate])

  return { events, reload, isLoadingEvents, navigationDate, setNavigationDate }
}
