import { Box, Divider, Stack, Typography, TypographyProps } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { utcToZonedTime } from 'date-fns-tz'
import React, { lazy, PropsWithChildren, Suspense, useEffect } from 'react'
import { Views } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'

import { AppLoading } from '@app/components/AppLoading'
import { BackButton } from '@app/components/Button/BackButton'
import { CalendarEvent } from '@app/components/Calendar/Event/Event'
import { ErrorPage } from '@app/components/ErrorPage'
import { Page } from '@app/components/Page'
import { UpdatingLivePill } from '@app/components/Pill/UpdatingLivePill'
import { useConfig } from '@app/context/config'
import { Clinician, useClinician } from '@app/hooks/use-clinician'
import { useClinicianCalendarEvents } from '@app/hooks/use-clinician-calendar-events'
import { getToken } from '@app/lib/auth'
import { apiRequest } from '@app/lib/request'
import { formatPhoneNumber } from '@app/pages/Monitoring/utils'
import { makeStrapiImageUrl } from '@app/util/url'

const LazyCalendar = lazy(() => import('@app/components/Calendar'))

export const ClinicianMonitoringPage: React.FC = () => {
  const { t } = useTranslation()
  const [isReloading, setIsReloading] = React.useState(false)

  const { clinicianId } = useParams()
  const [searchParams] = useSearchParams()

  const { config } = useConfig()

  const {
    clinician,
    isLoading: isLoadingClinician,
    hasError: hasErrorLoadingClinician,
    reload: reloadClinician,
  } = useClinician(Number(clinicianId))

  const time = searchParams.get('time')

  const {
    events,
    reload: reloadEvents,
    isLoadingEvents,
    navigationDate,
    setNavigationDate,
  } = useClinicianCalendarEvents(Number(clinicianId))

  useEffect(() => {
    if (time && reloadEvents) {
      reloadEvents()
    }
  }, [time, reloadEvents, isLoadingEvents])

  const handleBackgroundEventOverrides = async (date: Date, events: CalendarEvent[], callback?: () => void) => {
    await apiRequest<{ success: boolean }>(`/schedule/clinician/${clinicianId}/override/background-events`, {
      method: 'POST',
      getToken,
      body: {
        date,
        availability: events.map(event => ({
          label: event.title,
          start: event.start,
          end: event.end,
        })),
      },
    })

    setIsReloading(true)

    await reloadClinician()

    if (callback) {
      callback()
    }
  }

  if (isLoadingClinician && !isReloading) {
    return <AppLoading />
  }

  if (hasErrorLoadingClinician || !clinician) {
    return <ErrorPage />
  }

  return (
    <Page>
      <Stack direction="row" spacing={3} justifyContent="flex-start" alignItems="center">
        <BackButton to="/monitoring" sx={{ marginBottom: 2 }} />

        <ClinicianInfo clinician={clinician} />
      </Stack>

      <Box display="flex" flexDirection="row" alignItems="center" my={2}>
        <Heading mr={1}>{t('pages.clinician.patientsScheduled', { count: events?.length })}</Heading>
        <UpdatingLivePill />
        <Divider sx={{ flex: 1, ml: 1 }} />
      </Box>

      <Suspense fallback={<AppLoading />}>
        <LazyCalendar
          defaultView={Views.WEEK}
          workingHours={clinician.workingHours}
          scheduleOverrides={clinician.scheduleOverrides}
          daysOff={config.daysOff.map(day => utcToZonedTime(day.day, Intl.DateTimeFormat().resolvedOptions().timeZone))}
          timezone={config.timezone}
          events={events ?? []}
          isLoading={isLoadingEvents}
          navigationDate={navigationDate}
          setNavigationDate={setNavigationDate}
          handleBackgroundEventOverrides={handleBackgroundEventOverrides}
          isClinicalManager={true}
          remainingPageHeight={230}
        />
      </Suspense>
    </Page>
  )
}

function ClinicianInfo({ clinician }: { clinician: Clinician }) {
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      bgcolor="white"
      borderRadius="8px"
      paddingX={3}
      paddingY={2}
      flex="1"
    >
      {
        <Avatar
          src={clinician.profilePictureUrl ? makeStrapiImageUrl(clinician.profilePictureUrl) : undefined}
          sx={{ width: 52, height: 52, marginRight: 2 }}
        />
      }
      <Box display="flex" flexDirection="column" mr={6}>
        <Heading component="h1" fontSize={18}>
          {clinician.firstName} {clinician.lastName}
        </Heading>
        <Text>{clinician.professionTitle}</Text>
      </Box>
      <Box display="flex" flexDirection="column" mr={6}>
        <Heading>{t('pages.monitoring.tables.clinicians.category')}</Heading>
        <Text>{clinician.categories?.join(', ')}</Text>
      </Box>
      <Box display="flex" flexDirection="column" mr={6}>
        <Heading>{t('pages.monitoring.tables.clinicians.specialty')}</Heading>
        <Text>{clinician.specialtyAndSubSpecialtyTitle}</Text>
      </Box>
      <Box display="flex" flexDirection="column">
        <Heading>{t('pages.monitoring.tables.clinicians.phone')}</Heading>
        <Text>{formatPhoneNumber(clinician.phone)}</Text>
      </Box>
    </Box>
  )
}

function Heading({ children, ...rest }: PropsWithChildren<TypographyProps>) {
  return (
    <Typography fontWeight={700} fontSize={14} color="gray.900" {...rest}>
      {children}
    </Typography>
  )
}

function Text({ children }: { children: React.ReactNode }) {
  return (
    <Typography fontSize={14} color="gray.700" mt={0.5}>
      {children}
    </Typography>
  )
}
