import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import EmptyQueueImage from '@app/assets/images/no-results.svg'

export const SearchEmptyResults = () => {
  const { t } = useTranslation()
  return (
    <Stack spacing={1.5} alignItems="center" justifyContent="center" data-testid="empty-results">
      <Box mt={4}>
        <img src={EmptyQueueImage} alt="example" />
      </Box>
      <Typography variant="h2" sx={{ color: 'gray.700' }}>
        {t('pages.patients.search.noResults')}
      </Typography>
      <Typography sx={{ color: 'gray.700' }}>{t('pages.patients.search.tryModifyCriteria')}</Typography>
    </Stack>
  )
}
