import { Alert, Box, Grid, ListItem, ListItemButton, SvgIcon, Typography } from '@mui/material'
import { XCircle } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useErraticPatients } from '@app/context/erratic-patients'
import { usePatientSexTranslationKeys } from '@app/hooks/use-patient-sex-translation-keys'
import { ActionType, Consultation } from '@app/types'
import { parseOpenEMRDate } from '@app/util/date'

type HistoryConsultationRowProps = Consultation

function getButtonStyles(error: boolean) {
  if (error) {
    return { borderColor: 'error.main', borderWidth: 1, borderStyle: 'solid', pb: 0 }
  }

  return { px: 3, py: 4 }
}

export const HistoryConsultationRow: React.FC<HistoryConsultationRowProps> = consultation => {
  const { t } = useTranslation()
  const { patient } = consultation
  const { consultationIds } = useErraticPatients()

  const navigate = useNavigate()
  const patientSex = usePatientSexTranslationKeys(patient.sex)

  const isErratic = consultationIds.includes(consultation.id)

  const getClosedAction = (consultation: Consultation) => {
    return consultation.actions.find(a => a.type === ActionType.CLOSED || a.type === ActionType.CLOSED_WITH_ERROR)
  }

  function navigateToPatientDetail(id: string) {
    navigate(`/patients/${id}`)
  }

  const buttonStyles = getButtonStyles(isErratic)

  return (
    <ListItem component="div" role="row" disablePadding sx={{ mt: 2 }} data-testid="history-consultation-item">
      <ListItemButton
        sx={{ bgcolor: 'white', px: 0, py: 4, flexDirection: 'column', overflow: 'hidden', ...buttonStyles }}
        onClick={() => navigateToPatientDetail(patient.emrId)}
      >
        <Grid alignItems="center" container spacing={2} sx={{ px: 3 }}>
          <Grid role="cell" item xs>
            <Box display="flex">
              <Typography sx={{ whiteSpace: 'nowrap' }}>
                {t('dates.longDatetime', { date: getClosedAction(consultation)?.createdAt })}
              </Typography>
            </Box>
          </Grid>

          <Grid role="cell" item xs>
            <Typography fontWeight="bold" data-test="history-consultation-name">
              {patient.legalName}
            </Typography>
          </Grid>

          <Grid role="cell" item xs>
            <Typography>{patientSex || '-'}</Typography>
          </Grid>

          <Grid role="cell" item xs>
            <Typography data-test="history-consultation-dob">
              {t('dates.long', { date: parseOpenEMRDate(patient.dateOfBirth) })}
            </Typography>
          </Grid>

          <Grid role="cell" item xs>
            <Typography>{patient.emrId}</Typography>
          </Grid>
        </Grid>
        {isErratic ? (
          <Alert
            severity="error"
            icon={<SvgIcon component={XCircle} inheritViewBox />}
            sx={{
              width: '100%',
              backgroundColor: '#FEF2F2',
              borderRadius: 0,
              color: 'error.main',
              mt: 4,
              py: 0,
              fontWeight: 'bold',
            }}
          >
            {t('pages.consultation.modal.signAndShareError')}
          </Alert>
        ) : null}
      </ListItemButton>
    </ListItem>
  )
}
