import { Box, Chip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { AppLoading } from '@app/components/AppLoading'
import { useAuth } from '@app/context/auth'
import { CodeForm } from '@app/forms/Code'
import { PasswordForm } from '@app/forms/Password'
import { OnCodeSubmit, OnResendCode } from '@app/hooks/use-login'
import { invitationTokenIsValid } from '@app/lib/auth'
import * as Auth from '@app/lib/auth'
import { SUCCESS } from '@app/lib/success'

interface AcceptInvitationProps {
  token: string
  onTokenChange: (token: string) => void
}

const AcceptInvitation: React.FC<AcceptInvitationProps> = ({ token, onTokenChange }) => {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [maskedPhone, setMaskedPhone] = useState('')
  const auth = useAuth()

  async function onPasswordSubmit(input: { password: string }) {
    const { kind, data } = await Auth.mfa.resendViaSMS(token)
    if (kind === 'error' || !data?.accessToken || !data?.maskedPhone) {
      throw new Error('Error resending token')
    }
    onTokenChange(data.accessToken)
    setMaskedPhone(data.maskedPhone)
    setPassword(input.password)
  }

  const onCodeSubmit: OnCodeSubmit = async input => {
    await auth.acceptInvitationAndLogin(password, input.code, token)
    return SUCCESS
  }

  const onResendCode: OnResendCode = async () => {
    const { kind, data } = await Auth.mfa.resendViaSMS(token)
    if (kind === 'error' || !data?.accessToken) {
      throw new Error('Error resending token')
    }
    onTokenChange(data.accessToken)
  }

  return (
    <>
      <Box display="flex" mt={4}>
        <Chip color="primary" sx={{ height: 7, flex: 1, mr: 1, p: 0, backgroundColor: 'blue.800' }}></Chip>
        <Chip
          color="primary"
          sx={{ height: 7, flex: 1, p: 0, backgroundColor: 'blue.800', opacity: password ? 1 : 0.4 }}
        ></Chip>
      </Box>
      <Typography mt={2} sx={{ color: 'primary.main' }}>
        {t(`pages.invitation.steps.${password ? '2' : '1'}`)}
      </Typography>
      {password ? (
        <CodeForm
          onSubmit={onCodeSubmit}
          onResend={onResendCode}
          submitLabel={t('pages.invitation.code.submit')}
          maskedPhone={maskedPhone}
        />
      ) : (
        <PasswordForm
          onSubmit={onPasswordSubmit}
          heading={t('pages.invitation.password.heading')}
          submitLabel={t('pages.invitation.password.submit')}
        />
      )}
    </>
  )
}

const Expired: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Typography mt={2}>{t('pages.invitation.expired.description')}</Typography>
    </>
  )
}

export const InvitationPage: React.FC = () => {
  const { t } = useTranslation()
  const [isValidatingToken, setIsValidatingToken] = useState(true)
  const [validToken, setValidToken] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(
    function validateAndSetToken() {
      const token = searchParams.get('token')

      if (token) {
        // remove token from the query string
        searchParams.delete('token')
        setSearchParams(searchParams, { replace: true })

        if (invitationTokenIsValid(token)) {
          setValidToken(token)
        }
      }

      setIsValidatingToken(false)
    },
    [searchParams, setSearchParams]
  )

  return (
    <Box
      component="main"
      minHeight="100%"
      py={12}
      px={2}
      display="flex"
      alignItems="flex-start"
      justifyContent="center"
      flex={1}
      sx={{
        // This background is a placeholder, so leaving here for now
        background: 'linear-gradient(67.48deg, #454674 4.37%, #4448AB 99.84%)',
      }}
    >
      <Box bgcolor="white" p={4} maxWidth="420px" width="100%" sx={{ borderRadius: 2 }}>
        <Typography variant="h2" component="h1">
          {t('pages.invitation.heading')}
        </Typography>
        {isValidatingToken ? (
          <AppLoading />
        ) : validToken ? (
          <AcceptInvitation token={validToken} onTokenChange={newToken => setValidToken(newToken)} />
        ) : (
          <Expired />
        )}
      </Box>
    </Box>
  )
}
