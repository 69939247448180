import { Divider } from '@mui/material'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { DescriptionList } from '@app/components/EMR/common/DescriptionList'
import { Symptom } from '@app/types'

import { EmrCard } from './EmrCard'

interface Props {
  createdAt: string
  symptoms: Symptom[]
  withBackground?: boolean
  withCard?: boolean
}

export const EmrSymptomCollector: React.FC<Props> = ({ createdAt, symptoms = [], withBackground, withCard }) => {
  const { t } = useTranslation()

  if (!symptoms || symptoms.length === 0) return null

  const content = (
    <DescriptionList>
      {withBackground && (
        <DescriptionList.Header>
          <DescriptionList.Title>{t('pages.emr.symptomsCollectorLabel')}</DescriptionList.Title>
          <DescriptionList.Subtitle textAlign="left" paddingBottom="4px">
            {t('pages.emr.insertedByThePatient', { date: t('dates.longDatetime', { date: createdAt }) })}
          </DescriptionList.Subtitle>
        </DescriptionList.Header>
      )}
      <DescriptionList.Items withBackground={withBackground}>
        {withBackground && (
          <DescriptionList.Item>
            <DescriptionList.Label>{t('pages.emr.symptomsCollectorLabel')}</DescriptionList.Label>
            <DescriptionList.Subtitle textAlign="left">
              {t('pages.emr.insertedByThePatient', { date: t('dates.longDatetime', { date: createdAt }) })}
            </DescriptionList.Subtitle>
          </DescriptionList.Item>
        )}

        {symptoms.map((symptom, i) => (
          <Fragment key={i}>
            <DescriptionList.Item>
              <DescriptionList.ListLabel>{symptom.question}</DescriptionList.ListLabel>
              <DescriptionList.ListBody>{symptom.answer}</DescriptionList.ListBody>
            </DescriptionList.Item>
            {i !== symptoms.length - 1 && <Divider style={{ borderColor: '#DBDBDB' }} />}
          </Fragment>
        ))}
      </DescriptionList.Items>
    </DescriptionList>
  )

  if (withCard) {
    return <EmrCard>{content}</EmrCard>
  }

  return content
}
