import { minutesToMilliseconds } from 'date-fns'
import { StatusCodes } from 'http-status-codes'
import React, { useCallback, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useTimeoutFn } from 'react-use'

import { LogoutDialog } from '@app/components/LogoutWarning/LogoutDialog'
import { useUserActivity } from '@app/components/LogoutWarning/use-user-activity'
import { useAuth } from '@app/context/auth'
import { useConfig } from '@app/context/config'
import { getToken, sendHeartbeat } from '@app/lib/auth'
import { time } from '@app/util/date'

function getThreshold(config: { sessionLengthMins: number; warningBeforeLogoutMins: number }) {
  return config.sessionLengthMins - config.warningBeforeLogoutMins
}

function Logout({ reason }: { reason?: string }) {
  const auth = useAuth()
  const navigate = useNavigate()
  const qs = createSearchParams(reason && { reason })

  useEffect(() => {
    auth
      .logout()
      .then(() => navigate({ pathname: '/login', search: `?${qs}` }))
      .catch(err => console.error('Error logging out.', err))
  }, [auth, navigate, qs])

  return null
}

interface LogoutWarningInnerProps {
  sessionLengthMins: number
  warningBeforeLogoutMins: number
}

const heartbeat = () =>
  getToken()
    .then(token => {
      if (!token) throw new Error('No token found.')
      return sendHeartbeat(token)
    })
    .then(response => {
      if (response.status === StatusCodes.GONE) {
        throw new Error('Session has expired.')
      }
    })

export function LogoutWarningInner(props: LogoutWarningInnerProps) {
  const threshold = getThreshold(props)
  const [idle, setIdle] = useState(false)
  const [timerExpired, setTimerExpired] = useState(false)
  const [forceLogout, setForceLogout] = useState(false)
  const [, , resetTimeout] = useTimeoutFn(() => setIdle(true), minutesToMilliseconds(threshold))
  const heartbeatInterval = time.ms.Minute

  const handleHeartbeatError = useCallback(
    err => {
      console.warn(`🔴 Error sending heartbeat. ${err?.message}`)
      setTimerExpired(true)
    },
    [setTimerExpired]
  )

  const reset = useCallback(() => {
    setIdle(false)
    resetTimeout()
  }, [setIdle, resetTimeout])

  useEffect(() => {
    if (idle) return
    heartbeat().catch(handleHeartbeatError)
  }, [idle, handleHeartbeatError])

  const handleUserActivity = useCallback(() => {
    if (idle) return
    heartbeat()
      .then(() => reset())
      .catch(handleHeartbeatError)
  }, [idle, reset, handleHeartbeatError])

  useUserActivity(handleUserActivity, heartbeatInterval)

  const handleStaySignedIn = reset
  const handleLogout = () => setForceLogout(true)
  const handleTimerExpired = () => setTimerExpired(true)

  if (forceLogout) {
    return <Logout />
  }

  if (timerExpired) {
    return <Logout reason="inactivity" />
  }

  if (idle) {
    return (
      <LogoutDialog onLogout={handleLogout} onStaySignedIn={handleStaySignedIn} onTimerExpired={handleTimerExpired} />
    )
  }

  return null
}

export function LogoutWarning() {
  const { config } = useConfig()

  const sessionLengthMins = config.clinicianSessionLengthInMins
  const warningBeforeLogoutMins = config.warningBeforeClinicianLogoutInMins

  if (!sessionLengthMins || !warningBeforeLogoutMins) {
    console.error('🔴 Invalid session configuration.', {
      sessionLengthMins,
      warningBeforeLogoutMins,
    })
    return null
  }

  if (warningBeforeLogoutMins >= sessionLengthMins) {
    console.error('🔴 Warning before logout period is less than or equal to session length.', {
      sessionLengthMins,
      warningBeforeLogoutMins,
    })
    return null
  }

  return <LogoutWarningInner sessionLengthMins={sessionLengthMins} warningBeforeLogoutMins={warningBeforeLogoutMins} />
}
