import { Button, Grid, SvgIcon, Typography } from '@mui/material'
import { ArrowDown, ArrowUp } from 'phosphor-react'
import React from 'react'

interface SearchColumnHeaderProps {
  sortable?: boolean
  direction?: 'asc' | 'desc'
  onSort?: () => void
  onClick?: () => void
  sorted?: boolean
}

export const SearchColumnHeader: React.FC<SearchColumnHeaderProps> = ({
  sortable,
  sorted,
  direction,
  children,
  onSort,
  onClick,
}) => {
  return (
    <Grid role="columnheader" item container xs onClick={onClick} sx={{ cursor: sortable ? 'pointer' : 'default' }}>
      <Typography variant="body2" fontWeight="bold">
        {children}
      </Typography>
      {sortable ? (
        <Button
          size="small"
          onClick={onSort}
          sx={{
            minWidth: '22px',
            height: '22px',
            p: 0,
            ml: 0.5,
            background: 'primary.100',
            border: '1px solid',
            borderColor: 'gray.200',
            borderRadius: 1,
            color: sorted ? 'primary.500' : 'gray.400',
          }}
        >
          <SvgIcon
            component={direction === 'asc' ? ArrowUp : ArrowDown}
            weight="bold"
            sx={{ fontSize: '12px' }}
            inheritViewBox
          />
        </Button>
      ) : null}
    </Grid>
  )
}
