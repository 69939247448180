import { useMemo } from 'react'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'

type UseMedicationsListReturnType = { id: string; label: string }[]

export function useMedicationsList(medicationSearch: string): UseMedicationsListReturnType {
  const queryParams = new URLSearchParams({
    search: medicationSearch,
  })
  const { data: medications } = useSWR<{ name: string; ean: string }[]>(`/medication?${queryParams}`, {
    fetcher: fetcher.api,
  })

  const formattedMedications = useMemo(() => {
    return medications?.map(({ name, ean }) => ({ id: ean, label: name }))
  }, [medications])

  return formattedMedications || []
}
