import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export function useCodeSchema({ length = 6 }: { length?: number } = {}) {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object({
        code: yup
          .string()
          .min(length, t('forms.code.length', { length }))
          .max(length, t('forms.code.length', { length }))
          .required(t('forms.code.required')),
      }),
    [t, length]
  )
}
