import { useEffect } from 'react'

export function useUnloadDialog(message: string, show = true) {
  useEffect(() => {
    if (!show) return

    function beforeUnload(ev: BeforeUnloadEvent) {
      ev.preventDefault()
      ev.returnValue = message
      return message
    }

    window.addEventListener('beforeunload', beforeUnload)

    return function cleanup() {
      return window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [message, show])
}
