import { Alert, Box, Chip, Divider, Stack, SvgIcon, Typography } from '@mui/material'
import { capitalize } from 'lodash-es'
import { CalendarX, Info, Lock, XCircle } from 'phosphor-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { isCallActionCancelled } from '@app/components/ConsultationFeedback/utils'
import { ConsultationIcon } from '@app/components/EMR/common/ConsultationIcon'
import { BlockedUserAlert } from '@app/components/UserAlert'
import { useConfig } from '@app/context/config'
import { useConsultationFeedback } from '@app/context/consultation-feedback'
import { useConsultationActionList } from '@app/hooks/use-consultation-action-list'
import { useSymptomsDocuments } from '@app/hooks/use-symptomsDocuments'
import {
  ActionType,
  Consultation,
  ConsultationStatus,
  ConsultationType,
  EmptyConsultation,
  JournalData,
} from '@app/types'

import { EmrPatientFiles } from '../Consultation/common/EmrColumnComponents/EmrPatientFiles'
import { EmrProduct } from '../Consultation/common/EmrColumnComponents/EmrProduct'
import { EmrSummary } from '../Consultation/common/EmrColumnComponents/EmrSummary'
import { EmrSymptomCollector } from '../Consultation/common/EmrColumnComponents/EmrSymptomsCollector'

import { DescriptionList } from './common/DescriptionList'
import { MedicalHistoryDetails } from './common/MedicalHistoryDetails'
import { PatientDetails } from './common/PatientDetails'

type EMRConsultationViewProps = {
  consultation: Consultation | EmptyConsultation
  journal?: JournalData
  removePrescription?: (index: number) => void
}

const ConsultationTitle = ({ consultation }: { consultation: Consultation }) => {
  const { t } = useTranslation()
  let title = ''
  let subtitle = ''

  if (consultation.type === ConsultationType.ON_DEMAND) {
    title = t('pages.emr.consultations.onDemand')
    subtitle = capitalize(t('dates.longDayMonthYear', { date: consultation.createdAt }))
      .concat(' | ')
      .concat(t('dates.shortTime', { date: consultation.createdAt }))
  }

  if (consultation.type === ConsultationType.SCHEDULED) {
    title = t('pages.emr.consultations.scheduled')
    subtitle = capitalize(t('dates.longDayMonthYear', { date: consultation.startTime }))
      .concat(' | ')
      .concat(t('dates.shortTime', { date: consultation.startTime }))
      .concat(' - ')
      .concat(t('dates.shortTime', { date: consultation.endTime }))
  }

  if (consultation.type === ConsultationType.MEDICAL_UPDATE) {
    title = t('pages.emr.consultations.medicalUpdate')
    subtitle = capitalize(t('dates.longDayMonthYear', { date: consultation.createdAt }))
      .concat(' | ')
      .concat(t('dates.shortTime', { date: consultation.createdAt }))
  }

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={5}>
        <Stack spacing="10px">
          <Typography variant="h2" fontSize="1rem" fontWeight="bold">
            {title}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <ConsultationIcon type={consultation.type} />
            <Typography color="grey.700">{subtitle}</Typography>
          </Stack>
        </Stack>
        {consultation.type !== ConsultationType.MEDICAL_UPDATE && <EmrProduct productName={consultation.productName} />}
      </Stack>
      <Divider />
    </Stack>
  )
}

const JournalDefinition: React.FC<EMRConsultationViewProps> = ({ consultation, journal: theJournal }) => {
  const { t } = useTranslation()
  const { config } = useConfig()
  const journal = consultation.id !== null ? consultation.journal?.journal : theJournal?.journal

  const isMemed = useMemo(() => config.partnerConfig.isMemed, [config])

  const prescriptions = useMemo(
    () => (consultation.id !== null ? consultation.journal?.prescriptions : theJournal?.prescriptions),
    [consultation, theJournal]
  )

  if (!journal) return null

  if (
    !journal?.currentSituation &&
    !journal.status &&
    !journal.assessment &&
    !journal.diagnosis?.length &&
    !journal.plannedActions &&
    !prescriptions?.length
  ) {
    return null
  }

  const hasPrescriptions = prescriptions && prescriptions?.length > 0

  const closedAction = consultation.id !== null ? consultation.actions.find(a => a.type === ActionType.CLOSED) : null

  return (
    <DescriptionList>
      <DescriptionList.Header>
        <DescriptionList.Title>{t('pages.emr.journal.title')}</DescriptionList.Title>
        <DescriptionList.Subtitle>
          {closedAction && (
            <>
              {t('pages.emr.signedAndShared')}
              {': '}
              {t('dates.longDatetime', { date: closedAction.createdAt })}
              <br />
            </>
          )}
          {consultation.clinician?.title} {consultation.clinician?.firstName} {consultation.clinician?.lastName}{' '}
          {' - ID '}
          {consultation.clinician?.cardoId}
        </DescriptionList.Subtitle>
      </DescriptionList.Header>

      <DescriptionList.Items withBackground>
        {journal?.currentSituation ? (
          <DescriptionList.Item>
            <DescriptionList.Label>{t('pages.emr.journal.currentSituation')}</DescriptionList.Label>
            <DescriptionList.Body>{journal.currentSituation}</DescriptionList.Body>
          </DescriptionList.Item>
        ) : null}

        {journal?.status ? (
          <DescriptionList.Item>
            <DescriptionList.Label>{t('pages.emr.journal.status')}</DescriptionList.Label>
            <DescriptionList.Body>{journal.status}</DescriptionList.Body>
          </DescriptionList.Item>
        ) : null}

        {journal?.assessment ? (
          <DescriptionList.Item>
            <DescriptionList.Label>{t('pages.emr.journal.assessment')}</DescriptionList.Label>
            <DescriptionList.Body>{journal.assessment}</DescriptionList.Body>
          </DescriptionList.Item>
        ) : null}

        {journal?.diagnosis?.length ? (
          <DescriptionList.Item>
            <DescriptionList.Label>{t('pages.emr.journal.diagnosis')}</DescriptionList.Label>
            <DescriptionList.Body>
              {journal.diagnosis.map(item => (
                <Typography key={item.id}>{item.label}</Typography>
              ))}
            </DescriptionList.Body>
          </DescriptionList.Item>
        ) : null}

        {journal?.plannedActions || hasPrescriptions ? (
          <>
            <DescriptionList.Divider label={t('pages.emr.journal.nextSteps')} />
            {journal?.plannedActions ? (
              <DescriptionList.Item>
                <DescriptionList.Label>{t('pages.emr.journal.plannedActions')}</DescriptionList.Label>
                <DescriptionList.Body>{journal.plannedActions}</DescriptionList.Body>
              </DescriptionList.Item>
            ) : null}

            {hasPrescriptions && (
              <DescriptionList.Item>
                <DescriptionList.Label>
                  {isMemed ? t('pages.emr.journal.prescriptionsAndDocuments') : t('pages.emr.journal.prescription')}
                </DescriptionList.Label>

                {/* TODO: Add prescriptions here {PrescriptionCard} */}
              </DescriptionList.Item>
            )}
          </>
        ) : null}
      </DescriptionList.Items>
    </DescriptionList>
  )
}

type SensitiveNotesProps = {
  journal: undefined | JournalData
}

export const EMRSensitiveNotes: React.FC<SensitiveNotesProps> = ({ journal }) => {
  const { t } = useTranslation()
  const sensitiveNotes = journal?.sensitiveNotes

  if (!sensitiveNotes) return null

  return (
    <DescriptionList>
      <DescriptionList.Header>
        <DescriptionList.Title>{t('pages.emr.sensitiveNotes.title')}</DescriptionList.Title>
      </DescriptionList.Header>

      <DescriptionList.Items withBackground bgcolor="blue.100">
        <DescriptionList.Item>
          <Chip
            icon={<SvgIcon component={Lock} inheritViewBox sx={{ color: 'gray.900', mr: 1, ml: 0 }} />}
            label={t('pages.emr.sensitiveNotes.thisIsNotVisibleToPatient')}
            color="primary"
            sx={{ overflow: 'hidden', justifyContent: 'start', pl: 0 }}
          />
          <Typography>{sensitiveNotes}</Typography>
        </DescriptionList.Item>
      </DescriptionList.Items>
    </DescriptionList>
  )
}

export const EMRConsultationView: React.FC<EMRConsultationViewProps> = ({
  consultation,
  journal,
  removePrescription,
}) => {
  const { config } = useConfig()
  const { symptomsDocuments } = useSymptomsDocuments(consultation.id)
  const { t } = useTranslation()
  const { lastDispatchedCallAction } = useConsultationFeedback()
  const { actions, isLoading: isLoadingActions } = useConsultationActionList(consultation.id)
  const medicalHistory = consultation.id !== null ? consultation.journal?.medicalHistory : journal?.medicalHistory
  const cancelAction = actions.find(a => a.type === ActionType.CANCELLED)

  const isBlockedPatient = (consultation.patient as { blocked: boolean }).blocked

  return (
    <Stack minWidth={720}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
        <img src={config.partnerLogo} alt={config.partnerName} height="54" />
      </Stack>

      <ConsultationTitle consultation={consultation as Consultation} />

      <Box mt={2}>
        <Typography variant="h2" fontSize="1.125rem" fontWeight="bold">
          {consultation.patient.legalName}
        </Typography>

        <PatientDetails patient={consultation.patient} additionalInformation={journal?.additionalInformation} />
        <Divider />

        <BlockedUserAlert blocked={isBlockedPatient} />

        {consultation.id !== null && consultation.status === ConsultationStatus.CLOSED_WITH_ERROR ? (
          <Alert
            severity="error"
            icon={<SvgIcon component={XCircle} inheritViewBox />}
            sx={{ width: '100%', backgroundColor: 'red.50', color: 'error.main', mt: 3, fontWeight: 'bold' }}
          >
            {t('pages.consultation.modal.signAndShareError')}
          </Alert>
        ) : null}

        {consultation.id !== null &&
        !isLoadingActions &&
        consultation.status === ConsultationStatus.CANCELLED &&
        cancelAction?.user ? (
          <Alert
            severity="error"
            icon={<SvgIcon component={CalendarX} inheritViewBox sx={{ color: 'grey.900' }} />}
            sx={{ width: '100%', backgroundColor: 'red.50', color: 'grey.900', alignItems: 'center' }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
              {t('pages.consultation.modal.cancelledConsultation.title', {
                email: cancelAction.user.email,
              })}
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              {t('pages.consultation.modal.cancelledConsultation.description')}
            </Typography>
          </Alert>
        ) : null}

        {lastDispatchedCallAction && isCallActionCancelled(lastDispatchedCallAction) ? (
          <Alert
            data-test="sign-modal-alert"
            severity="error"
            icon={<SvgIcon component={Info} inheritViewBox />}
            sx={{ width: '100%', alignItems: 'center', backgroundColor: 'red.50', mt: 3 }}
          >
            <Typography variant="body2" sx={{ fontWeight: 700, mb: 1 }}>
              {t('consultationFeedback.cancelConsultationBanner.title')}
            </Typography>
            <Typography variant="body2">{t('consultationFeedback.cancelConsultationBanner.description')}</Typography>
          </Alert>
        ) : null}

        <Stack sx={{ mt: 2 }} spacing={3}>
          {consultation.id !== null ? (
            <Stack spacing={3}>
              <EmrSummary
                content={consultation.summary?.content}
                updatedAt={consultation.summary?.updatedAt}
                withBackground
              />
              <EmrSymptomCollector createdAt={consultation.createdAt} symptoms={consultation.symptoms} withBackground />
              <EmrPatientFiles attachments={symptomsDocuments} withBackground />
            </Stack>
          ) : null}

          <JournalDefinition consultation={consultation} journal={journal} removePrescription={removePrescription} />
          <EMRSensitiveNotes journal={consultation.id !== null ? consultation.journal : journal} />
          {medicalHistory ? <MedicalHistoryDetails medicalHistory={medicalHistory} showHeader withBackground /> : null}
        </Stack>
      </Box>
    </Stack>
  )
}
