import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import { Autocomplete, Box, Button, Divider, Stack, SvgIcon, TextField, Typography } from '@mui/material'
import { Info } from 'phosphor-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { snapshot, subscribe } from 'valtio'
import * as yup from 'yup'

import { Card } from '@app/components/Card/Card'
import { useConsultationJournalState } from '@app/components/Consultation/state/use-consultation-journal-state'
import { useConsultationPrescriptionsState } from '@app/components/Consultation/state/use-consultation-prescriptions-state'
import { DescriptionList } from '@app/components/EMR/common/DescriptionList'
import { useConfig } from '@app/context/config'
import { useDiagnosisList } from '@app/hooks/use-diagnosis-list'
import { JournalData, JournalState, Patient, Prescription } from '@app/types'

import { WORD_MIN_SIZE, MEDIUM_MAX_SIZE } from '../const'

type JournalProps = {
  patient: Patient
  defaultValues?: JournalState
}

export function useJournalSchema() {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object({
        currentSituation: yup
          .string()
          .trim()
          .nullable()
          .notRequired()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
          .max(MEDIUM_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: MEDIUM_MAX_SIZE })),
        assessment: yup
          .string()
          .trim()
          .nullable()
          .notRequired()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
          .max(MEDIUM_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: MEDIUM_MAX_SIZE })),
        status: yup
          .string()
          .trim()
          .nullable()
          .notRequired()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
          .max(MEDIUM_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: MEDIUM_MAX_SIZE })),
        plannedActions: yup
          .string()
          .trim()
          .nullable()
          .notRequired()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
          .max(MEDIUM_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: MEDIUM_MAX_SIZE })),
      }),
    [t]
  )
}

export const Journal: React.FC<JournalProps> = ({ defaultValues }) => {
  const { t } = useTranslation()
  const { config } = useConfig()
  const [prescriptions, setPrescriptions] = useState<JournalData['prescriptions']>([])
  const { setFormIsValid, setJournalState } = useConsultationJournalState()
  const { state: prescriptionsState } = useConsultationPrescriptionsState()
  const [diagnosisSearch, setDiagnosisSearch] = useState('')
  const diagnoses = useDiagnosisList(diagnosisSearch)
  const showAddPrescription = !config.partnerConfig.isMemed
  const prescriptionLabel = 'pages.emr.journal.prescription'
  const addPrescriptionLabel = 'pages.emr.journal.addPrescription'

  const journalSchema = useJournalSchema()
  const methods = useForm({
    resolver: yupResolver(journalSchema),
    defaultValues,
    mode: 'onChange',
  })

  const formValues = useWatch({
    control: methods.control,
  })

  const selectedDiagnosis = useWatch({ name: 'diagnosis', control: methods.control })

  const isAddPrescriptionDisabled = useMemo(() => {
    if (Array.isArray(selectedDiagnosis)) {
      return !selectedDiagnosis?.length
    }

    return !selectedDiagnosis
  }, [selectedDiagnosis])

  useEffect(() => {
    setJournalState(defaultValues)
    methods.trigger()
  }, [defaultValues])

  useEffect(() => {
    setFormIsValid(methods.formState.isValid)
  }, [methods.formState.isValid])

  useEffect(() => {
    if (methods.formState.isValidating || !methods.formState.isDirty || !methods.formState.isValid) return

    setJournalState(formValues as JournalState)
  }, [formValues, methods.formState.isDirty, methods.formState.isValid, methods.formState.isValidating])

  useEffect(() => {
    const unsubscribe = subscribe(prescriptionsState, () => {
      const values = (snapshot(prescriptionsState).prescriptions ?? []) as Prescription[]
      setPrescriptions(values)
    })

    return () => unsubscribe()
  }, [prescriptionsState])

  return (
    <>
      <Card sx={{ px: 3, py: 2, mt: 1 }}>
        <DescriptionList>
          <DescriptionList.Item>
            <DescriptionList.Title>{t('pages.emr.journal.title')}</DescriptionList.Title>
          </DescriptionList.Item>

          <DescriptionList.Items>
            <Controller
              name="currentSituation"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  type="text"
                  label={
                    <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                      {t('pages.emr.journal.currentSituation')}
                    </Typography>
                  }
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  multiline
                  data-test="currentSituation-input"
                />
              )}
            />

            <Controller
              name="status"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  type="text"
                  label={
                    <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                      {t('pages.emr.journal.status')}
                    </Typography>
                  }
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  multiline
                  data-test="status-input"
                />
              )}
            />

            <Controller
              name="assessment"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  type="text"
                  label={
                    <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                      {t('pages.emr.journal.assessment')}
                    </Typography>
                  }
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  multiline
                  data-test="assessment-input"
                />
              )}
            />

            <Controller
              name="diagnosis"
              control={methods.control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple={true}
                  filterSelectedOptions
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  size="small"
                  options={diagnoses}
                  onInputChange={(_, value) => setDiagnosisSearch(value)}
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={
                        <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                          {t('pages.emr.journal.diagnosis')}
                        </Typography>
                      }
                      data-test="diagnosis-dropdown"
                    />
                  )}
                />
              )}
            />

            <DescriptionList.Item>
              <DescriptionList.Label>{t('pages.emr.journal.nextSteps')}</DescriptionList.Label>
              <DescriptionList.Body>
                <Controller
                  name="plannedActions"
                  control={methods.control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="text"
                      label={
                        <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                          {t('pages.emr.journal.plannedActions')}
                        </Typography>
                      }
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      multiline
                      data-test="nextSteps-input"
                    />
                  )}
                />
              </DescriptionList.Body>
            </DescriptionList.Item>

            <DescriptionList.Item>
              <DescriptionList.Label>
                {showAddPrescription && (
                  <Stack direction="row" component="dt" fontWeight="bold" justifyContent="space-between" flex={1}>
                    {t(prescriptionLabel)}
                    <Button
                      sx={{ padding: 0, background: null, '&.Mui-disabled': { color: 'gray.500' } }}
                      disabled={isAddPrescriptionDisabled}
                      data-test="addPrescription-button"
                    >
                      <AddIcon sx={{ mr: 0.2 }} fontSize="small" />
                      {t(addPrescriptionLabel)}
                    </Button>
                  </Stack>
                )}
              </DescriptionList.Label>
              <DescriptionList.Body>
                {!selectedDiagnosis?.length ? (
                  <Box display="flex" alignItems="top" mt={1}>
                    <SvgIcon component={Info} size={16} inheritViewBox sx={{ mr: 0.5 }} />
                    {config.partnerConfig.prescriptionProvider === 'memed'
                      ? t('pages.emr.journal.prescriptionSelectDiagnosis-memed')
                      : t('pages.emr.journal.prescriptionSelectDiagnosis')}
                  </Box>
                ) : null}
                {prescriptions?.length ? (
                  <>
                    <Box sx={{ backgroundColor: 'gray.50' }} px={1} py={0.5} borderRadius={1} mt={1}>
                      {prescriptions.map((prescription, index) => (
                        <Box key={index}>
                          <Box sx={{ cursor: 'pointer' }} role="button" tabIndex={0}>
                            <Typography fontWeight="bold">{prescription.medication.label}</Typography>
                            <Typography>{prescription.instructions}</Typography>
                          </Box>
                          {index !== prescriptions.length - 1 && (
                            <Box mt={1.5} mb={1.5}>
                              <Divider variant="light" />
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>

                    <Box display="flex" alignItems="top" mt={1}>
                      <SvgIcon component={Info} size={16} inheritViewBox sx={{ mr: 0.5 }} />
                      {t('pages.emr.journal.prescriptionInfo')}
                    </Box>
                  </>
                ) : null}
              </DescriptionList.Body>
            </DescriptionList.Item>
          </DescriptionList.Items>
        </DescriptionList>
      </Card>
    </>
  )
}
