import { useMemo } from 'react'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'
import { Consultation, ConsultationStatus, ConsultationType } from '@app/types'

interface Pagination {
  total: number
  page: number
  pageCount: number
  pageSize: number
}

interface UseAssignedConsultationsOptions {
  clinicianId?: number
  page?: number
  pageSize?: number
}

interface UseAssignedConsultationsResponse {
  consultations: Consultation[]
  total: number
  isLoading: boolean
  hasError: boolean
}

export function useAssignedConsultations({
  clinicianId,
}: UseAssignedConsultationsOptions): UseAssignedConsultationsResponse {
  const qs = useMemo(() => {
    const qs = new URLSearchParams()
    qs.append('status', ConsultationStatus.ACTIVE)
    qs.append('type', ConsultationType.ON_DEMAND)
    if (clinicianId) {
      qs.append('clinicianId', String(clinicianId))
    }
    return qs
  }, [clinicianId])

  const { data, error } = useSWR<{
    consultations: Consultation[] | null
    pagination: Pagination
    fetchedAt?: string
  }>(clinicianId ? `/consultations?${qs}` : null, {
    fetcher: fetcher.api,
    refreshInterval: 0,
    revalidateOnMount: true,
  })

  return {
    consultations: data?.consultations ?? [],
    total: data?.pagination?.total ?? 0,
    isLoading: !error && !data,
    hasError: !!error,
  }
}
