import { Info } from 'phosphor-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isConsultationFeedbackMissing } from '@app/components/ConsultationFeedback/utils'
import { useConfig } from '@app/context/config'
import { useConsultationFeedback } from '@app/context/consultation-feedback'
import { useNotifications } from '@app/context/notifications'

export const ConsultationFeedbackMissingAlerts: React.FC = () => {
  const { t } = useTranslation()
  const [hideNotification, setHideNotification] = useState(false)
  const { addNotification, handleAlertClose } = useNotifications()
  const { reversedActions, setIsModalOpened } = useConsultationFeedback()
  const { config } = useConfig()

  const handleUndo = useCallback(() => {
    handleAlertClose()
    setIsModalOpened(true)
  }, [handleAlertClose, setIsModalOpened])

  useEffect(() => () => handleAlertClose(), [handleAlertClose])

  useEffect(() => {
    if (!config?.enableConsultationCallFeedback || reversedActions.length === 0 || hideNotification) return

    if (isConsultationFeedbackMissing(reversedActions)) {
      addNotification({
        action: {
          title: t('common.updateHere'),
          onClick: handleUndo,
        },
        autoHide: false,
        icon: Info,
        message: t('consultationFeedback.alertMessages.missingFeedbackError'),
        severity: 'error',
      })
    }

    return setHideNotification(true)
  }, [addNotification, config, handleUndo, hideNotification, reversedActions, t])

  return null
}
