import { Link } from '@mui/material'
import { isUrlExternal } from 'is-url-external'
import React from 'react'

type RefSelectorProp = {
  children: React.ReactNode
  url: string
}

export const RefSelector: React.FC<RefSelectorProp> = ({ children, url }) => {
  const isExternal = (url: string) => isUrlExternal(url, window.location.hostname)

  return isExternal(url) ? (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <Link href={url} target="_blank">
      {children}
    </Link>
  )
}
