import { partition } from 'lodash-es'
import { useMemo } from 'react'
import useSWR from 'swr'

import { getLatestUnassignedActionDate, isUnassigned } from '@app/lib/consultation'
import fetcher from '@app/lib/fetcher'
import { revalidateDataConfig } from '@app/lib/request'
import { Consultation, ConsultationStatus, ConsultationType } from '@app/types'

interface Pagination {
  total: number
  page: number
  pageCount: number
  pageSize: number
}

interface UseQueuedConsultationsOptions {
  categories?: number[]
  page?: number
  pageSize?: number
  skip?: boolean
}

interface UseQueuedConsultationsResponse {
  callbacks: Consultation[]
  consultations: Consultation[]
  pagination: Pagination
  isLoading: boolean
  hasError: boolean
  fetchedAt?: string
  refresh: () => void
}

export function useQueuedConsultations({
  categories,
  page = 1,
  pageSize = 5,
  skip = false,
}: UseQueuedConsultationsOptions): UseQueuedConsultationsResponse {
  const qs = useMemo(() => {
    const qs = new URLSearchParams()
    qs.append('status', ConsultationStatus.QUEUED)
    qs.append('page', String(page))
    qs.append('pageSize', String(pageSize))
    qs.append('type', ConsultationType.ON_DEMAND)
    if (categories?.length) {
      categories.forEach(c => qs.append('categories', String(c)))
    }
    qs.append('sortBy', 'createdAt:asc')
    return qs
  }, [categories, page, pageSize])

  const { data, error, isValidating, mutate } = useSWR<{
    consultations: Consultation[] | null
    pagination: Pagination
    fetchedAt?: string
  }>(skip ? null : `/consultations?${qs}`, {
    fetcher: fetcher.api,
    ...revalidateDataConfig,
  })

  const [callbacks, consultations] = useMemo(() => partition(data?.consultations, isUnassigned), [data?.consultations])

  const callbacksByUpdatedAt = useMemo(
    () =>
      callbacks.sort(
        (a, b) =>
          (getLatestUnassignedActionDate(a.actions)?.getTime() ?? 0) -
          (getLatestUnassignedActionDate(b.actions)?.getTime() ?? 0)
      ),
    [callbacks]
  )

  return {
    callbacks: callbacksByUpdatedAt,
    consultations,
    fetchedAt: data?.fetchedAt,
    pagination: data?.pagination ?? { page, pageSize, pageCount: 0, total: 0 },
    isLoading: (!error && !data) || isValidating,
    hasError: !!error,
    refresh: () => mutate(),
  }
}
