import { useCallback, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import { usePatientSearchResults } from '@app/context/patient-search-results'
import { SearchPatientsFormValues } from '@app/forms/Search/SearchPatients'
import fetcher from '@app/lib/fetcher'
import { isPatientSearchResultValid } from '@app/lib/patients'
import { EMRPatient, PatientsSearchQueryParams } from '@app/types'

type PatientsSearchResponse = Array<EMRPatient>

const formatDateOfBirth = ({ year, month, day }: SearchPatientsFormValues['dateOfBirth']) => {
  return [year, month, day].map(str => str.toString().padStart(2, '0')).join('-')
}

export function usePatientsSearch() {
  const [query, setQuery] = useState<PatientsSearchQueryParams | undefined>(undefined)
  const { searchResults, setSearchResults, clearSearchResults } = usePatientSearchResults()

  const searchUrl = useMemo(() => {
    if (query === undefined) return null

    const searchParams = new URLSearchParams({ ...query })
    return `/patients/search?${searchParams}`
  }, [query])

  const { data: results, error } = useSWR<PatientsSearchResponse>(searchUrl, {
    fetcher: fetcher.api,
  })

  const onSearch = useCallback(
    (values: SearchPatientsFormValues) => {
      const newQuery: PatientsSearchQueryParams = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        nationalId: values.nationalId,
      }

      if (values.phone && values.phoneCountryCode) {
        newQuery.phone = `+${values.phoneCountryCode}${values.phone}`
      }

      if (values.dateOfBirth.day && values.dateOfBirth.month && values.dateOfBirth.year) {
        newQuery.dateOfBirth = formatDateOfBirth(values.dateOfBirth)
      }

      setQuery(newQuery)
    },
    [setQuery]
  )

  const onClear = () => {
    setQuery(undefined)
    clearSearchResults()
  }

  const isLoading = searchUrl && !results && !error
  const filteredResults = useMemo(() => results?.filter(isPatientSearchResultValid), [results])

  useEffect(() => {
    if (filteredResults !== undefined && filteredResults !== null) {
      setSearchResults(filteredResults)
    }
  }, [filteredResults, setSearchResults])

  return { isLoading, error, searchResults, onSearch, onClear }
}
