export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}

function descendingComparator<RowDataType>(a: RowDataType, b: RowDataType, orderBy: keyof RowDataType) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator<RowDataType>(order: SortingOrder, orderBy: keyof RowDataType) {
  return order === 'desc'
    ? (a: RowDataType, b: RowDataType) => descendingComparator<RowDataType>(a, b, orderBy)
    : (a: RowDataType, b: RowDataType) => -descendingComparator<RowDataType>(a, b, orderBy)
}

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })

  return stabilizedThis.map(el => el[0])
}
