import { proxy } from 'valtio'

interface State {
  sensitiveNotes?: string
  formStatus: {
    isValid: boolean
  }
}

const state = proxy<State>({
  sensitiveNotes: undefined,
  formStatus: {
    isValid: true,
  },
})

const setSensitiveNotes = (sensitiveNotes?: string) => {
  state.sensitiveNotes = sensitiveNotes
}

const setFormIsValid = (isValid: boolean) => {
  state.formStatus.isValid = isValid
}

export const useConsultationSensitiveNotesState = () => {
  return {
    state,
    setSensitiveNotes,
    setFormIsValid,
  }
}
