import { LoadingButton } from '@mui/lab'
import { Box, Chip, Typography, Stack, SvgIcon, Button } from '@mui/material'
import { parseISO } from 'date-fns'
import { Timer, Info } from 'phosphor-react'
import React, { ElementType, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from '@app/context/auth'
import { useNotifications } from '@app/context/notifications'
import { usePatientSexTranslationKeys } from '@app/hooks/use-patient-sex-translation-keys'
import { assign, getLatestUnassignedActionDate, isUnassignedAction } from '@app/lib/consultation'
import { Consultation } from '@app/types'
import { calculateAge } from '@app/util/calculate-age'
import { addSentryBreadcrumb } from '@app/util/sentry'

import { Card } from '../Card'

type QueueCardProps = Consultation & {
  component?: ElementType
  isExpanded?: boolean
  disabled?: boolean
  highlight?: boolean
  onRefresh: VoidFunction
}

export const QueueConsultationCard: React.FC<QueueCardProps> = ({
  patient,
  category,
  createdAt,
  id,
  component,
  clinicianId,
  disabled,
  updatedAt,
  actions,
  highlight,
  onRefresh,
}) => {
  const { t } = useTranslation()
  const { profile } = useAuth()
  const navigate = useNavigate()
  const { addNotification } = useNotifications()

  const [isAssigning, setIsAssigning] = useState(false)
  const [highlighted, setHighlighted] = useState(false)

  const patientSex = usePatientSexTranslationKeys(patient?.sex)

  useEffect(
    function highlightConsultation() {
      let timer: ReturnType<typeof setTimeout>
      if (highlight) {
        setHighlighted(true)
        timer = setTimeout(() => setHighlighted(false), 2000)
      }
      return () => clearTimeout(timer)
    },
    [highlight]
  )

  const handleError = () =>
    addNotification({
      message: t('pages.queue.assignError'),
      severity: 'error',
      action: {
        title: t('common.refresh'),
        onClick: onRefresh,
      },
    })

  const handleAssign = async () => {
    setIsAssigning(true)
    if (!profile) return handleError()
    await assign(id)
    setIsAssigning(false)
    addSentryBreadcrumb('Assign patient')
    navigate(`/consultation/${id}`, { state: { previousLocationPathname: location.pathname } })
  }

  const [unassignedCount, unassignedDate] = useMemo(
    () => [actions.filter(isUnassignedAction).length, getLatestUnassignedActionDate(actions)],
    [actions]
  )

  const isAssigned = profile?.id === clinicianId

  return (
    <>
      <Card
        component={component}
        sx={{
          p: 0,
          border: isAssigned ? 1 : 0,
          borderColor: 'primary.light',
          boxShadow: highlighted ? '0px 0px 10px rgba(40, 37, 79, 0.21)' : 0,
          transition: 'box-shadow 400ms',
        }}
        data-test="patient-in-queue"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={5}
          color="inherit"
          sx={{ py: 6, px: 2 }}
        >
          <Stack direction="row" width="30%" alignItems="center">
            <Box flexGrow={0} mr={6}>
              {t('dates.shortTime', { date: createdAt })}
            </Box>

            <Stack display="flex" flexDirection="column">
              <Typography fontWeight="fontWeightBold">{patient.legalName}</Typography>
              <Typography variant="caption">
                {patientSex}, {calculateAge(patient.dateOfBirth)}
              </Typography>
            </Stack>
          </Stack>

          <Box width="20%" display="flex" justifyContent="center">
            <Chip label={category?.name} color="primary" variant="outlined" />
          </Box>

          <Stack width="30%" direction="row" justifyContent="flex-start" alignItems="center">
            {isAssigned ? (
              <Typography ml={1} variant="caption" color="gray.700">
                {t('pages.queue.assignedToMeAt', { time: t('dates.shortTime', { date: unassignedDate }) })}
              </Typography>
            ) : unassignedCount > 0 ? (
              <>
                <SvgIcon component={Info} size={16} inheritViewBox />
                <Stack>
                  <Typography ml={1} variant="caption" color="gray.700" fontWeight="bold">
                    {t('pages.queue.unassigned', { count: unassignedCount })}
                  </Typography>
                  <Typography ml={1} variant="caption" color="gray.700">
                    {t('pages.queue.returnedToQueue', { dates: [parseISO(updatedAt), new Date()] })}
                  </Typography>
                </Stack>
              </>
            ) : (
              <>
                <SvgIcon component={Timer} size={16} inheritViewBox />
                <Typography ml={1} variant="caption" color="gray.700">
                  {t('pages.queue.waitingTime', { dates: [parseISO(createdAt), new Date()] })}
                </Typography>
              </>
            )}
          </Stack>

          <Stack width="20%" alignItems="flex-end">
            {isAssigned ? (
              <Button
                component={Link}
                to={`/consultation/${id}`}
                state={{ previousLocationPathname: location.pathname }}
                size="small"
                variant="contained"
                sx={{ py: 1, fontWeight: '700' }}
              >
                {t('common.open')}
              </Button>
            ) : (
              <LoadingButton
                size="small"
                sx={{ py: 1, fontWeight: '700' }}
                variant="contained"
                disabled={disabled}
                onClick={handleAssign}
                loading={isAssigning}
                data-test="assign-button"
              >
                {t('pages.queue.assignToMe')}
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      </Card>
    </>
  )
}
