import { Alert, Box, Button, Grid, Stack, SvgIcon } from '@mui/material'
import { BookmarkSimple, Info } from 'phosphor-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { snapshot, subscribe } from 'valtio'

import { SignModal } from '@app/components/Consultation/common/SignModal'
import { useConsultationPastPrescriptionsState } from '@app/components/Consultation/state/use-consultation-past-presscriptions-state'
import { useConsultationState } from '@app/components/Consultation/state/use-consultation-state'
import { BlockedUserAlert } from '@app/components/UserAlert'
import { useConfig } from '@app/context/config'
import { useConsultationFeedback } from '@app/context/consultation-feedback'
import { useConsultationProgress } from '@app/context/consultation-progress'
import { useNotifications } from '@app/context/notifications'
import { PatientFileStatus, usePatientFileStatus } from '@app/context/patient-file-status'
import { useSymptomsDocuments } from '@app/hooks/use-symptomsDocuments'
import { isFinalizedStatus, save, close, unassign } from '@app/lib/consultation'
import { PageLockedAlert } from '@app/pages/Consultation/PageLockedAlert'
import { Consultation, ConsultationType, JournalData } from '@app/types'
import { addSentryBreadcrumb } from '@app/util/sentry'

import { AttachmentsModal } from '../common/AttachmentsModal/AttachmentsModal'
import { EmrPatientFiles } from '../common/EmrColumnComponents/EmrPatientFiles'
import { EmrProduct } from '../common/EmrColumnComponents/EmrProduct'
import { EmrSummary } from '../common/EmrColumnComponents/EmrSummary'
import { EmrSymptomCollector } from '../common/EmrColumnComponents/EmrSymptomsCollector'
import { Journal } from '../common/Journal'
import { MedicalHistory } from '../common/MedicalHistory'
import { PastConsultations } from '../common/PastConsultations'
import { PatientDetails } from '../common/PatientDetails/PatientDetails'
import { SensitiveNotes } from '../common/SensitiveNotes'
import { useConsultationPrescriptionsState } from '../state/use-consultation-prescriptions-state'

type ActiveConsultationProps = {
  consultation: Consultation
  onBlockHistoryWarning: (value: boolean) => void
}

const SAVE_DATA_DEBOUNCE_TIME_MS = 1500
const FEATURE_WATCHLIST = import.meta.env.VITE_FEATURE_WATCHLIST === 'true'

/**
 * Consultation from the queue (on demand) or a scheduled consultation.
 */
export const ActiveConsultation: React.FC<ActiveConsultationProps> = ({ consultation, onBlockHistoryWarning }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isSignDialogOpen, setIsSignDialogOpen] = useState(false)
  const [isUnassigning, setIsUnassigning] = useState(false)
  const [isSigning, setIsSigning] = useState(false)
  const [unassignError, setUnassignError] = useState(false)
  const { symptomsDocuments, newUploadedDocuments, newDocumentsHaveBeenLoaded, newDocumentsHaveBeenSaw } =
    useSymptomsDocuments(consultation.id)
  const { addNotification } = useNotifications()
  const status = usePatientFileStatus()
  const { actionToDispatch, isModalOpened } = useConsultationFeedback()
  const { watchConsultation } = useConsultationProgress()
  const { config } = useConfig()
  const { state, initialiseState, subscribeAllStates } = useConsultationState()
  const { setPrescriptionsState } = useConsultationPrescriptionsState()
  const { setPastPrescriptionsState } = useConsultationPastPrescriptionsState()
  const saveOnRedisTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const isLocked = status === PatientFileStatus.LOCKED

  const handleClose = useCallback(() => {
    newDocumentsHaveBeenSaw()
  }, [newDocumentsHaveBeenSaw])

  const signAndShare = useCallback(async () => {
    setIsSignDialogOpen(false)
    setIsSigning(true)

    const dataToSave = snapshot(state.journalData) as JournalData
    const res = await close(consultation.id, dataToSave)

    if (res.kind === 'success') {
      addNotification({ message: t('pages.emr.signAndShareSubmit'), severity: 'warning', icon: Info })
      watchConsultation(res.data.consultationId)

      if (consultation.type === ConsultationType.ON_DEMAND) {
        addSentryBreadcrumb('Sign and share on-demand consultation')
        return navigate('/')
      }

      if (consultation.type === ConsultationType.SCHEDULED) {
        addSentryBreadcrumb('Sign and share scheduled consultation')
        navigate('/schedule', { state: { highlightConsultationId: consultation.id } })
      }
    }

    setIsSigning(false)
  }, [addNotification, consultation.id, consultation.type, navigate, state.journalData, t, watchConsultation])

  const handleUnassign = useCallback(async () => {
    setUnassignError(false)
    setIsUnassigning(true)

    if (consultation.type === ConsultationType.ON_DEMAND) {
      const res = await unassign(consultation.id)
      setIsUnassigning(false)
      if (res.kind === 'success') {
        addSentryBreadcrumb('Unassign patient')
        addNotification({ message: t('pages.emr.unassignSuccess') })
        return navigate('/', { state: { highlightConsultationId: consultation.id } })
      }
    }

    if (consultation.type === ConsultationType.SCHEDULED) {
      addSentryBreadcrumb('Unlock patient')
      navigate('/schedule', { state: { highlightConsultationId: consultation.id } })
    }

    setUnassignError(true)
  }, [consultation, navigate, addNotification, t])

  const toggleConfirmationDialog = useCallback(() => {
    setIsSignDialogOpen(val => !val)
  }, [])

  const isConsultationFeedbackInProgress = !!actionToDispatch || isModalOpened

  useEffect(() => {
    if (consultation.journal?.prescriptions) {
      setPrescriptionsState(consultation.journal.prescriptions)
    }
  }, [consultation.journal?.prescriptions])

  useEffect(() => {
    if (consultation.journal?.pastPrescriptions) {
      setPastPrescriptionsState(consultation.journal.pastPrescriptions)
    }
  }, [consultation.journal?.pastPrescriptions])

  // We want to avoid showing the consultation page if the consultation is already finalized
  useEffect(() => {
    if (isFinalizedStatus(consultation.status)) {
      navigate(`/patients/${consultation.patient.emrId}`)
    }
  }, [consultation.patient.emrId, consultation.status, navigate])

  useEffect(() => {
    if (!config?.enableConsultationCallFeedback) return
    onBlockHistoryWarning(isConsultationFeedbackInProgress)
  }, [config, isConsultationFeedbackInProgress, onBlockHistoryWarning])

  useEffect(() => {
    const unsubscribe = subscribe(state.journalData, () => {
      if (saveOnRedisTimeoutRef.current) {
        clearTimeout(saveOnRedisTimeoutRef.current)
      }

      saveOnRedisTimeoutRef.current = setTimeout(async () => {
        const dataToSave = snapshot(state.journalData) as JournalData

        try {
          await save(consultation.id, dataToSave)
        } catch (err) {
          console.debug('🔴 Error sending save request.', err)
        }
      }, SAVE_DATA_DEBOUNCE_TIME_MS)
    })

    return () => {
      if (saveOnRedisTimeoutRef.current) {
        clearTimeout(saveOnRedisTimeoutRef.current)
      }
      unsubscribe()
    }
  }, [state.journalData, consultation.journal, consultation.id])

  useEffect(() => {
    initialiseState()
    const unsubscribeAllStates = subscribeAllStates()

    return () => {
      initialiseState()
      unsubscribeAllStates()
    }
  }, [])

  return (
    <>
      <Grid container rowSpacing={3}>
        {FEATURE_WATCHLIST && (
          <Grid item xs={12} container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button sx={{ color: 'gray.900' }}>
                  <SvgIcon component={BookmarkSimple} inheritViewBox sx={{ color: 'gray.900', mr: 1 }} />
                  {t('pages.emr.addToWatchList')}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        )}

        {isLocked ? <PageLockedAlert /> : null}

        {unassignError && (
          <Grid item xs={12} container>
            <Alert sx={{ alignItems: 'center' }} severity="error">
              {t('pages.emr.unassignError')}
            </Alert>
          </Grid>
        )}

        <BlockedUserAlert blocked={consultation.patient.blocked} />

        <Grid component="section" item xs={12}>
          <PatientDetails
            isSigning={isSigning}
            isUnassigning={isUnassigning}
            onSignAndShare={toggleConfirmationDialog}
            onUnassign={handleUnassign}
            consultation={consultation}
          />
        </Grid>

        <Grid component="section" item container columnSpacing={2} xs={12}>
          <Grid component="section" item xs={4}>
            <Box sx={{ mb: 2 }}>
              <EmrProduct productName={consultation.productName} withCard />
              <EmrSummary
                content={consultation.summary?.content}
                updatedAt={consultation.summary?.updatedAt}
                withCard
              />
              <EmrSymptomCollector createdAt={consultation.createdAt} symptoms={consultation.symptoms} withCard />
              <EmrPatientFiles attachments={symptomsDocuments} withCard />
            </Box>

            <PastConsultations patientId={consultation.patientId} />
          </Grid>

          <Grid component="section" item xs={4}>
            <MedicalHistory
              medicalHistory={consultation.journal?.medicalHistory}
              prescriptions={consultation.journal?.pastPrescriptions}
              patient={{ ...consultation.patient, id: consultation.patientId }}
            />
          </Grid>

          <Grid component="section" item xs={4}>
            <Box sx={{ mb: 2 }}>
              <Journal patient={consultation.patient} defaultValues={consultation.journal?.journal} />
            </Box>
            <SensitiveNotes defaultValue={consultation.journal?.sensitiveNotes} />
          </Grid>
        </Grid>
      </Grid>
      <AttachmentsModal
        isOpen={newDocumentsHaveBeenLoaded}
        onClose={handleClose}
        attachments={symptomsDocuments.slice(-newUploadedDocuments)}
        legalName={consultation.patient.legalName}
        sx={{ zIndex: 'snackbar' }}
      />
      <SignModal
        consultation={consultation}
        pastPrescriptions={null}
        isOpen={isSignDialogOpen}
        onClose={toggleConfirmationDialog}
        onSign={signAndShare}
      />
    </>
  )
}
