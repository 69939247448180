import { throttle } from 'lodash'
import { useEffect, useRef } from 'react'

const events = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel']

export function useUserActivity(fn: VoidFunction, throttleMs: number) {
  const callback = useRef(fn)

  useEffect(() => {
    const onEvent = throttle(() => callback.current(), throttleMs)

    for (let i = 0; i < events.length; i++) {
      window.addEventListener(events[i], onEvent)
    }

    document.addEventListener('visibilitychange', onEvent)

    return () => {
      for (let i = 0; i < events.length; i++) {
        window.removeEventListener(events[i], onEvent)
      }

      document.removeEventListener('visibilitychange', onEvent)
    }
  }, [throttleMs])

  useEffect(() => {
    callback.current = fn
  }, [fn])
}
