import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Rating,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import { Pencil, Star } from 'phosphor-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { DarkTooltip } from '@app/components/Tooltip/DarkTooltip'
import { useConfig } from '@app/context/config'
import { useConsultationFeedback } from '@app/context/consultation-feedback'
import { useNotifications } from '@app/context/notifications'
import { useFeatureFlags } from '@app/hooks/use-feature-flags'
import { ActionType, CallType, ConsultationFeedbackType } from '@app/types'

const defaultValues = {
  feedback: {
    type: null,
  },
  rating: 0,
  ratingComment: '',
}

interface FormValueProps {
  feedback: {
    type: ConsultationFeedbackType | null
  }
  rating: number
  ratingComment: string
}

export const ConsultationFeedbackModal: React.FC = () => {
  const { t } = useTranslation()
  const {
    callType,
    isFeedbackActionDispatched,
    isModalOpened,
    setActionToDispatch,
    setRating,
    setIsFeedbackActionDispatched,
    setIsModalOpened,
  } = useConsultationFeedback()
  const { config } = useConfig()
  const { featureFlags } = useFeatureFlags()
  const { handleAlertClose } = useNotifications()

  const consultationFeedbackOptions = useMemo(
    () => ({
      [ConsultationFeedbackType.CONFIRM_CALL]: {
        type: ConsultationFeedbackType.CONFIRM_CALL,
        text: t('consultationFeedback.modal.confirmCallAnswer'),
        action: callType === CallType.PHONE_CALL ? ActionType.PHONE_CALL_COMPLETED : ActionType.VIDEO_CALL_COMPLETED,
      },
      [ConsultationFeedbackType.REPEAT_CALL]: {
        type: ConsultationFeedbackType.REPEAT_CALL,
        text: t('consultationFeedback.modal.repeatCallAnswer'),
        action: callType === CallType.PHONE_CALL ? ActionType.PHONE_CALL_ATTEMPTED : ActionType.VIDEO_CALL_ATTEMPTED,
      },
      [ConsultationFeedbackType.CANCEL_CONSULTATION]: {
        type: ConsultationFeedbackType.CANCEL_CONSULTATION,
        text: t('consultationFeedback.modal.cancelConsultationAnswer'),
        action: callType === CallType.PHONE_CALL ? ActionType.PHONE_CALL_CANCELLED : ActionType.VIDEO_CALL_CANCELLED,
      },
    }),
    [callType, t]
  )

  const consultationFeedbackSchema = yup.object({
    ...(featureFlags?.enableClinicianCallRating && callType === CallType.VIDEO_CALL
      ? {
          rating: yup.number().min(1).max(5).required(),
          ratingComment: yup.string(),
        }
      : undefined),
    feedback: yup
      .object({
        type: yup.mixed<ConsultationFeedbackType>().oneOf(Object.values(ConsultationFeedbackType)).required(),
      })
      .required(),
  })

  const methods = useForm({
    resolver: yupResolver(consultationFeedbackSchema),
    mode: 'onBlur',
    defaultValues,
  })

  const onConfirm = useCallback(() => {
    methods.handleSubmit(({ feedback, rating, ratingComment }: FormValueProps) => {
      const action = feedback.type ? consultationFeedbackOptions[feedback.type].action : null
      setRating({ rating, ratingComment })
      setActionToDispatch(action)
      setIsModalOpened(false)
    })()
  }, [consultationFeedbackOptions, methods, setActionToDispatch, setIsModalOpened])

  useEffect(() => {
    if (!isModalOpened) return
    setIsFeedbackActionDispatched(false)
    return handleAlertClose()
  }, [handleAlertClose, isModalOpened, setIsFeedbackActionDispatched])

  useEffect(() => {
    if (isFeedbackActionDispatched) return methods.reset(defaultValues)
  }, [isFeedbackActionDispatched, methods])

  if (!config?.enableConsultationCallFeedback) return null

  return (
    <>
      <FormProvider {...methods}>
        <Dialog open={isModalOpened} maxWidth="xs">
          <DialogTitle sx={{ px: 2, pt: 3, pb: 0 }}>
            <Typography variant="h3" textAlign="center" lineHeight="32px">
              {t('consultationFeedback.modal.title')}
            </Typography>
          </DialogTitle>

          <DialogContent sx={{ px: 2, py: 0 }} data-testid="consultation-feedback-modal">
            {featureFlags?.enableClinicianCallRating && callType === CallType.VIDEO_CALL && (
              <Stack
                direction="column"
                spacing={2}
                display="flex"
                alignItems="center"
                textAlign="center"
                sx={{ mt: 1, mb: 3 }}
              >
                <Typography color="gray.700">{t('consultationFeedback.modal.rating')}</Typography>
                <Controller
                  name="rating"
                  render={({ field }) => (
                    <Rating
                      {...field}
                      name="size-large"
                      size="medium"
                      icon={<Star size={32} weight="fill" />}
                      emptyIcon={<Star size={32} fontSize="inherit" />}
                      sx={{ '& .MuiRating-iconEmpty': { color: 'blue.500' } }}
                    />
                  )}
                />
                <Controller
                  name="ratingComment"
                  render={({ field }) => (
                    <TextField {...field} size="small" multiline type="text" helperText="Please provide feedback" />
                  )}
                />
              </Stack>
            )}
            <Box
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', mt: 1, mb: 3 }}
            >
              <Typography color="gray.700">{t('consultationFeedback.modal.question')}</Typography>
            </Box>

            <Box sx={{ px: 1, mb: 2 }}>
              <Controller
                name="feedback"
                control={methods.control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    onChange={event =>
                      field.onChange({
                        type: event.target.value as ConsultationFeedbackType,
                      })
                    }
                  >
                    {Object.values(consultationFeedbackOptions).map(({ type, text }, i) => (
                      <FormControlLabel
                        data-testid={`consultation-feedback-modal-${type.replace(/[\s_]+/g, '-').toLocaleLowerCase()}`}
                        key={i}
                        value={type}
                        sx={{
                          mr: 0,
                        }}
                        control={<Radio sx={{ '&.Mui-checked': { color: 'blue.500' } }} />}
                        checked={field.value?.type === type}
                        label={
                          <Stack direction="column">
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: field.value?.type === type ? 700 : 400, pl: 0.5 }}
                            >
                              {text}
                            </Typography>

                            {type === ConsultationFeedbackType.CANCEL_CONSULTATION &&
                            field.value?.type === ConsultationFeedbackType.CANCEL_CONSULTATION ? (
                              <Typography sx={{ color: 'red.600', fontSize: '12px', mt: 0.5, pl: 0.5 }}>
                                {t('consultationFeedback.modal.cancelConsultationAnswerWarning')}
                              </Typography>
                            ) : null}
                          </Stack>
                        }
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Box>
          </DialogContent>

          <DialogActions sx={{ pt: 0, px: 2, pb: 2, flexDirection: 'column' }}>
            <LoadingButton
              variant="contained"
              fullWidth
              disableElevation
              sx={{
                backgroundColor: 'blue.500',
                fontWeight: 'bold',
                '&.Mui-disabled': { backgroundColor: 'blue.200' },
              }}
              onClick={onConfirm}
              loading={methods.formState.isValidating || methods.formState.isSubmitting}
              disabled={!methods.formState.isValid}
              data-testid="consultation-feedback-modal-submit-button"
            >
              {t('common.submit')}
            </LoadingButton>

            <DarkTooltip title={t('consultationFeedback.modal.disclaimerTooltip')} placement="bottom" arrow>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1, cursor: 'pointer' }}>
                <SvgIcon
                  aria-hidden="true"
                  component={Pencil}
                  fontSize="small"
                  inheritViewBox
                  sx={{ color: 'gray.700', mr: 1 }}
                />

                <Typography color="gray.700" variant="body2">
                  {t('consultationFeedback.modal.disclaimer')}
                </Typography>
              </Box>
            </DarkTooltip>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  )
}
