import { Box, Stack, Typography, Pagination, Button, Alert, CircularProgress, Skeleton, Divider } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import OfflineImage from '@app/assets/images/offline.svg'
import { HeroCard } from '@app/components/Card'
import CategoryFilter from '@app/components/CategoryFilter/CategoryFilter'
import { Page, PageHeading } from '@app/components/Page'
import { QueueConsultationList } from '@app/components/Queue/QueueConsultationList'
import { QueueEmpty } from '@app/components/Queue/QueueEmpty'
import { useAuth } from '@app/context/auth'
import { useAssignedConsultations } from '@app/hooks/use-assigned-consultations'
import { useNewQueueConsultationsTotal } from '@app/hooks/use-new-queue-consultations-total'
import { useOnlineState } from '@app/hooks/use-online-state'
import { useQueuedConsultations } from '@app/hooks/use-queued-consultations'
import { ConsultationStatus } from '@app/types'

const WelcomeBanner: React.FC = () => {
  const { t } = useTranslation()
  const auth = useAuth()

  return (
    <HeroCard>
      <Box data-test="welcome-label">
        <Typography color={'gray.700'} mb={1}>
          {t('pages.home.welcome')}
        </Typography>
        <Typography variant="h3" color={'gray.900'} fontSize={20} mb={4}>
          {auth.profile?.clinicianData?.professionTitle} {auth.profile?.firstName} {auth.profile?.lastName}
        </Typography>
        <img width={160} src={OfflineImage} alt="example" />
        <Typography color={'gray.700'} fontSize={14} mt={4}>
          {t('pages.home.setOnlineStatus')}
        </Typography>
      </Box>
    </HeroCard>
  )
}

interface LocationState {
  highlightConsultationId?: number
}

const Queue: React.FC = () => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(1)
  const location = useLocation()
  const locationState = location.state as LocationState

  const { categories } = useOnlineState()
  const { profile } = useAuth()

  const assignedConsultations = useAssignedConsultations({ clinicianId: profile?.id })

  const queuedConsultations = useQueuedConsultations({
    categories,
    page: currentPage,
    skip: assignedConsultations.isLoading,
  })

  // prefetch the next page to cache it for improved performance
  const { refresh: prefetchNextPage } = useQueuedConsultations({
    categories,
    page: currentPage + 1,
    skip: queuedConsultations.isLoading || currentPage >= queuedConsultations.pagination.pageCount,
  })

  const newQueueConsultationsTotal = useNewQueueConsultationsTotal({
    status: [ConsultationStatus.QUEUED, ConsultationStatus.ACTIVE, ConsultationStatus.CLOSED],
    categories,
    updatedFrom: queuedConsultations.fetchedAt,
    skip: queuedConsultations.isLoading,
  })

  useEffect(
    // once we've highlighted the consultation, clear the state
    // & use window to prevent unnecessary re-render
    function clearLocationState() {
      if (locationState) {
        window.history.replaceState({}, document.title)
      }
    },
    [locationState]
  )

  const handlePageChange = useCallback((_: React.ChangeEvent<unknown>, value: number) => setCurrentPage(value), [])

  const handleCategoryChange = useCallback(() => setCurrentPage(1), [])

  const handleRefresh = useCallback(() => {
    queuedConsultations.refresh()
    prefetchNextPage()
  }, [prefetchNextPage, queuedConsultations])

  const hasAssignedConsultation = assignedConsultations.consultations.length > 0
  const isEmpty = !queuedConsultations.callbacks.length && !queuedConsultations.consultations.length

  return (
    <>
      {assignedConsultations.hasError ? (
        <Alert sx={{ alignItems: 'center' }} severity="error">
          {t('pages.queue.error')}
        </Alert>
      ) : (
        <Stack spacing={5}>
          {assignedConsultations.consultations.length > 0 && (
            <QueueConsultationList
              consultations={assignedConsultations.consultations}
              title={t('pages.queue.assignedToMe')}
              isLoading={assignedConsultations.isLoading}
              onRefresh={handleRefresh}
            />
          )}

          <Typography fontWeight={700}>
            {t('pages.queue.patientsInQueue', { count: queuedConsultations.pagination.total })}{' '}
            {queuedConsultations.isLoading ? (
              <CircularProgress size={16} aria-label={t('common.loading')} sx={{ ml: 1 }} />
            ) : (
              `(${queuedConsultations.pagination.total})`
            )}
          </Typography>

          {newQueueConsultationsTotal.total > 0 && (
            <Alert
              sx={{ alignItems: 'center' }}
              severity="info"
              action={
                <Button color="inherit" onClick={handleRefresh} sx={{ fontWeight: 'bold' }}>
                  {t('common.refresh')}
                </Button>
              }
            >
              <Stack>
                <Typography fontWeight="bold">{t('pages.queue.queueUpdated.title')}</Typography>
                <Typography mt={1}>{t('pages.queue.queueUpdated.description')}</Typography>
              </Stack>
            </Alert>
          )}

          <CategoryFilter onChange={handleCategoryChange} />

          {queuedConsultations.isLoading ? (
            <>
              <Typography role="status" sx={visuallyHidden}>
                {t('common.loading')}
              </Typography>
              <Stack spacing={4} mt={0}>
                <Divider />
                <Skeleton height={140} animation="wave" sx={{ borderRadius: 2, transform: 'scale(1)' }} />
                <Skeleton height={140} animation="wave" sx={{ borderRadius: 2, transform: 'scale(1)' }} />
              </Stack>
            </>
          ) : isEmpty ? (
            <QueueEmpty />
          ) : (
            <>
              {queuedConsultations.callbacks.length > 0 && (
                <QueueConsultationList
                  consultations={queuedConsultations.callbacks}
                  title={t('pages.queue.callbacks')}
                  hasAssigned={hasAssignedConsultation}
                  hasError={queuedConsultations.hasError}
                  fetchedAt={queuedConsultations.fetchedAt}
                  highlightConsultationId={locationState?.highlightConsultationId}
                  onRefresh={handleRefresh}
                />
              )}
              <QueueConsultationList
                consultations={queuedConsultations.consultations}
                title={t('pages.queue.nextPatient')}
                hasAssigned={hasAssignedConsultation}
                hasError={queuedConsultations.hasError}
                fetchedAt={queuedConsultations.fetchedAt}
                onRefresh={handleRefresh}
              />
              {queuedConsultations.pagination.pageCount > 1 && (
                <Stack spacing={2} sx={{ alignItems: 'center' }}>
                  <Pagination
                    count={queuedConsultations.pagination.pageCount}
                    page={currentPage}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePageChange}
                  />
                </Stack>
              )}
            </>
          )}
        </Stack>
      )}
    </>
  )
}

export const HomePage: React.FC = () => {
  const { t } = useTranslation()
  const { online } = useOnlineState()

  return (
    <Page>
      <PageHeading>{t('pages.home.heading')}</PageHeading>
      {online ? <Queue /> : <WelcomeBanner />}
    </Page>
  )
}
