import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@app/components/Card'
import { Page, PageHeading } from '@app/components/Page'
import { SearchEmptyResults } from '@app/components/SearchPatientsTable/SearchEmptyResults'
import { SearchPatientsError } from '@app/components/SearchPatientsTable/SearchPatientsError'
import { SearchPatientsTable } from '@app/components/SearchPatientsTable/SearchPatientsTable'
import { ErraticPatientsProvider } from '@app/context/erratic-patients'
import { SearchPatientsForm } from '@app/forms/Search/SearchPatients'
import { usePatientsSearch } from '@app/hooks/use-patients-search'

const SkeletonState = () => {
  return (
    <Card sx={{ mt: 6 }}>
      <Stack direction="row" justifyContent="space-between">
        <Skeleton variant="text" width="15%" />
        <Skeleton variant="text" width="15%" />
        <Skeleton variant="text" width="15%" />
        <Skeleton variant="text" width="15%" />
      </Stack>
    </Card>
  )
}

export const PatientsPage: React.FC = () => {
  const { t } = useTranslation()

  const { isLoading, error, searchResults, onSearch, onClear } = usePatientsSearch()

  return (
    <ErraticPatientsProvider allPatients>
      <Page>
        <PageHeading>{t('pages.patients.search.heading')}</PageHeading>

        <Box sx={{ background: 'white', borderRadius: 2, px: 5, pt: 4, pb: 4.5 }}>
          <SearchPatientsForm onSubmit={onSearch} onClear={onClear} />
        </Box>

        {isLoading ? (
          <SkeletonState />
        ) : error ? (
          <Stack sx={{ mt: 6 }}>
            <SearchPatientsError />
          </Stack>
        ) : searchResults && searchResults.length === 0 ? (
          <Stack sx={{ mt: 6 }}>
            <SearchEmptyResults />
          </Stack>
        ) : searchResults && searchResults.length > 0 ? (
          <Stack sx={{ mt: 6 }}>
            <SearchPatientsTable patients={searchResults} />
          </Stack>
        ) : null}
      </Page>
    </ErraticPatientsProvider>
  )
}
