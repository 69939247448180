import { proxy } from 'valtio'

import { AdditionalInformation } from '@app/types'

interface State {
  additionalInformation?: AdditionalInformation
  formStatus: {
    isValid: boolean
  }
}

const state = proxy<State>({
  additionalInformation: {
    curp: undefined,
    address: undefined,
    weight: undefined,
    height: undefined,
  },
  formStatus: {
    isValid: true,
  },
})

const setAdditionalInformation = (newState: AdditionalInformation) => {
  state.additionalInformation = { ...state.additionalInformation, ...newState }
}

const setFormIsValid = (isValid: boolean) => {
  state.formStatus.isValid = isValid
}

export const useConsultationAdditionalInformationState = () => {
  return {
    state,
    setAdditionalInformation,
    setFormIsValid,
  }
}
