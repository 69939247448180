import { proxy } from 'valtio'

import { JournalState } from '@app/types'
import { sanitizeJournalData } from '@app/util/journal-data-formatting'

interface State {
  journal?: JournalState
  formStatus: {
    isValid: boolean
  }
}

const state = proxy<State>({
  journal: {
    currentSituation: undefined,
    status: undefined,
    assessment: undefined,
    diagnosis: [],
    plannedActions: undefined,
  },
  formStatus: {
    isValid: true,
  },
})

const setJournalState = (newJournal?: JournalState) => {
  state.journal = {
    ...state.journal,
    ...sanitizeJournalData(newJournal),
  }
}

const setFormIsValid = (isValid: boolean) => {
  state.formStatus.isValid = isValid
}

export const useConsultationJournalState = () => {
  return {
    state,
    setJournalState,
    setFormIsValid,
  }
}

/*
 TODO:

 1. Private notes is not showing the updated validation error
*/
