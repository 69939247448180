import qs from 'qs'
import useSWR, { SWRConfiguration } from 'swr'

import fetcher from '@app/lib/fetcher'
import { revalidateDataConfig } from '@app/lib/request'
import { Consultation } from '@app/types'

type UseConsultationOptions = {
  ignoreCache?: boolean
}

type Configuration = SWRConfiguration<Consultation>

export function useConsultation(
  id: null | number,
  opts: UseConsultationOptions = {},
  configuration: Configuration = {}
) {
  const queryParams = qs.stringify({ ignoreCache: Boolean(opts.ignoreCache) })

  const {
    data: consultation,
    error,
    isValidating,
  } = useSWR<Consultation>(id ? `/consultations/${id}?${queryParams}` : null, {
    fetcher: fetcher.api,
    ...revalidateDataConfig,
    ...configuration,
  })

  return {
    consultation: consultation ?? null,
    hasError: !!error,
    isLoading: (!error && !consultation) || isValidating,
  }
}
