import qs from 'qs'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'

export interface StrapiFeatureFlags {
  attributes: FeatureFlags
}

export type FeatureFlags = {
  enablePatientScheduling: boolean
  enableClinicianScheduling: boolean
  enableClinicianCallRating: boolean
}

export function useFeatureFlags(): { featureFlags: FeatureFlags | undefined; isLoading: boolean; error: unknown } {
  const query = qs.stringify(
    {
      fields: '*',
      localeFallback: 'en',
    },
    { encodeValuesOnly: true }
  )

  const { data, error } = useSWR<{ data: StrapiFeatureFlags }>(`/api/feature-flag?${query}`, fetcher.strapi)

  return {
    featureFlags: data?.data?.attributes,
    isLoading: !error && !data,
    error,
  }
}
