import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, Button, CircularProgress, Typography, TypographyProps } from '@mui/material'
import { noop } from 'lodash-es'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CodeInput } from '@app/components/CodeInput'
import { ForceLoginModal } from '@app/components/ForceLoginModals/ForceLoginModal'
import { RetryForceLoginModal } from '@app/components/ForceLoginModals/RetryForceLoginModal'
import { useConfig } from '@app/context/config'
import { useFocusableError } from '@app/hooks/use-focusable-error'
import { OnCodeSubmit, OnForceLogin, OnResendCode } from '@app/hooks/use-login'
import { useCodeSchema } from '@app/schemas/use-code'

interface CodeFormValues {
  code: string
}

interface CodeFormProps {
  heading?: React.ReactNode
  headingProps?: TypographyProps<React.ElementType, { component?: React.ElementType }>
  submitLabel?: string
  maskedPhone: string
  onSubmit: OnCodeSubmit
  onResend: OnResendCode
  onForceLogin?: OnForceLogin
}

export const CodeForm: React.FC<CodeFormProps> = ({
  heading,
  submitLabel,
  maskedPhone,
  headingProps,
  onSubmit,
  onResend,
  onForceLogin = noop,
}) => {
  const { t } = useTranslation()
  const { config } = useConfig()
  const codeSchema = useCodeSchema()
  const headingRef = useRef<HTMLHeadingElement>(null)
  const [resendCount, setResendCount] = useState(0)
  const [isResending, setIsResending] = useState(false)
  const [resendSuccess, setResendSuccess] = useState(false)
  const [maxSessionsToken, setMaxSessionsToken] = useState<string | null>(null)
  const [showRetryForceLoginModal, setShowRetryForceLoginModal] = useState(false)
  const [isForceLoginLoading, setIsForceLoginLoading] = React.useState(false)
  const { error, setError, errorRef } = useFocusableError()

  const {
    control,
    handleSubmit,
    formState: { errors = {}, isSubmitted, isSubmitting } = {},
  } = useForm<CodeFormValues>({
    resolver: yupResolver(codeSchema),
  })

  // a11y: When the page changes, let's focus the heading so that a
  // screen reader user understands the change in context
  useEffect(function focusHeading() {
    headingRef.current?.focus()
  }, [])

  function handleSubmitError() {
    setError(t('forms.code.error'))
  }

  const handleFormSubmit: SubmitHandler<CodeFormValues> = async input => {
    setError('')

    try {
      const result = await onSubmit(input)
      if (result.kind === 'max-session-reached') {
        setMaxSessionsToken(result.maxSessionsToken)
      }
    } catch (err) {
      handleSubmitError()
    }
  }

  function handleResendError() {
    setError(t('forms.code.resend.error'))
    setIsResending(false)
  }

  function handleRetry() {
    setMaxSessionsToken(null)
    window.location.reload()
  }

  async function handleForceLogin() {
    if (maxSessionsToken) {
      setIsForceLoginLoading(true)
      try {
        await onForceLogin(maxSessionsToken)
      } catch (error) {
        setIsForceLoginLoading(false)
        setShowRetryForceLoginModal(true)
      }
    }
  }

  async function handleClickResend() {
    setIsResending(true)
    setResendSuccess(false)
    setError('')

    try {
      await onResend()
      setIsResending(false)
      setResendSuccess(true)
      setResendCount(c => c + 1)
    } catch (err) {
      handleResendError()
    }
  }

  const codeExpiry = useMemo(() => {
    return Date.now() + config.otpValidity * 60 * 1000
    // resendCount makes sure we reset the expiry when a new code is requested
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendCount, config.otpValidity])

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
      <Typography
        component="h2"
        mt={2}
        fontWeight="bold"
        ref={headingRef}
        tabIndex={-1}
        sx={{
          // ux: if we've submitted the form with a mouse,
          // let's not show the focus ring
          outlineWidth: 0,
          ':focus-visible': {
            outlineWidth: 1,
          },
        }}
        {...headingProps}
      >
        {heading ?? t('forms.code.heading')}
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }} ref={errorRef} tabIndex={-1}>
          {error}
        </Alert>
      )}
      {resendSuccess && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {t('forms.code.resend.success')}
        </Alert>
      )}
      <Typography mt={2}>
        {t('forms.code.intro')}
        <Typography mt={2} fontWeight="bold" component="span" display="block">
          {maskedPhone}
        </Typography>
      </Typography>
      <Box mt={4}>
        <Controller
          name="code"
          control={control}
          render={({ field: { onChange, onBlur } }) => (
            <CodeInput
              count={config.otpLength}
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={errors.code?.message}
              required
              // it's ok to focus an input when there's a validation error
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={'code' in errors && isSubmitted && !isSubmitting}
            />
          )}
        />
      </Box>
      {isResending ? (
        <Box my={4} display="flex" justifyContent="center">
          <CircularProgress size={24} aria-label={t('forms.code.resend.loading')} />
          <Typography ml={2}>{t('forms.code.resend.loading')}</Typography>
        </Box>
      ) : null}
      <Typography mt={2}>
        <Countdown
          key={resendCount}
          autoStart
          date={codeExpiry}
          renderer={({ total, minutes, seconds }) => {
            return total
              ? t('forms.code.helpText', {
                  remaining: `${zeroPad(minutes)}:${zeroPad(seconds)}`,
                })
              : t('forms.code.otpExpired')
          }}
        />
      </Typography>
      <Typography mt={2}>
        {t('forms.code.resend.noCode')}
        <Button
          onClick={handleClickResend}
          variant="text"
          sx={{ p: 0, ml: 1, mb: 0.5, fontWeight: 'normal', textDecoration: 'underline' }}
          data-test="request-code-button"
        >
          {t('forms.code.resend.requestNewCode')}
        </Button>
      </Typography>
      <LoadingButton
        variant="contained"
        fullWidth
        sx={{ mt: 6 }}
        type="submit"
        loading={isSubmitting}
        loadingPosition={isSubmitting ? 'end' : undefined}
        data-test="submit-button"
      >
        {submitLabel ?? t('common.submit')}
      </LoadingButton>

      {maxSessionsToken && !showRetryForceLoginModal && (
        <ForceLoginModal
          onConfirm={handleForceLogin}
          onClose={handleRetry}
          loading={isForceLoginLoading}
          buttonTestID="force-submit-button"
        />
      )}
      {maxSessionsToken && showRetryForceLoginModal && (
        <RetryForceLoginModal onConfirm={handleForceLogin} onClose={handleRetry} loading={isForceLoginLoading} />
      )}
    </form>
  )
}
