import {
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { ErrorResult } from '@app/pages/Monitoring/CustomTable/ErrorResult'
import { NoSearchResults } from '@app/pages/Monitoring/CustomTable/NoSearchResults'
import { HeadCell } from '@app/pages/Monitoring/CustomTable/types'
import { getComparator, SortingOrder, stableSort } from '@app/pages/Monitoring/CustomTable/utils'

const DEFAULT_PAGE = 1
const DEFAULT_ROWS_PER_PAGE = 7
const MUI_DEFAULT_ROW_HEIGHT = 53
const NO_DATA_ROW_HEIGHT = MUI_DEFAULT_ROW_HEIGHT * DEFAULT_ROWS_PER_PAGE

interface CustomTableHeadProps {
  cells: readonly HeadCell[]
  order: SortingOrder
  orderBy: string
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
}

interface CustomPaginationProps {
  page: number
  totalPages: number
  onChangeCallback: (event: React.ChangeEvent<unknown>, page: number) => void
}

interface CustomTableProps<T> {
  isLoading: boolean
  hasError: boolean
  ariaLabel: string
  headCells: readonly HeadCell[]
  rows: T[]
  noDataColSpan?: number
  defaultOrder?: SortingOrder
  defaultOrderBy?: string

  children(rows: T[]): unknown
}

const NoDataRowSkeleton: React.FC<{ noDataColSpan: number }> = ({ noDataColSpan, children }) => {
  return (
    <TableRow
      style={{
        height: NO_DATA_ROW_HEIGHT,
      }}
    >
      <TableCell colSpan={noDataColSpan}>{children}</TableCell>
    </TableRow>
  )
}

export const CustomTableRow = styled(TableRow)(({ position }: { position: number }) => ({
  backgroundColor: position % 2 === 0 ? '#F2F1F6' : 'white',
  height: '61px',
}))

export const CustomTableCell = styled(TableCell)(() => ({
  borderBottom: 'none',
  paddingLeft: '32px',
  verticalAlign: 'middle',
  lineHeight: '21px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '300px',
}))

export const CustomTableHead = (props: CustomTableHeadProps) => {
  const { cells, order, orderBy, onRequestSort } = props

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow sx={{ height: '66px' }}>
        {cells.map(headCell => (
          <CustomTableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              sx={{ color: 'gray.600', fontSize: '12px' }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : SortingOrder.ASC}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label.toUpperCase()}
            </TableSortLabel>
          </CustomTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export const CustomPagination = ({ page, totalPages, onChangeCallback }: CustomPaginationProps) => {
  return (
    <Stack sx={{ padding: '0 16px 16px' }} direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <Pagination
        size={'medium'}
        page={page}
        count={totalPages}
        variant="outlined"
        shape="rounded"
        onChange={onChangeCallback}
      />
    </Stack>
  )
}

export const CustomTable: React.FC<CustomTableProps<never>> = ({
  isLoading = true,
  hasError,
  children,
  ariaLabel,
  headCells,
  rows,
  noDataColSpan = 0,
  defaultOrder = SortingOrder.ASC,
  defaultOrderBy = '',
}) => {
  const [order, setOrder] = React.useState<SortingOrder>(defaultOrder)
  const [orderBy, setOrderBy] = React.useState<string>(defaultOrderBy)
  const [page, setPage] = React.useState(DEFAULT_PAGE)

  const totalPages = useMemo(() => Math.ceil(rows.length / DEFAULT_ROWS_PER_PAGE), [rows])

  const visibleRows = React.useMemo(() => {
    const startIndex = (page - 1) * DEFAULT_ROWS_PER_PAGE
    const endIndex = startIndex + DEFAULT_ROWS_PER_PAGE
    const sortedRows = stableSort(rows, getComparator(order, orderBy))

    return sortedRows.slice(startIndex, endIndex)
  }, [order, orderBy, page, rows])

  const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }, [])

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: string) => {
      const isAsc = orderBy === property && order === SortingOrder.ASC
      setOrder(isAsc ? SortingOrder.DESC : SortingOrder.ASC)
      setOrderBy(property)
      setPage(DEFAULT_PAGE)
    },
    [setOrder, setOrderBy, order, orderBy]
  )

  return (
    <Stack sx={{ width: '100%' }} direction="column" alignItems="center" spacing={2}>
      <Paper sx={{ width: '100%', borderRadius: '8px', boxShadow: 'none' }}>
        <TableContainer sx={{ borderRadius: '8px' }}>
          <Table
            sx={{ minWidth: 750, whiteSpace: 'nowrap' }}
            aria-labelledby={ariaLabel}
            size={'medium'}
            data-testid={ariaLabel.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}
          >
            <CustomTableHead cells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {children(visibleRows)}
              {!isLoading && hasError && (
                <NoDataRowSkeleton noDataColSpan={noDataColSpan}>
                  <ErrorResult />
                </NoDataRowSkeleton>
              )}
              {!isLoading && !hasError && !visibleRows.length && (
                <NoDataRowSkeleton noDataColSpan={noDataColSpan}>
                  <NoSearchResults />
                </NoDataRowSkeleton>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CustomPagination page={page} totalPages={totalPages} onChangeCallback={handleChangePage} />
    </Stack>
  )
}
