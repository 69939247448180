import { Grid, List, ListItem, ListItemButton, MenuItem, Select, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { LinkBehavior } from '@app/components/LinkBehavior'
import { Page, PageHeading } from '@app/components/Page'
import * as clinician from '@app/lib/clinician'

export const WatchlistPage = () => {
  const { t } = useTranslation()
  const [sortOrder, setSortOrder] = useState('asc')

  const { data } = useSWR({ sortOrder }, clinician.watchlist.all)

  return (
    <Page>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <PageHeading mb={0}>{t('pages.watchlist.heading')}</PageHeading>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography id="sort-order">{t('common.sortBy')}</Typography>
          <Select
            labelId="sort-order"
            value={sortOrder}
            onChange={evt => setSortOrder(evt.target.value)}
            sx={{ bgcolor: 'white' }}
          >
            <MenuItem value="asc">{t('common.sortAsc')}</MenuItem>
            <MenuItem value="desc">{t('common.sortDesc')}</MenuItem>
          </Select>
        </Stack>
      </Stack>

      <List aria-label={t('pages.watchlist.tableLabel')} component={Stack} spacing={2} role="table" sx={{ mt: 2 }}>
        <ListItem component="div" role="rowgroup" disablePadding sx={{ color: 'gray.700' }}>
          <Grid role="row" container px={3}>
            <Grid role="columnheader" aria-sort={sortOrder === 'asc' ? 'ascending' : 'descending'} item xs>
              <Typography variant="body2">{t('common.patient.name')}</Typography>
            </Grid>

            <Grid role="columnheader" item xs>
              <Typography variant="body2">{t('common.patient.sexLabel')}</Typography>
            </Grid>

            <Grid role="columnheader" item xs>
              <Typography variant="body2">{t('common.patient.dateOfBirth')}</Typography>
            </Grid>

            <Grid role="columnheader" item xs>
              <Typography variant="body2">{t('common.patient.id')}</Typography>
            </Grid>

            <Grid role="columnheader" item xs>
              <Typography variant="body2">{t('common.patient.phone')}</Typography>
            </Grid>
          </Grid>
        </ListItem>

        {data?.map(patient => (
          <ListItem component="div" role="row" key={patient.emrId} disablePadding>
            <ListItemButton
              component={LinkBehavior}
              href={`/patients/${patient.emrId}`}
              sx={{ bgcolor: 'white', px: 3, py: 4 }}
            >
              <Grid alignItems="center" container sx={{ color: 'gray.900' }}>
                <Grid role="cell" item xs>
                  <Typography fontWeight="bold">{patient.legalName}</Typography>
                </Grid>
                <Grid role="cell" item xs>
                  <Typography>{patient.sex}</Typography>
                </Grid>
                <Grid role="cell" item xs>
                  <Typography>{patient.dateOfBirth}</Typography>
                </Grid>
                <Grid role="cell" item xs>
                  <Typography>{patient.emrId}</Typography>
                </Grid>
                <Grid role="cell" item xs>
                  <Typography>{patient.phone}</Typography>
                </Grid>
              </Grid>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Page>
  )
}
