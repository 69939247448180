import * as React from 'react'
import { SVGProps } from 'react'

interface Props {
  color?: string
  size?: number
}

export const DoctorIcon = (props: SVGProps<SVGSVGElement> & Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ?? 32}
    height={props.size ?? 32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? '#2C2C2C'}
      fillRule="evenodd"
      d="M3.468 11.846h.968c.46 0 .827.376.906.83a4.873 4.873 0 0 0 9.602 0c.078-.454.444-.83.905-.83h.968V4.337a.834.834 0 0 0-.834-.834h-.224a1.668 1.668 0 1 1 0-1.669h.224a2.503 2.503 0 0 1 2.502 2.503v7.509c.461 0 .839.374.797.833a9.177 9.177 0 0 1-6.637 7.996v2.017c0 1.09-.696 2.016-1.668 2.36a4.38 4.38 0 0 0 8.76-.066V21.65a4.797 4.797 0 0 1 9.594 0v2.02a2.504 2.504 0 1 1-1.668 0v-2.02a3.129 3.129 0 1 0-6.257 0v3.337a6.049 6.049 0 0 1-12.097.066 2.504 2.504 0 0 1-1.67-2.36v-2.017a9.178 9.178 0 0 1-6.636-7.996c-.042-.459.336-.833.797-.833V4.337a2.503 2.503 0 0 1 2.502-2.503h.224a1.668 1.668 0 1 1 0 1.669h-.224a.834.834 0 0 0-.834.834v7.509Zm13.995 1.668h-.996a6.54 6.54 0 0 1-12.649 0h-.996a7.509 7.509 0 0 0 14.642 0ZM29.331 26.03a.834.834 0 1 0-1.668 0 .834.834 0 0 0 1.668 0Zm-18.354-3.337v-1.669H9.308v1.669a.834.834 0 0 0 1.669 0Z"
      clipRule="evenodd"
    />
  </svg>
)
