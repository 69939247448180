import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box, Chip } from '@mui/material'
import i18n from 'i18next'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DarkTooltip } from '@app/components/Tooltip/DarkTooltip'
import { PatientQueue, PatientsQueueAction, usePatientsQueue } from '@app/hooks/use-patients-queue'
import { CustomTable, CustomTableCell, CustomTableRow } from '@app/pages/Monitoring/CustomTable'
import { TableBodySkeleton } from '@app/pages/Monitoring/CustomTable/TableBodySkeleton'
import { HeadCell } from '@app/pages/Monitoring/CustomTable/types'
import { SortingOrder } from '@app/pages/Monitoring/CustomTable/utils'
import {
  ageHeader,
  assignedToHeader,
  categoryHeader,
  patientHeader,
  phoneHeader,
  productHeader,
  sexHeader,
  statusHeader,
  timeInServiceHeader,
} from '@app/pages/Monitoring/tableRows'
import { fieldContains, formatPhoneNumber, getLatestAction } from '@app/pages/Monitoring/utils'
import { colors } from '@app/theme/color'
import { ActionType, Sex } from '@app/types'
import { calculateAge } from '@app/util/calculate-age'
import { elapsedTime } from '@app/util/elapsed-time/elapsed-time'

export interface PatientRowData {
  key: number
  id: string
  age: number | null
  phone: string
  sex: Sex | null
  status: string
  category: string
  product: string
  assignedTo: string
  emrId: string
  timeInService?: string
}

const patientHeadCells: readonly HeadCell[] = [
  patientHeader,
  phoneHeader,
  ageHeader,
  sexHeader,
  statusHeader,
  categoryHeader,
  productHeader,
  assignedToHeader,
  timeInServiceHeader,
]

function getStatus(actions: PatientsQueueAction[]): string {
  const latestAction = getLatestAction(actions)
  if (!latestAction) {
    return ''
  }

  if (latestAction.type === ActionType.QUEUED) {
    return i18n.t('pages.monitoring.status.firstTimeInQueue')
  } else if (latestAction.type === ActionType.UNASSIGNED) {
    const unassignedTimes = actions.filter(action => action.type === ActionType.UNASSIGNED).length
    return i18n.t('pages.monitoring.status.inQueueUnassigned', { count: unassignedTimes })
  } else {
    return i18n.t('pages.monitoring.status.pickedUp')
  }
}

function mapRow({ actions, clinician, patient, category, product, consultation }: PatientQueue): PatientRowData {
  const queuedAction = actions.find(a => a.type === ActionType.QUEUED)

  return {
    key: consultation.id,
    id: patient.cardoId,
    age: patient.dateOfBirth ? calculateAge(patient.dateOfBirth) : null,
    phone: formatPhoneNumber(patient.phone || ''),
    sex: patient.sex,
    status: getStatus(actions),
    category: category.name,
    product: product.name,
    assignedTo: [clinician?.firstName, clinician?.lastName].join(' ').trim() || clinician?.username || '-',
    emrId: patient?.emrId || '',
    timeInService: queuedAction?.createdAt ?? '',
  }
}

export const PatientMonitoringTable = ({ searchFilter }: { searchFilter?: string }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data, patientsQueue, isLoading, hasError } = usePatientsQueue()
  const [filteredRows, setFilteredRows] = useState<PatientRowData[]>([])

  const handleClick = useCallback(
    (emrId: string) => {
      navigate(`/patients/${emrId}`, { state: { previousLocationPathname: '/monitoring' } })
    },
    [navigate]
  )

  useEffect(() => {
    if (!patientsQueue) return

    const filtered = patientsQueue
      .map(mapRow)
      .filter(
        (row: PatientRowData) =>
          !searchFilter ||
          fieldContains(row.id, searchFilter) ||
          fieldContains(row.phone, searchFilter) ||
          fieldContains(row.assignedTo, searchFilter) ||
          fieldContains(row.category, searchFilter)
      )

    setFilteredRows(filtered)
  }, [patientsQueue, searchFilter])

  return (
    <CustomTable
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // this table shows the patient's data for its consultation in the queue
      rows={filteredRows as PatientRowData[]}
      noDataColSpan={9}
      isLoading={!data && isLoading}
      hasError={hasError}
      ariaLabel={'monitoringPatientsTable'}
      headCells={patientHeadCells}
      defaultOrder={SortingOrder.ASC}
      defaultOrderBy={'timeInService'}
    >
      {data
        ? (visibleRows: PatientRowData[]) => {
            return visibleRows.map((row, index) => {
              return (
                <CustomTableRow
                  hover
                  sx={{ cursor: 'pointer' }}
                  key={row.key}
                  position={index}
                  onClick={() => handleClick(row.emrId)}
                  data-testid="patients-table-row"
                >
                  <CustomTableCell data-testid="patients-table-cell-id">{row.id}</CustomTableCell>
                  <CustomTableCell data-testid="patients-table-cell-phone">{row.phone}</CustomTableCell>
                  <CustomTableCell>{row.age ?? ''}</CustomTableCell>
                  <CustomTableCell>{row.sex ?? ''}</CustomTableCell>
                  <CustomTableCell data-testid="patients-table-cell-status">{row.status}</CustomTableCell>
                  <CustomTableCell>{row.category}</CustomTableCell>
                  <CustomTableCell>{row.product}</CustomTableCell>
                  <CustomTableCell data-testid="patients-table-cell-assigned">{row.assignedTo || '-'}</CustomTableCell>
                  <CustomTableCell data-testid="patients-table-cell-time">
                    <Chip
                      sx={{
                        padding: '4px',
                        borderRadius: '8px',
                        backgroundColor: colors.green[100],
                        '&.MuiChip-root svg': {
                          width: '16px',
                        },
                      }}
                      avatar={<AccessTimeIcon />}
                      label={
                        <DarkTooltip
                          sx={{ maxWidth: '200px' }}
                          title={`${t('pages.monitoring.tables.patients.timeInServiceTooltip')} (HH:mm)`}
                          placement="bottom"
                          arrow
                        >
                          <Box>{row.timeInService ? elapsedTime(row.timeInService) : '--:--'}</Box>
                        </DarkTooltip>
                      }
                    />
                  </CustomTableCell>
                </CustomTableRow>
              )
            })
          }
        : () => (!hasError ? <TableBodySkeleton rows={4} cols={9} /> : null)}
    </CustomTable>
  )
}
