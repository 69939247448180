import * as Sentry from '@sentry/react'
import { Breadcrumb } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import * as React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT
const VERSION = import.meta.env.VITE_VERSION
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const VITE_API_URL = import.meta.env.VITE_API_URL

const ENABLE_SENTRY = ['dev', 'dev-vibe', 'staging', 'uat', 'uat-vibe', 'prod', 'prod-vibe'].includes(ENVIRONMENT)
const ENABLE_SENTRY_DEBUG = ['dev', 'dev-vibe', 'staging', 'uat', 'uat-vibe'].includes(ENVIRONMENT)

export function initSentry() {
  if (!ENABLE_SENTRY) {
    return
  }
  Sentry.init({
    dsn: SENTRY_DSN,
    release: VERSION,
    environment: ENVIRONMENT,
    debug: ENABLE_SENTRY_DEBUG,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
        tracePropagationTargets: [VITE_API_URL, /^\//],
      }),
      new Sentry.Replay({
        maskAllText: true,
        maskAllInputs: true,
        blockAllMedia: true,
      }),
    ],
    // Can be high for now since we have a small number of clinicians
    tracesSampleRate: 1.0,
    // Do not record the entire user session, only capture a replay when an error occurs
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })

  startSession()
}

const BREADCRUMB_CATEGORY: Breadcrumb['category'] = 'log'
const BREADCRUMB_LEVEL: Breadcrumb['level'] = 'log'

type BreadcrumbOptions = {
  level?: Breadcrumb['level']
  data?: Breadcrumb['data']
}

export function addSentryBreadcrumb(
  message: string,
  options: BreadcrumbOptions = {
    level: BREADCRUMB_LEVEL,
    data: undefined,
  }
) {
  const { level = BREADCRUMB_LEVEL, data = undefined }: BreadcrumbOptions = options
  if (!ENABLE_SENTRY) {
    return
  }
  Sentry.addBreadcrumb({
    category: BREADCRUMB_CATEGORY,
    message,
    level,
    data,
  })
}

function startSession() {
  const sessionId = crypto.randomUUID().toLowerCase()

  if (sessionStorage.getItem('sessionId') === null) {
    sessionStorage.setItem('sessionId', sessionId)
  }

  Sentry.setContext('session', { sessionId })
}

export function endSession() {
  sessionStorage.removeItem('sessionId')
  return startSession()
}

export function getSessionId(): string {
  return sessionStorage.getItem('sessionId') ?? ''
}

export function getTraceId() {
  /* TODO: investigate how to use Sentry's tracing API to generate a traceId
      - Sentry.Browser.startTransaction({ name: 'request' })
      - Sentry.Browser.setContext('trace', { traceId: crypto.randomUUID() })
      - Sentry.Browser.setTag('traceId', crypto.randomUUID())
  */

  return crypto.randomUUID().toLowerCase()
}
