import { Box, SvgIcon, Tooltip, Typography } from '@mui/material'
import NexmoClient from 'nexmo-client'
import { Phone } from 'phosphor-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStopwatch } from 'react-timer-hook'

import { DropdownButton } from '@app/components/TalkToPatient/DropdownButton'
import { useConsultationFeedback } from '@app/context/consultation-feedback'
import { getToken } from '@app/lib/auth'
import { dispatchConsultationAction } from '@app/lib/consultation'
import { apiRequest } from '@app/lib/request'
import { ActionType, CallType, Consultation, Patient } from '@app/types'

import { PatientCallPlayer } from './PatientCallPlayer'

export enum CallStatus {
  started = 'started',
  ringing = 'ringing',
  canceled = 'canceled',
  busy = 'busy',
  answered = 'answered',
  unanswered = 'unanswered',
  timeout = 'timeout',
  rejected = 'rejected',
  completed = 'completed',
  failed = 'failed',
}

type PhoneCallProps = {
  data: {
    consultation: {
      id: Consultation['id'] | null
    }
    patient: {
      emrId: Patient['emrId']
      firstName: Patient['firstName']
      lastName: Patient['lastName']
      legalName: Patient['legalName']
      phoneNumber: Patient['phone']
    }
  }
  onDropdownClick?: VoidFunction
}

function useButtonStatus(loading: boolean, status: CallStatus | null) {
  const { t } = useTranslation()

  if (loading) {
    return t(`call.button.statuses.started`)
  }

  switch (status) {
    case CallStatus.started:
    case CallStatus.ringing:
    case CallStatus.answered:
      return t(`call.button.statuses.${status}`)
    default:
      return t('pages.emr.callThePatient')
  }
}

export const PhoneCall: React.FC<PhoneCallProps> = ({ data, onDropdownClick }) => {
  const { t } = useTranslation()
  const stopwatchOffset = new Date()
  const { seconds, minutes, start, pause } = useStopwatch({ offsetTimestamp: stopwatchOffset })
  const [showCallPlayer, setShowCallPlayer] = useState(false)
  const [callStatus, setCallStatus] = useState<CallStatus | null>(null)
  const [nexmoCall, setNexmoCall] = useState<any>(null)
  const [muted, setMuted] = useState(false)
  const [loading, setLoading] = useState(false)
  const buttonStatus = useButtonStatus(loading, callStatus)
  const { setCallType, setIsModalOpened } = useConsultationFeedback()
  const missingPhoneNumber = !data.patient.phoneNumber

  const dispatchPhoneCallAction = (status: CallStatus) => {
    const consultationId = data.consultation.id

    if (!consultationId) return

    if (
      [
        CallStatus.busy,
        CallStatus.canceled,
        CallStatus.failed,
        CallStatus.rejected,
        CallStatus.timeout,
        CallStatus.unanswered,
      ].includes(status)
    ) {
      setIsModalOpened(true)
      return dispatchConsultationAction(consultationId, { type: ActionType.PHONE_CALL_FAILED })
    }
    if (status === CallStatus.answered) {
      return dispatchConsultationAction(consultationId, { type: ActionType.PHONE_CALL_STARTED })
    }
    if (status === CallStatus.completed) {
      setIsModalOpened(true)
      return dispatchConsultationAction(consultationId, { type: ActionType.PHONE_CALL_ENDED })
    }
  }

  const beginCall = async () => {
    setLoading(true)
    setCallType(CallType.PHONE_CALL)

    const { data: tokenData } = await apiRequest<{ token: string }>('/voice/token', { getToken })
    const jwt = tokenData?.token

    if (!jwt) {
      return console.log('No token, unable to make call')
    }

    new NexmoClient()
      .createSession(jwt)
      .then(app => {
        app.callServer(data.patient.emrId)

        app.on('member:call', (member: any, call: any) => {
          call.conversation.on('audio:mute:off', (member: any, event: any) => {
            if (event.conversation.me.id === member.memberId) {
              setMuted(false)
            }
          })

          call.conversation.on('audio:mute:on', (member: any, event: any) => {
            // set mute flag ONLY after verifying that it is the current user who
            // just muted themself and the SDK really did mute them.
            if (event.conversation.me.id === member.memberId) {
              setMuted(true)
            }
          })
        })

        app.on('call:status:changed', (call: any) => {
          console.log('STATUS:', call.status)
          setCallStatus(call.status)
          dispatchPhoneCallAction(call.status)

          if (
            [
              CallStatus.completed,
              CallStatus.rejected,
              CallStatus.canceled,
              CallStatus.timeout,
              CallStatus.unanswered,
            ].includes(call.status)
          ) {
            setNexmoCall(null)
            pause()
            setShowCallPlayer(false)
          }

          if (call.status === CallStatus.answered) {
            start()
          }

          if (call.status === CallStatus.started) {
            setLoading(false)
            setShowCallPlayer(true)
            setNexmoCall(call)
          }
        })
      })
      .catch(_ => {
        dispatchPhoneCallAction(CallStatus.failed)
        setIsModalOpened(true)
        setLoading(false)
      })
  }

  const handleHangUp = () => {
    nexmoCall.hangUp()
    setShowCallPlayer(false)
  }

  const handleMute = () => {
    nexmoCall.conversation.me.mute(!muted)
  }

  const handleChangeInputDevice = (id: string) => {
    // TODO: fix later
    nexmoCall.conversation.media.updateAudioConstraints({ deviceId: { exact: id } }, 'audio')
  }

  const handleChangeOutputDevice = (id: string) => {
    // TODO: fix later
    nexmoCall.conversation.media.updateAudioConstraints({ deviceId: { exact: id } }, 'audio')
  }

  return (
    <Box>
      <Tooltip title={data.patient.phoneNumber} placement="top" arrow disableHoverListener={missingPhoneNumber}>
        <Box>
          <DropdownButton
            onButtonClick={missingPhoneNumber ? undefined : beginCall}
            onDropdownClick={onDropdownClick}
            disabled={loading || nexmoCall}
            colorDisabled={missingPhoneNumber}
          >
            <SvgIcon aria-hidden="true" weight="fill" component={Phone} size={24} inheritViewBox sx={{ mr: 1 }} />
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {buttonStatus}
            </Typography>
          </DropdownButton>
        </Box>
      </Tooltip>

      {missingPhoneNumber && (
        <Box display="flex" justifyContent="center" sx={{ mt: '0.5rem' }}>
          <Typography color="error" variant="caption" sx={{ fontSize: '0.5rem' }}>
            {t('pages.emr.missingPhoneNumberError')}
          </Typography>
        </Box>
      )}

      {callStatus && showCallPlayer && (
        <PatientCallPlayer
          status={callStatus}
          muted={muted}
          patient={data.patient}
          minutes={minutes}
          seconds={seconds}
          onHangUp={handleHangUp}
          onMute={handleMute}
          onChangeInputDevice={handleChangeInputDevice}
          onChangeOutputDevice={handleChangeOutputDevice}
        />
      )}
    </Box>
  )
}
