import { Button } from '@mui/material'
import { ButtonOwnProps } from '@mui/material/Button/Button'
import { CaretLeft } from 'phosphor-react'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { colors } from '@app/theme/color'

interface Props {
  to: LinkProps['to']
  sx?: ButtonOwnProps['sx']
}

export function BackButton({ to, sx }: Props) {
  return (
    <Button
      component={Link}
      to={to}
      sx={{
        p: 0,
        minWidth: 0,
        ...sx,
      }}
    >
      <CaretLeft size={32} color={colors.gray[900]} />
    </Button>
  )
}
