import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@app/components/Card'
import { MedicalHistoryAllergies } from '@app/components/Consultation/common/MedicalHistoryAllergies'
import { MedicalHistoryNonPathological } from '@app/components/Consultation/common/MedicalHistoryNonPathological'
import { MedicalHistoryPathological } from '@app/components/Consultation/common/MedicalHistoryPathological'
import { DescriptionList } from '@app/components/EMR/common/DescriptionList'
import { MedicalHistory as IMedicalHistory, Patient, Prescriptions } from '@app/types'

type MedicalHistoryProps = {
  medicalHistory?: IMedicalHistory
  prescriptions?: Prescriptions | null
  patient: Patient
}

export const MedicalHistory: React.FC<MedicalHistoryProps> = ({ prescriptions, patient, medicalHistory }) => {
  const { t } = useTranslation()

  return (
    <>
      <Card sx={{ px: 3, py: 2, mt: 1 }}>
        <DescriptionList>
          <DescriptionList.Item sx={{ mb: 2 }}>
            <DescriptionList.Title>{t('pages.emr.medicalHistory.title')}</DescriptionList.Title>
          </DescriptionList.Item>

          <MedicalHistoryAllergies patient={patient} defaultValues={medicalHistory?.allergies} />

          <MedicalHistoryNonPathological defaultValues={medicalHistory?.nonPathologicalPersonalHistory} />

          <MedicalHistoryPathological
            patient={patient}
            prescriptions={prescriptions}
            defaultValues={medicalHistory?.pathologicalPersonalHistory}
          />
        </DescriptionList>
      </Card>
    </>
  )
}
