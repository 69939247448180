import { Box, CardActionArea, Chip, Grid, Stack, SvgIcon, Typography } from '@mui/material'
import { t } from 'i18next'
import { capitalize } from 'lodash-es'
import { CalendarX, CheckCircle, Prescription, XCircle } from 'phosphor-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@app/components/Card'
import { ConsultationIcon } from '@app/components/EMR/common/ConsultationIcon'
import { useClinician } from '@app/hooks/use-clinician'
import { Action, ConsultationStatus, ConsultationType } from '@app/types'

type ConsultationCardProps = {
  onClick?: () => void
  clinician: {
    firstName: string
    lastName: string
    category: string
    title: string
  }
  consultation: {
    consultationDate: string
    category: string
    status: ConsultationStatus
    prescriptionId: string | null
    type: ConsultationType
    closedAction?: Action
  }
}

function getCardStyles(status: ConsultationStatus) {
  if (status === 'CLOSED_WITH_ERROR') {
    return { borderColor: 'error.main', borderWidth: 1, borderStyle: 'solid' }
  }

  return undefined
}

const getConsultationType = (consultation: ConsultationCardProps['consultation']) => {
  if (consultation.type === ConsultationType.ON_DEMAND) {
    return t('pages.emr.queueConsultation')
  }

  if (consultation.type === ConsultationType.SCHEDULED) {
    return t('pages.emr.scheduledConsultation')
  }

  if (consultation.type === ConsultationType.MEDICAL_UPDATE) {
    return t('pages.emr.clinicianUpdate')
  }
}

const ConsultationStatusText: React.FC<{ consultation: ConsultationCardProps['consultation'] }> = ({
  consultation,
}) => {
  const { t } = useTranslation()

  if (consultation.status === ConsultationStatus.CLOSED_WITH_ERROR) {
    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <SvgIcon component={XCircle} inheritViewBox sx={{ width: '16px', color: 'red.600' }} />
        <Typography variant="body2" fontSize={12} fontWeight="bold" textAlign="right" color="red.600">
          {t('pages.emr.signAndShareFailed')}
        </Typography>
      </Stack>
    )
  }

  if (consultation.status === ConsultationStatus.CLOSED) {
    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <SvgIcon component={CheckCircle} inheritViewBox sx={{ width: '16px', color: 'green.700' }} />
        <Typography variant="body2" fontSize={12} fontWeight="bold" textAlign="right" color="green.700">
          {t('pages.emr.signedAndShared')}
        </Typography>
      </Stack>
    )
  }

  if (consultation.status === ConsultationStatus.CANCELLED) {
    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <SvgIcon component={CalendarX} inheritViewBox sx={{ width: '16px' }} />
        <Typography variant="body2" fontSize={12} fontWeight="bold" textAlign="right">
          {t('pages.emr.consultationCancelled')}
        </Typography>
      </Stack>
    )
  }

  return null
}

const ConsultationStatusIcon: React.FC<{ consultation: ConsultationCardProps['consultation'] }> = ({
  consultation,
}) => {
  if (consultation.prescriptionId) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        borderRadius={1}
        sx={{ backgroundColor: 'blue.100', width: '24px' }}
      >
        <SvgIcon component={Prescription} inheritViewBox sx={{ width: '20px', color: 'blue.500' }} />
      </Box>
    )
  }

  return null
}

const ConsultationStatusBadge: React.FC<{ consultation: ConsultationCardProps['consultation'] }> = ({
  consultation,
}) => {
  return (
    <Grid item xs={6}>
      <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
        <ConsultationStatusText consultation={consultation} />
      </Stack>
    </Grid>
  )
}

const ClinicianLabel: React.FC<{ clinician: ConsultationCardProps['clinician'] }> = ({ clinician }) => {
  return (
    <Typography variant="body2">
      <Box fontWeight="bold" display="inline">
        {clinician.title} {clinician.firstName} {clinician.lastName}
      </Box>{' '}
      | {clinician.category}
    </Typography>
  )
}

export const ConsultationCard: React.FC<ConsultationCardProps> = ({ clinician, consultation, onClick }) => {
  const { t } = useTranslation()
  const consultationSignerData = useClinician(consultation.closedAction?.user.id)
  const cardStyles = getCardStyles(consultation.status)

  const consultationSigner = useMemo(() => {
    if (!consultationSignerData.clinician) return

    return {
      firstName: consultationSignerData.clinician.firstName ?? '',
      lastName: consultationSignerData.clinician.lastName ?? '',
      title: consultationSignerData.clinician.professionTitle ?? '',
      category: consultationSignerData.clinician.specialtyAndSubSpecialtyTitle ?? '',
    }
  }, [consultationSignerData.clinician])

  return (
    <Card sx={{ p: 0, mt: 1, width: '100%', ...cardStyles }}>
      <CardActionArea data-testid="consultation-card-button" sx={{ px: 2, py: 2 }} onClick={onClick}>
        <Grid container>
          <Grid item xs={6}>
            <Stack display="flex" direction="row" spacing={1} alignItems="center">
              <ConsultationIcon type={consultation.type} size="16px" color="grey.600" />
              <Typography variant="body2" fontSize="12px" sx={{ color: 'grey.600' }}>
                {getConsultationType(consultation)} - {t('dates.long', { date: consultation.consultationDate })}
              </Typography>
            </Stack>
          </Grid>

          <ConsultationStatusBadge consultation={consultation} />
        </Grid>

        <Grid container sx={{ mt: 1 }}>
          <Grid item xs>
            <ClinicianLabel clinician={consultationSigner || clinician} />
          </Grid>

          <Grid item xs={6}>
            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
              <ConsultationStatusIcon consultation={consultation} />
              {consultation.category && (
                <Chip
                  label={capitalize(consultation.category.toLowerCase())}
                  color="primary"
                  variant="outlined"
                  sx={{ border: 0, color: 'blue.500' }}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}
