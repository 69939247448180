import { SvgIcon } from '@mui/material'
import { CalendarCheck, PencilSimpleLine, Timer } from 'phosphor-react'
import React from 'react'

import { ConsultationType } from '@app/types'

interface ConsultationIconProps {
  type: ConsultationType
  size?: string
  color?: string
}

export const ConsultationIcon = ({ type, size, color }: ConsultationIconProps) => {
  if (type === ConsultationType.ON_DEMAND) {
    return <SvgIcon component={Timer} inheritViewBox sx={{ color, width: size }} />
  }

  if (type === ConsultationType.SCHEDULED) {
    return <SvgIcon component={CalendarCheck} inheritViewBox sx={{ color, width: size }} />
  }

  if (type === ConsultationType.MEDICAL_UPDATE) {
    return <SvgIcon component={PencilSimpleLine} inheritViewBox sx={{ color, width: size }} />
  }

  return null
}
