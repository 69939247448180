import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Alert, SvgIcon, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ArrowRight, CheckCircle } from 'phosphor-react'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PasswordInput } from '@app/components/PasswordInput'
import { PasswordStrength } from '@app/components/PasswordStrength'
import { useFocusableError } from '@app/hooks/use-focusable-error'
import { usePasswordSchema } from '@app/schemas/use-password'
import { colors } from '@app/theme/color'
import { PasswordStrength as TPasswordStrength } from '@app/types'

interface PasswordFormValues {
  password: string
  confirmPassword: string
}

interface PasswordFormProps {
  heading?: string
  submitLabel?: string
  onSubmit: (data: PasswordFormValues) => Promise<void>
}

export const PasswordForm: React.FC<PasswordFormProps> = ({ heading, submitLabel, onSubmit }) => {
  const { t } = useTranslation()
  const passwordSchema = usePasswordSchema()
  const { error, setError, errorRef } = useFocusableError()

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitted, isSubmitting, errors = {} } = {},
  } = useForm<PasswordFormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(passwordSchema),
    defaultValues: { password: '', confirmPassword: '' },
    shouldFocusError: true,
  })

  const hasError = 'password' in errors

  function handleSubmitError() {
    setError(t('forms.password.error'))
  }

  const handleFormSubmit: SubmitHandler<PasswordFormValues> = async input => {
    setError('')

    try {
      await onSubmit(input)
    } catch (err) {
      handleSubmitError()
    }
  }

  const hasValue = getValues('password')
  const numberOfErrors = !hasValue ? 4 : Object.values(errors?.password?.types ?? {}).filter(Boolean).length
  const passwordStrength = Math.min(Math.max(3 - numberOfErrors, 0), 3) as TPasswordStrength

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
      <Typography component="h2" mt={2} fontWeight="bold">
        {heading ?? t('forms.password.heading')}
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mt: 2 }} ref={errorRef} tabIndex={-1}>
          {error}
        </Alert>
      )}
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <PasswordInput
            {...field}
            required
            errorMessage={isSubmitted && hasError ? errors.password?.message : ''}
            visuallyHiddenError
          />
        )}
      />
      <Box display="flex" mt={2}>
        <PasswordStrength strength={passwordStrength} />
      </Box>
      <Box mt={2} id="password-criteria">
        <Box display="flex">
          <SvgIcon
            aria-hidden="true"
            component={CheckCircle}
            size={24}
            inheritViewBox
            sx={{ color: hasValue && !errors.password?.types?.min ? 'green.500' : colors.gray[500] }}
          />
          <Typography ml={1} fontWeight={hasValue && !errors.password?.types?.min ? 'bold' : 'normal'}>
            {t('forms.password.min')}
          </Typography>
        </Box>
        <Box display="flex" mt={1}>
          <SvgIcon
            aria-hidden="true"
            component={CheckCircle}
            size={24}
            inheritViewBox
            sx={{
              color: hasValue && !errors.password?.types?.containsCriteria ? 'green.500' : colors.gray[500],
            }}
          />
          <Typography ml={1} fontWeight={hasValue && !errors.password?.types?.containsCriteria ? 'bold' : 'normal'}>
            {t('forms.password.atLeastThree')}
          </Typography>
        </Box>
        <Box component="ul" mt={1} mb={0}>
          <Typography component="li">{t('forms.password.lowerCase')}</Typography>
          <Typography component="li">{t('forms.password.upperCase')})</Typography>
          <Typography component="li">{t('forms.password.numbers')}</Typography>
          <Typography component="li">{t('forms.password.specialCharacters')}</Typography>
        </Box>
      </Box>
      <Controller
        name="confirmPassword"
        control={control}
        render={({ field }) => (
          <PasswordInput
            {...field}
            id="confirm-password"
            label={t('forms.password.confirm.label')}
            required
            errorMessage={isSubmitted ? errors.confirmPassword?.message : ''}
          />
        )}
      />
      <LoadingButton
        variant="contained"
        fullWidth
        sx={{ mt: 6 }}
        type="submit"
        loading={isSubmitting}
        loadingPosition={isSubmitting ? 'end' : undefined}
      >
        {submitLabel ?? t('common.submit')}
        {!isSubmitting && <SvgIcon aria-hidden="true" component={ArrowRight} size={24} inheritViewBox sx={{ ml: 1 }} />}
      </LoadingButton>
    </form>
  )
}
