import React, { createContext, useCallback, useState } from 'react'

import { EMRPatient } from '@app/types'

export type PatientSearchResultsContextType = {
  searchResults: EMRPatient[] | undefined
  setSearchResults: (results: EMRPatient[]) => void
  clearSearchResults: () => void
}

export const PatientSearchResultsContext = createContext<PatientSearchResultsContextType | undefined>(undefined)

export const PatientSearchResultsProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchResults, setSearchResults] = useState<EMRPatient[] | undefined>(undefined)

  const clearSearchResults = useCallback(() => {
    setSearchResults(undefined)
  }, [])

  const value: PatientSearchResultsContextType = {
    searchResults,
    setSearchResults,
    clearSearchResults,
  }

  return <PatientSearchResultsContext.Provider value={value}>{children}</PatientSearchResultsContext.Provider>
}

export const usePatientSearchResults = () => {
  const context = React.useContext(PatientSearchResultsContext)
  if (!context) {
    throw new Error('usePatientSearchResults must be used within a PatientSearchResultsProvider')
  }
  return context
}
