import React from 'react'
import { useTranslation } from 'react-i18next'

import { DescriptionList } from '@app/components/EMR/common/DescriptionList'

import { EmrCard } from './EmrCard'

interface Props {
  content?: string
  updatedAt?: string
  withBackground?: boolean
  withCard?: boolean
}

export const EmrSummary: React.FC<Props> = ({ content: summaryText, updatedAt, withBackground, withCard }) => {
  const { t } = useTranslation()

  if (!summaryText || !updatedAt) return null

  const content = (
    <DescriptionList>
      {withBackground && (
        <DescriptionList.Header>
          <DescriptionList.Title>{t('pages.emr.summaryLabel')}</DescriptionList.Title>
          <DescriptionList.Subtitle textAlign="left" paddingBottom="4px">
            {t('pages.emr.summaryUpdatedAt', { date: t('dates.longDatetime', { date: updatedAt }) })}
          </DescriptionList.Subtitle>
        </DescriptionList.Header>
      )}
      <DescriptionList.Items withBackground={withBackground}>
        {!withBackground && (
          <DescriptionList.Item>
            <DescriptionList.Label>{t('pages.emr.summaryLabel')} </DescriptionList.Label>
            <DescriptionList.Subtitle textAlign="left">
              {t('pages.emr.summaryUpdatedAt', { date: t('dates.longDatetime', { date: updatedAt }) })}
            </DescriptionList.Subtitle>
          </DescriptionList.Item>
        )}
        <DescriptionList.Item>
          <DescriptionList.ListLabel>{t('pages.emr.summaryContent')}</DescriptionList.ListLabel>
          <DescriptionList.ListBody>{summaryText}</DescriptionList.ListBody>
        </DescriptionList.Item>
      </DescriptionList.Items>
    </DescriptionList>
  )

  if (withCard) {
    return <EmrCard>{content}</EmrCard>
  }

  return content
}
