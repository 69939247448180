import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogProps,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { MediaGallery } from '@app/components/MediaGallery/MediaGallery'
import { MediaItem } from '@app/components/MediaGallery/MediaItem'
import { FileInfo } from '@app/types'

type AttachmentsModalProps = {
  legalName: string
  isOpen: boolean
  attachments: Array<FileInfo>
  onClose: () => void
  sx?: DialogProps['sx']
}

export const AttachmentsModal: React.FC<AttachmentsModalProps> = ({
  legalName,
  isOpen,
  onClose,
  sx,
  attachments = [],
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={isOpen} keepMounted onClose={onClose} aria-describedby="alert-dialog-slide-description" sx={sx}>
      <DialogTitle style={{ textAlign: 'center' }}>{t('pages.consultation.modal.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t('pages.consultation.modal.content', { name: legalName, number: attachments.length })}
        </DialogContentText>
        <Box sx={{ mt: 2 }}>
          <MediaGallery>
            {attachments.map((attachment, index) => (
              <MediaItem key={index} {...attachment} />
            ))}
          </MediaGallery>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button variant="contained" color="primary" size="small" disableElevation onClick={onClose}>
          {t('pages.consultation.modal.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
