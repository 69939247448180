import { Stack } from '@mui/material'
import React from 'react'
import { Gallery } from 'react-photoswipe-gallery'

import 'photoswipe/dist/photoswipe.css'

type MediaGalleryProps = {
  children: React.ReactNode
}

export const MediaGallery: React.FC<MediaGalleryProps> = ({ children }) => {
  return (
    <Gallery
      withDownloadButton
      withCaption
      options={{
        showHideAnimationType: 'none',
        wheelToZoom: true,
        padding: { top: 60, right: 60, bottom: 60, left: 60 },
      }}
    >
      <Stack role="list" direction="row" justifyContent="flex-start" flexWrap="wrap" alignItems="center">
        {children}
      </Stack>
    </Gallery>
  )
}
