import { Box, Stack } from '@mui/material'
import i18n from 'i18next'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Page, PageHeading } from '@app/components/Page'
import { UpdatingLivePill } from '@app/components/Pill/UpdatingLivePill'
import { MonitoringTab, useMonitoring } from '@app/context/monitoring'
import { OnDemandMonitoringPage } from '@app/pages/Monitoring/OnDemand/OnDemandMonitoringPage'
import { SchedulingMonitoringPage } from '@app/pages/Monitoring/Scheduling/SchedulingMonitoringPage'
import { TabPanel } from '@app/pages/Monitoring/TabPanel/TabPanel'
import { colors } from '@app/theme/color'

const tabs = [
  {
    label: i18n.t('pages.monitoring.tabs.onDemand'),
    value: MonitoringTab.ON_DEMAND_CONSULTATIONS,
  },
  {
    label: i18n.t('pages.monitoring.tabs.scheduled'),
    value: MonitoringTab.SCHEDULED_CONSULTATIONS,
  },
]

export function MonitoringPage() {
  const { t } = useTranslation()
  const { tab, setTab } = useMonitoring()

  return (
    <Page
      sx={{
        height: '100%',
        backgroundColor: colors.blue[50],
      }}
    >
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <PageHeading data-testid="monitoring-heading">{t('pages.monitoring.heading')}</PageHeading>
          <UpdatingLivePill />
        </Stack>
        <Box sx={{ position: 'relative' }}>
          <TabPanel tabs={tabs} selected={tab} variant="solid">
            {selectedTab => {
              switch (selectedTab) {
                case MonitoringTab.ON_DEMAND_CONSULTATIONS:
                  setTab(MonitoringTab.ON_DEMAND_CONSULTATIONS)
                  return <OnDemandMonitoringPage />
                case MonitoringTab.SCHEDULED_CONSULTATIONS:
                  setTab(MonitoringTab.SCHEDULED_CONSULTATIONS)
                  return <SchedulingMonitoringPage />
                default:
                  return null
              }
            }}
          </TabPanel>
        </Box>
      </Stack>
    </Page>
  )
}
