import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ErrorImage from '@app/assets/images/error.png'
import { Page } from '@app/components/Page'

export function ErrorPage() {
  const { t } = useTranslation()

  return (
    <Page>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
        <img width={200} src={ErrorImage} alt="Error" />
        <Typography fontWeight={700} fontSize={20} color="gray.700" mt={2} mb={0.5}>
          {t('errorScreen.inner.title')}
        </Typography>
        <Typography fontWeight={400} fontSize={14} color="gray.700" mb={2}>
          {t('errorScreen.inner.subtitle')}
        </Typography>
        <Button
          variant="contained"
          size="medium"
          sx={{ fontWeight: 700, fontSize: 16, px: 3, py: 2 }}
          disableElevation
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('errorScreen.inner.button')}
        </Button>
      </Box>
    </Page>
  )
}
