import { Box } from '@mui/material'
import i18n from 'i18next'
import * as React from 'react'
import { useCallback } from 'react'

import { MonitoringTable, useMonitoring } from '@app/context/monitoring'
import { DatePickerInput } from '@app/pages/Monitoring/Filters/DatePicker'
import { SearchInput } from '@app/pages/Monitoring/Filters/SearchInput'
import { TabPanel } from '@app/pages/Monitoring/TabPanel/TabPanel'

import { ClinicianMonitoringTable } from './ClinicianMonitoringTable'
import { ConsultationMonitoringTable } from './ConsultationMonitoringTable'

const tabs = [
  {
    label: i18n.t('pages.monitoring.schedulingTabs.patients'),
    value: MonitoringTable.CONSULTATIONS,
  },
  {
    label: i18n.t('pages.monitoring.schedulingTabs.clinicians'),
    value: MonitoringTable.CLINICIANS,
  },
]

export function SchedulingMonitoringPage() {
  const { table, setTable, searchValue, setSearchValue, date, setDate } = useMonitoring()

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
    },
    [setSearchValue]
  )

  const handleSelectDate = (value: Date | null) => {
    value && setDate(value)
  }

  return (
    <Box mt={6}>
      <Box sx={{ position: 'relative' }}>
        {table === MonitoringTable.CONSULTATIONS && <DatePickerInput date={date} handleSelectDate={handleSelectDate} />}
        <SearchInput value={searchValue} handleSearchChange={handleSearchChange} />
        <TabPanel tabs={tabs} selected={table} variant="underline">
          {selectedTab => {
            switch (selectedTab) {
              case MonitoringTable.CONSULTATIONS:
                setTable(MonitoringTable.CONSULTATIONS)
                return <ConsultationMonitoringTable searchFilter={searchValue} date={date} />
              case MonitoringTable.CLINICIANS:
                setTable(MonitoringTable.CLINICIANS)
                return <ClinicianMonitoringTable searchFilter={searchValue} />
              default:
                return null
            }
          }}
        </TabPanel>
      </Box>
    </Box>
  )
}
