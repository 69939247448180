import { useEffect } from 'react'
import useSWRImmutable from 'swr/immutable'

import fetcher from '@app/lib/fetcher'

// https://developer.nylas.com/docs/api/v3/ecc/#get-/v3/grants/-grant_id-/events
export type CalendarScheduledEvent = {
  id: string
  object: 'event'
  grantId: string
  calendarId: string
  title: string
  participants: [
    {
      name: string
      email: string
      comment: string
      phone_number: string
      status: string
    }
  ]
  when: {
    startTime: number
    endTime: number
    object: 'timespan'
  }
  busy: boolean
  metadata: {
    consultationId: string
  }
}

export function useClinicianEvents(clinicianId: number | undefined, start: number, end: number) {
  const { data, error, mutate } = useSWRImmutable<CalendarScheduledEvent[]>(
    clinicianId ? `/schedule/clinician/${clinicianId}/event?start=${start}&end=${end}` : null,
    fetcher.api,
    { refreshInterval: 1000 * 30, revalidateOnReconnect: true, revalidateOnFocus: true, revalidateIfStale: true } // revalidate every 30 seconds
  )

  useEffect(() => {
    if (error) {
      console.error('🔴 Error getting clinician availabilities', { error, data })
    }
  }, [error, data])

  return { events: data, reload: mutate, isLoading: !error && !data, error }
}
