import { Box } from '@mui/material'
import React from 'react'

type PageProps = {
  children: React.ReactNode
  maxWidth?: number
  sx?: Record<string, string | number>
}

export const Page: React.FC<PageProps> = ({ children, maxWidth, sx }) => (
  <Box sx={{ py: 4, px: 4, ...sx }} component="main">
    <Box mx="auto" maxWidth={maxWidth}>
      {children}
    </Box>
  </Box>
)
