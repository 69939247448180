import { Box } from '@mui/material'
import React, { useCallback, useRef, useState } from 'react'

import { PhoneCall } from '@app/components/Consultation/common/PhoneCall'
import { SelectContactMethod } from '@app/components/SelectContactMethod'
import { VideoCall } from '@app/components/VideoCall'
import { useConfig } from '@app/context/config'
import { Consultation, Patient, PreferredContactMethod } from '@app/types'

interface TalkToPatientViewProps {
  consultation: {
    id: Consultation['id'] | null
    preferredContactMethod: PreferredContactMethod | null
  }
  patient: {
    id: Consultation['patientId']
    emrId: Patient['emrId']
    legalName: Patient['legalName']
    firstName: Patient['firstName']
    lastName: Patient['lastName']
    phoneNumber: Patient['phone']
  }
  videoCallsEnabled: boolean
}

export const TalkToPatientView: React.FC<TalkToPatientViewProps> = ({ consultation, patient, videoCallsEnabled }) => {
  const available = [
    PreferredContactMethod.PHONE,
    ...(videoCallsEnabled && consultation.id ? [PreferredContactMethod.VIDEO] : []),
  ]
  const [selectedMethod, setSelectedMethod] = useState(
    consultation.preferredContactMethod || PreferredContactMethod.PHONE
  )
  const containerRef = useRef()
  const [callTypeOpen, setCallTypeOpen] = useState(false)
  const handleDropdownClick = useCallback(() => setCallTypeOpen(true), [])

  return (
    <Box ref={containerRef}>
      {selectedMethod === PreferredContactMethod.VIDEO && consultation.id !== null ? (
        <VideoCall
          data={{
            consultation: { id: consultation.id },
            patient: {
              id: patient.id,
              legalName: patient.legalName,
            },
          }}
          onDropdownClick={available.length > 1 ? handleDropdownClick : undefined}
        />
      ) : null}
      {selectedMethod === PreferredContactMethod.PHONE ? (
        <PhoneCall
          data={{
            consultation: { id: consultation.id },
            patient: {
              emrId: patient.emrId,
              firstName: patient.firstName,
              lastName: patient.lastName,
              legalName: patient.legalName,
              phoneNumber: patient.phoneNumber,
            },
          }}
          onDropdownClick={available.length > 1 ? handleDropdownClick : undefined}
        />
      ) : null}
      <SelectContactMethod
        anchorEl={containerRef.current}
        isOpen={callTypeOpen}
        onClose={() => setCallTypeOpen(false)}
        onSave={method => {
          setSelectedMethod(method)
          setCallTypeOpen(false)
        }}
        method={selectedMethod}
        preferred={consultation.preferredContactMethod}
        available={available}
      />
    </Box>
  )
}

interface TalkToPatientProps {
  consultation: TalkToPatientViewProps['consultation']
  patient: TalkToPatientViewProps['patient']
  videoCallsEnabled?: boolean
}

export const TalkToPatient: React.FC<TalkToPatientProps> = ({ consultation, patient, videoCallsEnabled }) => {
  const { config } = useConfig()
  return (
    <TalkToPatientView
      consultation={{
        id: consultation.id,
        preferredContactMethod: consultation.preferredContactMethod,
      }}
      patient={{
        emrId: patient.emrId,
        id: patient.id,
        firstName: patient.firstName,
        lastName: patient.lastName,
        legalName: patient.legalName,
        phoneNumber: patient.phoneNumber,
      }}
      videoCallsEnabled={
        !!(videoCallsEnabled ?? config.availableContactMethods?.includes(PreferredContactMethod.VIDEO))
      }
    />
  )
}
