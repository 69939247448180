import { Box, ListItem, ListItemButton, Typography } from '@mui/material'
import React from 'react'

import { useSideBarLinks } from '@app/hooks/use-side-bar-links'
import { SidebarLink } from '@app/types'
import { makeStrapiImageUrl } from '@app/util/url'

import { RefSelector } from './RefSelector'

export const SideBarLinks: React.FC = () => {
  const { links, error } = useSideBarLinks()

  if (error) {
    //TODO not sure if you are handling errors right now
    return null
  }

  // TODO should add a <Divider /> between links like in AppSidebar.tsx
  return (
    <>
      {links.map((link: SidebarLink) => (
        <ListItem key={link.id} disablePadding>
          <ListItemButton>
            <RefSelector url={link.url}>
              <Box
                sx={{
                  textDecoration: 'none',
                  color: 'rgb(73, 73, 73)',
                  fontSize: '0.875rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={link.icon?.url ? makeStrapiImageUrl(link.icon.url) : ''}
                  alt=""
                  loading="lazy"
                  width="24"
                  height="24"
                  style={{ marginRight: '12px' }}
                />
                <Typography variant="body2" component="span">
                  {link.name}
                </Typography>
              </Box>
            </RefSelector>
          </ListItemButton>
        </ListItem>
      ))}
    </>
  )
}
