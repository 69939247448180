import {
  Box,
  Button,
  Chip,
  Popover,
  PopoverProps,
  Radio,
  RadioGroup,
  RadioProps,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material'
import { Phone, Star, VideoCamera } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PreferredContactMethod } from '@app/types'

interface SelectContactMethodProps {
  anchorEl: PopoverProps['anchorEl']
  isOpen: boolean
  onClose: VoidFunction
  onSave: (method: PreferredContactMethod) => void
  method: PreferredContactMethod
  preferred: PreferredContactMethod | null
  available: Array<PreferredContactMethod>
}

type MethodRadioProps = RadioProps & {
  isChecked?: boolean
}

const MethodRadio: React.FC<MethodRadioProps> = ({ isChecked = false, ...props }) => {
  return (
    <Box
      component="label"
      data-checked={isChecked}
      sx={{
        px: 1,
        py: 2,
        borderRadius: 2,
        transition: 'background-color 0.3s',
        '&:has(input:checked)': { backgroundColor: 'blue.100' },
      }}
    >
      <Stack alignItems="center" direction="row" spacing={1}>
        <Radio {...props} sx={{ display: 'none' }} hidden />
        {props.children}
      </Stack>
    </Box>
  )
}

const Preferred = () => {
  const { t } = useTranslation()

  return (
    <Chip
      icon={<SvgIcon component={Star} fontSize="small" weight="fill" inheritViewBox />}
      label={t('selectContactMethod.preferred')}
      color="warning"
      variant="outlined"
      sx={{
        backgroundColor: 'yellow.50',
        color: 'yellow.900',
        borderColor: 'yellow.800',
        fontWeight: 'bold',
        height: 24,
        marginLeft: 'auto !important',
        '& .MuiChip-icon': {
          color: 'yellow.800',
          width: '17px',
          marginLeft: 1,
          marginRight: 0,
        },
        '& .MuiChip-label': {
          fontSize: '12px',
          paddingLeft: 0.75,
          paddingRight: 2,
        },
      }}
    />
  )
}

interface FormElements extends HTMLFormControlsCollection {
  method: HTMLInputElement
}
interface ContactMethodFormElement extends HTMLFormElement {
  readonly elements: FormElements
}

export const SelectContactMethod: React.FC<SelectContactMethodProps> = ({
  anchorEl,
  isOpen,
  onClose,
  onSave,
  method,
  preferred,
  available,
}) => {
  const { t } = useTranslation()

  const handleSave = (e: React.FormEvent<ContactMethodFormElement>) => {
    e.preventDefault()
    onSave(e.currentTarget.elements.method.value as PreferredContactMethod)
  }

  const isAvailable = (method: PreferredContactMethod) => available.includes(method)
  const isPreferred = (method: PreferredContactMethod) => method === preferred

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPopover-paper': {
          width: 291,
          borderRadius: 2,
          boxShadow: 6,
          border: '1px solid',
          borderColor: 'gray.200',
          padding: 1,
          mt: 1,
        },
      }}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <form onSubmit={handleSave}>
        <RadioGroup name="method" defaultValue={method}>
          <Stack spacing={1}>
            {isAvailable(PreferredContactMethod.PHONE) ? (
              <MethodRadio
                aria-labelledby={`method-${PreferredContactMethod.PHONE}`}
                value={PreferredContactMethod.PHONE}
              >
                <Stack spacing={1} width={1}>
                  <Stack direction="row" spacing={1}>
                    <SvgIcon aria-hidden="true" component={Phone} weight="fill" inheritViewBox />
                    <Typography
                      id={`method-${PreferredContactMethod.PHONE}`}
                      fontWeight="bold"
                      data-testid={`select-contact-method-${PreferredContactMethod.PHONE}`}
                    >
                      {t('selectContactMethod.phone')}
                    </Typography>
                    {isPreferred(PreferredContactMethod.PHONE) ? <Preferred /> : null}
                  </Stack>
                </Stack>
              </MethodRadio>
            ) : null}

            {isAvailable(PreferredContactMethod.VIDEO) ? (
              <MethodRadio
                aria-labelledby={`method-${PreferredContactMethod.VIDEO}`}
                value={PreferredContactMethod.VIDEO}
              >
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SvgIcon aria-hidden="true" component={VideoCamera} weight="fill" inheritViewBox />
                    <Typography
                      id={`method-${PreferredContactMethod.VIDEO}`}
                      fontWeight="bold"
                      data-testid={`select-contact-method-${PreferredContactMethod.VIDEO}`}
                    >
                      {t('selectContactMethod.video')}
                    </Typography>
                    {isPreferred(PreferredContactMethod.VIDEO) ? <Preferred /> : null}
                  </Stack>
                  <Typography>{t('selectContactMethod.videoDescription')}</Typography>
                </Stack>
              </MethodRadio>
            ) : null}
          </Stack>
        </RadioGroup>

        <Button
          variant="contained"
          sx={{ py: 1, mt: 2 }}
          type="submit"
          fullWidth
          data-testid="select-contact-method-save-button"
        >
          {t('common.save')}
        </Button>
      </form>
    </Popover>
  )
}
