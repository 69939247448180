import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { minutesToMilliseconds } from 'date-fns'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useInterval, useUpdate } from 'react-use'

import { DialogCloseButton } from '@app/components/Dialog'
import { Timer } from '@app/components/LogoutWarning/Timer'
import { getDuration, getProgress } from '@app/components/LogoutWarning/utils'
import { useConfig } from '@app/context/config'

interface LogoutDialogViewProps {
  onLogout: VoidFunction
  onStaySignedIn: VoidFunction
  progress: number
  duration: Duration
}

export const LogoutDialogView: React.FC<LogoutDialogViewProps> = ({ onLogout, onStaySignedIn, progress, duration }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={true}>
      <DialogContent sx={{ py: 4, maxWidth: 380, m: '0 auto' }}>
        <DialogCloseButton onClick={onStaySignedIn} />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Timer progress={progress} duration={duration} />
          <Typography variant="body1" fontSize={18} fontWeight="bold" mt={3}>
            {t('components.logoutDialog.heading')}
          </Typography>
          <Typography mt={2}>{t('components.logoutDialog.text')}</Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2, justifyContent: 'space-between' }}>
        <Button variant="text" sx={{ fontWeight: 'bold' }} onClick={onLogout}>
          {t('components.logoutDialog.logout')}
        </Button>
        <Button variant="contained" sx={{ fontWeight: 'bold' }} onClick={onStaySignedIn}>
          {t('components.logoutDialog.staySignedIn')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface LogoutDialogProps {
  onStaySignedIn: VoidFunction
  onLogout: VoidFunction
  onTimerExpired: VoidFunction
}

function useStartTime() {
  return useMemo(() => Date.now(), [])
}

function useEndTime(startTime: number) {
  const { config } = useConfig()
  const { warningBeforeClinicianLogoutInMins } = config
  return useMemo(
    () => startTime + minutesToMilliseconds(warningBeforeClinicianLogoutInMins),
    [startTime, warningBeforeClinicianLogoutInMins]
  )
}

export const LogoutDialog: React.FC<LogoutDialogProps> = ({ onStaySignedIn, onLogout, onTimerExpired }) => {
  const startTime = useStartTime()
  const endTime = useEndTime(startTime)
  const now = Date.now()
  const duration = getDuration(startTime, endTime, now)
  const progress = getProgress(startTime, endTime, now)
  const update = useUpdate()
  useInterval(update, progress < 100 ? 1000 : null)

  useEffect(() => {
    if (progress === 100) onTimerExpired()
  }, [progress, onTimerExpired])

  return (
    <LogoutDialogView onLogout={onLogout} onStaySignedIn={onStaySignedIn} progress={progress} duration={duration} />
  )
}
