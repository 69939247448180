import { Alert, Link, Stack, SvgIcon, Typography } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import { MagnifyingGlass } from 'phosphor-react'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { AppLoading } from '@app/components/AppLoading'
import { EMRHistory } from '@app/components/EMR/EMRHistory'
import { LinkBehavior } from '@app/components/LinkBehavior'
import { Page } from '@app/components/Page'
import { useAuth } from '@app/context/auth'
import { usePatient } from '@app/hooks/use-patient'
import * as clinician from '@app/lib/clinician'
import { assertIsDefined } from '@app/util/asserts'

const FEATURE_WATCHLIST = import.meta.env.VITE_FEATURE_WATCHLIST === 'true'

const NotFoundState = () => {
  const { t } = useTranslation()
  return (
    <Stack spacing={1.5} alignItems="center" justifyContent="center" sx={{ py: 8 }}>
      <SvgIcon component={MagnifyingGlass} sx={{ width: 32, height: 32, color: 'gray.700' }} inheritViewBox />
      <Typography variant="h2" sx={{ color: 'gray.700' }}>
        {t('pages.patients.notFound.heading')}
      </Typography>
      <Typography sx={{ color: 'gray.700' }}>{t('pages.patients.notFound.description')}</Typography>
      <Link component={LinkBehavior} href="/patients">
        {t('pages.patients.notFound.searchPatients')}
      </Link>
    </Stack>
  )
}

export const PatientDetailPage: React.FC = () => {
  const { t } = useTranslation()
  const { profile, updateProfile } = useAuth()
  const { emrId } = useParams<{ emrId: string }>()
  assertIsDefined(emrId)
  const { data, hasError: hasPatientErrors } = usePatient(emrId)

  const [action, setAction] = useState<'add' | 'remove'>('add')
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  const [hasError, setHasError] = useState(false)

  const watchlistId = useMemo(() => {
    return profile?.watchlists.find(watchlist => watchlist.patient.emrId === emrId)?.id
  }, [profile, emrId])

  async function handleAddToWatchlist() {
    if (!data) return

    setAction('add')

    const watchlistAdd = await clinician.watchlist.add(data.patient.id)

    if (!watchlistAdd.data || watchlistAdd.error) {
      setHasError(true)
    }

    setIsNotificationOpen(true)
    updateProfile()
  }

  async function handleRemoveFromWatchlist() {
    if (!watchlistId) return

    setAction('remove')

    const { error } = await clinician.watchlist.remove(watchlistId)

    if (error) {
      console.error(error)
      setHasError(true)
    }

    setIsNotificationOpen(true)
    updateProfile()
  }

  function closeNotification() {
    setIsNotificationOpen(false)
  }

  return (
    <Page>
      {!data && !hasPatientErrors ? <AppLoading /> : null}
      {!data && hasPatientErrors ? <NotFoundState /> : null}
      {data ? (
        <EMRHistory
          data={{
            patient: data.patient,
            medicalHistory: data.medicalHistory,
            prescriptions: data.pastPrescriptions,
            additionalInformation: data.additionalInformation,
          }}
          onAddWatchlist={handleAddToWatchlist}
          onRemoveWatchlist={handleRemoveFromWatchlist}
          isWatchlisted={!!watchlistId}
          showWatchlistButton={FEATURE_WATCHLIST}
        />
      ) : null}

      <Snackbar
        open={isNotificationOpen}
        autoHideDuration={8000}
        onClose={closeNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {hasError ? (
          <Alert severity="error" onClose={closeNotification}>
            {action === 'add' ? t('pages.watchlist.addFailed') : t('pages.watchlist.removeFailed')}
          </Alert>
        ) : (
          <Alert severity="success" onClose={closeNotification}>
            {action === 'add' ? t('pages.watchlist.addSuccess') : t('pages.watchlist.removeSuccess')}
          </Alert>
        )}
      </Snackbar>
    </Page>
  )
}
