import { Grid, List, ListItem, MenuItem, Select, Stack, Typography } from '@mui/material'
import { orderBy } from 'lodash-es'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useConfig } from '@app/context/config'
import { EMRPatient } from '@app/types'

import { SearchColumnHeader as ColumnHeader } from './SearchColumnHeader'
import { SearchPatientRow } from './SearchPatientRow'

type Patients = Array<EMRPatient>

interface SearchPatientsTableProps {
  patients: Patients
}

export const SearchPatientsTable: React.FC<SearchPatientsTableProps> = ({ patients }) => {
  const { t } = useTranslation()
  const { config } = useConfig()

  const [sortField, setSortField] = useState('legalName')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')

  const sortedResults = useMemo(() => {
    if (!patients.length) return []

    return orderBy(patients, [sortField], [sortDirection])
  }, [patients, sortField, sortDirection])

  // We should somehow be able to decide this, but either we choose an existing partner config
  // variable that is as close as possible to the desired semantics or we introduce one more
  // partner config variable to control the visibility of the CPF column
  const cpfFieldDispayed = !!config.partnerConfig.isMemed

  const toggleSortDirection = () => setSortDirection(dir => (dir === 'asc' ? 'desc' : 'asc'))

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography>{t('common.sortBy')}:</Typography>
          <Select
            value={sortField}
            onChange={evt => setSortField(evt.target.value)}
            sx={{ bgcolor: 'white' }}
            size="small"
          >
            <MenuItem value="legalName">{t('common.patient.name')}</MenuItem>
            <MenuItem value="dateOfBirth">{t('common.patient.dateOfBirth')}</MenuItem>
          </Select>
        </Stack>
      </Stack>

      <List role="table" sx={{ mt: 4 }}>
        <ListItem component="div" role="rowgroup" disablePadding sx={{ color: 'gray.700' }}>
          <Grid role="row" container px={3} spacing={2}>
            <ColumnHeader
              direction={sortDirection}
              sorted={sortField === 'legalName'}
              onSort={toggleSortDirection}
              onClick={() => setSortField('legalName')}
              sortable
            >
              {t('common.patient.name')}
            </ColumnHeader>
            <ColumnHeader>{t('common.patient.sexLabel')}</ColumnHeader>
            <ColumnHeader
              direction={sortDirection}
              sorted={sortField === 'dateOfBirth'}
              onSort={toggleSortDirection}
              onClick={() => setSortField('dateOfBirth')}
              sortable
            >
              {t('common.patient.dateOfBirth')}
            </ColumnHeader>
            <ColumnHeader>{t('common.patient.id')}</ColumnHeader>
            <ColumnHeader>{t('common.patient.phone')}</ColumnHeader>
            <ColumnHeader>{t('common.patient.email')}</ColumnHeader>
            {cpfFieldDispayed ? <ColumnHeader>{t('common.patient.cpfCode')}</ColumnHeader> : undefined}
          </Grid>
        </ListItem>

        {sortedResults.map(item => (
          <SearchPatientRow key={item.emrId} {...item} />
        ))}
      </List>
    </>
  )
}
