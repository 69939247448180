import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { format, getYear, startOfYear } from 'date-fns'
import React, { useState } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { BaseSchema } from 'yup'

import { MedicalHistoryTitle } from '@app/components/EMR/common/MedicalHistoryTitle'
import { dateLocales } from '@app/i18n/config'
import { colors } from '@app/theme/color'
import { DateSelectionType } from '@app/types'

type AutocompleteOption = { id: string; label: string }
type DateValidation = {
  dateValidation: {
    startDate: {
      min?: Date
      max?: Date
    }
    endDate?: {
      min?: Date
      max?: Date
    }
  }
}

const filter = createFilterOptions<AutocompleteOption>()

const MIN_ALLOWED_YEAR = 1901
const MAX_ALLOWED_YEARS_IN_FUTURE = 99

type OptionDatesProps = {
  onRemove: () => void
  firstDateOnly?: boolean
  comments?: boolean
  index: number
  updateItem: (index: number, newItem: DateSelectionType & { comments: string }) => void
  item: DateSelectionType & { comments: string }
} & DateValidation

const OptionDates: React.FC<OptionDatesProps> = ({
  onRemove,
  firstDateOnly,
  comments,
  updateItem,
  item,
  index,
  dateValidation,
}) => {
  const { i18n, t } = useTranslation()

  return (
    <Stack
      direction="column"
      sx={{ background: colors.blue[50], mt: 2, borderRadius: 1, padding: 2 }}
      data-test={`${item.label}-section`}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1" fontWeight="bold" sx={{ mb: 0.5 }}>
          {item.label}
        </Typography>
        <Button
          variant="text"
          sx={{ padding: 0, background: null }}
          size="small"
          onClick={onRemove}
          data-test="remove-button"
        >
          <CloseIcon sx={{ mr: 0.2 }} fontSize="small" />
          {t('common.remove')}
        </Button>
      </Stack>

      <Stack direction="row">
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocales[i18n.resolvedLanguage]}>
          <Box mr={2}>
            <Typography fontWeight="bold" mb={1}>
              {firstDateOnly ? t('common.at') : t('common.from')}
            </Typography>
            <DesktopDatePicker
              maxDate={item.dates.to ? new Date(item.dates.to) : dateValidation.startDate.max}
              minDate={dateValidation.startDate.min}
              views={item.approximateYear ? ['year'] : undefined}
              value={item.dates.from ? new Date(item.dates.from) : undefined}
              onChange={(newValue: Date | null) => {
                if (newValue && isNaN(newValue.getTime())) return

                const toValue = item.dates.to ? new Date(item.dates.to).getTime() : 0

                if (item.approximateYear) {
                  const introducedYear = newValue?.getFullYear()
                  const minYear = dateValidation.startDate.min?.getFullYear() || MIN_ALLOWED_YEAR
                  const maxYear = new Date().getFullYear() + MAX_ALLOWED_YEARS_IN_FUTURE

                  if (introducedYear && (introducedYear < minYear || introducedYear > maxYear)) return
                }

                if (!toValue || (newValue && newValue?.getTime() <= toValue)) {
                  const from =
                    newValue && item.approximateYear ? startOfYear(new Date(getYear(newValue), 0, 1)) : newValue

                  updateItem(index, {
                    ...item,
                    dates: {
                      ...item.dates,
                      from: from && from.toISOString(),
                    },
                  })
                }
              }}
            />
          </Box>

          {!firstDateOnly && (
            <Box ml={2}>
              <Typography fontWeight="bold" mb={1}>
                {t('common.to')}
              </Typography>
              <DesktopDatePicker
                minDate={item.dates.from ? new Date(item.dates.from) : dateValidation.endDate?.min}
                maxDate={dateValidation.endDate?.max}
                views={item.approximateYear ? ['year'] : undefined}
                value={item.dates.to ? new Date(item.dates.to) : undefined}
                onChange={(newValue: Date | null) => {
                  if (newValue && isNaN(newValue.getTime())) return

                  const fromValue = item.dates.from ? new Date(item.dates.from).getTime() : 0

                  if (!fromValue || (newValue && newValue.getTime() >= fromValue)) {
                    const to =
                      newValue && item.approximateYear ? startOfYear(new Date(getYear(newValue), 0, 1)) : newValue

                    updateItem(index, {
                      ...item,
                      dates: {
                        ...item.dates,
                        to: to && to.toISOString(),
                      },
                    })
                  }
                }}
              />
            </Box>
          )}
        </LocalizationProvider>
      </Stack>

      <Box mt={2}>
        <FormControlLabel
          control={
            <Switch
              onChange={e => {
                if (e.target.checked) {
                  const newDates = {
                    from: item.dates.from && new Date(new Date(item.dates.from).getFullYear(), 0, 1).toISOString(),
                    to: item.dates.to && new Date(new Date(item.dates.to).getFullYear(), 0, 1).toISOString(),
                  }

                  updateItem(index, {
                    ...item,
                    dates: newDates,
                  })
                } else {
                  updateItem(index, {
                    ...item,
                    dates: { from: null, to: null },
                  })
                }
                updateItem(index, {
                  ...item,
                  approximateYear: e.target.checked,
                })
              }}
              checked={item.approximateYear}
              sx={{ mr: 2 }}
              data-test="useApproximation-switch"
            />
          }
          label={
            <Typography variant="caption" fontWeight="bold">
              {t('common.useApproximation')}
            </Typography>
          }
          sx={{ margin: 0 }}
        />
      </Box>

      {comments && (
        <Box mt={2}>
          <TextField
            value={item.comments}
            size="small"
            type="text"
            label={
              <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                {t('common.notes')}
              </Typography>
            }
            onChange={e => {
              updateItem(index, { ...item, comments: e.target.value })
            }}
            multiline
            data-test="notes-input"
          />
        </Box>
      )}
    </Stack>
  )
}

type SelectionDialogProps = {
  items: (DateSelectionType & { comments: string })[]
  label: string
  onClose: () => void
  onSearch: (value: string) => void
  open: boolean
  options: AutocompleteOption[]
  name: string
  control: Control
  inputSchema?: BaseSchema
  firstDateOnly?: boolean
  comments?: boolean
  prependItem: (item: DateSelectionType & { comments: string }) => void
  removeItem: (index: number) => void
  updateItem: (index: number, newItem: DateSelectionType & { comments: string }) => void
} & DateValidation

const SelectionDialog: React.FC<SelectionDialogProps> = ({
  items,
  label,
  onClose,
  onSearch,
  open,
  options,
  inputSchema,
  firstDateOnly,
  comments,
  dateValidation,
  prependItem,
  removeItem,
  updateItem,
}) => {
  const { t } = useTranslation()
  const [validationError, setValidationError] = useState<string | null>(null)

  const onInputChange = async (value: string) => {
    try {
      await inputSchema?.validate(value)
      setValidationError(null)
      onSearch(value)
    } catch (err) {
      setValidationError((err as Error).message)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <Autocomplete
          filterSelectedOptions
          size="small"
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onInputChange={(_, value) => onInputChange(value)}
          renderInput={params => (
            <>
              {validationError && (
                <Typography variant="caption" display="block" color="error" gutterBottom>
                  {validationError}
                </Typography>
              )}
              <TextField
                {...params}
                multiline
                error={!!validationError}
                InputProps={{ ...params.InputProps, autoFocus: true }}
                data-test="medicalHistory-input"
              />
            </>
          )}
          onChange={(_, data) => {
            if (data) {
              prependItem({ ...data, dates: { from: null, to: null }, comments: '', approximateYear: false })
            }
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          filterOptions={(options, params) => {
            const filtered = filter(options, params)

            const { inputValue } = params
            // Suggest the creation of a new value
            const isExisting = options.some(option => inputValue === option.label)
            if (!validationError && inputValue !== '' && !isExisting) {
              filtered.push({
                id: uuid(),
                label: inputValue,
              })
            }
            return filtered
          }}
        />
        {items?.map((item, index) => (
          <OptionDates
            index={index}
            key={`${item.id}-${index}`}
            onRemove={() => removeItem(index)}
            firstDateOnly={firstDateOnly}
            comments={comments}
            dateValidation={dateValidation}
            updateItem={updateItem}
            item={item}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-test="close-button">
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type MedicalHistoryEditableSelectionWithDatesProps = {
  list?: (DateSelectionType & { comments?: string })[]
  name: string
  control: Control
  inputSchema?: BaseSchema
  options: { id: string; label: string }[]
  onSearch?: (value: string) => void
  children: string
  firstDateOnly?: boolean
  showAlert?: boolean
  comments?: boolean
  prependItem: (item: DateSelectionType & { comments: string }) => void
  removeItem: (index: number) => void
  updateItem: (index: number, newItem: DateSelectionType & { comments: string }) => void
} & DateValidation

export const MedicalHistoryEditableSelectionWithDates: React.FC<MedicalHistoryEditableSelectionWithDatesProps> = ({
  list,
  children,
  name,
  control,
  inputSchema,
  options,
  onSearch,
  firstDateOnly,
  showAlert,
  comments,
  dateValidation,
  prependItem,
  removeItem,
  updateItem,
}) => {
  const [showButton, setShowButton] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { t } = useTranslation()

  const handleDialogSearch = (value: string) => {
    onSearch?.(value)
  }

  const handleDialogClose = (_ = {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason && reason === 'backdropClick') {
      return
    }

    onSearch?.('')
    setDialogOpen(false)
  }

  const handleShowButton = () => {
    setShowButton(true)
  }

  const handleStackClick = () => {
    setDialogOpen(true)
  }

  return (
    <>
      <Stack
        direction="column"
        component="dt"
        sx={{
          padding: 1,
          borderRadius: 1,
          '&:hover, &:focus-within': { background: colors.blue[50] },
        }}
        onMouseEnter={handleShowButton}
        onMouseLeave={() => {
          setShowButton(false)
        }}
      >
        <Stack
          direction="column"
          role="button"
          tabIndex={0}
          onClick={handleStackClick}
          sx={{ cursor: 'pointer' }}
          onFocus={handleShowButton}
          onBlur={() => setShowButton(false)}
        >
          <Stack
            direction="row"
            component="dt"
            fontWeight="bold"
            justifyContent="space-between"
            data-test={`${children}-section`}
          >
            <MedicalHistoryTitle showAlert={Boolean(showAlert && list?.length)}>{children}</MedicalHistoryTitle>
            <Button
              variant="text"
              sx={{ padding: 0, background: null, ...(showButton ? {} : visuallyHidden) }}
              size="small"
              data-test="select-button"
            >
              {t('common.select').toLocaleLowerCase()}
              <KeyboardArrowRightIcon sx={{ ml: 0.2 }} fontSize="small" />
            </Button>
          </Stack>
          {list?.map((item, i) => (
            <Typography key={`${item.id}-${i}`} variant="body1" sx={{ my: 0.5 }}>
              {item.label}
              {item.dates.from
                ? ` ${(!item.dates.to ? t('common.at') : t(`common.from`)).toLocaleLowerCase()} ${format(
                    new Date(item.dates.from),
                    item.approximateYear ? 'yyyy' : 'P'
                  )}`
                : ''}{' '}
              {item.dates.to
                ? ` ${t(`common.to`).toLocaleLowerCase()} ${format(
                    new Date(item.dates.to),
                    item.approximateYear ? 'yyyy' : 'P'
                  )}`
                : ''}
              {item.approximateYear && ` ${t('common.approximately').toLocaleLowerCase()}`}
              {comments && item.comments ? ` - ${t('common.notes')}: ${item.comments}` : ''}
            </Typography>
          ))}
        </Stack>
      </Stack>
      <SelectionDialog
        onSearch={handleDialogSearch}
        onClose={handleDialogClose}
        options={options}
        open={dialogOpen}
        label={children}
        name={name}
        control={control}
        inputSchema={inputSchema}
        firstDateOnly={firstDateOnly}
        comments={comments}
        dateValidation={dateValidation}
        items={(list || []) as (DateSelectionType & { comments: string })[]}
        prependItem={prependItem}
        removeItem={removeItem}
        updateItem={updateItem}
      />
    </>
  )
}
