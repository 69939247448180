import { Divider, Stack, Typography } from '@mui/material'
import { utcToZonedTime } from 'date-fns-tz'
import React, { lazy, Suspense } from 'react'
import { Views } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'

import { AppLoading } from '@app/components/AppLoading'
import { Page, PageHeading } from '@app/components/Page'
import { UpdatingLivePill } from '@app/components/Pill/UpdatingLivePill'
import { useAuth } from '@app/context/auth'
import { useConfig } from '@app/context/config'
import { useClinician } from '@app/hooks/use-clinician'
import { useClinicianCalendarEvents } from '@app/hooks/use-clinician-calendar-events'
import { isClinicalManager } from '@app/lib/auth'
import { RedirectToLogin } from '@app/routes'
import { Role } from '@app/types'

const LazyCalendar = lazy(() => import('@app/components/Calendar'))

export const SchedulePage: React.FC = () => {
  const { profile, role } = useAuth()

  if (!profile || !role) {
    return <RedirectToLogin />
  }

  return <SchedulePageInner clinicianId={profile.id} role={role} />
}

function SchedulePageInner({ clinicianId, role }: { clinicianId: number; role: Role }) {
  const { t } = useTranslation()

  const { config } = useConfig()

  const { clinician, isLoading: isLoadingClinician } = useClinician(clinicianId)

  const { events, isLoadingEvents, navigationDate, setNavigationDate } = useClinicianCalendarEvents(clinicianId)

  return (
    <Page>
      <Stack spacing={2} maxHeight="100vh">
        <Stack>
          <PageHeading data-test="history-heading">{t('pages.schedule.heading')}</PageHeading>
          <Typography variant="body1" component="p" maxWidth="70%">
            {t('pages.schedule.subTitle')}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="body2" fontWeight="700" color="gray.900">
            {t('pages.schedule.yourSchedule')}
          </Typography>
          <UpdatingLivePill />
          <Divider sx={{ flex: 1 }} />
        </Stack>

        <Suspense fallback={<AppLoading />}>
          <LazyCalendar
            defaultView={Views.DAY}
            workingHours={clinician?.workingHours}
            scheduleOverrides={clinician?.scheduleOverrides}
            daysOff={config.daysOff.map(dayOff =>
              utcToZonedTime(dayOff.day, Intl.DateTimeFormat().resolvedOptions().timeZone)
            )}
            events={events ?? []}
            isLoading={isLoadingEvents || isLoadingClinician}
            timezone={config.timezone}
            navigationDate={navigationDate}
            setNavigationDate={setNavigationDate}
            isClinicalManager={isClinicalManager(role)}
          />
        </Suspense>
      </Stack>
    </Page>
  )
}
