import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Stack,
  SvgIcon,
} from '@mui/material'
import { CaretDown } from 'phosphor-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { snapshot } from 'valtio'

import { useConsultationPrescriptionsState } from '@app/components/Consultation/state/use-consultation-prescriptions-state'
import { useConsultationState } from '@app/components/Consultation/state/use-consultation-state'
import { EMRConsultationView } from '@app/components/EMR/EMRConsultationView'
import { useConfig } from '@app/context/config'
import { useNewPrescriptionsContext } from '@app/context/use-new-prescriptions'
import { useConsultationForm } from '@app/hooks/use-consultation-form'
import { useScroll } from '@app/hooks/use-scroll'
import { Consultation, EmptyConsultation, JournalData, Prescription } from '@app/types'

import { ERecetaPrescriptionCTA } from './ERecetaPrescriptionCTA'

interface ScrollIndicatorProps {
  isBottom: boolean
  onClick: () => void
}

export const ScrollIndicator: React.FC<ScrollIndicatorProps> = ({ isBottom, onClick }) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: -20,
        width: '100%',
        height: '80px',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
        opacity: isBottom ? 0 : 1,
        pointerEvents: isBottom ? 'none' : 'all',
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <Button
        variant="outlined"
        endIcon={<SvgIcon component={CaretDown} inheritViewBox />}
        sx={{
          px: 8,
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'gray.50',
          '&:hover': {
            backgroundColor: 'gray.100',
          },
        }}
        onClick={onClick}
        data-test="readAll-button"
      >
        {t('common.readAll')}
      </Button>
    </Box>
  )
}

interface SignModalProps {
  consultation: Consultation | EmptyConsultation
  pastPrescriptions: Prescription[] | null
  isOpen: boolean
  onClose: () => void
  onSign: () => void
}

type SignModalContentProps = SignModalProps & {
  removePrescription: (index: number) => void
}

export const SignModalContent: React.FC<SignModalContentProps> = ({
  consultation,
  pastPrescriptions,
  onClose,
  onSign,
}) => {
  const { t } = useTranslation()
  const [isConfirmationChecked, setIsConfirmationChecked] = useState(false)
  const { config } = useConfig()
  const { state: consultationJournalState } = useConsultationState()

  const showSignAndShareWithEReceta = config?.partnerConfig.prescriptionProvider === 'ereceta'

  const scrollableRef = useRef<HTMLElement>(null)
  const { isBottom, isBottomOffset, scrollDown } = useScroll(scrollableRef, 200)

  const journal = useMemo(() => {
    const journalData = snapshot(consultationJournalState.journalData) as JournalData

    return { ...journalData, pastPrescriptions }
  }, [consultationJournalState.journalData, pastPrescriptions])

  const updatedConsultation = useMemo(() => {
    return {
      ...consultation,
      journal,
    }
  }, [consultation, journal])

  return (
    <DialogContent ref={scrollableRef} sx={{ position: 'relative' }}>
      <EMRConsultationView consultation={updatedConsultation} journal={journal} />

      <Box borderTop="1px solid" borderColor="gray.200" sx={{ px: 3, pb: 2, pt: 3, mt: 3, mx: -3 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isConfirmationChecked}
                onChange={evt => setIsConfirmationChecked(evt.target.checked)}
                data-test="confirmation-checkbox"
              />
            }
            label={t('pages.emr.readAndVerifiedConfirmation')}
          />
        </FormGroup>

        <Stack direction="row" justifyContent="end" spacing={2} paddingTop={2}>
          <Button onClick={onClose}>{t('common.edit')}</Button>
          {!showSignAndShareWithEReceta ? (
            <Button
              variant="contained"
              disabled={!isConfirmationChecked}
              onClick={onSign}
              data-test="modal-signAndShare-button"
            >
              {t('pages.emr.signAndShare')}
            </Button>
          ) : null}

          {showSignAndShareWithEReceta ? (
            <ERecetaPrescriptionCTA
              isConfirmationChecked={isConfirmationChecked}
              consultation={consultation}
              onSign={onSign}
            />
          ) : null}
        </Stack>
      </Box>

      {!isBottom ? <ScrollIndicator isBottom={isBottomOffset} onClick={() => scrollDown(300)} /> : null}
    </DialogContent>
  )
}

export const SignModal: React.FC<SignModalProps> = props => {
  const { t } = useTranslation()
  const { setPrescriptionsState } = useConsultationPrescriptionsState()
  const { onSaveNewPrescriptions } = useConsultationForm({ setPrescriptions: setPrescriptionsState })
  const { newPrescriptions, removeNewPrescription } = useNewPrescriptionsContext()

  const removePrescription = (index: number) => {
    removeNewPrescription(index)
  }

  useEffect(() => {
    if (props.isOpen) {
      onSaveNewPrescriptions(newPrescriptions)
    }
  }, [newPrescriptions, props.isOpen, onSaveNewPrescriptions])

  return (
    <Dialog
      aria-label={t('pages.emr.consultationDetail')}
      maxWidth="md"
      fullWidth
      open={props.isOpen}
      onClose={props.onClose}
    >
      <SignModalContent {...props} removePrescription={removePrescription} />
    </Dialog>
  )
}
