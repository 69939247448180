import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Collapse, Stack, TextField } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import React, { useCallback, useEffect, useState } from 'react'
import { Control, Controller, FieldValues, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { MedicalHistoryTitle } from '@app/components/EMR/common/MedicalHistoryTitle'
import { colors } from '@app/theme/color'

interface MedicalHistoryEditableTextProps {
  name: string
  control: Control
  setValue: UseFormSetValue<FieldValues>
  showAlert?: boolean
}

export const MedicalHistoryEditableText: React.FC<MedicalHistoryEditableTextProps> = ({
  children,
  name,
  control,
  setValue,
  showAlert,
}) => {
  const [showButton, setShowButton] = useState(false)
  const { t } = useTranslation()
  const fieldValue = useWatch<FieldValues>({ name, control })
  // show it in edit mode if the value is already set
  const [showInput, setShowInput] = useState<boolean>(!!fieldValue)

  const handleShowButton = useCallback(() => {
    setShowButton(true)
  }, [])

  const handleShowInput = useCallback(() => {
    setShowInput(true)
  }, [])

  useEffect(() => {
    if (fieldValue) handleShowInput()
  }, [fieldValue, handleShowInput])

  const handleClick = () => {
    if (showInput) {
      setValue(name, '', { shouldDirty: true })
      setShowInput(false)
      return
    }

    handleShowButton()
    handleShowInput()
  }

  return (
    <Stack
      direction="column"
      component="dt"
      sx={{
        padding: 1,
        borderRadius: 1,
        '&:hover, &:focus-within': {
          background: colors.blue[50],
        },
      }}
      onMouseEnter={handleShowButton}
      onMouseLeave={() => {
        setShowButton(false)
      }}
      data-test={`${children}-section`}
    >
      <Stack direction="row" component="dt" fontWeight="bold" justifyContent="space-between">
        <MedicalHistoryTitle showAlert={showAlert && fieldValue}>{children}</MedicalHistoryTitle>

        <Button
          variant="text"
          sx={{ padding: 0, background: null, ...(showButton ? {} : visuallyHidden) }}
          size="small"
          onClick={handleClick}
          onFocus={() => setShowButton(true)}
          onBlur={() => setShowButton(false)}
          data-test={showInput ? 'remove-button' : 'add-button'}
        >
          {showInput ? <CloseIcon sx={{ mr: 0.2 }} fontSize="small" /> : <AddIcon sx={{ mr: 0.2 }} fontSize="small" />}
          {showInput ? t('common.remove') : t('common.add')}
        </Button>
      </Stack>

      <Collapse orientation="vertical" in={showInput}>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              size="small"
              multiline
              type="text"
              sx={{
                width: '100%',
                mt: 1,
              }}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Collapse>
    </Stack>
  )
}
