import { Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { useCallback } from 'react'

interface TabPanelProps {
  tabs: { label: string; value: string }[]
  selected?: string
  variant?: 'underline' | 'solid'
  children(selectedTab: string): React.ReactNode
}

const indicatorStyle = {
  underline: {
    height: '4px',
  },
  solid: {
    height: '100%',
    borderRadius: 2,
  },
}

const tabStyle = {
  underline: {
    '&.Mui-selected': {
      fontWeight: '700',
      color: 'grey.900',
    },
    '&:not(.Mui-selected)': {
      fontWeight: '400',
      color: 'grey.600',
    },
  },
  solid: {
    fontSize: 16,
    zIndex: 1,
    color: '#fff',
    '&.Mui-selected': {
      fontWeight: '700',
      color: '#fff',
    },
    '&:not(.Mui-selected)': {
      fontWeight: '400',
      color: 'grey.600',
    },
  },
}

export const TabPanel = ({ children, tabs, selected, variant = 'solid' }: TabPanelProps) => {
  const [selectedTab, setSelectedTab] = React.useState<string>(selected ?? tabs[0].value)

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue)
  }, [])

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{ marginBottom: '16px' }}
        TabIndicatorProps={{
          sx: {
            backgroundColor: 'blue.500',
            ...indicatorStyle[variant],
          },
        }}
      >
        {tabs.map(tab => (
          <Tab
            data-testid={`tab-${tab.label.toLocaleLowerCase()}`}
            key={tab.value}
            value={tab.value}
            label={tab.label}
            sx={{
              minWidth: '116px',
              textTransform: 'capitalize',
              ...tabStyle[variant],
            }}
          />
        ))}
      </Tabs>
      {children(selectedTab)}
    </>
  )
}
