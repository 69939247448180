import { IconButton, InputAdornment, TextField } from '@mui/material'
import * as React from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CancelIcon } from '@app/components/icons/CancelIcon'
import { MagnifyingGlassIcon } from '@app/components/icons/MagnifyingGlassIcon'

interface SearchInputProps {
  disabled?: boolean
  value: string
  handleSearchChange: (value: React.ChangeEvent<HTMLInputElement>) => void
}

export const SearchInput = ({ value, handleSearchChange, disabled }: SearchInputProps) => {
  const { t } = useTranslation()
  const handleClearSearch = useCallback(() => {
    handleSearchChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)
  }, [handleSearchChange])

  return (
    <TextField
      disabled={disabled}
      size="small"
      placeholder={t('common.search')}
      onChange={handleSearchChange}
      id="outlined-start-adornment"
      value={value}
      sx={{
        m: 1,
        width: '32ch',
        position: 'absolute',
        zIndex: '1',
        right: '-8px',
        top: '-16px',
        '& .MuiOutlinedInput-root': {
          fontSize: '14px',
          borderRadius: '8px',
          backgroundColor: 'white',
          border: '1px solid #E3E3E3',
          height: '53px',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MagnifyingGlassIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClearSearch} edge="end">
              <CancelIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
