import * as React from 'react'
import { SVGProps } from 'react'

interface Props {
  color?: string
  size?: number
}

export const UserIcon = (props: SVGProps<SVGSVGElement> & Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ?? 32}
    height={props.size ?? 32}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? '#2C2C2C'}
      fillRule="evenodd"
      d="M16 5a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color ?? '#2C2C2C'}
      fillRule="evenodd"
      d="M16 20.999a13 13 0 0 0-11.259 6.5 1 1 0 1 1-1.732-.999 15 15 0 0 1 25.982 0 1 1 0 1 1-1.732 1 13 13 0 0 0-11.26-6.501Z"
      clipRule="evenodd"
    />
  </svg>
)
