import { useMemo } from 'react'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'

type UseProcedureListReturnType = { id: string; label: string }[]

export function useProcedureList(allergySearch: string): UseProcedureListReturnType {
  const queryParams = new URLSearchParams({
    search: allergySearch,
  })
  const { data: procedures } = useSWR<{ name: string; code: string }[]>(`/procedures?${queryParams}`, {
    fetcher: fetcher.api,
  })

  const formattedProcedures = useMemo(() => {
    return procedures?.map(({ name, code }) => ({ id: code, label: `${code} ${name}` }))
  }, [procedures])

  return formattedProcedures || []
}
