import { ActionType, Clinician, Consultation, PrescriptionData, Prescriptions } from '@app/types'

export const joinPrescriptionsWithConsultations = (
  prescriptions: Prescriptions = [],
  consultations: Consultation[] = []
): PrescriptionData[] => {
  const consultationInfoMap = new Map<string, { clinician?: Clinician; createdAt?: string }>()
  for (const consultation of consultations) {
    const { actions, encounterUuid, clinician } = consultation
    const actionClosed = actions.find(action => [ActionType.CLOSED, ActionType.CLOSED_WITH_ERROR].includes(action.type))

    consultationInfoMap.set(encounterUuid, {
      clinician,
      createdAt: actionClosed?.createdAt,
    })
  }

  return prescriptions.map(prescription => {
    const { encounterUuid } = prescription
    const { clinician, createdAt } = consultationInfoMap.get(encounterUuid) || {}

    return { ...prescription, clinician, createdAt }
  })
}

export const findPrescription = (
  prescriptions: Prescriptions | undefined,
  selectedPrescriptionId: string | undefined
) => {
  return prescriptions?.find(prescription => prescription.emrId === selectedPrescriptionId)
}
