import qs from 'qs'
import { useEffect, useState } from 'react'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'
import { revalidateDataConfig } from '@app/lib/request'
import { Consultation, ConsultationSortBy, ConsultationStatus, ConsultationType } from '@app/types'

const REFRESH_INTERVAL = 1000 * 15 // 15 seconds

interface UseConsultationParams {
  patientId?: number
  clinicianId?: number
  status?: ConsultationStatus | ConsultationStatus[]
  sortBy?: ConsultationSortBy
  type?: ConsultationType | ConsultationType[]
  date?: string
  page?: number
  pageSize?: number
  autoRefresh?: boolean
}

interface Pagination {
  total: number
  page: number
  pageCount: number
  pageSize: number
}

export interface ConsultationsResponse {
  consultations: Array<Consultation>
  pagination: Pagination
}

export function useConsultations(params: UseConsultationParams) {
  const [pageCount, setPageCount] = useState(0)

  const queryParams = qs.stringify(
    {
      patientId: params?.patientId?.toString(),
      clinicianId: params?.clinicianId?.toString(),
      status: params.status,
      type: params.type,
      date: params.date,
      sortBy: params.sortBy,
      page: params.page,
      pageSize: params.pageSize,
    },
    { indices: false }
  )

  const { data, error, isValidating } = useSWR<ConsultationsResponse>(`/consultations?${queryParams}`, {
    fetcher: fetcher.api,
    refreshInterval: params.autoRefresh ? REFRESH_INTERVAL : undefined,
    ...revalidateDataConfig,
  })

  useEffect(() => {
    if (!data && !error) return
    if (error) setPageCount(0)
    setPageCount(data?.pagination.pageCount ?? 0)
  }, [data, error])

  return { data, isLoading: isValidating || (!error && !data), hasError: !!error, pageCount }
}
