import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { Card } from '@app/components/Card'
import { useConsultationAdditionalInformationState } from '@app/components/Consultation/state/use-consultation-additional-information-state'
import { AdditionalInformation, Address, Patient } from '@app/types'

function formatAddress(address: Address) {
  const { stateName, streetName, streetNumber, cityName, zipCode } = address

  return `${streetName} ${streetNumber}, ${cityName}, ${zipCode}, ${stateName}`
}

function useAdditionalDetailsSchema() {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object({
        curp: yup
          .string()
          .trim()
          .nullable()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .notRequired()
          .matches(/^[a-zA-Z0-9_]*$/, t('pages.emr.errors.curpAlphanumeric'))
          .length(18, t('pages.emr.errors.curpLength')),
        address: yup.string().nullable().trim().notRequired(),
        weight: yup
          .string()
          .nullable()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .matches(/^[0-9_]*$/, t('pages.emr.errors.number.positive')),
        height: yup
          .string()
          .nullable()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .matches(/^[0-9_]*$/, t('pages.emr.errors.number.positive')),
      }),
    [t]
  )
}

type PatientDetailsProps = {
  patient: Patient
  defaultValues?: AdditionalInformation
  readonly?: boolean
}

export const AdditionalInformationDetails: React.FC<PatientDetailsProps> = ({ defaultValues, patient, readonly }) => {
  const { t } = useTranslation()
  const { setAdditionalInformation, setFormIsValid } = useConsultationAdditionalInformationState()
  const additionalInformationSchema = useAdditionalDetailsSchema()

  const methods = useForm<AdditionalInformation>({
    resolver: yupResolver(additionalInformationSchema),
    defaultValues,
    mode: 'onChange',
  })

  const formValues = useWatch({
    control: methods.control,
  })

  const patientAddress = useMemo(
    () => (patient.homeAddress ? formatAddress(patient.homeAddress) : ''),
    [patient.homeAddress]
  )

  useEffect(() => {
    methods.trigger()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    methods.setValue('address', patientAddress)
    setAdditionalInformation({ ...defaultValues, address: patientAddress })
    setFormIsValid(methods.formState.isValid)
  }, [patientAddress])

  useEffect(() => {
    setFormIsValid(methods.formState.isValid)
  }, [methods.formState.isValid, setFormIsValid])

  useEffect(() => {
    if (methods.formState.isValidating || !methods.formState.isDirty || !methods.formState.isValid) return

    setAdditionalInformation(formValues)
  }, [formValues, methods.formState.isDirty, methods.formState.isValid, methods.formState.isValidating])

  return (
    <Grid item style={{ flexGrow: 1 }}>
      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container rowSpacing={3} columnSpacing={3} sx={{ mb: 2 }}>
          <Grid item xs={5}>
            {patient.cpfData?.code ? (
              <TextField
                size="small"
                type="text"
                disabled
                value={patient.cpfData?.code}
                label={
                  <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                    {t('common.patient.cpfCode')}
                  </Typography>
                }
                data-test="cpf-input"
              />
            ) : (
              <Controller
                name="curp"
                control={methods.control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="text"
                    label={
                      <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                        {t('common.patient.curp')}
                      </Typography>
                    }
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    disabled={readonly}
                    data-test="curp-input"
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={7}>
            <Controller
              name="address"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  type="text"
                  {...(patientAddress ? { disabled: true, value: patientAddress } : {})}
                  label={
                    <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                      {t('common.patient.address')}
                    </Typography>
                  }
                  error={Boolean(fieldState.error)}
                  multiline
                  disabled={readonly}
                  data-test="address-input"
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item xs={3}>
            <Controller
              name="weight"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  type="text"
                  label={
                    <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                      {t('common.patient.weight')}
                    </Typography>
                  }
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t('common.kg')}</InputAdornment>,
                  }}
                  disabled={readonly}
                  data-test="weight-input"
                />
              )}
            />
          </Grid>

          <Grid item xs={3}>
            <Controller
              name="height"
              control={methods.control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  type="text"
                  label={
                    <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                      {t('common.patient.height')}
                    </Typography>
                  }
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t('common.cm')}</InputAdornment>,
                  }}
                  disabled={readonly}
                  data-test="height-input"
                />
              )}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}
