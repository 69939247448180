import { Divider, SvgIcon } from '@mui/material'
import { CaretRight } from 'phosphor-react'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Card } from '@app/components/Card'
import { DescriptionList } from '@app/components/EMR/common/DescriptionList'
import { useConsultations } from '@app/hooks/use-consultations'
import { ConsultationSortBy, ConsultationStatus } from '@app/types'

import { PastConsultationModal } from './PastConsultationModal'

interface PastConsultationsProps {
  patientId: number
}

export const PastConsultations: React.FC<PastConsultationsProps> = ({ patientId }) => {
  const { t } = useTranslation()
  const [selectedConsultation, setSelectedConsultation] = useState<number | null>(null)
  const { data, hasError } = useConsultations({
    patientId,
    status: ConsultationStatus.CLOSED,
    sortBy: ConsultationSortBy.CREATED_AT_DESC,
  })

  if (hasError || !data) {
    return null
  }

  return (
    <>
      <Card sx={{ px: 3, py: 2, mt: 1 }}>
        <DescriptionList>
          <DescriptionList.Header>
            <DescriptionList.Title>{t('pages.consultation.pastConsultations')}</DescriptionList.Title>
          </DescriptionList.Header>

          <DescriptionList.Items>
            {data.consultations?.length > 0 &&
              data.consultations.map((consultation, i) => (
                <Fragment key={i}>
                  <DescriptionList.Item
                    icon={<SvgIcon component={CaretRight} size={24} inheritViewBox />}
                    onClick={() => setSelectedConsultation(consultation.id)}
                  >
                    <DescriptionList.Label>{t('dates.long', { date: consultation.createdAt })}</DescriptionList.Label>
                    <DescriptionList.Body>
                      {consultation.category.name ? `${consultation.category.name} - ` : ' '}
                      {consultation.clinician
                        ? `${consultation.clinician.firstName} ${consultation.clinician.lastName}`
                        : ''}
                    </DescriptionList.Body>
                  </DescriptionList.Item>
                  {i !== data.consultations.length - 1 && <Divider variant="light" />}
                </Fragment>
              ))}
          </DescriptionList.Items>
        </DescriptionList>
      </Card>

      {selectedConsultation && (
        <PastConsultationModal
          consultationId={selectedConsultation}
          isOpen={Boolean(selectedConsultation)}
          onClose={() => setSelectedConsultation(null)}
        />
      )}
    </>
  )
}
