// This screen is a placeholder to help with testing until we get designs for changing user language

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

import { AppLoading } from '@app/components/AppLoading'
import { Page, PageHeading } from '@app/components/Page'

import { useLocales } from '../../context/locales'

const I18N_DEBUG = import.meta.env.VITE_I18N_DEBUG === 'true'

export const LanguagePage: React.FC = () => {
  const locales = useLocales()

  return (
    <Page>
      <PageHeading>Debug: Language</PageHeading>
      {locales.isLoading || !locales.supportedLanguages?.length ? (
        <AppLoading />
      ) : (
        <FormControl>
          <InputLabel id="select-language">Select a language</InputLabel>
          <Select
            value={locales.currentLanguage}
            onChange={e => locales.changeLanguage(e.target.value)}
            labelId="select-language"
          >
            {locales.supportedLanguages?.map(locale => (
              <MenuItem key={locale.code} value={locale.code}>
                {locale.name}
              </MenuItem>
            ))}
            {I18N_DEBUG && <MenuItem value="show-keys">Show keys</MenuItem>}
          </Select>
        </FormControl>
      )}
    </Page>
  )
}

export default LanguagePage
