import { Chip, Divider as MaterialDivider, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Bookmarks, CalendarCheck, Clipboard, ClipboardText, Clock, Gear, MagnifyingGlass, Power } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useMatch } from 'react-router-dom'

import { Spacer } from '@app/components/Spacer'
import { LightTooltip } from '@app/components/Tooltip/LightTooltip'
import { useAuth } from '@app/context/auth'
import { useErraticPatients } from '@app/context/erratic-patients'
import { useFeatureFlags } from '@app/hooks/use-feature-flags'
import { isClinicalManager, isClinician } from '@app/lib/auth'
import { getSessionId } from '@app/util/sentry'

import { Doctor } from './Doctor'
import { Menu, MenuItem, QueueMenuItem } from './Menu'
import { Sidebar } from './Sidebar'
import { SideBarLinks } from './SideBarLinks'

const FEATURE_WATCHLIST = import.meta.env.VITE_FEATURE_WATCHLIST === 'true'
const FEATURE_SETTINGS = import.meta.env.VITE_FEATURE_SETTINGS === 'true'

const ChipErraticPatients = ({ count }: { count: number }) => {
  const { t } = useTranslation()

  return (
    <LightTooltip title={t('common.sidebar.erraticPatientsPillTooltip')} placement="right" arrow>
      <Chip
        color="error"
        size="small"
        label={count}
        sx={{
          width: 16,
          height: 16,
          p: 0,
          ml: 1,
          '& .MuiChip-label': { p: 0, fontSize: 8, fontWeight: 'bold' },
        }}
      />
    </LightTooltip>
  )
}

function Divider() {
  return <MaterialDivider light />
}

const SessionDebugger = () => {
  const [sessionId, setSessionId] = useState('')
  const [ip, setIp] = useState('')
  const auth = useAuth()
  const location = useLocation()

  const queryIp = async () => {
    try {
      const res = await fetch('https://api.ipify.org/?format=json')
      const resJson = await res.json()
      return resJson?.ip
    } catch (error) {
      console.error('Error fetching IP for debug info', error)
    }
  }

  useEffect(() => {
    const updateDebugInfo = async () => {
      setSessionId(getSessionId())
      setIp(await queryIp())
    }
    updateDebugInfo()
  }, [auth.profile])

  if (!auth.profile?.showDebugInfo) {
    return null
  }

  return (
    <Box>
      <Stack direction="column" alignItems="stretch" maxWidth="600px" width="full" alignSelf="center" px={0.5}>
        <Typography fontSize={8}>{`Session ID: ${sessionId}`}</Typography>
        <Typography fontSize={8}>{`Time: ${new Date().toISOString()}`}</Typography>
        <Typography fontSize={8}>{`Screen: ${location.pathname}`}</Typography>
        <Typography fontSize={8}>{`Params: ${JSON.stringify(location.search ?? {})}`}</Typography>
        <Typography fontSize={8}>{`IP: ${ip}`}</Typography>
      </Stack>
    </Box>
  )
}

export const AppSidebar: React.FC = () => {
  const auth = useAuth()
  const [loggingOut, setLoggingOut] = useState(false)
  const { count } = useErraticPatients()
  const { featureFlags } = useFeatureFlags()

  const { t } = useTranslation()
  const newConsultationMatch = useMatch('/patients/:emrId/consultation')

  async function handleLogout() {
    if (!newConsultationMatch || window.confirm(t('errorScreen.newConsultation.unloadConfirmation'))) {
      setLoggingOut(true)
      await auth.logout()
      window.location.reload()
    }
  }

  return (
    <Sidebar>
      <Box px={1}>
        <Doctor
          firstName={auth.profile?.firstName}
          lastName={auth.profile?.lastName}
          title={auth.profile?.clinicianData?.professionTitle}
          speciality={auth.profile?.clinicianData?.specialtyAndSubSpecialtyTitle}
          photography={auth.profile?.clinicianData?.photography?.url}
        />
      </Box>
      <SessionDebugger />
      <Spacer y={4} />
      <Menu>
        <Divider />
        {auth.role && isClinician(auth.role) && <QueueMenuItem href="/" icon={Clipboard} />}
        <Divider />
        {FEATURE_WATCHLIST ? (
          <>
            <MenuItem href="/watchlist" icon={Bookmarks} data-test="navigation-watchlist-button">
              {t('common.sidebar.items.watchlist')}
            </MenuItem>
            <Divider />
          </>
        ) : null}
        {featureFlags?.enableClinicianScheduling && auth.role && isClinician(auth.role) ? (
          <>
            <MenuItem href="/schedule" icon={CalendarCheck} data-test="navigation-schedule-button">
              {t('common.sidebar.items.schedule')}
            </MenuItem>
            <Divider />
          </>
        ) : null}
        <MenuItem href="/history" icon={Clock} data-test="navigation-history-button">
          {t('common.sidebar.items.history')}
          {count > 0 ? <ChipErraticPatients count={count} /> : null}
        </MenuItem>
        <Divider />
        {auth.role && isClinicalManager(auth.role) && (
          <>
            <MenuItem href="/monitoring" icon={ClipboardText} data-test="navigation-monitoring-button">
              {t('common.sidebar.items.monitoring')}
            </MenuItem>
            <Divider />
          </>
        )}
      </Menu>

      <Spacer />

      <Menu>
        <Divider />
        <MenuItem href="/patients" icon={MagnifyingGlass} data-test="navigation-search-button">
          {t('common.sidebar.items.patients')}
        </MenuItem>
        <SideBarLinks />
        <Divider />
        {FEATURE_SETTINGS ? (
          <MenuItem href="/settings" icon={Gear} data-test="navigation-settings-button">
            {t('common.sidebar.items.settings')}
          </MenuItem>
        ) : null}
        <MenuItem
          component="button"
          icon={Power}
          onClick={handleLogout}
          data-test="logout-button"
          disabled={loggingOut}
        >
          {t('common.sidebar.items.logout')}
        </MenuItem>
      </Menu>
    </Sidebar>
  )
}
