import { FormControlLabel, Stack, Switch, Typography } from '@mui/material'
import * as React from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { useTranslation } from 'react-i18next'

import { useOnlineState } from '@app/hooks/use-online-state'

const ID = 'online-switch'

export function isOnlineSwitch(element: EventTarget): boolean {
  return element instanceof HTMLInputElement && element.id === ID
}

export function OnlineSwitch() {
  const { t } = useTranslation()
  const { online, onlineSince } = useOnlineState()

  return (
    <Stack direction="row" alignItems="center" minHeight={40}>
      <FormControlLabel
        data-test="online-switch-container"
        control={<Switch id={ID} checked={online} data-test="online-switch" />}
        label={
          <Stack direction="column" ml={2}>
            <Typography
              variant="caption"
              fontWeight={online ? 'bold' : 'normal'}
              color={online ? 'gray.900' : 'gray.700'}
            >
              {online ? t('common.online') : t('common.offline')}
            </Typography>
            {online && onlineSince && (
              <Countdown
                key={+onlineSince}
                autoStart
                date={onlineSince}
                overtime
                renderer={({ hours, minutes, seconds }) => {
                  return (
                    <Typography variant="caption" role="timer" color="gray.900">
                      {hours ? `${zeroPad(hours)}:` : ''}
                      {zeroPad(minutes)}:{zeroPad(seconds)}
                    </Typography>
                  )
                }}
              />
            )}
          </Stack>
        }
        // Remove unneeded margin
        sx={{ margin: 0 }}
      />
    </Stack>
  )
}
