import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import React from 'react'

import { useAuth } from '@app/context/auth'
import { useOnlineState } from '@app/hooks/use-online-state'

type CategoryFilterProps = {
  onChange: VoidFunction
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({ onChange }) => {
  const { profile } = useAuth()
  const { categories, changeCategoryState } = useOnlineState()

  const handleChange = (categoryId: number, checked: boolean) => {
    let newCategories

    if (checked) {
      newCategories = [...categories, categoryId]
    } else {
      newCategories = categories.filter(id => id !== categoryId)
    }

    onChange()
    changeCategoryState(newCategories)
  }

  if (!profile?.categories?.length) return null

  return (
    <Stack direction="row" alignItems="center">
      {profile?.categories
        ? profile.categories.map(c => (
            <FormControlLabel
              key={c.id}
              control={
                <Checkbox
                  checked={categories.includes(c.id)}
                  onChange={e => handleChange(c.id, e.target.checked)}
                  data-test={`${c.name}-category`}
                />
              }
              label={c.name}
            />
          ))
        : null}
    </Stack>
  )
}

export default CategoryFilter
