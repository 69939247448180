import { SvgIcon, SxProps } from '@mui/material'
import { Warning } from 'phosphor-react'
import React from 'react'

type WarningIconProps = {
  sx?: SxProps
}

export const WarningTriangleIcon: React.FC<WarningIconProps> = ({ sx }) => {
  return <SvgIcon component={Warning} weight="fill" inheritViewBox sx={{ color: 'error.main', mr: 0.5, ...sx }} />
}
