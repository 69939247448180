import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { colors } from '@app/theme/color'

const BlinkingCircle = styled('div')({
  width: '8px !important',
  height: '8px !important',
  borderRadius: '50%',
  backgroundColor: colors.green[500],
  animation: 'blink 4s infinite',

  '@keyframes blink': {
    '0%': {
      backgroundColor: colors.green[500],
    },
    '50%': {
      backgroundColor: colors.green[200],
    },
    '100%': {
      backgroundColor: colors.green[500],
    },
  },
})

export function UpdatingLivePill() {
  const { t } = useTranslation()

  return (
    <Chip
      sx={{
        fontSize: '12px',
        padding: '8px 0px 8px 3px',
        borderRadius: '8px',
        backgroundColor: 'green.100',
        '& .MuiChip-label': {
          paddingRight: '8px',
        },
      }}
      avatar={<BlinkingCircle />}
      label={t('pages.monitoring.updatingLive')}
    />
  )
}
