import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

export const LightTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip classes={{ popper: className }} {...props}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[900],
    filter: 'drop-shadow(6px 6px 24px rgba(0, 0, 0, 0.25))',
    fontSize: 12,
    padding: '8px 16px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}))
