import { Box, CircularProgress, Typography } from '@mui/material'
import { Duration } from 'date-fns'
import React from 'react'

import { colors } from '@app/theme/color'

function DurationText({ duration }: { duration: Duration }) {
  const out = []
  out.push(`${duration.minutes}`.padStart(2, '0'))
  out.push(`${duration.seconds}`.padStart(2, '0'))
  return <>{out.join(':')}</>
}

export function Timer({ progress, duration }: { progress: number; duration: Duration }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={124} thickness={2} variant="determinate" value={100} sx={{ position: 'absolute' }} />
      <CircularProgress
        size={124}
        thickness={2}
        variant="determinate"
        sx={{ svg: { color: colors.gray['100'] } }}
        value={progress}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography fontWeight="bold" variant="subtitle2" component="div" color="gray.900">
          <DurationText duration={duration} />
        </Typography>
      </Box>
    </Box>
  )
}
