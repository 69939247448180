import { differenceInYears } from 'date-fns'

import { parseOpenEMRDate } from './date'

export const calculateAge = (dob?: string): number | null => {
  if (dob === undefined) {
    return null
  }

  const date = parseOpenEMRDate(dob)

  if (!date) {
    return null
  }

  return differenceInYears(new Date(), date)
}
