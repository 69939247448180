import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogContent, Stack, Typography } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import CancelConsultationImage from '@app/assets/images/cancel-consultation.svg'

type CancelConsultationModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export const CancelConsultationModal: React.FC<CancelConsultationModalProps> = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <Dialog aria-label={t('pages.emr.consultationDetail')} maxWidth="xs" open={isOpen} onClose={onClose}>
      <DialogContent>
        <Stack spacing={2} textAlign="center" alignItems="center">
          <Box width={120} height={120}>
            <img src={CancelConsultationImage} alt="cancel-consultation" />
          </Box>
          <Stack spacing={1}>
            <Typography fontWeight="700" fontSize={24}>
              {t('pages.emr.cancelConsultation.confirmationModal.title')}
            </Typography>
            <Typography fontSize={14} fontWeight="400" color="grey.700">
              <Trans i18nKey="pages.emr.cancelConsultation.confirmationModal.description">
                <Typography
                  fontSize={14}
                  fontWeight="500"
                  color="grey.700"
                  style={{ textDecoration: 'underline' }}
                  display="inline"
                />
              </Trans>
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1}>
            <LoadingButton variant="contained" onClick={onConfirm}>
              <Typography fontWeight="700">{t('pages.emr.cancelConsultation.confirmationModal.confirm')}</Typography>
            </LoadingButton>
            <LoadingButton variant="outlined" onClick={onClose}>
              <Typography fontWeight="700">{t('pages.emr.cancelConsultation.confirmationModal.cancel')}</Typography>
            </LoadingButton>
            <Typography fontSize={12} fontWeight="400" color="grey.600">
              {t('pages.emr.cancelConsultation.confirmationModal.disclaimer')}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
