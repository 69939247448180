import { proxy } from 'valtio'

import { Prescription } from '@app/types'

interface State {
  pastPrescriptions: Prescription[]
}

const state = proxy<State>({
  pastPrescriptions: [],
})

const setPastPrescriptionsState = (prescriptions: Prescription[]) => {
  state.pastPrescriptions = prescriptions
}

export const useConsultationPastPrescriptionsState = () => {
  return {
    state,
    setPastPrescriptionsState,
  }
}
