import { useEffect } from 'react'

export function useHistoryReplace(message: string) {
  useEffect(() => {
    const origReplaceState = window.history.replaceState

    window.history.replaceState = function (...args) {
      const cont = confirm(message)
      if (cont) origReplaceState.apply(window.history, args)
    }

    return function cleanup() {
      window.history.replaceState = origReplaceState
    }
  }, [message])
}
