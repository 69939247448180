import React from 'react'
import { useTranslation } from 'react-i18next'

import { BaseForceLoginModal, ForceLoginModalProps } from '@app/components/ForceLoginModals/BaseForceLoginModal'
import { LockKeyIcon } from '@app/components/icons/LockKeyIcon'

export const ForceLoginModal: React.FC<ForceLoginModalProps> = props => {
  const { t } = useTranslation()

  return (
    <BaseForceLoginModal
      {...props}
      image={<LockKeyIcon />}
      title={t('components.forceLogin.title')}
      message={t('components.forceLogin.message')}
      confirmButton={t('components.forceLogin.confirmButton')}
    />
  )
}
