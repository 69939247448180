import { Grid, Alert, Stack, Typography } from '@mui/material'
import { Warning } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { palette } from '@app/theme/color'

export const BlockedUserAlert = ({ blocked }: { blocked: boolean | undefined }) => {
  const { t } = useTranslation()

  return blocked ? (
    <Grid item xs={12} container>
      <Alert
        sx={{
          alignItems: 'center',
          width: '100%',
          borderLeftWidth: '3px',
          borderLeftColor: palette.error.main,
          borderLeftStyle: 'solid',
        }}
        severity="error"
        icon={<Warning color={palette.error.main} size={24} />}
      >
        <Stack>
          <Typography color="gray.900" fontSize={14} fontWeight="bold">
            {t('pages.emr.blocked.title')}
          </Typography>
          <Typography color="gray.900" variant="body2" mt={1}>
            {t('pages.emr.blocked.description')}
          </Typography>
        </Stack>
      </Alert>
    </Grid>
  ) : null
}
