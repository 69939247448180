import { ThemeOptions } from '@mui/material/styles'

import { colors } from './color'

export const components: ThemeOptions['components'] = {
  MuiAlert: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          borderWidth: 0,
          borderLeftWidth: 3,
          borderRadius: 2,
          borderStyle: 'solid',
          minWidth: '100%',
          '.MuiAlert-action .MuiButton-root': {
            color: 'blue.500',
            mt: '-3px',
            py: 1,
            textDecoration: 'underline',
          },
        }),
    },
    variants: [
      {
        props: { severity: 'info' },
        style: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'blue.400',
            color: 'white',
            borderColor: 'blue.700',
            '.MuiAlert-icon': {
              color: 'inherit',
            },
            '.MuiAlert-action .MuiButton-root': {
              color: 'white',
            },
          }),
      },
      {
        props: { severity: 'success' },
        style: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'green.100',
            borderColor: 'green.500',
            '.MuiAlert-icon': {
              color: 'green.500',
            },
          }),
      },
      {
        props: { severity: 'warning' },
        style: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'yellow.100',
            borderColor: 'yellow.700',
            '.MuiAlert-icon': {
              color: 'yellow.700',
            },
          }),
      },
      {
        props: { severity: 'error' },
        style: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'red.100',
            borderColor: 'red.600',
            '.MuiAlert-icon': {
              color: 'red.600',
            },
          }),
      },
    ],
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          p: 2,
          borderRadius: 2,
          textTransform: 'none',
          ':focus-visible': {
            outline: 'auto',
            outlineOffset: 4,
            outlineColor: colors.blue[500],
          },
        }),
    },
  },
  MuiInput: {
    styleOverrides: {
      root: ({ theme }) => theme.unstable_sx({}),
    },
  },
  MuiInputBase: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          borderRadius: 2,
          fontSize: 16,
          border: `1px solid ${colors.gray[600]}`,
          '&.Mui-disabled': {
            border: `1px solid ${colors.gray[500]}`,
            '.MuiInputAdornment-root .MuiTypography-root': {
              color: colors.gray[500],
            },
          },
          '&.MuiOutlinedInput-root fieldset': {
            display: 'none',
          },
        }),
      input: ({ theme }) =>
        theme.unstable_sx({
          p: 2,
        }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          color: colors.gray[800],
          fontWeight: 'bold',
        }),
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          color: colors.gray[800],
          fontWeight: 'bold',
          transform: 'none',
          position: 'static',
          mb: 1,
          overflow: 'visible',
        }),
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
      fullWidth: true,
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ ownerState, theme }) =>
        theme.unstable_sx({
          borderRadius: 2,
          borderColor: ownerState.error ? theme.palette.error.main : '',
        }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        ':focus-visible': {
          outline: 'auto',
          outlineColor: colors.blue[500],
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          px: 1.5,
          borderRadius: 1,
          ':hover': {
            backgroundColor: colors.gray[100],
          },
          '&.Mui-selected': {
            position: 'relative',
            backgroundColor: colors.blue[100],
            '&:before': {
              backgroundColor: colors.blue[500],
              content: '""',
              top: 8,
              bottom: 8,
              left: 0,
              width: '4px',
              position: 'absolute',
              borderRadius: 9999,
            },
          },
        }),
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          py: 1.25,
          px: 2,
          borderRadius: 2,
          borderColor: 'gray.300',
          color: 'gray.900',
          fontWeight: 700,
          fontSize: 14,
          '&:hover': {
            backgroundColor: 'inherit',
            borderColor: 'gray.500',
          },
          '& *': {
            padding: '0 !important',
          },
        }),
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          borderRadius: 2,
          padding: 0,
        }),
    },
  },
  MuiList: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          padding: 0,
        }),
    },
  },
  MuiMenuList: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          borderRadius: 8,
          padding: 0,
        }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          py: 1.25,
          px: 2,
          color: 'gray.900',
          fontSize: 14,
          fontWeight: 400,
          backgroundColor: 'white',
          '&.Mui-selected': {
            backgroundColor: colors.blue[100],
            fontWeight: 700,
          },
          '&:hover': {
            backgroundColor: colors.blue[50],
          },
        }),
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) =>
        theme.unstable_sx({
          '&.MuiDivider-textAlignLeft': {
            width: '100%',
            '&:before': {
              width: 0,
            },
            '.MuiDivider-wrapper': {
              paddingLeft: 0,
            },
          },
        }),
    },
    variants: [
      {
        props: { variant: 'light' },
        style: {
          borderColor: colors.gray[100],
        },
      },
    ],
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: 48,
        height: 28,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 24,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(20px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: colors.green[300],
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 24,
          height: 24,
          borderRadius: 12,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 28 / 2,
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
      }),
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        fontWeight: 700,
        color: colors.gray[700],
        border: `1px solid ${colors.gray[200]}`,
        backgroundColor: 'white',
        '&.Mui-selected': {
          backgroundColor: 'white',
          borderColor: colors.blue[500],
        },
        '&.Mui-disabled': {
          backgroundColor: colors.gray[600],
        },
      },
      icon: {
        color: colors.gray[700],
      },
    },
  },
  MuiChip: {
    variants: [
      {
        props: { variant: 'outlined', color: 'primary' },
        style: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'blue.100',
            borderColor: 'blue.300',
            fontWeight: 700,
          }),
      },
      {
        props: { variant: 'filled', color: 'primary' },
        style: ({ theme }) =>
          theme.unstable_sx({
            backgroundColor: 'blue.100',
            borderColor: 'blue.300',
            color: 'gray.900',
            borderRadius: 2,
            py: 3,
            px: 1,
          }),
      },
    ],
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) =>
        theme.unstable_sx({
          borderRadius: 2,
          boxShadow: 'none',
        }),
    },
  },
}

declare module '@mui/material/Divider' {
  interface DividerPropsVariantOverrides {
    light: true
  }
}
