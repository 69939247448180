import { Box, Pagination, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ClipboardImage from '@app/assets/images/clipboard.svg'
import { Card } from '@app/components/Card'
import { HistoryConsultationsTable } from '@app/components/HistoryConsultationsTable'
import { Page, PageHeading } from '@app/components/Page'
import { useAuth } from '@app/context/auth'
import { useConsultations } from '@app/hooks/use-consultations'
import { ConsultationSortBy, ConsultationStatus } from '@app/types'

const SkeletonState = () => {
  return (
    <Card sx={{ mt: 6 }}>
      <Stack direction="row" justifyContent="space-between" spacing="2">
        <Skeleton variant="text" width="15%" />
        <Skeleton variant="text" width="15%" />
        <Skeleton variant="text" width="15%" />
        <Skeleton variant="text" width="15%" />
        <Skeleton variant="text" width="15%" />
      </Stack>
    </Card>
  )
}

const EmptyState = () => {
  const { t } = useTranslation()
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" sx={{ p: 6 }}>
      <img src={ClipboardImage} alt={t('pages.history.empty.title')} />
      <Typography component="h2" variant="h3" sx={{ color: 'gray.700', mt: 4 }}>
        {t('pages.history.empty.title')}
      </Typography>
      <Typography sx={{ color: 'gray.700', mt: 1 }}>{t('pages.history.empty.description')}</Typography>
    </Stack>
  )
}

export const HistoryPage: React.FC = () => {
  const { t } = useTranslation()
  const auth = useAuth()

  const [page, setPage] = React.useState(1)
  const { data, isLoading, pageCount } = useConsultations({
    clinicianId: auth.profile?.id,
    status: [ConsultationStatus.CLOSED, ConsultationStatus.CLOSED_WITH_ERROR, ConsultationStatus.CANCELLED],
    sortBy: ConsultationSortBy.UPDATED_AT_DESC,
    page,
  })

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => setPage(value)

  return (
    <Page>
      <PageHeading data-test="history-heading">{t('pages.history.heading')}</PageHeading>

      {isLoading ? <SkeletonState /> : null}

      {!isLoading && data?.consultations.length ? (
        <Box sx={{ mt: 4 }}>
          <HistoryConsultationsTable consultations={data} />

          {pageCount ? (
            <Stack sx={{ alignItems: 'center', mt: 2 }}>
              <Pagination
                count={pageCount}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            </Stack>
          ) : null}
        </Box>
      ) : null}

      {!isLoading && !data?.consultations.length ? <EmptyState /> : null}
    </Page>
  )
}
