import { useMemo } from 'react'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'
import { revalidateDataConfig } from '@app/lib/request'
import { ActionType, Sex } from '@app/types'

const REFRESH_INTERVAL = 1000 * 15 // 15 seconds

export interface PatientsQueueAction {
  type: ActionType
  note: string
  createdAt: string
  assignedTo?: number
}

export interface PatientQueue {
  consultation: {
    id: number
  }
  patient: {
    id: number
    dateOfBirth: string | null
    sex: Sex | null
    emrId: string
    cardoId: string
    phone?: string
  }
  category: {
    id: number
    name: string
  }
  product: {
    id: number
    name: string
  }
  actions: PatientsQueueAction[]
  clinician?: {
    firstName: string | null
    lastName: string | null
    username: string
  }
}

interface PatientsQueueResponse {
  queue: PatientQueue[]
}

export function usePatientsQueue() {
  const { data, error, isValidating } = useSWR<PatientsQueueResponse>(`/queue`, {
    fetcher: fetcher.api,
    refreshInterval: REFRESH_INTERVAL,
    ...revalidateDataConfig,
  })

  const patientsCategories = useMemo(() => {
    return (
      data?.queue?.reduce((categories: Record<string, number>, { category }) => {
        if (categories[category.name]) {
          categories[category.name]++
        } else {
          categories[category.name] = 1
        }
        return categories
      }, {}) ?? {}
    )
  }, [data])

  return {
    hasError: !!error,
    isLoading: (!error && !data) || isValidating,
    data,
    patientsQueue: data?.queue,
    totalPatientsInQueue: data?.queue.length || 0,
    patientsCategories,
  }
}
