import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'

import { makeStrapiImageUrl } from '@app/util/url'

type DoctorProps = {
  firstName?: string
  lastName?: string
  speciality?: string
  title?: string
  photography?: string
}

type ClinicianAvatarProps = {
  firstName: string
  lastName: string
  photographyUrl: string
}

const ClinicianAvatar = ({ firstName, lastName, photographyUrl }: ClinicianAvatarProps) => {
  if (photographyUrl) {
    return <Avatar src={makeStrapiImageUrl(photographyUrl)} sx={{ width: 52, height: 52, marginRight: 2 }} />
  }

  return (
    <Avatar alt={lastName} sx={{ marginRight: 1.5 }}>
      <Typography sx={{ fontWeight: 'bold' }}>{`${firstName[0]}${lastName[0]}`}</Typography>
    </Avatar>
  )
}

export const Doctor: React.FC<DoctorProps> = ({
  firstName = '',
  lastName = '',
  speciality = '',
  title = '',
  photography = '',
}) => {
  const name = `${title} ${firstName} ${lastName}`
  return (
    <Box display="flex" flexDirection="row" alignItems="center" pb={3}>
      <Box>
        <ClinicianAvatar firstName={firstName} lastName={lastName} photographyUrl={photography} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography sx={{ fontWeight: 'bold', color: 'gray.800', position: 'relative' }} variant="body2">
          {name}
        </Typography>
        <Typography variant="body2" component="span">
          {speciality}
        </Typography>
      </Box>
    </Box>
  )
}
