import { Chip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'

import type { PasswordStrength as TPasswordStrength } from 'src/types'

interface PasswordStrengthProps {
  strength: TPasswordStrength
}

const colorMaps = [
  { 3: 'success', 2: 'warning', 1: 'error', 0: 'default' },
  { 3: 'success', 2: 'warning', 1: 'default', 0: 'default' },
  { 3: 'success', 2: 'default', 1: 'default', 0: 'default' },
] as const

export const PasswordStrength: React.FC<PasswordStrengthProps> = ({ strength }) => {
  const { t } = useTranslation()

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" width="100%">
        <Chip color={colorMaps[0][strength]} sx={{ height: 5, flex: 1, mr: '2px' }} />
        <Chip color={colorMaps[1][strength]} sx={{ height: 5, flex: 1, mr: '2px' }} />
        <Chip color={colorMaps[2][strength]} sx={{ height: 5, flex: 1 }} />
      </Box>
      <Typography mt={2} aria-live="polite">
        <Typography component="span" sx={visuallyHidden}>
          {t(`inputs.password.strength.label`)}
        </Typography>
        {t(`inputs.password.strength.${strength}`)} &nbsp;
      </Typography>
    </Box>
  )
}

export default PasswordStrength
