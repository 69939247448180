import { useEffect } from 'react'

export function useHistoryPush(message: string, blockHistoryWarning: boolean) {
  useEffect(() => {
    if (!blockHistoryWarning) return

    const origPushState = window.history.pushState

    window.history.pushState = function (...args) {
      const cont = confirm(message)
      if (cont) origPushState.apply(window.history, args)
    }

    return function cleanup() {
      window.history.pushState = origPushState
    }
  }, [blockHistoryWarning, message])
}
