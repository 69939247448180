import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export function useEmailAndPasswordSchema() {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email(t('forms.emailAndPassword.email.validEmail'))
          .required(t('forms.emailAndPassword.email.required')),
        password: yup.string().required(t('forms.emailAndPassword.password.required')),
      }),
    [t]
  )
}
