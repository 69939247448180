import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import React from 'react'

import { DialogCloseButton } from '@app/components/Dialog'

export interface ForceLoginModalProps {
  onConfirm: VoidFunction
  onClose: VoidFunction
  loading: boolean
  buttonTestID?: string
}

type Props = ForceLoginModalProps & {
  image: JSX.Element
  title: string
  message: string
  confirmButton: string
}

export const BaseForceLoginModal: React.FC<Props> = ({
  onConfirm,
  onClose,
  loading,
  buttonTestID,
  image,
  title,
  message,
  confirmButton,
}) => {
  return (
    <Dialog open={true}>
      <DialogContent sx={{ pt: 4, pb: 0, maxWidth: 380, m: '0 auto' }}>
        <DialogCloseButton onClick={onClose} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          {image}
          <Typography variant="body1" fontSize={18} fontWeight="bold" mt={2}>
            {title}
          </Typography>
          <Typography mt={1}>{message}</Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ mx: 3, mt: 2, mb: 3, p: 0, justifyContent: 'space-between' }}>
        <LoadingButton
          variant="contained"
          fullWidth
          disableElevation
          sx={{ fontWeight: 'bold' }}
          onClick={() => {
            onConfirm()
          }}
          loading={loading}
          data-test={buttonTestID}
        >
          {confirmButton}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
