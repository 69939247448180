import { Grid, List, ListItem, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ConsultationsResponse } from '@app/hooks/use-consultations'

import { HistoryConsultationRow } from './HistoryConsultationRow'

export const ColumnHeader: React.FC = ({ children }) => {
  return (
    <Grid role="columnheader" item container xs sx={{ cursor: 'pointer' }}>
      <Typography variant="body2" fontWeight="bold">
        {children}
      </Typography>
    </Grid>
  )
}

interface HistoryConsultationsTableProps {
  consultations: ConsultationsResponse
}

export const HistoryConsultationsTable: React.FC<HistoryConsultationsTableProps> = ({ consultations }) => {
  const { t } = useTranslation()

  return (
    <List role="table" sx={{ mt: 4 }}>
      <ListItem component="div" role="rowgroup" disablePadding sx={{ color: 'gray.700' }} data-testid="history-columns">
        <Grid role="row" container px={3} spacing={2}>
          <ColumnHeader>{t('pages.emr.signedAndShared')}</ColumnHeader>
          <ColumnHeader>{t('common.patient.name')}</ColumnHeader>
          <ColumnHeader>{t('common.patient.sexLabel')}</ColumnHeader>
          <ColumnHeader>{t('common.patient.dateOfBirth')}</ColumnHeader>
          <ColumnHeader>{t('common.patient.id')}</ColumnHeader>
        </Grid>
      </ListItem>

      {consultations.consultations.map(consultation => (
        <HistoryConsultationRow key={consultation.id} {...consultation} />
      ))}
    </List>
  )
}
