import type { CountryCode } from 'libphonenumber-js/types'
import qs from 'qs'
import React, { useContext, useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'

import { PreferredContactMethod } from '@app/types'
import { makeStrapiImageUrl } from '@app/util/url'

import fetcher from '../lib/fetcher'

export type Config = {
  countryCodes: Array<CountryCode>
  enableMfa: boolean
  enableChatbotSummary?: boolean
  enableConsultationCallFeedback?: boolean
  otpLength: number
  otpValidity: number
  timezone: string
  partnerName: string
  partnerLogo: string
  partnerPhoneNumber: string
  partnerConfig: Record<string, boolean | number | string>
  clinicianAppLanguage: string
  warningBeforeClinicianLogoutInMins: number
  clinicianSessionLengthInMins: number
  moreKnowledge: string
  moreKnowledgeLink: string
  availableContactMethods?: PreferredContactMethod[]
  displayCurrentMedication: boolean
  daysOff: { day: string }[]
}

type ContextType = {
  config: Config
}

export const ConfigContext = React.createContext<ContextType | undefined>(undefined)

export const ConfigProvider: React.FC = ({ children }) => {
  const query = qs.stringify({ populate: { partnerLogo: '*', daysOff: '*' } })
  const { data, error } = useSWRImmutable(`/api/backend-config?${query}`, {
    fetcher: fetcher.strapi,
  })

  const config: Config = useMemo(
    function transformConfig() {
      if (!data?.data?.attributes) return

      return {
        ...data.data.attributes,
        displayCurrentMedication: data.data.attributes.partnerConfig?.prescriptionProvider !== 'memed',
        partnerLogo: data.data.attributes.partnerLogo?.data?.attributes.url
          ? makeStrapiImageUrl(data.data.attributes.partnerLogo?.data?.attributes.url)
          : null,
        partnerConfig: {
          ...data.data.attributes.partnerConfig,
          isMemed: data.data.attributes.partnerConfig?.prescriptionProvider === 'memed',
          isMedikit: data.data.attributes.partnerConfig?.prescriptionProvider === 'medikit',
        },
      }
    },
    [data]
  )

  const value = useMemo(() => ({ config }), [config])

  if (error || !config) {
    // TODO: decide what to do?
    return null
  }

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
}

export const useConfig = () => {
  const context = useContext(ConfigContext)

  if (!context) {
    throw new Error('useConfig() must be wrapped inside ConfigProvider.')
  }

  return context
}
