import React from 'react'

import { CalendarView } from '@app/components/Calendar/Calendar'

type ContextType = {
  timeFocus: string
  setTimeFocus: React.Dispatch<React.SetStateAction<string>>
  calendarView?: CalendarView
  setCalendarView: React.Dispatch<React.SetStateAction<CalendarView | undefined>>
}

const CalendarTimeFocusContext = React.createContext<ContextType | undefined>(undefined)

export const CalendarTimeFocusProvider = ({ children }: { children: React.ReactNode }) => {
  const [timeFocus, setTimeFocus] = React.useState('')
  const [calendarView, setCalendarView] = React.useState<CalendarView>()
  return (
    <CalendarTimeFocusContext.Provider
      value={{
        timeFocus,
        setTimeFocus,
        calendarView,
        setCalendarView,
      }}
    >
      {children}
    </CalendarTimeFocusContext.Provider>
  )
}

export const useCalendarTimeFocus = () => {
  const context = React.useContext(CalendarTimeFocusContext)
  if (!context) {
    throw new Error('useCalendarTimeFocus must be used within a CalendarTimeFocusProvider')
  }
  return context
}
