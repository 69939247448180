import React from 'react'
import { useTranslation } from 'react-i18next'

import { BaseForceLoginModal, ForceLoginModalProps } from '@app/components/ForceLoginModals/BaseForceLoginModal'
import { WarningCircleIcon } from '@app/components/icons/WarningCircleIcon'

export const RetryForceLoginModal: React.FC<ForceLoginModalProps> = props => {
  const { t } = useTranslation()

  return (
    <BaseForceLoginModal
      {...props}
      image={<WarningCircleIcon />}
      title={t('components.retryForceLogin.title')}
      message={t('components.retryForceLogin.message')}
      confirmButton={t('components.retryForceLogin.confirmButton')}
    />
  )
}
