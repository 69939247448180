import { PatientsQueueAction } from '@app/hooks/use-patients-queue'
import { Action } from '@app/types'

export const fieldContains = (field: string | string[], filter: string) => {
  const doesFieldContain = (f: string) => f.toLowerCase().includes(filter.toLowerCase())

  return Array.isArray(field) ? field.some(doesFieldContain) : doesFieldContain(field)
}

export const formatPhoneNumber = (phoneNumber: string) => {
  const countryCode = phoneNumber.slice(0, 3)
  const restOfNumber = phoneNumber.slice(3)

  return `(+${countryCode.slice(1)}) ${restOfNumber}`
}

export function getLatestAction<T extends PatientsQueueAction | Action>(actions: T[]): T | null {
  if (actions.length === 0) {
    return null
  }

  const now = Date.now()

  let latest = actions[0]

  for (let i = 1; i < actions.length; i++) {
    const diff = new Date(actions[i].createdAt).getTime() - now
    if (diff > new Date(latest.createdAt).getTime() - now) {
      latest = actions[i]
    }
  }

  return latest
}
