import { Dialog, DialogContent } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AppLoading } from '@app/components/AppLoading'
import { EMRConsultationView } from '@app/components/EMR/EMRConsultationView'
import { useConsultation } from '@app/hooks/use-consultation'

type PastConsultationModalProps = {
  consultationId: number
  isOpen: boolean
  onClose: () => void
}

export const PastConsultationModal: React.FC<PastConsultationModalProps> = ({ consultationId, isOpen, onClose }) => {
  const { t } = useTranslation()

  const { consultation, isLoading } = useConsultation(consultationId)

  return (
    <Dialog aria-label={t('pages.emr.consultationDetail')} maxWidth="md" open={isOpen} onClose={onClose}>
      <DialogContent>
        {!isLoading && consultation ? (
          <EMRConsultationView consultation={consultation} journal={consultation.journal} />
        ) : (
          <AppLoading />
        )}
      </DialogContent>
    </Dialog>
  )
}
