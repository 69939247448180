export interface ColorHues {
  50: string
  100: string
  200: string
  300: string
  400: string
  500: string
  600: string
  700: string
  800: string
  900: string
}

export const colors = {
  blue: {
    900: '#141328',
    800: '#28254F',
    700: '#3D3877',
    600: '#514A9E',
    500: '#655DC6',
    400: '#7B72E3',
    300: '#9289FF',
    200: '#C2BDFF',
    100: '#E4E2FF',
    50: '#F8F7FF',
  },
  pink: {
    900: '#460019',
    800: '#680025',
    700: '#8A0132',
    600: '#AC0B44',
    500: '#CE185A',
    400: '#F02A71',
    300: '#FF5C96',
    200: '#FF8BB5',
    100: '#FFBAD3',
    50: '#FFEAF1',
  },
  gray: {
    900: '#2C2C2C',
    800: '#494949',
    700: '#535659',
    600: '#818181',
    500: '#929292',
    400: '#B7B7B7',
    300: '#C8C8C8',
    200: '#DBDBDB',
    100: '#EDEDED',
    50: '#F8F8F8',
  },
  green: {
    900: '#14532D',
    800: '#166534',
    700: '#15803D',
    600: '#16A34A',
    500: '#22C55E',
    400: '#4ADE80',
    300: '#4ADE80',
    200: '#BBF7D0',
    100: '#DCFCE7',
    50: '#F0FDF4',
  },
  red: {
    50: '#FEF2F2',
    100: '#FEE2E2',
    200: '#FECACA',
    300: '#FCA5A5',
    400: '#F87171',
    500: '#EF4444',
    600: '#DC2626',
    700: '#B91C1C',
    800: '#991B1B',
    900: '#7F1D1D',
  },
  yellow: {
    900: '#AD6004',
    800: '#F5B13D',
    700: '#F8C74B',
    600: '#FFD875',
    500: '#FEE199',
    400: '#FAE5B0',
    300: '#FCEBBF',
    200: '#FCF1D3',
    100: '#FEF5DF',
    50: '#FFFAED',
  },
}

export const palette = {
  primary: {
    light: colors.blue[200],
    main: colors.blue[700],
  },
  secondary: {
    main: colors.pink[700],
  },
  success: {
    main: colors.green[700],
  },
  error: {
    main: '#E14E2E',
  },
  warning: {
    main: '#F8C74B',
  },
  text: {
    primary: colors.gray[900],
    secondary: colors.gray[700],
    disabled: colors.gray[500],
  },
  background: {
    default: colors.blue[50],
    paper: 'white',
  },
  action: {
    disabledBackground: colors.gray[600],
    disabled: 'white',
  },
  ...colors,
}
