import { Box } from '@mui/material'
import React from 'react'

export const Sidebar: React.FC = ({ children }) => {
  return (
    <Box
      component="header"
      sx={{
        display: 'flex',
        flexShrink: 0,
        flexDirection: 'column',
        width: 192,
        background: 'white',
        borderRight: '1px solid',
        borderRightColor: 'gray.100',
        pt: 3,
        px: 1,
        height: '100vh',
        position: 'sticky',
        top: '0',
        overflowY: 'auto',
      }}
    >
      {children}
    </Box>
  )
}
