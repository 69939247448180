import { Box, Button, Grid, Stack, SvgIcon } from '@mui/material'
import { BookmarkSimple, Info } from 'phosphor-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { snapshot } from 'valtio'

import { useConsultationProgress } from '@app/context/consultation-progress'
import { useNotifications } from '@app/context/notifications'
import { createAndClose } from '@app/lib/consultation'
import { PageLockedAlert } from '@app/pages/Consultation/PageLockedAlert'
import { Consultation, EmptyConsultation, JournalData, JournalState } from '@app/types'

import { Journal } from '../common/Journal'
import { MedicalHistory } from '../common/MedicalHistory'
import { PatientDetails } from '../common/PatientDetails/PatientDetails'
import { SensitiveNotes } from '../common/SensitiveNotes'
import { SignModal } from '../common/SignModal'
import { useConsultationState } from '../state/use-consultation-state'

const FEATURE_WATCHLIST = import.meta.env.VITE_FEATURE_WATCHLIST === 'true'

type NewConsultationProps = {
  consultation: EmptyConsultation
  handleUnlock: VoidFunction
  onBlockHistoryWarning: (value: boolean) => void
  isLocked: boolean
}

const emptyConsultationJournal: JournalState = {
  currentSituation: undefined,
  status: undefined,
  assessment: undefined,
  diagnosis: [],
  plannedActions: undefined,
}

/**
 * Consultation update.
 */
export const NewConsultation: React.FC<NewConsultationProps> = ({
  consultation,
  handleUnlock,
  isLocked,
  onBlockHistoryWarning,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const [isSigning, setIsSigning] = useState(false)
  const { addNotification } = useNotifications()
  const { watchConsultation } = useConsultationProgress()
  const { state: newConsultationState, initialiseState, subscribeAllStates } = useConsultationState()

  const handleContinue = useCallback(async () => {
    setIsConfirmationDialogOpen(false)
    setIsSigning(true)

    try {
      const dataToSave = snapshot(newConsultationState.journalData) as JournalData
      const res = await createAndClose(consultation.patient.emrId, dataToSave)

      if (res.kind === 'success') {
        onBlockHistoryWarning(false)
        addNotification({ message: t('pages.emr.signAndShareSubmit'), severity: 'warning', icon: Info })
        watchConsultation(res.data.consultationId)
        navigate(`/patients/${consultation.patient.emrId}`)
      } else {
        addNotification({ message: t('pages.emr.errorCreatingConsultation'), severity: 'error' })
        console.error('🔴 Unable to create consultation.', res)
      }
    } catch (err) {
      addNotification({ message: t('pages.emr.errorCreatingConsultation'), severity: 'error' })
      console.log('🔴 Unable to create consultation.', err)
    } finally {
      setIsSigning(false)
    }
  }, [
    addNotification,
    consultation.patient.emrId,
    navigate,
    newConsultationState,
    onBlockHistoryWarning,
    t,
    watchConsultation,
  ])

  const toggleConfirmationDialog = useCallback(() => {
    setIsConfirmationDialogOpen(val => !val)
  }, [])

  const handleCancel = useCallback(() => {
    handleUnlock()
    navigate(`/patients/${consultation.patient.emrId}`)
  }, [consultation.patient.emrId, handleUnlock, navigate])

  useEffect(() => {
    initialiseState()
    const unsubscribeAllStates = subscribeAllStates()

    return () => {
      initialiseState()
      unsubscribeAllStates()
    }
  }, [])

  return (
    <>
      <Grid container rowSpacing={3}>
        {FEATURE_WATCHLIST && (
          <Grid item xs={12} container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button sx={{ color: 'gray.900' }}>
                  <SvgIcon component={BookmarkSimple} inheritViewBox sx={{ color: 'gray.900', mr: 1 }} />
                  {t('pages.emr.addToWatchList')}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        )}

        {isLocked ? <PageLockedAlert /> : null}

        <Grid component="section" item xs={12}>
          <PatientDetails
            isSigning={isSigning}
            onCancel={handleCancel}
            onContinue={toggleConfirmationDialog}
            consultation={consultation as unknown as Consultation}
          />
        </Grid>

        <Grid component="section" item container columnSpacing={2} xs={12}>
          <Grid component="section" item xs={6}>
            <MedicalHistory
              medicalHistory={consultation.medicalHistory}
              prescriptions={consultation.pastPrescriptions}
              patient={consultation.patient}
            />
          </Grid>

          <Grid component="section" item xs={6}>
            <Box sx={{ mb: 2 }}>
              <Journal patient={consultation.patient} defaultValues={emptyConsultationJournal} />
            </Box>
            <SensitiveNotes />
          </Grid>
        </Grid>
      </Grid>

      <SignModal
        consultation={consultation}
        pastPrescriptions={null}
        isOpen={isConfirmationDialogOpen}
        onClose={toggleConfirmationDialog}
        onSign={handleContinue}
      />
    </>
  )
}
