import { Box, useTheme } from '@mui/material'
import React from 'react'

type SpacerProps = {
  y?: number
  x?: number
}

export const Spacer: React.FC<SpacerProps> = ({ y, x }) => {
  const theme = useTheme()

  if (x) {
    return <Box width={theme.spacing(x)} />
  }

  if (y) {
    return <Box height={theme.spacing(y)} />
  }

  return <Box flexGrow={1} />
}
