import { Box } from '@mui/material'
import React from 'react'

import { MUI } from '@app/util/styles'

import { AppContent } from '../AppContent'
import { AppSidebar } from '../AppSidebar'

type LayoutProps = {
  children: React.ReactNode
}

export const AppLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box display="flex" flexDirection="row" flexGrow={1} sx={{ bgcolor: 'background.default', width: MUI.Width.FULL }}>
      <AppSidebar />
      <AppContent>{children}</AppContent>
    </Box>
  )
}
