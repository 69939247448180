import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Alert, Box, SvgIcon, TextField, Typography, TypographyProps } from '@mui/material'
import { Timer } from 'phosphor-react'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { ForceLoginModal } from '@app/components/ForceLoginModals/ForceLoginModal'
import { RetryForceLoginModal } from '@app/components/ForceLoginModals/RetryForceLoginModal'
import { PasswordInput } from '@app/components/PasswordInput'
import { useConfig } from '@app/context/config'
import { useFocusableError } from '@app/hooks/use-focusable-error'
import { OnEmailPasswordSubmit, OnForceLogin } from '@app/hooks/use-login'
import { useEmailAndPasswordSchema } from '@app/schemas/use-email-and-password'

interface EmailAndPasswordFormValues {
  email: string
  password: string
}

interface EmailAndPasswordFormProps {
  heading?: React.ReactNode
  headingProps?: TypographyProps<React.ElementType, { component?: React.ElementType }>
  submitLabel?: string
  onSubmit: OnEmailPasswordSubmit
  onForceLogin: OnForceLogin
}

const LoggedOutHeader = () => {
  const { t } = useTranslation()
  const { config } = useConfig()

  return (
    <Box sx={{ textAlign: 'center', mt: 1 }}>
      <SvgIcon component={Timer} inheritViewBox fontSize="large" />
      <Typography variant="body1" fontSize={20} fontWeight="bold" sx={{ mt: 1 }}>
        {t('pages.login.loggedOut.heading')}
      </Typography>
      <Typography mt={1}>
        {t('pages.login.loggedOut.text', { partner: config.partnerName, mins: config.clinicianSessionLengthInMins })}
      </Typography>
    </Box>
  )
}

export const EmailAndPasswordForm: React.FC<EmailAndPasswordFormProps> = ({
  heading,
  headingProps,
  submitLabel,
  onSubmit,
  onForceLogin,
}) => {
  const { t } = useTranslation()
  const passwordSchema = useEmailAndPasswordSchema()
  const { error, setError, errorRef } = useFocusableError()
  const [params] = useSearchParams()
  const showLoggedOutHeader = params.get('reason') === 'inactivity'

  const [maxSessionsToken, setMaxSessionsToken] = React.useState<string | null>(null)
  const [showRetryForceLoginModal, setShowRetryForceLoginModal] = React.useState(false)
  const [isForceLoginLoading, setIsForceLoginLoading] = React.useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors = {}, isSubmitting } = {},
  } = useForm<EmailAndPasswordFormValues>({
    resolver: yupResolver(passwordSchema),
    defaultValues: { email: '', password: '' },
    shouldFocusError: true,
  })

  function handleSubmitError() {
    setError(t('forms.emailAndPassword.error'))
  }

  const handleFormSubmit: SubmitHandler<EmailAndPasswordFormValues> = async input => {
    setError('')

    try {
      const result = await onSubmit(input)
      if (result.kind === 'max-session-reached') {
        setMaxSessionsToken(result.maxSessionsToken)
      }
    } catch (err) {
      handleSubmitError()
    }
  }

  async function handleForceLogin() {
    if (maxSessionsToken) {
      setIsForceLoginLoading(true)
      try {
        await onForceLogin(maxSessionsToken)
      } catch (error) {
        setIsForceLoginLoading(false)
        setShowRetryForceLoginModal(true)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off" noValidate>
      {showLoggedOutHeader && <LoggedOutHeader />}
      <Typography component="h2" mt={2} fontWeight="bold" {...headingProps}>
        {heading ?? t('forms.emailAndPassword.heading')}
      </Typography>
      {error && (
        <Alert severity="error" sx={{ my: 2 }} ref={errorRef} tabIndex={-1}>
          {error}
        </Alert>
      )}
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="email"
            label={t('inputs.email.label')}
            helperText={errors.email?.message}
            error={'email' in errors}
            data-test="email-input"
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => <PasswordInput {...field} required errorMessage={errors.password?.message} />}
      />
      <LoadingButton
        variant="contained"
        fullWidth
        sx={{ mt: 6 }}
        type="submit"
        loading={isSubmitting}
        loadingPosition={isSubmitting ? 'end' : undefined}
        data-test="submit-button"
      >
        {submitLabel ?? t('common.submit')}
      </LoadingButton>

      {maxSessionsToken && !showRetryForceLoginModal && (
        <ForceLoginModal
          onConfirm={handleForceLogin}
          onClose={() => {
            setMaxSessionsToken(null)
          }}
          loading={isForceLoginLoading}
          buttonTestID="force-submit-button"
        />
      )}
      {maxSessionsToken && showRetryForceLoginModal && (
        <RetryForceLoginModal
          onConfirm={handleForceLogin}
          onClose={() => {
            setMaxSessionsToken(null)
          }}
          loading={isForceLoginLoading}
        />
      )}
    </form>
  )
}
