import { Box, Divider, Stack, SvgIcon, SxProps, Typography } from '@mui/material'
import React from 'react'

interface ListItemsProps {
  withBackground?: boolean
  bgcolor?: string
}

const ListItems: React.FC<ListItemsProps> = ({ children, withBackground, bgcolor }) => {
  const insetStyle = {
    px: 2,
    py: 1.5,
    bgcolor: bgcolor || 'gray.100',
  }

  return (
    <Stack
      component="dl"
      direction="column"
      spacing={2}
      sx={{ borderRadius: 2, m: 0, ...(withBackground && insetStyle) }}
    >
      {children}
    </Stack>
  )
}

const ListHeader: React.FC = ({ children }) => {
  return (
    <Stack flex="1" direction="row" justifyContent="space-between" alignItems="end" spacing={2}>
      {children}
    </Stack>
  )
}

const ListTitle: React.FC = ({ children }) => {
  return (
    <Typography fontWeight="bold" fontSize="1.25rem">
      {children}
    </Typography>
  )
}

interface ListSubtitleProps {
  textAlign?: string
  paddingBottom?: string
}

const ListSubtitle: React.FC<ListSubtitleProps> = ({ children, textAlign = 'right', paddingBottom }) => {
  return (
    <Typography variant="body2" style={{ marginTop: '-2px', paddingBottom }} sx={{ color: 'gray.800', textAlign }}>
      {children}
    </Typography>
  )
}

interface ListItemProps {
  inset?: boolean
  icon?: React.ReactNode
  onClick?: () => void
  sx?: SxProps
}

const ListItem: React.FC<ListItemProps> = ({ children, inset, icon, onClick, sx }) => {
  const component = (
    <Stack direction="column" spacing={0.5} sx={{ pl: inset ? 4 : undefined, ...sx }} data-test="subSection">
      {children}
    </Stack>
  )

  if (icon) {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={onClick}
        sx={{ cursor: onClick ? 'pointer' : 'initial', ...sx }}
        role="button"
        tabIndex={0}
      >
        {component}
        {icon}
      </Stack>
    )
  }

  return component
}

const ListLabel: React.FC = ({ children }) => {
  return (
    <Stack direction="row" component="dt" fontWeight="bold">
      {children}
    </Stack>
  )
}

const ListListLabel: React.FC = ({ children }) => {
  return (
    <Typography fontSize="0.875rem" fontWeight="bold">
      {children}
    </Typography>
  )
}

const ListListBody: React.FC = ({ children }) => {
  return (
    <Typography fontSize="0.875rem" sx={{ m: 0, color: 'gray.700' }}>
      {children}
    </Typography>
  )
}

const ListBody: React.FC = ({ children }) => {
  return (
    <Box component="dd" sx={{ m: 0, color: 'gray.700' }} data-test="values-section">
      {children}
    </Box>
  )
}

interface ListDividerProps {
  icon?: React.ElementType
  label: React.ReactNode
}

const ListDivider: React.FC<ListDividerProps> = ({ icon, label }) => {
  return (
    <Divider textAlign="left">
      <Stack direction="row" alignItems="center" spacing={1}>
        {icon ? <SvgIcon component={icon} inheritViewBox size={24} /> : null}
        <Typography fontSize="1.125rem" fontWeight="bold">
          {label}
        </Typography>
      </Stack>
    </Divider>
  )
}

type ListComponent = React.FC & {
  Header: typeof ListHeader
  Title: typeof ListTitle
  Subtitle: typeof ListSubtitle
  Items: typeof ListItems
  Item: typeof ListItem
  Label: typeof ListLabel
  ListLabel: typeof ListListLabel
  ListBody: typeof ListListBody
  Body: typeof ListBody
  Divider: typeof ListDivider
}

// Probably a bug 🐞: ESLint: 'children' is missing in props validation(react/prop-types)
// eslint-disable-next-line react/prop-types
const List: ListComponent = ({ children }) => {
  return (
    <Stack direction="column" spacing={2} data-test="medicalHistory-section">
      {children}
    </Stack>
  )
}

List.Header = ListHeader
List.Title = ListTitle
List.Subtitle = ListSubtitle
List.Items = ListItems
List.Item = ListItem
List.Label = ListLabel
List.ListLabel = ListListLabel
List.ListBody = ListListBody
List.Body = ListBody
List.Divider = ListDivider

export const DescriptionList = List
