import { getToken } from './auth'
import { apiRequest } from './request'

interface CreateVideoCallOptions {
  consultationId: number
  clinicianId: number
  patientId: number
}

export async function createVideoCall({ consultationId, clinicianId, patientId }: CreateVideoCallOptions) {
  return apiRequest<{ id: number; sessionId: string }>(`/video-calls`, {
    method: 'POST',
    getToken,
    body: {
      moderators: [clinicianId],
      publishers: [patientId],
      consultationId,
    },
  })
}

export async function getVideoCallToken(id: number) {
  return apiRequest<{ token: string; sessionId: string }>(`/video-calls/${id}`, { getToken })
}
