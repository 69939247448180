import { Grid } from '@mui/material'
import React, { useMemo } from 'react'

import { AdditionalInformationDetails } from '@app/components/Consultation/common/PatientDetails/AdditionalInformationDetails'
import { ConsultationActions } from '@app/components/Consultation/common/PatientDetails/ConsultationActions'
import { PersonalInformationDetails } from '@app/components/Consultation/common/PatientDetails/PersonalInformationDetails'
import { Consultation, Patient } from '@app/types'

type PatientDetailsProps = {
  isSigning: boolean
  isUnassigning?: boolean
  onCancel?: VoidFunction
  onContinue?: VoidFunction
  onSignAndShare?: VoidFunction
  onUnassign?: VoidFunction
  consultation: Consultation
}

export const PatientDetails: React.FC<PatientDetailsProps> = ({
  isSigning,
  isUnassigning,
  onCancel,
  onContinue,
  onSignAndShare,
  onUnassign,
  consultation,
}) => {
  const patient = useMemo<Patient>(() => consultation.patient, [consultation.patient])

  return (
    <Grid container spacing={2} alignItems="center">
      <PersonalInformationDetails patient={patient} />

      <AdditionalInformationDetails patient={patient} defaultValues={consultation.journal?.additionalInformation} />

      <ConsultationActions
        consultation={consultation}
        isSigning={isSigning}
        isUnassigning={isUnassigning}
        onCancel={onCancel}
        onContinue={onContinue}
        onSignAndShare={onSignAndShare}
        onUnassign={onUnassign}
      />
    </Grid>
  )
}
