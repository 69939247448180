import { yupResolver } from '@hookform/resolvers/yup'
import { Chip, SvgIcon, TextField, Typography } from '@mui/material'
import { Lock } from 'phosphor-react'
import React, { useEffect, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { Card } from '@app/components/Card/Card'
import { useConsultationSensitiveNotesState } from '@app/components/Consultation/state/use-consultation-sensitive-notes-state'
import { DescriptionList } from '@app/components/EMR/common/DescriptionList'

import { MEDIUM_MAX_SIZE, WORD_MIN_SIZE } from '../const'

export function useSensitiveNotesSchema() {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object({
        sensitiveNotes: yup
          .string()
          .trim()
          .nullable()
          .notRequired()
          .transform((curr, orig) => (orig === '' ? undefined : curr))
          .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
          .max(MEDIUM_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: MEDIUM_MAX_SIZE })),
      }),
    [t]
  )
}

type SensitiveNotesProps = {
  defaultValue?: string
}

export const SensitiveNotes: React.FC<SensitiveNotesProps> = ({ defaultValue }) => {
  const { t } = useTranslation()
  const { setFormIsValid, setSensitiveNotes } = useConsultationSensitiveNotesState()
  const sensitiveNotesSchema = useSensitiveNotesSchema()
  const methods = useForm({
    resolver: yupResolver(sensitiveNotesSchema),
    defaultValues: {
      sensitiveNotes: defaultValue,
    },
    mode: 'onChange',
  })
  const formValues = useWatch({
    control: methods.control,
  })

  useEffect(() => {
    setSensitiveNotes(defaultValue)
    methods.trigger()
  }, [defaultValue])

  useEffect(() => {
    setFormIsValid(methods.formState.isValid)
  }, [methods.formState.isValid, setFormIsValid])

  useEffect(() => {
    if (methods.formState.isValidating || !methods.formState.isDirty || !methods.formState.isValid) return

    setSensitiveNotes(formValues.sensitiveNotes)
  }, [formValues, methods.formState.isDirty, methods.formState.isValid, methods.formState.isValidating])

  return (
    <Card sx={{ px: 3, py: 2, mt: 1 }}>
      <DescriptionList>
        <DescriptionList.Item>
          <DescriptionList.Title>{t('pages.emr.sensitiveNotes.title')}</DescriptionList.Title>
          <DescriptionList.Subtitle textAlign="left">
            <Chip
              icon={<SvgIcon component={Lock} inheritViewBox sx={{ color: 'gray.900', mr: 1 }} />}
              label={t('pages.emr.sensitiveNotes.thisIsNotVisibleToPatient')}
              color="primary"
            />
          </DescriptionList.Subtitle>
        </DescriptionList.Item>

        <DescriptionList.Items>
          <Controller
            name="sensitiveNotes"
            control={methods.control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                size="small"
                type="text"
                label={
                  <Typography variant="body2" fontWeight="bold" sx={{ color: 'gray.900' }}>
                    {t('pages.emr.sensitiveNotes.title')}
                  </Typography>
                }
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                multiline
                data-test="sensitiveNotes-input"
              />
            )}
          />
        </DescriptionList.Items>
      </DescriptionList>
    </Card>
  )
}
