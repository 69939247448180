const STRAPI_API_URL = import.meta.env.VITE_STRAPI_API_URL

/**
 * For images coming from the admin (Strapi) media library.
 * In development, Strapi images come from the local /public/uploads folder, whereas
 * in production they come from AWS S3.
 * @param imageUrl the value of `data.attributes.url` of an image field of a Strapi
 *  REST API response object.
 */
export function makeStrapiImageUrl(imageUrl: string) {
  return `${new URL(imageUrl, STRAPI_API_URL)}`
}
