import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'
import { revalidateDataConfig } from '@app/lib/request'
import { Address, MedicalHistory, Prescriptions, Sex } from '@app/types'

export interface PatientResponse {
  patient: {
    id: number
    cardoId: string
    lockedForEdits: boolean
    lockedById: number | null
    phone: string
    disabled: boolean
    firstName: string
    lastName: string
    legalName: string
    dateOfBirth: string
    sex: Sex
    emrId: string
    genderInfo: string | null
    cpfData?: {
      code: string
    }
    homeAddress: Address
    holder?: PatientResponse['patient']
    blocked: boolean
  }
  medicalHistory: MedicalHistory
  pastPrescriptions: Prescriptions
  additionalInformation: {
    curp?: string
    address?: string
    height?: string
    weight?: string
  }
}

export function usePatient(emrId: undefined | string) {
  const { data, error, isValidating } = useSWR<PatientResponse>(emrId ? `/patients/${emrId}` : null, {
    fetcher: fetcher.api,
    ...revalidateDataConfig,
  })

  return {
    hasError: !!error,
    isLoading: (!error && !data) || isValidating,
    data: data ?? null,
  }
}
