declare type OT = typeof import('@opentok/client')

export type PasswordStrength = 0 | 1 | 2 | 3

export enum PreferredContactMethod {
  PHONE = 'phone',
  VIDEO = 'video',
}

export interface InvitationTokenPayload {
  exp: number
  iat: number
  id: string
  nonce: string
}

export enum Role {
  PATIENT = 'patient',
  CLINICIAN = 'clinician',
  CLINICAL_MANAGER = 'clinical-manager',
  CLINICIAN_AND_CLINICAL_MANAGER = 'clinician-and-clinical-manager',
}

export interface TokenPayload {
  id: string
  role: Role
  scopes: string[]
  exp: number
  iat: number
}

export interface User {
  id: number
  username: string
  email: string
  phone: string
  emrId: string
}

export enum Sex {
  Male = 'Male',
  Female = 'Female',
}

export interface EMRPatient {
  id: number
  sex?: Sex
  firstName?: string
  lastName?: string
  legalName?: string
  emrId: string
  blocked: boolean
  dateOfBirth?: string
  phone?: string
  email?: string
  nationalId?: string
}

export type DateSelectionType = {
  id: string
  label: string
  uuid?: string
  approximateYear: boolean
  dates: {
    from: string | null
    to: string | null
  }
}

export type Medication = DateSelectionType
export type Allergy = DateSelectionType & { comments: string }

export enum Administration {
  INHALED = 'inhaled',
  INTRAARTICULAR = 'intra-articular',
  INTRAMUSCULAR = 'intramuscular',
  INTRAVAGINAL = 'intravaginal',
  INTRAVENOUS = 'intravenous',
  NASAL = 'nasal',
  OPHTHALMIC = 'ophthalmic',
  ORAL = 'oral',
  OTIC = 'otic',
  PARENTERAL = 'parenteral',
  RECTAL = 'rectal',
  SD = 'S/D',
  SUBCUTANEOUS = 'subcutaneous',
  SUBLINGUAL = 'sublingual',
  TOPIC = 'topic',
  VAGINAL = 'vaginal',
}

export interface JournalState {
  currentSituation?: string
  status?: string
  assessment?: string
  diagnosis?: Diagnosis[]
  plannedActions?: string
}

export type Diagnosis = {
  id: string
  label: string
}

export enum PrescriptionMedicalHistoryType {
  CURRENT = 'current',
  PAST = 'past',
}

export type PrescriptionData = Prescription & {
  createdAt?: string
  clinician?: Clinician
}

export type PrescriptionForm = {
  diagnosis: Diagnosis | null
  medication: {
    id: string
    label: string
  } | null
  quantity: number | string | null
  duration: number | string | null
  administration: Administration | null
  instructions: string
  forbidSubstitute: boolean
  medicalHistory?: {
    type: PrescriptionMedicalHistoryType
    date: Date | null
    approximateYear: boolean
  } | null
  emrId?: string
}

export type Prescriptions = Prescription[]

export type Prescription = {
  diagnosis?: Diagnosis
  medication: {
    uuid?: string
    id: string
    label: string
  }
  quantity: number | null
  duration: number | null
  administration: Administration
  instructions: string
  forbidSubstitute: boolean
  medicalHistory?: {
    type: PrescriptionMedicalHistoryType
    date: Date | null
    approximateYear: boolean
  }
  encounterUuid: string
  emrId?: string
  prescriptionId?: string
  prescriptionUrl?: string
  securityCode?: string
}

export type Allergies = {
  drugAllergies?: Array<Allergy>
  hypersensitivities?: string
  otherWarning?: string
}

export type NonPathologicalPersonalHistory = {
  familyHistoryDiseases?: string
  socialConditions?: string
  bloodType?: string
  obstetricsAndGynecologicalHistory?: string
}

export type PathologicalPersonalHistory = {
  previousDiseases?: DateSelectionType[]
  previousMedication?: DateSelectionType[]
  previousSurgeries?: DateSelectionType[]
  currentDiseases?: DateSelectionType[]
  currentMedication?: DateSelectionType[]
  currentSurgeries?: DateSelectionType[]
}

export type MedicalHistory = {
  allergies?: Allergies
  nonPathologicalPersonalHistory?: NonPathologicalPersonalHistory
  pathologicalPersonalHistory?: PathologicalPersonalHistory
}

export type AdditionalInformation = {
  curp?: string
  address?: string
  weight?: string
  height?: string
}

export type JournalData = {
  additionalInformation?: AdditionalInformation
  medicalHistory?: MedicalHistory
  journal?: JournalState
  sensitiveNotes?: string
  prescriptions: Prescriptions
  pastPrescriptions?: Prescriptions | null
}

export type Patient = {
  id: number
  dateOfBirth: string
  emrId: string
  firstName: string
  lastName: string
  legalName: string
  sex: Sex
  cardoId: string
  phone: string
  genderInfo: string | null
  cpfData?: {
    code: string
  }
  homeAddress?: Address
  lockedById?: number | null
  holder?: Patient
  b2bCompany?: B2BCompany
  blocked?: boolean
}

export type B2BCompany = {
  companyName: string
}

export type Address = {
  zipCode: string
  cityName: string
  stateName: string
  streetName: string
  streetNumber: string
  district?: string
  comments?: string
}

export type Symptom = {
  question: string
  answer: string
}

export type Summary = {
  content: string
  updatedAt: string
}

export type Clinician = {
  firstName: string
  lastName: string
  username: string
  npi: string
  emrId: string
  title: string
  cardoId: string
  specialtyAndSubSpecialtyTitle: string
}

export enum ActionType {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED = 'ASSIGNED',
  VIDEO_CALL_STARTED = 'VIDEO_CALL_STARTED',
  PHONE_CALL_STARTED = 'PHONE_CALL_STARTED',
  VIDEO_CALL_FAILED = 'VIDEO_CALL_FAILED',
  PHONE_CALL_FAILED = 'PHONE_CALL_FAILED',
  VIDEO_CALL_ENDED = 'VIDEO_CALL_ENDED',
  PHONE_CALL_ENDED = 'PHONE_CALL_ENDED',
  VIDEO_CALL_COMPLETED = 'VIDEO_CALL_COMPLETED',
  PHONE_CALL_COMPLETED = 'PHONE_CALL_COMPLETED',
  VIDEO_CALL_ATTEMPTED = 'VIDEO_CALL_ATTEMPTED',
  PHONE_CALL_ATTEMPTED = 'PHONE_CALL_ATTEMPTED',
  VIDEO_CALL_CANCELLED = 'VIDEO_CALL_CANCELLED',
  PHONE_CALL_CANCELLED = 'PHONE_CALL_CANCELLED',
  CALL_STARTED = 'CALL_STARTED',
  QUEUED = 'QUEUED',
  CLOSED = 'CLOSED',
  CLOSED_WITH_ERROR = 'CLOSED_WITH_ERROR',
  CANCELLED = 'CANCELLED',
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
}

export type Action = {
  type: ActionType
  createdAt: string
  updatedAt: string
  user: { id: number; email: string; role: string }
}

export interface PatientAdditionalInformation {
  curp?: string
  address?: string
  weight?: string
  height?: string
}

export interface Consultation {
  additionalInformation?: PatientAdditionalInformation
  id: number
  type: ConsultationType
  encounterUuid: string
  patient: Patient
  createdAt: string
  updatedAt: string
  category: Category
  status: ConsultationStatus
  symptoms: Symptom[]
  summary?: Summary
  symptomsDocuments: Array<FileInfo>
  preferredContactMethod: PreferredContactMethod | null
  productName: string
  journal?: JournalData
  clinician?: Clinician
  clinicianId?: number
  patientId: number
  actions: Action[]
  prescriptionId: string | null
  videoCallId?: number
  /** Only for scheduled consultations. */
  startTime?: string
  /** Only for scheduled consultations. */
  endTime?: string
}

export interface EmptyConsultation {
  id: null
  patient: {
    id: number
    dateOfBirth: string
    emrId: string
    firstName: string
    lastName: string
    legalName: string
    sex: Sex
    genderInfo: string | null
    cardoId: string
    phone: string
    lockedById: number | null
    cpfData?: {
      code: string
    }
    homeAddress: Address
  }
  clinician: {
    title: string
    firstName: string
    lastName: string
    cardoId: string
  }
  patientId: number
  medicalHistory: MedicalHistory
  pastPrescriptions: Prescriptions
  additionalInformation: PatientAdditionalInformation
  type: ConsultationType
}

export enum ConsultationType {
  SCHEDULED = 'scheduled',
  ON_DEMAND = 'on-demand',
  MEDICAL_UPDATE = 'medical-update',
}

export enum ConsultationStatus {
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  CLOSING = 'CLOSING',
  CLOSED_WITH_ERROR = 'CLOSED_WITH_ERROR',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
}

export enum ConsultationSortBy {
  POSITION_ASC = 'position:asc',
  POSITION_DESC = 'position:desc',
  CREATED_AT_ASC = 'createdAt:asc',
  CREATED_AT_DESC = 'createdAt:desc',
  UPDATED_AT_DESC = 'updatedAt:desc',
  START_TIME_ASC = 'startTime:asc',
}

export interface Category {
  id: number
  name: string
}

export interface FileInfo {
  id: string
  mime: string
  name: string
  url: string
}

export interface StrapiSideBarLink {
  id: number
  attributes: {
    name: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    altText: string
    url: string
    locale: string
    icon: {
      data: {
        attributes: {
          url: string
        }
      }
    }
  }
}

export interface SidebarLink {
  id: number
  name: string
  updatedAt: string
  icon: null | {
    url: string
  }
  createdAt: string
  publishedAt: string
  url: string
  locale: string
}

export interface StrapiError {
  status: number
  details: any
  name: string
  message: string
}

export interface PatientsSearchQueryParams {
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
  dateOfBirth?: string
  nationalId?: string
}

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr]
}

declare global {
  interface Window {
    OT: OT
  }
}

export enum ConsultationFeedbackType {
  CONFIRM_CALL = 'CONFIRM_CALL',
  REPEAT_CALL = 'REPEAT_CALL',
  CANCEL_CONSULTATION = 'CANCEL_CONSULTATION',
}

export enum CallType {
  PHONE_CALL = 'PHONE_CALL',
  VIDEO_CALL = 'VIDEO_CALL',
}

export type CallRating = {
  rating: number
  ratingComment: string
}
