import fetch from 'cross-fetch'

import i18n from '@app/i18n/config'
import { getToken } from '@app/lib/auth'
import { getSessionId, getTraceId } from '@app/util/sentry'

import { handleUnauthorizedRequest } from './utils/helper'

const BASE_URL = import.meta.env.VITE_API_URL

export async function apiFetcher(endpoint: string, options: RequestInit = {}) {
  const token = await getToken()
  const resp = await fetch(`${BASE_URL}${endpoint}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-locale': i18n.language,
      'x-session-id': getSessionId(),
      'x-trace-id': getTraceId(),
      'x-app': 'clinician',
      ...options.headers,
    },
    ...options,
  })

  await handleUnauthorizedRequest(resp.status)

  const data = await resp.json()

  if (!resp.ok) {
    throw data
  }

  return data
}
