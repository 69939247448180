import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const usePatientSearchSchema = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().trim(),
        lastName: yup.string().trim(),
        email: yup.string().trim().email(),
        nationalId: yup.string().trim(),
        phone: yup
          .string()
          .trim()
          .matches(/^\d+$/)
          .nullable()
          .transform(value => (value ? value : null)),
        phoneCountryCode: yup.string().trim().matches(/^\d+$/),
        dateOfBirth: yup.object().shape(
          {
            day: yup
              .number()
              .min(1)
              .max(31)
              .nullable()
              .transform(value => (value ? value : null))
              .when(['month', 'year'], {
                is: (month: number, year: number) => month || year,
                then: yup.number().required(),
              }),
            month: yup
              .number()
              .min(1)
              .max(12)
              .nullable()
              .transform(value => (value ? value : null))
              .when(['day', 'year'], {
                is: (day: number, year: number) => day || year,
                then: yup.number().required(),
              }),
            year: yup
              .number()
              .min(1900)
              .max(new Date().getFullYear())
              .nullable()
              .transform(value => (value ? value : null))
              .when(['day', 'month'], {
                is: (day: number, month: number) => day || month,
                then: yup.number().required(),
              }),
          },
          [
            ['month', 'year'],
            ['day', 'year'],
            ['day', 'month'],
          ]
        ),
      }),
    [t]
  )
}
