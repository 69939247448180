import { Alert, AlertColor, Button, Snackbar, SnackbarProps, SvgIcon } from '@mui/material'
import React, { useCallback, useContext, useState } from 'react'

type NotificationAction = {
  title: string
  onClick: VoidFunction
}

export type Notification = {
  message: string
  severity?: AlertColor
  action?: NotificationAction
  autoHide?: boolean
  icon?: React.ComponentType
}

type ContextType = {
  addNotification: (notification: Notification) => void
  handleAlertClose: VoidFunction
}

type SnackbarOncloseFn = NonNullable<SnackbarProps['onClose']>

export const NOTIFICATION_DURATION_MS = 6000 // 6 seconds

export const NotificationsContext = React.createContext<ContextType | undefined>(undefined)

export const NotificationsProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [notification, setNotification] = useState<Notification>()

  const addNotification = useCallback(notification => {
    setIsOpen(true)
    setNotification(notification)
  }, [])

  const handleAlertClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleSnackbarClose = useCallback<SnackbarOncloseFn>(
    (_, reason) => {
      const preventClose = notification?.autoHide === false && reason === 'clickaway'
      if (preventClose) return undefined
      setIsOpen(false)
    },
    [notification]
  )

  const autoHideDuration = notification?.autoHide ?? true ? NOTIFICATION_DURATION_MS : null

  return (
    <NotificationsContext.Provider value={{ addNotification, handleAlertClose }}>
      <Snackbar
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={handleSnackbarClose}
        message={notification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={notification?.severity}
          sx={{ width: '100%', alignItems: 'center' }}
          icon={notification?.icon ? <SvgIcon component={notification.icon} inheritViewBox /> : undefined}
          action={
            notification?.action ? (
              <Button
                color="inherit"
                size="small"
                onClick={notification.action.onClick}
                data-testid={`consultation-snackbar-notification-${notification.action.title.toLowerCase()}-button`}
              >
                {notification.action.title}
              </Button>
            ) : null
          }
          data-testid="consultation-snackbar-notification"
        >
          {notification?.message}
        </Alert>
      </Snackbar>
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotifications = () => {
  const context = useContext(NotificationsContext)

  if (!context) {
    throw new Error('useNotifications() must be wrapped inside NotificationsProvider.')
  }

  return context
}
