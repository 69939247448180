import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputBase,
  InputBaseProps,
  SvgIcon,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { EyeSlash, Eye } from 'phosphor-react'
import React, { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface PasswordInputProps {
  id?: string
  label?: string
  required?: boolean
  errorMessage?: string
  inputRef?: RefObject<HTMLInputElement>
  visuallyHiddenError?: boolean
  ariaDescribedBy?: string
}

export const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps & InputBaseProps>(
  ({ label, required, errorMessage, visuallyHiddenError, id, ariaDescribedBy, ...rest }, inputRef) => {
    const { t } = useTranslation()
    const [showPassword, setShowPassword] = useState(false)

    const hasError = !!errorMessage
    const inputId = id ?? 'password-input'
    const errorMessageId = `${inputId}-error-message`

    return (
      <FormControl variant="standard" fullWidth sx={{ mt: 6 }} error={hasError}>
        <FormLabel htmlFor={inputId}>{label ?? t('inputs.password.label')}</FormLabel>
        <InputBase
          sx={{ mt: 1, borderColor: hasError ? 'red' : undefined }}
          fullWidth
          inputRef={inputRef}
          inputProps={{
            'aria-required': required,
            'aria-invalid': hasError,
            // we always want to override this if there's an error
            'aria-describedby': hasError ? errorMessageId : ariaDescribedBy,
          }}
          id={inputId}
          type={showPassword ? 'text' : 'password'}
          data-test="password-input"
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 1 }}>
              <IconButton
                aria-label={t(`inputs.password.${showPassword ? 'hidePassword' : 'showPassword'}`)}
                onClick={() => setShowPassword(!showPassword)}
              >
                <SvgIcon aria-hidden="true" component={showPassword ? Eye : EyeSlash} size={24} inheritViewBox />
              </IconButton>
            </InputAdornment>
          }
          {...rest}
        />
        {hasError && (
          <FormHelperText id={errorMessageId} sx={visuallyHiddenError ? visuallyHidden : {}}>
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
)

PasswordInput.displayName = 'PasswordInput'
