import { useEffect, useRef, useState } from 'react'

export function useFocusableError() {
  const [error, setError] = useState('')
  const errorRef = useRef<HTMLDivElement>(null)

  useEffect(
    function focusError() {
      if (error) {
        errorRef.current?.focus()
      }
    },
    [error]
  )

  return { error, errorRef, setError }
}
