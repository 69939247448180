import useSWR, { useSWRConfig } from 'swr'

import { useAuth } from '@app/context/auth'
import { getToken } from '@app/lib/auth'
import fetcher from '@app/lib/fetcher'
import { apiRequest } from '@app/lib/request'
import { addSentryBreadcrumb } from '@app/util/sentry'

type UseOnlineStateReturnType = {
  changeStatus: (online: boolean) => void
  changeCategoryState: (categories: number[]) => void
  online: boolean
  categories: number[]
  onlineSince: Date | null
  isLoading: boolean
  error: Error | null
}

export const ONLINE_STATUS_ENDPOINT = '/clinician/online-status'

const updateFn = (categories: number[], online: boolean) =>
  apiRequest<{ status: boolean }>(ONLINE_STATUS_ENDPOINT, {
    body: { categories, online },
    getToken,
  })

export function useOnlineState(): UseOnlineStateReturnType {
  const { profile } = useAuth()

  const { mutate } = useSWRConfig()
  const { data, error } = useSWR<{ categories?: number[]; online: boolean; onlineSince?: Date }>(
    ONLINE_STATUS_ENDPOINT,
    { fetcher: fetcher.api }
  )

  const changeStatus = (online: boolean) => {
    if (!data) return false

    let categories = data.categories || []

    // No categories selected, make first one default selection.
    if (!categories.length && profile?.categories?.length) {
      categories = [profile?.categories[0].id]
    }

    addSentryBreadcrumb(`Set clinician online to ${online}`)

    mutate(ONLINE_STATUS_ENDPOINT, updateFn(categories, online), {
      optimisticData: { online, categories, onlineSince: data.onlineSince },
      rollbackOnError: true,
      populateCache: false,
    })
  }

  const changeCategoryState = (categories: number[]) => {
    if (!data?.categories) return false

    const online = categories.length > 0

    mutate(ONLINE_STATUS_ENDPOINT, updateFn(categories, online), {
      optimisticData: { online, categories, onlineSince: data.onlineSince },
      rollbackOnError: true,
      populateCache: false,
    })
  }

  return {
    changeStatus,
    changeCategoryState,
    online: data?.online || false,
    categories: data?.categories || [],
    onlineSince: data?.onlineSince ? new Date(data.onlineSince) : null,
    isLoading: !error && !data,
    error,
  }
}
