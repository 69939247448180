import { Box, Stack } from '@mui/material'
import i18n from 'i18next'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DoctorIcon } from '@app/components/icons/DoctorIcon'
import { UserIcon } from '@app/components/icons/UserIcon'
import { MonitoringTable, useMonitoring } from '@app/context/monitoring'
import { useClinicians } from '@app/hooks/use-clinicians'
import { usePatientsQueue } from '@app/hooks/use-patients-queue'
import { SearchInput } from '@app/pages/Monitoring/Filters/SearchInput'
import { InfoCard } from '@app/pages/Monitoring/InfoCard/InfoCard'
import { TabPanel } from '@app/pages/Monitoring/TabPanel/TabPanel'
import { colors } from '@app/theme/color'

import { ClinicianMonitoringTable } from './ClinicianMonitoringTable'
import { PatientMonitoringTable } from './PatientMonitoringTable'

const tabs = [
  {
    label: i18n.t('pages.monitoring.onDemandTabs.patients'),
    value: MonitoringTable.CONSULTATIONS,
  },
  {
    label: i18n.t('pages.monitoring.onDemandTabs.clinicians'),
    value: MonitoringTable.CLINICIANS,
  },
]

const categorySubTitles = (categories: Record<string, number>) => {
  const entries = Object.entries(categories)

  return entries.length ? entries.map(([category, count]) => `${count} ${category}`).join(' | ') : '-'
}

export function OnDemandMonitoringPage() {
  const { t } = useTranslation()
  const { totalCliniciansOnline, cliniciansCategories } = useClinicians()
  const { totalPatientsInQueue, patientsCategories } = usePatientsQueue()
  const { table, setTable, searchValue, setSearchValue } = useMonitoring()

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
    },
    [setSearchValue]
  )

  const cliniciansCategoriesSubTitles = useMemo(() => {
    return categorySubTitles(cliniciansCategories)
  }, [cliniciansCategories])

  const patientsCategoriesSubTitles = useMemo(() => {
    return categorySubTitles(patientsCategories)
  }, [patientsCategories])

  return (
    <Box mt={6}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ marginTop: '16px', marginBottom: '24px' }}
      >
        <InfoCard
          caption={<DoctorIcon size={24} color={colors.blue[500]} />}
          title={t('pages.monitoring.cliniciansOnline', { count: totalCliniciansOnline })}
          subTitles={cliniciansCategoriesSubTitles}
        />
        <InfoCard
          caption={<UserIcon size={24} color={colors.blue[500]} />}
          title={t('pages.monitoring.patientsInQueue', { count: totalPatientsInQueue })}
          subTitles={patientsCategoriesSubTitles}
        />
      </Stack>
      <Box sx={{ position: 'relative' }}>
        <SearchInput value={searchValue} handleSearchChange={handleSearchChange} />
        <TabPanel tabs={tabs} selected={table} variant="underline">
          {selectedTab => {
            switch (selectedTab) {
              case MonitoringTable.CONSULTATIONS:
                setTable(MonitoringTable.CONSULTATIONS)
                return <PatientMonitoringTable searchFilter={searchValue} />
              case MonitoringTable.CLINICIANS:
                setTable(MonitoringTable.CLINICIANS)
                return <ClinicianMonitoringTable searchFilter={searchValue} />
              default:
                return null
            }
          }}
        </TabPanel>
      </Box>
    </Box>
  )
}
