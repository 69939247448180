import * as Sentry from '@sentry/react'
import React, { Suspense } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { AppLayout } from '@app/components/AppLayout'
import { AppLoading } from '@app/components/AppLoading'
import { InnerErrorBoundary } from '@app/components/ErrorBoundary'
import { LogoutWarning } from '@app/components/LogoutWarning'
import { useAuth } from '@app/context/auth'
import { ClinicianMonitoringPage } from '@app/pages/ClinicianMonitoring/ClinicianMonitoringPage'
import { ActiveConsultationPage } from '@app/pages/Consultation'
import { NewConsultationPage } from '@app/pages/Consultation/NewConsultationPage'
import { HistoryPage } from '@app/pages/History'
import { HomePage } from '@app/pages/Home'
import { InvitationPage } from '@app/pages/Invitation'
import { LanguagePage } from '@app/pages/Language'
import { LoginPage } from '@app/pages/Login'
import { MonitoringPage } from '@app/pages/Monitoring'
import { PatientDetailPage, PatientsPage } from '@app/pages/Patients'
import { SchedulePage } from '@app/pages/Schedule'
import { WatchlistPage } from '@app/pages/Watchlist'

const I18N_DEBUG = import.meta.env.VITE_I18N_DEBUG === 'true'
const FEATURE_WATCHLIST = import.meta.env.VITE_FEATURE_WATCHLIST === 'true'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const AppRoutes = () => {
  const auth = useAuth()

  if (auth.loading) {
    return <AppLoading />
  }

  if (!auth.isLoggedIn) {
    return <LoggedOutRoutes />
  }

  return <LoggedInRoutes />
}

export function RedirectToLogin() {
  const { pathname, search } = useLocation()
  return <Navigate replace to="login" state={{ from: { pathname, search } }} />
}

function LoggedOutRoutes() {
  return (
    <SentryRoutes>
      <Route index element={<Navigate replace to="login" />} />
      <Route path="*" element={<RedirectToLogin />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="invitation" element={<InvitationPage />} />
      {I18N_DEBUG && <Route path="language" element={<LanguagePage />} />}
    </SentryRoutes>
  )
}

function RedirectToHome() {
  const { pathname, search } = useLocation()
  return <Navigate replace to="/" state={{ from: { pathname, search } }} />
}

function LoggedInRoutes() {
  const location = useLocation()

  return (
    <AppLayout>
      <LogoutWarning />
      <InnerErrorBoundary key={location.pathname}>
        <Suspense fallback={<AppLoading />}>
          <SentryRoutes>
            <Route path="/" element={<HomePage />} />
            <Route path="/patients" element={<PatientsPage />} />
            <Route path="/patients/:emrId" element={<PatientDetailPage />} />
            <Route path="/patients/:emrId/consultation" element={<NewConsultationPage />} />
            <Route path="/consultation/:id" element={<ActiveConsultationPage />} />
            {FEATURE_WATCHLIST && <Route path="/watchlist" element={<WatchlistPage />} />}
            <Route path="/schedule" element={<SchedulePage />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="/monitoring" element={<MonitoringPage />} />
            <Route path="/monitoring/clinicians/:clinicianId" element={<ClinicianMonitoringPage />} />
            <Route path="language" element={<LanguagePage />} />
            <Route path="*" element={<RedirectToHome />} />
            {I18N_DEBUG && <Route path="language" element={<LanguagePage />} />}
          </SentryRoutes>
        </Suspense>
      </InnerErrorBoundary>
    </AppLayout>
  )
}
