import React, { useContext, useEffect, useMemo } from 'react'
import useSWR from 'swr'

import { AuthContextType, useAuth } from '@app/context/auth'
import { onConsultationClosed } from '@app/lib/events'
import fetcher from '@app/lib/fetcher'

// ----- Context

type PatientId = number
type ConsultationId = number
type Count = number

type Response = {
  patients: {
    [patientId: PatientId]: {
      consultationId: ConsultationId
    }
  }
}

type ContextType = {
  data: Response | undefined
  consultationIds: ConsultationId[]
  patientIds: PatientId[]
  count: Count
  refresh: VoidFunction
}

export const ErraticPatientsContext = React.createContext<ContextType | undefined>(undefined)

function getConsultationIds(data: Response | undefined): ConsultationId[] {
  return data ? Object.values(data.patients).map(patient => patient.consultationId) : []
}

function getPatientIds(data: Response | undefined): PatientId[] {
  return data ? Object.keys(data.patients).map(Number) : []
}

function getPatientCount(data: Response | undefined): Count {
  return data ? Object.keys(data.patients).length : 0
}

function getUrl(auth: AuthContextType, allPatients: boolean) {
  if (!auth.isLoggedIn || !auth.profile) return null
  const path = '/patients/erratic-consultations'
  return allPatients ? path : `${path}?clinicianId=${auth.profile.id}`
}

export const ErraticPatientsProvider: React.FC<{ allPatients?: boolean }> = ({ children, allPatients = false }) => {
  const auth = useAuth()
  const { data, mutate } = useSWR<Response>(getUrl(auth, allPatients), fetcher.api)
  const value = useMemo(
    () => ({
      data,
      consultationIds: getConsultationIds(data),
      patientIds: getPatientIds(data),
      count: getPatientCount(data),
      refresh: mutate,
    }),
    [data, mutate]
  )

  useEffect(() => {
    const { dispose } = onConsultationClosed.subscribe(() => mutate())
    return dispose
  }, [mutate])

  return <ErraticPatientsContext.Provider value={value}>{children}</ErraticPatientsContext.Provider>
}

export const useErraticPatients = () => {
  const context = useContext(ErraticPatientsContext)

  if (!context) {
    throw new Error('useErraticPatients() must be wrapped inside ErraticPatientsProvider.')
  }

  return context
}
