import { Box, ImageListItem, Stack, SvgIcon, Typography } from '@mui/material'
import { File as FileIcon, FilePdf as FilePdfIcon } from 'phosphor-react'
import React, { RefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Item as GalleryItem } from 'react-photoswipe-gallery'

function getContentType(mimeType: string) {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return 'image'
    case 'video/mp4':
      return 'video'
    case 'application/pdf':
      return 'pdf'
    default:
      return 'file'
  }
}

function getFileIcon(contentType: string) {
  switch (contentType) {
    case 'pdf':
      return FilePdfIcon
    default:
      return FileIcon
  }
}

function truncateFileName(fileName: string, maxLength = 9) {
  if (fileName.length <= maxLength) {
    return fileName
  }

  const extension = fileName.split('.').pop()
  return `${fileName.substring(0, maxLength)}…${extension}`
}

export type MediaItemType = {
  url: string
  thumbnailUrl?: string
  mime: string
  title?: string
  name?: string
  width?: number | string
  height?: number | string
}

const FileItem: React.FC<MediaItemType & { contentType: string }> = ({ contentType, ...item }) => {
  const { t } = useTranslation()

  const renderGalleryContent = () => {
    if (contentType === 'pdf') {
      return (
        <Box
          component="object"
          aria-label={item.name}
          data={`${item.url}#zoom=85&scrollbar=0&toolbar=0&navpanes=0`}
          type="application/pdf"
          sx={{ width: '60%', height: '100%', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
        />
      )
    }

    return (
      <Typography
        fontWeight="bold"
        sx={{ position: 'absolute', inset: 0, color: 'white', margin: 'auto', width: '400px', height: '40px' }}
      >
        {t('common.noPreviewAvailable')}
      </Typography>
    )
  }

  return (
    <Stack spacing={1} alignItems="center">
      <GalleryItem original={item.url} caption={item.name} content={renderGalleryContent()}>
        {({ ref, open }) => (
          <Stack
            ref={ref as RefObject<HTMLButtonElement>}
            role="listitem"
            component="button"
            alignItems="center"
            sx={{
              appearance: 'none',
              p: 0,
              cursor: 'pointer',
              bgcolor: 'gray.50',
              color: 'gray.800',
              borderRadius: '8px',
              border: '1px solid #EBEBEB',
              width: '100%',
              height: '80px',
              '&:hover': {
                bgcolor: 'gray.100',
              },
            }}
            onClick={open}
          >
            <SvgIcon component={getFileIcon(contentType)} inheritViewBox />
          </Stack>
        )}
      </GalleryItem>

      {item.name ? (
        <Typography sx={{ color: 'gray.800', fontSize: '10px' }}>{truncateFileName(item.name)}</Typography>
      ) : null}
    </Stack>
  )
}

const ImageItem: React.FC<MediaItemType> = ({ ...item }) => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 })

  function setImageDimension(this: HTMLImageElement, _ev: Event) {
    setDimensions({ width: this.naturalWidth, height: this.naturalHeight })
  }

  useEffect(() => {
    const img = new Image()

    if (item) {
      img.addEventListener('load', setImageDimension)
      img.src = item.url
    }

    return () => {
      img.removeEventListener('load', setImageDimension)
    }
  }, [item])

  return (
    <GalleryItem original={item.url} caption={item.name} width={dimensions.width} height={dimensions.height}>
      {({ ref, open }) => (
        <Box
          ref={ref}
          component="button"
          role="listitem"
          minWidth={100}
          minHeight={100}
          maxWidth={100}
          maxHeight={100}
          padding={0}
          marginRight={1.5}
          marginBottom={1.5}
          sx={{
            appearance: 'none',
            background: 'none',
            cursor: 'pointer',
            borderRadius: '8px',
            overflow: 'hidden',
            border: '1px solid #EBEBEB',
          }}
          onClick={open}
        >
          <ImageListItem key={item.url} style={{ height: '100%' }}>
            <img src={item.url} alt={item.title} style={{ objectFit: 'cover' }} />
          </ImageListItem>
        </Box>
      )}
    </GalleryItem>
  )
}

export const MediaItem: React.FC<MediaItemType> = ({ ...item }) => {
  const contentType = getContentType(item.mime)

  switch (contentType) {
    case 'image':
      return <ImageItem {...item} />
    default:
      return <FileItem {...item} contentType={contentType} />
  }
}
