import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import type { Tokens } from '@app/lib/auth'
import * as Auth from '@app/lib/auth'
import { getTokenPayload } from '@app/lib/auth'
import { apiRequest } from '@app/lib/request'
import { Role, User } from '@app/types'
import { addSentryBreadcrumb } from '@app/util/sentry'

const fetchProfile = async () => apiRequest<Profile>('/user/me', { getToken: Auth.getToken }).then(resp => resp.data)

type Category = {
  id: number
  name: string
}

type Watchlist = {
  id: number
  patient: User
}

export type Profile = {
  id: number
  firstName: string
  lastName: string
  categories: Category[]
  watchlists: Watchlist[]
  cardoId: string
  prescriptionUserId: string
  showDebugInfo?: boolean
  clinicianData?: {
    professionTitle: string
    specialtyAndSubSpecialtyTitle: string
    dateOfBirth: string
    photography?: {
      url: string
    }
  }
}

export interface AuthState {
  loading: boolean
  isReady: boolean
  profile: Profile | null
  isLoggedIn: boolean
  role: Role | null
}

export interface AuthContextType extends AuthState {
  acceptInvitationAndLogin: (password: string, code: string, token: string) => Promise<void>
  logout: () => Promise<void>
  saveTokens: (tokens: Tokens) => void
  updateProfile: () => Promise<void>
}

const initialState = {
  loading: true,
  isReady: false,
  profile: null,
  isLoggedIn: false,
  role: null,
} as const

export const AuthContext = React.createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<AuthState>(initialState)
  const navigate = useNavigate()

  useEffect(() => {
    async function initialise() {
      try {
        const token = await Auth.getToken()
        if (!token) {
          throw new Error('Unauthorized')
        }
        const profile = await fetchProfile()
        const tokenPayload = getTokenPayload(token)
        setState({
          isLoggedIn: true,
          isReady: true,
          profile,
          loading: false,
          role: tokenPayload.role,
        })
      } catch (err) {
        setState(s => ({
          ...s,
          isReady: true,
          loading: false,
        }))
      }
    }
    initialise()
  }, [])

  const updateProfile = useCallback(async () => {
    const profile = await fetchProfile()
    setState(s => ({
      ...s,
      profile,
    }))
  }, [])

  const saveTokens = useCallback(async (tokens: Tokens) => {
    Auth.saveToken('token', tokens.accessToken)
    Auth.saveToken('refreshToken', tokens.refreshToken)

    const profile = await fetchProfile()
    const tokenPayload = getTokenPayload(tokens.accessToken)

    addSentryBreadcrumb('Login completed successfully')

    setState({
      isReady: true,
      isLoggedIn: true,
      loading: false,
      profile,
      role: tokenPayload.role,
    })
  }, [])

  const acceptInvitationAndLogin = useCallback(async (password, code, token) => {
    const { data, kind } = await Auth.invitation.accept({ password, code }, token)
    if (kind === 'error' || !data?.accessToken) {
      throw new Error('Failed to accept invitation')
    }

    Auth.saveToken('token', data.accessToken)

    const profile = await fetchProfile()
    const tokenPayload = getTokenPayload(data.accessToken)

    setState(s => ({ ...s, isReady: true, isLoggedIn: true, profile, role: tokenPayload.role }))
  }, [])

  const logout = useCallback(async () => {
    await Auth.logout()
    setState({ ...initialState, isReady: true, loading: false })
    addSentryBreadcrumb('Logout')
    navigate('/', { replace: true })
  }, [navigate])

  const value = useMemo(() => {
    return { acceptInvitationAndLogin, logout, saveTokens, updateProfile, ...state }
  }, [acceptInvitationAndLogin, logout, saveTokens, state, updateProfile])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth() must be wrapped inside AuthProvider.')
  }
  return context
}
