import jwtDecode from 'jwt-decode'

import { apiRequest, BackendError } from '@app/lib/request'
import type { InvitationTokenPayload, TokenPayload } from '@app/types'
import { Role } from '@app/types'
import { endSession } from '@app/util/sentry'

import { removePersistedData } from './storage'

const JWT_REFRESH_BEFORE_EXPIRATION_SECONDS = import.meta.env.VITE_JWT_REFRESH_BEFORE_EXPIRATION_SECONDS

export async function getToken() {
  const token = sessionStorage.getItem('token')

  if (shouldRefresh(token)) {
    const tokens = await refreshTokens()
    return tokens?.accessToken ?? null
  }

  return token
}

export function saveToken(key: string, token: string) {
  sessionStorage.setItem(key, token)
}

export const isMfaPending = (token?: string): boolean => {
  if (!token) return false
  const decodedJwt: Record<string, string> | undefined = jwtDecode(token)
  return decodedJwt?.mfa === 'PENDING'
}

export function removeToken() {
  sessionStorage.removeItem('token')
}

export function getTokenPayload(token: string): TokenPayload {
  return jwtDecode<TokenPayload>(token)
}

export function invitationTokenIsValid(token: string): boolean {
  const decodedJwt = jwtDecode<InvitationTokenPayload>(token)
  if (!decodedJwt.exp || decodedJwt.exp < Date.now() / 1000) {
    return false
  }
  return true
}

const CLIENT_ID = import.meta.env.VITE_CLIENT_ID

export const login = {
  callback(identifier: string, password: string) {
    return apiRequest<Tokens & { maskedPhone?: string }, MaxSessionsReachedResponse>(
      `/auth/login?clientId=${CLIENT_ID}`,
      {
        body: { identifier, password },
      }
    )
  },

  mfa(code: string, token: string) {
    return apiRequest<Tokens, MaxSessionsReachedResponse>('/auth/login/mfa', {
      body: { code },
      headers: { Authorization: `Bearer ${token}` },
    })
  },

  forceLogin(maxSessionsToken: string) {
    return apiRequest<Tokens, string | BackendError>('/auth/login/force', {
      method: 'post',
      body: {},
      headers: { Authorization: `Bearer ${maxSessionsToken}` },
    })
  },
}

export const invitation = {
  accept({ password, code }: { password: string; code: string }, token: string) {
    return apiRequest<{ accessToken: string }>('/auth/invitation/accept', {
      body: { password, code },
      headers: { Authorization: `Bearer ${token}` },
    })
  },
}

type ResendMfaCodeResponse = {
  accessToken: string
  maskedPhone: string
}

export const mfa = {
  resendViaSMS(token: string) {
    return apiRequest<ResendMfaCodeResponse>('/auth/mfa/resend-via-sms', {
      body: null,
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
    })
  },
}

function removeLocalData() {
  removeToken()
  removePersistedData()
  endSession()
}

export const logout = async () => {
  // Not using getToken as we probably don't need expiry check for logout
  // We need to hit logout even with the expired token
  const token = sessionStorage.getItem('token')

  if (!token) return

  await apiRequest(`/auth/logout`, { body: {}, getToken: async () => token })

  removeLocalData()
}

function shouldRefresh(token: string | null) {
  if (!token) return false

  const decodedJwt = getTokenPayload(token)

  return decodedJwt.exp - Date.now() / 1000 < JWT_REFRESH_BEFORE_EXPIRATION_SECONDS
}

async function renew() {
  const token = sessionStorage.getItem('refreshToken')

  if (!token) {
    console.log('No refresh token')
    return null
  }

  const { data, error } = await apiRequest<Tokens>(`/auth/refresh`, {
    headers: { Authorization: `Bearer ${token}` },
  })

  if (error) {
    console.log('Unable to renew session')
    return null
  }

  saveToken('token', data.accessToken)
  saveToken('refreshToken', data.refreshToken)

  return data
}

export type Tokens = { accessToken: string; refreshToken: string }

type MaxSessionsReachedResponse = { maxSessionsToken?: string }

let refreshPromise: Promise<Tokens | null> | null

export async function refreshTokens() {
  if (!refreshPromise) refreshPromise = renew()

  return refreshPromise.then(d => {
    refreshPromise = null
    if (!d) {
      removeLocalData()
      window.location.reload()
    }
    return d
  })
}

export const sendHeartbeat = (token: string) =>
  apiRequest('/heartbeat', {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
  })

export function isClinician(role: Role): boolean {
  return role === Role.CLINICIAN || role === Role.CLINICIAN_AND_CLINICAL_MANAGER
}

export function isClinicalManager(role: Role): boolean {
  return role === Role.CLINICAL_MANAGER || role === Role.CLINICIAN_AND_CLINICAL_MANAGER
}
