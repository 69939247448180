import { LoadingButton } from '@mui/lab'
import { Button, Grid, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { Card } from '@app/components/Card'
import { useConsultationState } from '@app/components/Consultation/state/use-consultation-state'
import { TalkToPatient } from '@app/components/TalkToPatient'
import { Consultation, ConsultationType } from '@app/types'

type PatientDetailsProps = {
  consultation: Consultation
  isSigning: boolean
  isUnassigning?: boolean
  onCancel?: VoidFunction
  onContinue?: VoidFunction
  onSignAndShare?: VoidFunction
  onUnassign?: VoidFunction
}

export const ConsultationActions: React.FC<PatientDetailsProps> = ({
  consultation,
  isSigning,
  isUnassigning,
  onCancel,
  onContinue,
  onSignAndShare,
  onUnassign,
}) => {
  const { t } = useTranslation()
  const { state } = useConsultationState()
  const consultationFormState = useSnapshot(state.formStatus)

  const shouldDisableUnassignButton = isUnassigning || isSigning
  const shouldDisableSignAndShareButton = !consultationFormState.isValid || shouldDisableUnassignButton

  return (
    <Grid item>
      <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1, height: '100%' }}>
        <Stack direction="column" spacing={1}>
          <TalkToPatient
            consultation={consultation}
            patient={{
              id: consultation.patientId,
              emrId: consultation.patient.emrId,
              legalName: consultation.patient.legalName,
              firstName: consultation.patient.firstName,
              lastName: consultation.patient.lastName,
              phoneNumber: consultation.patient.phone,
            }}
          />
          {onContinue && (
            <LoadingButton
              variant="contained"
              color="primary"
              size="medium"
              disableElevation
              loading={isSigning}
              onClick={onContinue}
              disabled={shouldDisableSignAndShareButton}
              data-test="continue-button"
            >
              {t('pages.emr.continue')}
            </LoadingButton>
          )}

          {onCancel && (
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              disableElevation
              onClick={onCancel}
              disabled={shouldDisableUnassignButton}
              data-test="cancel-button"
              sx={{ '&.Mui-disabled': { color: 'gray.600' } }}
            >
              {t('pages.emr.cancel')}
            </Button>
          )}

          {onSignAndShare && (
            <LoadingButton
              variant="contained"
              color="primary"
              size="medium"
              style={{ padding: '8px 16px', fontWeight: 700 }}
              disableElevation
              loading={isSigning}
              onClick={onSignAndShare}
              disabled={shouldDisableSignAndShareButton}
              data-test="consultation-complete-button"
            >
              {t('pages.emr.completeSession')}
            </LoadingButton>
          )}

          {onUnassign && (
            <LoadingButton
              variant={isUnassigning ? 'contained' : 'outlined'}
              onClick={onUnassign}
              size="medium"
              style={{ padding: '8px 16px', fontWeight: 700 }}
              loading={isUnassigning}
              disabled={shouldDisableUnassignButton}
              sx={shouldDisableUnassignButton ? { '&.Mui-disabled': { color: 'gray.600' } } : {}}
              data-test="consultation-unassign-button"
            >
              {consultation.type === ConsultationType.ON_DEMAND
                ? t('pages.emr.unassign')
                : t('pages.emr.exitWithoutSave')}
            </LoadingButton>
          )}
        </Stack>
      </Card>
    </Grid>
  )
}
