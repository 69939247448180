import { format, parseISO } from 'date-fns'
import { cloneDeep } from 'lodash-es'

import { OPEN_EMR_DATE_FORMAT } from '@app/i18n/config'
import { getToken } from '@app/lib/auth'
import {
  Action,
  ActionType,
  CallRating,
  Consultation,
  ConsultationStatus,
  ConsultationType,
  DateSelectionType,
  JournalData,
} from '@app/types'

import { apiRequest } from './request'

export const isFinalizedStatus = (status: ConsultationStatus) => {
  return [
    ConsultationStatus.CANCELLING,
    ConsultationStatus.CANCELLED,
    ConsultationStatus.CLOSED,
    ConsultationStatus.CLOSED_WITH_ERROR,
    ConsultationStatus.CLOSING,
  ].includes(status)
}

const formatDate = (date: Date, approximateYear: boolean) =>
  `${format(date, OPEN_EMR_DATE_FORMAT)} ${approximateYear ? '12:00:00' : '00:00:00'}`

const formatDatesObject = (dates: DateSelectionType['dates'], approximateYear: boolean): DateSelectionType['dates'] => {
  return {
    from: dates.from ? formatDate(new Date(dates.from), approximateYear) : null,
    to: dates.to ? formatDate(new Date(dates.to), approximateYear) : null,
  }
}

export const formatJournalDates = (journal: JournalData) => {
  const journalCopy = cloneDeep(journal)

  const pathologicalPersonalHistory = journalCopy.medicalHistory?.pathologicalPersonalHistory

  if (journalCopy.medicalHistory?.allergies && journalCopy.medicalHistory.allergies.drugAllergies) {
    journalCopy.medicalHistory.allergies.drugAllergies = journalCopy.medicalHistory.allergies.drugAllergies.map(d => ({
      ...d,
      dates: formatDatesObject(d.dates, d.approximateYear),
    }))
  }

  if (pathologicalPersonalHistory) {
    const keys = Object.keys(pathologicalPersonalHistory) as Array<keyof typeof pathologicalPersonalHistory>
    keys.forEach(key => {
      pathologicalPersonalHistory[key] = pathologicalPersonalHistory[key]?.map(item => ({
        ...item,
        dates: formatDatesObject(item.dates, item.approximateYear),
      })) as DateSelectionType[]
    })
  }

  return journalCopy
}

export const save = (consultationId: number, body: JournalData) => {
  return apiRequest<{ status: boolean }>(`/consultations/${consultationId}/save`, {
    method: 'POST',
    getToken,
    body,
  })
}

export const close = (consultationId: number, body: JournalData) => {
  return apiRequest<{ status: boolean; consultationId: number }>(`/consultations/${consultationId}/close`, {
    method: 'PUT',
    getToken,
    body: formatJournalDates(body),
  })
}

export const createAndClose = (patientEmrId: string, body: JournalData) => {
  return apiRequest<{ status: boolean; consultationId: number }>(`/patients/${patientEmrId}/consultations`, {
    method: 'POST',
    getToken,
    body: formatJournalDates(body),
  })
}

export const assign = (consultationId: number) => {
  return apiRequest<object, { message: string; code?: string }>(`/consultations/${consultationId}/assign`, {
    method: 'POST',
    getToken,
  })
}

export const dispatchConsultationAction = (consultationId: number, body: { type: ActionType }) => {
  return apiRequest<object, { message: string; code?: string }>(`/consultations/${consultationId}/actions`, {
    method: 'POST',
    getToken,
    body,
  })
}

export const saveCallRating = (consultationId: number, body: CallRating) => {
  return apiRequest<object, { message: string; code?: string }>(`/consultations/${consultationId}/rate`, {
    method: 'PUT',
    getToken,
    body,
  })
}

export const unassign = (consultationId: number) => {
  return apiRequest<{ status: boolean }>(`/consultations/${consultationId}/unassign`, {
    method: 'POST',
    body: {},
    getToken,
  })
}

export const isUnassignedAction = (action: Action) => action.type === ActionType.UNASSIGNED

export const isUnassigned = (consultation: Consultation) =>
  consultation.status === ConsultationStatus.QUEUED && consultation.actions.some(isUnassignedAction)

export const getLatestUnassignedActionDate = (actions: Action[] = []) =>
  actions.length ? new Date(Math.max(...actions.map(a => parseISO(a.createdAt).getTime()))) : null

export const getConsultationDate = (consultation: Consultation): string => {
  return consultation.type === ConsultationType.SCHEDULED ? consultation.startTime! : consultation.createdAt
}

export const getClosedAction = (consultation: Consultation) => {
  return consultation.actions.find(
    action => action.type === ActionType.CLOSED || action.type === ActionType.CLOSED_WITH_ERROR
  )
}

export const isClosedOrCancelled = (consultation: Consultation) =>
  consultation.status === ConsultationStatus.CLOSED ||
  consultation.status === ConsultationStatus.CLOSED_WITH_ERROR ||
  consultation.status === ConsultationStatus.CANCELLED ||
  consultation.status === ConsultationStatus.CLOSING ||
  consultation.status === ConsultationStatus.CANCELLING
