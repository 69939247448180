import { Grid } from '@mui/material'
import React from 'react'

import { PersonalInformationDetails } from '@app/components/Consultation/common/PatientDetails/PersonalInformationDetails'
import { Patient, PatientAdditionalInformation } from '@app/types'

import { AdditionalInformationDetails } from './AdditionalInformationDetails'

type PatientDetailsProps = {
  additionalInformation: PatientAdditionalInformation
  patient: Patient
}

export const PatientDetailsReadOnly: React.FC<PatientDetailsProps> = ({ additionalInformation, patient }) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <PersonalInformationDetails patient={patient} />
      <AdditionalInformationDetails patient={patient} defaultValues={additionalInformation} readonly={true} />
    </Grid>
  )
}
