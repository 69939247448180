import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogContent, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ErrorImage from '@app/assets/images/error.png'

type CancelledConsultationModalProps = {
  isOpen: boolean
  handleClose: () => void
  title?: string
  description?: string
  button?: string
}

export const ErrorModal: React.FC<CancelledConsultationModalProps> = ({
  isOpen,
  handleClose,
  title,
  description,
  button,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog aria-label={t('pages.emr.consultationDetail')} maxWidth="xs" open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Stack spacing={2} textAlign="center" alignItems="center">
          <Box width={120} height={120}>
            <img src={ErrorImage} alt="cancel-consultation" />
          </Box>
          <Stack spacing={1}>
            <Typography fontWeight="700" fontSize={24}>
              {title || t('common.errorModal.title')}
            </Typography>
            <Typography fontSize={14} fontWeight="400" color="grey.700">
              {description || t('common.errorModal.description')}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1} width="100%">
            <LoadingButton variant="contained" fullWidth onClick={handleClose}>
              <Typography fontWeight="700">{button || t('common.errorModal.button')}</Typography>
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
