import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Divider, Stack, SvgIcon, Typography } from '@mui/material'
import { CaretRight } from 'phosphor-react'
import React, { useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'
import * as yup from 'yup'

import { ReactComponent as Virus } from '@app/assets/healthicons/virus.svg'
import { useConsultationMedicalHistoryPathologicalState } from '@app/components/Consultation/state/use-consultation-medical-history-pathological-state'
import { DescriptionList } from '@app/components/EMR/common/DescriptionList'
import { MedicalHistoryEditableSelectionWithDates } from '@app/components/EMR/common/MedicalHistoryEditableSelectionWithDates'
import { joinPrescriptionsWithConsultations } from '@app/components/EMR/utils/prescriptions'
import { useConfig } from '@app/context/config'
import { useConsultations } from '@app/hooks/use-consultations'
import { useDiagnosisList } from '@app/hooks/use-diagnosis-list'
import { useMedicationsList } from '@app/hooks/use-medications-list'
import { useProcedureList } from '@app/hooks/use-procedure-list'
import { ConsultationSortBy, ConsultationStatus, PathologicalPersonalHistory, Patient, Prescriptions } from '@app/types'
import { parseOpenEMRDate } from '@app/util/date'

import { WORD_MIN_SIZE, SMALL_MAX_SIZE } from '../const'

import { useMedicalHistorySelectSchema } from './use-medical-history-select-schema'

export function useMedicalHistoryPathologicalSchema() {
  const { t } = useTranslation()

  return useMemo(
    () =>
      yup.object({
        pathologicalPersonalHistory: yup.object({
          previousDiseases: yup.array(
            yup.object({
              label: yup
                .string()
                .trim()
                .transform((curr, orig) => (orig === '' ? undefined : curr))
                .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
                .max(SMALL_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: SMALL_MAX_SIZE })),
            })
          ),
          previousMedication: yup.array(
            yup.object({
              label: yup
                .string()
                .trim()
                .transform((curr, orig) => (orig === '' ? undefined : curr))
                .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
                .max(SMALL_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: SMALL_MAX_SIZE })),
            })
          ),
          previousSurgeries: yup.array(
            yup.object({
              label: yup
                .string()
                .trim()
                .transform((curr, orig) => (orig === '' ? undefined : curr))
                .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
                .max(SMALL_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: SMALL_MAX_SIZE })),
            })
          ),
          currentDiseases: yup.array(
            yup.object({
              label: yup
                .string()
                .trim()
                .transform((curr, orig) => (orig === '' ? undefined : curr))
                .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
                .max(SMALL_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: SMALL_MAX_SIZE })),
            })
          ),
          currentMedication: yup.array(
            yup.object({
              label: yup
                .string()
                .trim()
                .transform((curr, orig) => (orig === '' ? undefined : curr))
                .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
                .max(SMALL_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: SMALL_MAX_SIZE })),
            })
          ),
          currentSurgeries: yup.array(
            yup.object({
              label: yup
                .string()
                .trim()
                .transform((curr, orig) => (orig === '' ? undefined : curr))
                .min(WORD_MIN_SIZE, t('pages.emr.errors.text.minWordLength'))
                .max(SMALL_MAX_SIZE, t('pages.emr.errors.text.maxLength', { maxLength: SMALL_MAX_SIZE })),
            })
          ),
        }),
      }),
    [t]
  )
}

type MedicalHistoryPathologicalProps = {
  patient: Patient
  defaultValues?: PathologicalPersonalHistory
  prescriptions?: Prescriptions | null
  pathologicalHistory?: PathologicalPersonalHistory
}

export const MedicalHistoryPathological: React.FC<MedicalHistoryPathologicalProps> = ({
  patient,
  prescriptions,
  defaultValues,
}) => {
  const { t } = useTranslation()
  const { config } = useConfig()
  const [diagnosisSearch, setDiagnosisSearch] = useState('')
  const [medicationSearch, setMedicationSearch] = useState('')
  const [proceduresSearch, setProceduresSearch] = useState('')

  const diagnosis = useDiagnosisList(diagnosisSearch)
  const medications = useMedicationsList(medicationSearch)
  const procedures = useProcedureList(proceduresSearch)
  const medicalHistorySelectSchema = useMedicalHistorySelectSchema()

  const { state, setFormIsValid, setPathologicalState } = useConsultationMedicalHistoryPathologicalState()
  const pathologicalPersonalHistorySnapshot = useSnapshot(state)

  const pathologicalSchema = useMedicalHistoryPathologicalSchema()
  const methods = useForm<PathologicalPersonalHistory>({
    resolver: yupResolver(pathologicalSchema),
    defaultValues,
    mode: 'onTouched',
  })

  const formValues = useWatch({
    control: methods.control,
  })

  const previousDiseasesListController = useFieldArray({
    control: methods.control,
    name: 'previousDiseases',
  })

  const previousMedicationListController = useFieldArray({
    control: methods.control,
    name: 'previousMedication',
  })

  const previousSurgeriesListController = useFieldArray({
    control: methods.control,
    name: 'previousSurgeries',
  })

  const currentDiseasesListController = useFieldArray({
    control: methods.control,
    name: 'currentDiseases',
  })

  const currentMedicationListController = useFieldArray({
    control: methods.control,
    name: 'currentMedication',
  })

  const currentSurgeriesListController = useFieldArray({
    control: methods.control,
    name: 'currentSurgeries',
  })

  const consultations = useConsultations({
    patientId: patient.id,
    status: [ConsultationStatus.CLOSED, ConsultationStatus.CLOSED_WITH_ERROR],
    sortBy: ConsultationSortBy.CREATED_AT_DESC,
  })

  const joinedPrescriptions = useMemo(() => {
    return joinPrescriptionsWithConsultations(prescriptions ?? [], consultations.data?.consultations || [])
  }, [consultations.data?.consultations, prescriptions])

  const patientDateOfBirth = useMemo(() => parseOpenEMRDate(patient.dateOfBirth), [patient.dateOfBirth])
  const patientDateOfBirthAndCurrentDateValidation = useMemo(
    () => ({
      startDate: {
        min: patientDateOfBirth,
        max: new Date(),
      },
      endDate: {
        min: patientDateOfBirth,
        max: new Date(),
      },
    }),
    [patientDateOfBirth]
  )

  useEffect(() => {
    setPathologicalState(defaultValues as PathologicalPersonalHistory)
    methods.trigger()
  }, [defaultValues])

  useEffect(() => {
    setFormIsValid(methods.formState.isValid)
  }, [methods.formState.isValid, setFormIsValid])

  useEffect(() => {
    if (methods.formState.isValidating || !methods.formState.isDirty || !methods.formState.isValid) return

    setPathologicalState(formValues as PathologicalPersonalHistory)
  }, [formValues, methods.formState.isDirty, methods.formState.isValid, methods.formState.isValidating])

  return (
    <>
      <DescriptionList.Divider
        label={t('pages.emr.medicalHistory.pathologicalPersonalHistory.title')}
        icon={() => <SvgIcon component={Virus} inheritViewBox />}
      />

      <MedicalHistoryEditableSelectionWithDates
        list={
          pathologicalPersonalHistorySnapshot.pathologicalPersonalHistory
            ?.previousDiseases as PathologicalPersonalHistory['previousDiseases']
        }
        removeItem={previousDiseasesListController.remove}
        prependItem={previousDiseasesListController.prepend}
        updateItem={previousDiseasesListController.update}
        key={'previousDiseases'}
        name={`previousDiseases`}
        control={methods.control}
        options={diagnosis}
        inputSchema={medicalHistorySelectSchema}
        onSearch={setDiagnosisSearch}
        dateValidation={patientDateOfBirthAndCurrentDateValidation}
      >
        {t('pages.emr.medicalHistory.pathologicalPersonalHistory.previousDiseases')}
      </MedicalHistoryEditableSelectionWithDates>

      <MedicalHistoryEditableSelectionWithDates
        list={
          pathologicalPersonalHistorySnapshot.pathologicalPersonalHistory
            ?.previousMedication as PathologicalPersonalHistory['previousMedication']
        }
        removeItem={previousMedicationListController.remove}
        prependItem={previousMedicationListController.append}
        updateItem={previousMedicationListController.update}
        key={'previousMedication'}
        name={`previousMedication`}
        control={methods.control}
        options={medications}
        inputSchema={medicalHistorySelectSchema}
        onSearch={setMedicationSearch}
        dateValidation={patientDateOfBirthAndCurrentDateValidation}
      >
        {t('pages.emr.medicalHistory.pathologicalPersonalHistory.previousMedication')}
      </MedicalHistoryEditableSelectionWithDates>

      <MedicalHistoryEditableSelectionWithDates
        list={
          pathologicalPersonalHistorySnapshot.pathologicalPersonalHistory
            ?.previousSurgeries as PathologicalPersonalHistory['previousSurgeries']
        }
        removeItem={previousSurgeriesListController.remove}
        prependItem={previousSurgeriesListController.append}
        updateItem={previousSurgeriesListController.update}
        key={'previousSurgeries'}
        name={`previousSurgeries`}
        control={methods.control}
        options={procedures}
        inputSchema={medicalHistorySelectSchema}
        onSearch={setProceduresSearch}
        dateValidation={patientDateOfBirthAndCurrentDateValidation}
      >
        {t('pages.emr.medicalHistory.pathologicalPersonalHistory.previousSurgeries')}
      </MedicalHistoryEditableSelectionWithDates>

      <MedicalHistoryEditableSelectionWithDates
        list={
          pathologicalPersonalHistorySnapshot.pathologicalPersonalHistory
            ?.currentDiseases as PathologicalPersonalHistory['currentDiseases']
        }
        removeItem={currentDiseasesListController.remove}
        prependItem={currentDiseasesListController.append}
        updateItem={currentDiseasesListController.update}
        key={'currentDiseases'}
        name={`currentDiseases`}
        control={methods.control}
        options={diagnosis}
        inputSchema={medicalHistorySelectSchema}
        onSearch={setDiagnosisSearch}
        dateValidation={patientDateOfBirthAndCurrentDateValidation}
      >
        {t('pages.emr.medicalHistory.pathologicalPersonalHistory.currentDiseases')}
      </MedicalHistoryEditableSelectionWithDates>

      <MedicalHistoryEditableSelectionWithDates
        list={
          pathologicalPersonalHistorySnapshot.pathologicalPersonalHistory
            ?.currentMedication as PathologicalPersonalHistory['currentMedication']
        }
        removeItem={currentMedicationListController.remove}
        prependItem={currentMedicationListController.append}
        updateItem={currentMedicationListController.update}
        key={'currentMedication'}
        name={`currentMedication`}
        control={methods.control}
        options={medications}
        inputSchema={medicalHistorySelectSchema}
        onSearch={setMedicationSearch}
        dateValidation={patientDateOfBirthAndCurrentDateValidation}
      >
        {t('pages.emr.medicalHistory.pathologicalPersonalHistory.currentMedication')}
      </MedicalHistoryEditableSelectionWithDates>

      <MedicalHistoryEditableSelectionWithDates
        list={
          pathologicalPersonalHistorySnapshot.pathologicalPersonalHistory
            ?.currentSurgeries as PathologicalPersonalHistory['currentSurgeries']
        }
        removeItem={currentSurgeriesListController.remove}
        prependItem={currentSurgeriesListController.append}
        updateItem={currentSurgeriesListController.update}
        key={'currentSurgeries'}
        name={`currentSurgeries`}
        control={methods.control}
        options={procedures}
        inputSchema={medicalHistorySelectSchema}
        onSearch={setProceduresSearch}
        dateValidation={patientDateOfBirthAndCurrentDateValidation}
      >
        {t('pages.emr.medicalHistory.pathologicalPersonalHistory.currentSurgeries')}
      </MedicalHistoryEditableSelectionWithDates>

      <Typography fontWeight="bold" p={1}>
        {t('pages.emr.medicalHistory.pathologicalPersonalHistory.prescriptionsMadeByPartner', {
          partnerName: config.partnerName,
        })}
      </Typography>
      {joinedPrescriptions?.length ? (
        <Box sx={{ backgroundColor: 'gray.50' }} px={1} py={3} borderRadius={1} mt={1}>
          {joinedPrescriptions.map((prescription, index) => (
            <Stack
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              role="button"
              tabIndex={0}
            >
              <Box>
                <Box>
                  <Typography fontWeight="bold">{prescription.medication.label}</Typography>
                  <Typography variant="body2">{prescription.instructions}</Typography>
                  {prescription.createdAt && prescription.clinician ? (
                    <Typography variant={'body2'} color={'blue.300'} mt={1}>
                      {t('pages.emr.journal.prescriptionModal.prescribedBy', {
                        prescribedBy: `${prescription.clinician.firstName} ${prescription.clinician.lastName}`,
                        prescribedAt: prescription.createdAt,
                      })}
                    </Typography>
                  ) : null}
                </Box>
                {index !== joinedPrescriptions.length - 1 && (
                  <Box mt={1.5} mb={1.5}>
                    <Divider variant="light" />
                  </Box>
                )}
              </Box>
              <SvgIcon component={CaretRight} size={24} inheritViewBox />
            </Stack>
          ))}
        </Box>
      ) : null}
    </>
  )
}
