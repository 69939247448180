import qs from 'qs'
import useSWR from 'swr'

import fetcher from '@app/lib/fetcher'
import { Consultation, ConsultationStatus, ConsultationType } from '@app/types'

interface Pagination {
  total: number
  page: number
  pageCount: number
  pageSize: number
}

interface UseNewQueuedConsultationsOptions {
  status: ConsultationStatus[]
  categories?: number[]
  updatedFrom?: string
  skip?: boolean
}

interface UseNewQueuedConsultationsResponse {
  total: number
  isLoading: boolean
  hasError: boolean
}

export function useNewQueueConsultationsTotal(
  options: UseNewQueuedConsultationsOptions
): UseNewQueuedConsultationsResponse {
  const queryParams = qs.stringify(
    {
      status: options.status,
      pageSize: 1, // set `pageSize` to 1 to check if there is a single new consultation in the queue
      ...(options.updatedFrom ? { updatedFrom: options.updatedFrom } : {}),
      categories: options.categories,
      type: ConsultationType.ON_DEMAND,
    },
    { indices: false }
  )

  const { data, error, isValidating } = useSWR<{
    consultations: Consultation[] | null
    pagination: Pagination
    fetchedAt?: string
  }>(!options.skip && options.updatedFrom ? `/consultations?${queryParams}` : null, {
    fetcher: fetcher.api,
    refreshInterval: 1000,
    revalidateOnMount: true,
  })

  return {
    total: data?.pagination.total ?? 0,
    isLoading: (!error && !data) || isValidating,
    hasError: !!error,
  }
}
