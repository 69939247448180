import { intervalToDuration } from 'date-fns'

export function getDuration(startTime: number, endTime: number, now: number) {
  const start = Math.min(now, endTime)
  return intervalToDuration({ start, end: endTime })
}

export function getProgress(startTime: number, endTime: number, now: number) {
  const range = endTime - startTime
  const position = Math.min(now - startTime, range)
  const progress = (position / range) * 100
  return Math.round(progress)
}
