import { Box, Stack, Typography } from '@mui/material'
import { StackTypeMap } from '@mui/material/Stack/Stack'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { JournalData } from '@app/types'
import { calculateAge } from '@app/util/calculate-age'
import { parseOpenEMRDate } from '@app/util/date'

type PatientDescriptionItemProps = {
  label: string
} & StackTypeMap['props']

const PatientDescriptionItem: React.FC<PatientDescriptionItemProps> = ({ label, children, ...rest }) => {
  return (
    <Stack direction="row" spacing={0} {...rest}>
      <Typography
        component="dt"
        variant="body2"
        sx={{ color: 'gray.900', minWidth: '3rem', flexShrink: 0 }}
        data-test={`${label}-label`}
      >
        {label}
      </Typography>
      <Typography
        component="dd"
        variant="body2"
        fontWeight="bold"
        sx={{ color: 'gray.800', ml: 1 }}
        data-test={`${label}-value`}
      >
        {children}
      </Typography>
    </Stack>
  )
}

type PatientData = {
  cardoId: string
  sex: string | undefined
  dateOfBirth: string
  genderInfo: string | null
  cpfCode?: string
}

type PatientDetailsProps = {
  patient: PatientData
  additionalInformation?: JournalData['additionalInformation']
}

export const PatientDetails: React.FC<PatientDetailsProps> = ({ patient, additionalInformation }) => {
  const { t } = useTranslation()

  return (
    <>
      <PatientDescriptionItem marginTop={2} label={t('common.patient.id')}>
        {patient.cardoId}
      </PatientDescriptionItem>
      <Box
        component="dl"
        sx={{
          color: 'gray.800',
          display: 'grid',
          gridTemplateColumns: '1fr 2fr',
          gridAutoFlow: 'row',
          columnGap: '3rem',
          rowGap: '0.5rem',
        }}
      >
        <PatientDescriptionItem label={t('common.patient.sexLabel')}>{patient.sex}</PatientDescriptionItem>

        {additionalInformation?.address ? (
          <PatientDescriptionItem sx={{ gridRow: 'span 2 / span 2' }} label={t('common.patient.address')}>
            {additionalInformation?.address}
          </PatientDescriptionItem>
        ) : null}

        {additionalInformation?.curp ? (
          <PatientDescriptionItem label={t('common.patient.curp')}>
            {additionalInformation?.curp}
          </PatientDescriptionItem>
        ) : null}

        {additionalInformation?.weight ? (
          <PatientDescriptionItem label={t('common.patient.weight')}>
            {additionalInformation?.weight} {t('common.kg')}
          </PatientDescriptionItem>
        ) : null}

        {additionalInformation?.height ? (
          <PatientDescriptionItem label={t('common.patient.height')}>
            {additionalInformation?.height} {t('common.cm')}
          </PatientDescriptionItem>
        ) : null}

        <PatientDescriptionItem label={t('common.patient.age')}>
          {calculateAge(patient.dateOfBirth)}
        </PatientDescriptionItem>

        <PatientDescriptionItem label={t('common.patient.dob')}>
          {t('dates.long', { date: parseOpenEMRDate(patient.dateOfBirth) })}
        </PatientDescriptionItem>

        {patient.genderInfo ? (
          <PatientDescriptionItem sx={{ gridColumn: 'span 2' }} label={t('common.patient.genderInfo')}>
            {patient.genderInfo}
          </PatientDescriptionItem>
        ) : null}
      </Box>
    </>
  )
}
