import { useEffect, useState } from 'react'

import { apiFetcher } from '@app/lib/fetcher/api'

export function useERecetaPrescription(
  patientId: number,
  consultationId: number | null
): {
  iframeUrl: string | undefined
  error: unknown
} {
  const [iframeUrl, setIframeUrl] = useState(undefined)

  useEffect(() => {
    if (!patientId) return

    const fetchData = async () => {
      const { iframeUrl } = await apiFetcher(`/patients/${patientId}/prescription/${consultationId}/get-iframe-url`)
      setIframeUrl(iframeUrl)
    }

    fetchData()
  }, [patientId, consultationId])

  return {
    iframeUrl,
    error: undefined,
  }
}
