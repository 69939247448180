import { Stack, SvgIcon, Typography } from '@mui/material'
import { format, formatDuration } from 'date-fns'
import { CalendarCheck } from 'phosphor-react'
import React from 'react'

import { Circle } from '@app/components/Circle'

type Props = {
  /**
   * Scheduled consultation start time, for example '2023-12-28T15:00:00.000Z'.
   */
  startTime: string
  /**
   * Scheduled consultation end time shown to the patient (ie excluding the buffer),
   * for example '2023-12-28T15:45:00.000Z'.
   */
  endTime: string
}

export function ScheduledConsultationDateAndDuration({ startTime, endTime }: Props) {
  return (
    <Stack direction="row" alignItems="center">
      <Circle size={32} backgroundColor="blue.200" mr={1}>
        <SvgIcon component={CalendarCheck} sx={{ color: 'blue.500', fontSize: 20 }} inheritViewBox aria-hidden="true" />
      </Circle>
      <Typography color={'gray.900'} fontSize={14} fontWeight="bold" mr={0.3}>
        {formatScheduledConsultationDate(startTime, endTime) + ' |'}
      </Typography>
      <Typography color={'gray.900'} fontSize={14}>
        {formatScheduledConsultationDuration(startTime, endTime)}
      </Typography>
    </Stack>
  )
}

/**
 * Format as "Thursday 28th December 2023 - 16:00 - 16:45".
 */
function formatScheduledConsultationDate(startTime: string, endTime: string): string {
  return format(new Date(startTime), 'EEEE do MMMM Y - HH:mm') + ' - ' + format(new Date(endTime), 'HH:mm')
}

/**
 * Format as "45 minutes" or "1 hour 45 minutes".
 */
function formatScheduledConsultationDuration(startTime: string, endTime: string): string {
  const start = new Date(startTime)
  const end = new Date(endTime)
  const totalMinutes = (end.getTime() - start.getTime()) / 1000 / 60
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return formatDuration({ hours, minutes })
}
