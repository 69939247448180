import { RefObject, useEffect, useMemo, useState } from 'react'

const hasArrivedBottom = (element: HTMLElement | null, offset = 0) => {
  if (!element) return false

  const { scrollTop, scrollHeight, clientHeight } = element
  return scrollTop + clientHeight + offset >= scrollHeight
}

export const useScroll = (ref: RefObject<HTMLElement>, offset = 10) => {
  const [scrollTop, setScrollTop] = useState(0)

  const isBottomOffset = useMemo(() => hasArrivedBottom(ref.current, offset), [ref, offset, scrollTop])

  const isBottom = useMemo(() => hasArrivedBottom(ref.current, offset / 2), [ref, offset, scrollTop])

  const scrollDown = (scrollOffset: number) => {
    if (!ref.current) return
    ref.current.scrollTo({ top: ref.current.scrollTop + scrollOffset, behavior: 'smooth' })
  }

  const scrollUp = (scrollOffset = 0) => {
    if (!ref.current) return
    ref.current.scrollTo({ top: scrollOffset, behavior: 'smooth' })
  }

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const handleScroll = () => setScrollTop(element.scrollTop)
    element.addEventListener('scroll', handleScroll)

    return () => {
      element.removeEventListener('scroll', handleScroll)
    }
  }, [ref])

  return { isBottomOffset, isBottom, scrollDown, scrollUp }
}
