import * as React from 'react'
import ReactDOM from 'react-dom'

import { initSentry } from '@app/util/sentry'

import App from './App'

import './i18n/config'

initSentry()

function init() {
  ReactDOM.render(<App />, document.getElementById('app'))
}

init()
