import { format, formatDistance, isAfter } from 'date-fns'
import i18n from 'i18next'
import { Activity, CalendarX, Check, ClipboardText, Clock, WarningCircle } from 'phosphor-react'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Pill } from '@app/components/Pill/Pill'
import { useConsultations } from '@app/hooks/use-consultations'
import { dateLocales } from '@app/i18n/config'
import { isFinalizedStatus } from '@app/lib/consultation'
import { CustomTable, CustomTableCell, CustomTableRow } from '@app/pages/Monitoring/CustomTable'
import { TableBodySkeleton } from '@app/pages/Monitoring/CustomTable/TableBodySkeleton'
import { HeadCell } from '@app/pages/Monitoring/CustomTable/types'
import { SortingOrder } from '@app/pages/Monitoring/CustomTable/utils'
import {
  ageHeader,
  assignedToHeader,
  bookingTimeHeader,
  categoryHeader,
  patientHeader,
  productHeader,
  sexHeader,
  statusHeader,
} from '@app/pages/Monitoring/tableRows'
import { fieldContains, getLatestAction } from '@app/pages/Monitoring/utils'
import { colors } from '@app/theme/color'
import {
  Action,
  ActionType,
  Consultation,
  ConsultationSortBy,
  ConsultationStatus,
  ConsultationType,
  Role,
  Sex,
} from '@app/types'
import { calculateAge } from '@app/util/calculate-age'

export interface PatientRowData {
  consultationId: number
  name: string
  age: number | null
  sex: Sex | null
  status: React.JSX.Element | null
  consultationStatus: ConsultationStatus
  category: string
  product: string
  assignedTo: string
  emrId: string
  bookingTime: string
}

const patientHeadCells: readonly HeadCell[] = [
  patientHeader,
  ageHeader,
  sexHeader,
  categoryHeader,
  productHeader,
  assignedToHeader,
  bookingTimeHeader,
  statusHeader,
]

const consultationHasStarted = (consultation: Consultation) => {
  return isAfter(Date.now(), new Date(consultation.startTime!))
}

function getStatus(consultation: Consultation) {
  const latestAction = getLatestAction<Action>(consultation.actions)

  if (!latestAction) {
    return null
  }

  if (latestAction.type === ActionType.CLOSED) {
    return (
      <Pill
        text={i18n.t('pages.monitoring.status.completed')}
        backgroundColor="green.100"
        icon={<Check size={16} color={colors.green[500]} />}
      />
    )
  }

  if (latestAction.type === ActionType.CLOSED_WITH_ERROR) {
    return (
      <Pill
        text={i18n.t('pages.monitoring.status.error')}
        backgroundColor="red.100"
        icon={<WarningCircle size={16} color={colors.red[500]} />}
      />
    )
  }

  if (latestAction.type === ActionType.CANCELLED) {
    if (latestAction.user.role === Role.PATIENT) {
      return (
        <Pill
          text={i18n.t('pages.monitoring.status.cancelledByPatient')}
          backgroundColor="red.100"
          icon={<CalendarX size={16} color={colors.red[500]} />}
        />
      )
    }

    return (
      <Pill
        text={i18n.t('pages.monitoring.status.cancelledByClinician')}
        backgroundColor="red.100"
        icon={<CalendarX size={16} color={colors.red[500]} />}
      />
    )
  }

  if (consultation.patient.lockedById && consultationHasStarted(consultation)) {
    return (
      <Pill
        text={i18n.t('pages.monitoring.status.ongoing')}
        backgroundColor="green.100"
        icon={<Activity size={16} color={colors.green[500]} />}
      />
    )
  }

  if (latestAction.type === ActionType.QUEUED && consultationHasStarted(consultation)) {
    const time = formatDistance(Date.now(), new Date(consultation.startTime!), {
      locale: dateLocales[i18n.resolvedLanguage],
    })
    return (
      <Pill
        text={i18n.t('pages.monitoring.status.delayed', { time })}
        backgroundColor="yellow.100"
        icon={<Clock size={16} color={colors.yellow[500]} />}
      />
    )
  }

  return (
    <Pill
      text={i18n.t('pages.monitoring.status.scheduled')}
      backgroundColor="blue.100"
      icon={<ClipboardText size={16} color={colors.blue[500]} />}
    />
  )
}

function mapRow(consultation: Consultation): PatientRowData {
  const { clinician, patient, category, productName, startTime, endTime } = consultation
  return {
    consultationId: consultation.id,
    name: patient.firstName + ' ' + patient.lastName,
    age: patient.dateOfBirth ? calculateAge(patient.dateOfBirth) : null,
    sex: patient.sex,
    category: category.name,
    product: productName,
    assignedTo: [clinician?.firstName, clinician?.lastName].join(' ').trim() || clinician?.username || '-',
    emrId: patient?.emrId || '',
    bookingTime: `${format(new Date(startTime!), 'HH:mm')} - ${format(new Date(endTime!), 'HH:mm')}`,
    status: getStatus(consultation),
    consultationStatus: consultation.status,
  }
}

export const ConsultationMonitoringTable = ({ searchFilter, date }: { searchFilter?: string; date: Date }) => {
  const navigate = useNavigate()
  const formattedDate = format(date, 'yyyy-MM-dd')
  const { data, isLoading, hasError } = useConsultations({
    type: ConsultationType.SCHEDULED,
    date: formattedDate,
    sortBy: ConsultationSortBy.START_TIME_ASC,
    autoRefresh: true,
  })
  const [filteredRows, setFilteredRows] = useState<PatientRowData[]>([])

  const handleClick = useCallback(
    (row: PatientRowData) => {
      if (isFinalizedStatus(row.consultationStatus)) {
        return navigate(`/patients/${row.emrId}`, { state: { previousLocationPathname: '/monitoring' } })
      }
      navigate(`/consultation/${row.consultationId}`, { state: { previousLocationPathname: '/monitoring' } })
    },
    [navigate]
  )

  useEffect(() => {
    if (!data?.consultations) return

    const filtered = data.consultations
      .map(mapRow)
      .filter(
        (row: PatientRowData) =>
          !searchFilter ||
          fieldContains(row.name, searchFilter) ||
          fieldContains(row.assignedTo, searchFilter) ||
          fieldContains(row.category, searchFilter) ||
          fieldContains(row.product, searchFilter)
      )

    setFilteredRows(filtered)
  }, [data?.consultations, searchFilter])

  return (
    <CustomTable
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rows={filteredRows as PatientRowData[]}
      noDataColSpan={9}
      isLoading={!data && isLoading}
      hasError={hasError}
      ariaLabel={'monitoringPatientsTable'}
      headCells={patientHeadCells}
      defaultOrder={SortingOrder.ASC}
      defaultOrderBy={'bookingTime'}
    >
      {data?.consultations
        ? (visibleRows: PatientRowData[]) => {
            return visibleRows.map((row, index) => {
              return (
                <CustomTableRow
                  hover
                  sx={{ cursor: 'pointer', zIndex: -100 }}
                  key={row.consultationId}
                  position={index}
                  onClick={() => {
                    handleClick(row)
                  }}
                  data-testid="patients-table-row"
                >
                  <CustomTableCell data-testid="patients-table-cell-id">
                    <Link
                      to={`/patients/${row.emrId}`}
                      state={{ previousLocationPathname: '/monitoring' }}
                      onClick={e => e.stopPropagation()}
                      style={{ color: colors.gray[900], textDecoration: 'underline', zIndex: 10 }}
                    >
                      {row.name}
                    </Link>
                  </CustomTableCell>
                  <CustomTableCell>{row.age ?? ''}</CustomTableCell>
                  <CustomTableCell>{row.sex ?? ''}</CustomTableCell>
                  <CustomTableCell>{row.category}</CustomTableCell>
                  <CustomTableCell>{row.product}</CustomTableCell>
                  <CustomTableCell data-testid="patients-table-cell-assigned">{row.assignedTo}</CustomTableCell>
                  <CustomTableCell data-testid="patients-table-cell-assigned">{row.bookingTime}</CustomTableCell>
                  <CustomTableCell data-testid="patients-table-cell-status">{row.status}</CustomTableCell>
                </CustomTableRow>
              )
            })
          }
        : () => (!hasError ? <TableBodySkeleton rows={4} cols={9} /> : null)}
    </CustomTable>
  )
}
