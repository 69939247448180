import { SvgIcon, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Lock } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const PageLockedAlert: React.FC = () => {
  const { t } = useTranslation()

  return (
    // This color just wasn't in the theme. We should revisit the colors in general.
    <Box
      data-testid="page-locked-alert"
      bgcolor="#e3e3e3"
      borderRadius={2}
      p={2}
      display="flex"
      alignItems="center"
      width="100%"
      mt={2}
    >
      <SvgIcon component={Lock} inheritViewBox size={24} sx={{ mr: 1 }} />
      <Typography sx={{ fontSize: 14 }}>
        <Typography fontWeight="bold" sx={{ fontSize: 14 }} component="strong">
          {t('pageLockedAlert.title')}
        </Typography>{' '}
        {t('pageLockedAlert.text')}
      </Typography>
    </Box>
  )
}
