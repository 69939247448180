import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { useNotifications } from '@app/context/notifications'
import { onConsultationClosed } from '@app/lib/events'
import fetcher from '@app/lib/fetcher'
import { Consultation, ConsultationStatus } from '@app/types'

// ----- Components

const NO_REFRESH = 0
const REFRESH_INTERVAL_MS = 2000

const isClosedWithError = (status: ConsultationStatus) => status === ConsultationStatus.CLOSED_WITH_ERROR
const isClosedOk = (status: ConsultationStatus) => status === ConsultationStatus.CLOSED
const isClosed = (status: ConsultationStatus) => isClosedOk(status) || isClosedWithError(status)

function getFullName(input: { legalName: string }) {
  return input.legalName
}

const usePollClosedConsultation = (id: Consultation['id']) => {
  const { data: consultation, error } = useSWR<Consultation>(
    /* I couldn't find a way to disable cache invalidation in the rest of the app
       so I'm using this which results in a different cache key */
    { url: `/consultations/${id}` },
    ({ url }) => fetcher.api(url),
    {
      refreshInterval: data => (!data ? REFRESH_INTERVAL_MS : isClosed(data.status) ? NO_REFRESH : REFRESH_INTERVAL_MS),
    }
  )

  return {
    consultation: consultation ?? null,
    isLoading: !error && !consultation,
    hasError: !!error,
  }
}

const Watcher = memo(({ consultationId }: { consultationId: Consultation['id'] }) => {
  const { addNotification } = useNotifications()
  const { t } = useTranslation()
  const { consultation } = usePollClosedConsultation(consultationId)

  useEffect(() => {
    if (consultation?.status && isClosedWithError(consultation.status)) {
      addNotification({
        message: t('pages.emr.signAndShareError', {
          patient: { name: getFullName(consultation.patient), id: consultation.patient.cardoId },
        }),
        autoHide: false,
        severity: 'error',
      })
    }
  }, [consultation?.status, consultation?.patient, consultation?.patientId, addNotification, t])

  useEffect(() => {
    if (consultation?.status && isClosed(consultation?.status)) {
      onConsultationClosed.emit(consultation.id)
    }
  }, [consultation?.status, consultation?.id])

  return null
})

Watcher.displayName = 'withMemo(Watcher)'

// ----- Context

type ContextType = {
  watchConsultation: (consultationId: Consultation['id']) => void
}

export const ConsultationProgressContext = React.createContext<ContextType | undefined>(undefined)

export const ConsultationProgressProvider: React.FC = ({ children }) => {
  const [consultationIds, setConsultationIds] = useState<Consultation['id'][]>([])

  const watchConsultation = useCallback<ContextType['watchConsultation']>(
    consultationId => setConsultationIds([...consultationIds, consultationId]),
    [consultationIds]
  )

  const value = useMemo(() => ({ watchConsultation }), [watchConsultation])

  return (
    <ConsultationProgressContext.Provider value={value}>
      {consultationIds.map(consultationId => (
        <Watcher key={consultationId} consultationId={consultationId} />
      ))}
      {children}
    </ConsultationProgressContext.Provider>
  )
}

export const useConsultationProgress = () => {
  const context = useContext(ConsultationProgressContext)

  if (!context) {
    throw new Error('useConsultationProgress() must be wrapped inside ConsultationProgressProvider.')
  }

  return context
}
