import {
  Device,
  Publisher,
  PublisherProperties,
  Session,
  Stream,
  Subscriber,
  SubscriberProperties,
} from '@opentok/client'
import partition from 'lodash/partition'
import { useCallback, useEffect, useState } from 'react'

import { OpenTokEvent, useOpenTokEvent } from './use-opentok-event'

const OPENTOK_API_KEY = String(import.meta.env.VITE_OPENTOK_API_KEY)
const OPENTOK_LOG_ENABLED = import.meta.env.VITE_OPENTOK_LOG_ENABLED === 'true'

function log(msg: string, ...args: unknown[]) {
  if (OPENTOK_LOG_ENABLED) {
    // eslint-disable-next-line no-console
    console.log(`[use-opentok] ${msg}`, ...args)
  }
}

const defaultOptions: PublisherProperties = {
  insertMode: 'append',
  width: '100%',
  height: '100%',
  publishAudio: true,
  publishVideo: true,
  style: {
    buttonDisplayMode: 'off',
    nameDisplayMode: 'off',
  },
}

// Note: This implementation assumes a 1:1 publisher:subscriber call
// We would need to rework it a bit to support 1:many calls

export function useOpenTok() {
  const [session, setSession] = useState<Session | null>(null)
  const [stream, setStream] = useState<Stream | null>(null)
  const [publisher, setPublisher] = useState<Publisher | null>(null)
  const [subscriber, setSubscriber] = useState<Subscriber | null>(null)
  const [isInitialised, setIsInitialised] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const [videoDevices, setVideoDevices] = useState<Device[]>([])
  const [audioDevices, setAudioDevices] = useState<Device[]>([])

  const connectSession = useCallback(async (sessionToConnect: Session, token: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!sessionToConnect) return reject('Session does not exist')

      sessionToConnect.connect(token, function (err) {
        if (err) {
          return reject(err)
        }
        resolve()
      })
    })
  }, [])

  const connect = useCallback(
    async ({ sessionId, token }: { sessionId: string; token: string }) => {
      log('initialising session', { sessionId })

      setIsConnecting(true)
      const newSession = OT.initSession(OPENTOK_API_KEY, sessionId)

      if (!newSession) {
        log('failed to connect to session', { sessionId })
        throw new Error('Failed to connect')
      }

      setSession(newSession)
      setIsInitialised(true)

      log('session established', { session: newSession })
      log('connecting to session', { session: newSession })

      await connectSession(newSession, token)
      setIsConnected(true)
      setIsConnecting(false)

      log('connected to session...', { session: newSession })
    },
    [connectSession]
  )

  const disconnect = useCallback(() => {
    log('disconnecting', { session })
    session?.disconnect()
    log('disconnected', { session })
    setIsConnected(false)
  }, [session])

  const initialisePublisher = useCallback((options: Partial<PublisherProperties> = {}) => {
    return new Promise<Publisher>((resolve, reject) => {
      const newPublisher = OT.initPublisher(
        'publisher',
        {
          ...defaultOptions,
          ...options,
        },
        err => {
          if (err) {
            log('error initialising publisher', err)
            return reject(err)
          }
        }
      )
      resolve(newPublisher)
    })
  }, [])

  const publish = useCallback(
    async (options: Partial<PublisherProperties> = {}) => {
      log('publish')

      if (!session) {
        throw new Error('Error subscribing to stream')
      }

      const newPublisher = await initialisePublisher(options)

      log('publisher created', newPublisher)

      return new Promise<void>((resolve, reject) => {
        session.publish(newPublisher, err => {
          if (err) {
            log('error subscribing to stream')
            return reject(err)
          }
          setPublisher(newPublisher)
          resolve()
        })
      })
    },
    [session, initialisePublisher]
  )

  const unpublish = useCallback(() => {
    log('unpublish', { publisher })
    if (publisher) {
      session?.unpublish(publisher)
      setPublisher(null)
    }
  }, [publisher, session])

  const subscribe = useCallback(
    (options: Partial<SubscriberProperties> = {}) => {
      log('subscribe', { stream })

      if (!stream || !session) {
        log('no stream or session to subscribe to', { stream, session })
        throw new Error('Error subscribing to stream')
      }

      const newSubscriber = session.subscribe(stream, 'subscriber', { ...defaultOptions, ...options })

      log('subscribed to stream', { stream, subscriber: newSubscriber })

      setSubscriber(newSubscriber)
    },
    [stream, session]
  )

  const unsubscribe = useCallback(() => {
    log('unsubscribe', { subscriber })

    if (subscriber) {
      session?.unsubscribe(subscriber)
      setSubscriber(null)
    }
  }, [subscriber, session])

  const refreshDevices = useCallback(() => {
    log('refresh devices')
    return new Promise<void>((resolve, reject) => {
      OT.getDevices((err, devices) => {
        if (err) {
          log('error refreshing devices', err)
          return reject(err)
        }

        const [videos, audios] = partition(devices, device => device.kind === 'videoInput')

        log('devices refreshed', { videos, audios })

        // TODO: set selected ones
        setVideoDevices(videos)
        setAudioDevices(audios)
        resolve()
      })
    })
  }, [])

  const toggleVideoEnabled = useCallback(() => {
    if (!publisher?.stream) return
    log('toggle video', !publisher.stream.hasVideo)
    publisher.publishVideo(!publisher.stream.hasVideo)
  }, [publisher])

  const toggleAudioEnabled = useCallback(() => {
    if (!publisher?.stream) return
    log('toggle audio', !publisher.stream.hasAudio)
    publisher.publishAudio(!publisher.stream.hasAudio)
  }, [publisher])

  const handleConnectionCreated = useCallback(event => {
    // TODO: keep track of these?
    log('connection created', { event })
  }, [])

  const handleConnectionDestroyed = useCallback(event => {
    // TODO: keep track of these?
    log('connection destroyed', { event })
  }, [])

  const handleStreamCreated = useCallback(event => {
    log('stream created', { event })
    setStream(event.stream)
  }, [])

  const handleStreamDestroyed = useCallback(event => {
    log('stream destroyed', { event })
    setStream(null)
  }, [])

  const handleStreamPropertyChanged = useCallback(
    event => {
      log('stream property changed', { event, streamId: event.stream?.streamId })

      if (event.stream?.streamId === publisher?.stream?.streamId) {
        log('publisher stream changed')
        setPublisher({ ...event.stream.publisher })
      }
      if (event.stream?.streamId === subscriber?.stream?.streamId) {
        log('subscriber stream changed')
        setStream({ ...event.stream })
      }
    },
    [publisher, subscriber]
  )

  useOpenTokEvent(OpenTokEvent.CONNECTION_CREATED, handleConnectionCreated, session)
  useOpenTokEvent(OpenTokEvent.CONNECTION_DESTROYED, handleConnectionDestroyed, session)
  useOpenTokEvent(OpenTokEvent.STREAM_CREATED, handleStreamCreated, session)
  useOpenTokEvent(OpenTokEvent.STREAM_DESTROYED, handleStreamDestroyed, session)
  useOpenTokEvent(OpenTokEvent.STREAM_PROPERTY_CHANGED, handleStreamPropertyChanged, session)

  useEffect(() => {
    if (publisher) {
      refreshDevices()
    }
  }, [refreshDevices, publisher])

  useEffect(() => {
    return disconnect
  }, [disconnect])

  return {
    // state
    isInitialised,
    isConnected,
    isConnecting,
    stream,
    publisher,
    session,
    subscriber,
    videoDevices,
    audioDevices,
    // actions
    connect,
    disconnect,
    publish,
    unpublish,
    subscribe,
    unsubscribe,
    refreshDevices,
    setIsConnecting,
    toggleAudioEnabled,
    toggleVideoEnabled,
  }
}
