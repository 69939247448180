export function elapsedTime(dateString: string): string {
  const startDate = new Date(dateString)
  const currentDate = new Date(Date.now())
  const timeDifferenceMs = currentDate.getTime() - startDate.getTime()
  const minutes = Math.floor(timeDifferenceMs / 60000) % 60
  const hours = Math.floor(timeDifferenceMs / 3600000)
  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}`
}
