import { Alert, Box, Button, Grid, Stack, SvgIcon, Typography } from '@mui/material'
import { BookmarkSimple, CaretLeft, CaretRight, PencilSimpleLine, Trash } from 'phosphor-react'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { PatientDetailsReadOnly } from '@app/components/Consultation/common/PatientDetails/PatientDetailsReadOnly'
import { EMRClinicianInteractions } from '@app/components/EMR/EMRClinicianInteractions'
import { joinPrescriptionsWithConsultations } from '@app/components/EMR/utils/prescriptions'
import { BlockedUserAlert } from '@app/components/UserAlert'
import { useConsultations } from '@app/hooks/use-consultations'
import { PatientResponse } from '@app/hooks/use-patient'
import { palette } from '@app/theme/color'
import { ConsultationSortBy, ConsultationStatus, MedicalHistory, Prescriptions } from '@app/types'

import { Card } from '../Card'

import { MedicalHistoryDetails } from './common/MedicalHistoryDetails'

interface EMRHistoryViewProps {
  data: {
    patient: PatientResponse['patient']
    medicalHistory: MedicalHistory
    prescriptions: Prescriptions
    additionalInformation: PatientResponse['additionalInformation']
  }
  onAddWatchlist?: () => void
  onRemoveWatchlist?: () => void
  isWatchlisted?: boolean
  showWatchlistButton?: boolean
}

export const EMRHistory: React.FC<EMRHistoryViewProps> = ({
  data,
  onAddWatchlist,
  onRemoveWatchlist,
  isWatchlisted,
  showWatchlistButton,
}) => {
  const { t } = useTranslation()
  const { additionalInformation, patient, medicalHistory, prescriptions } = data
  const navigate = useNavigate()
  const previousLocationPathname: string | undefined = useLocation().state?.previousLocationPathname
  const consultations = useConsultations({
    patientId: patient.id,
    status: [ConsultationStatus.CLOSED, ConsultationStatus.CLOSED_WITH_ERROR],
    sortBy: ConsultationSortBy.CREATED_AT_DESC,
  })

  const previousUrl = useMemo(() => {
    if (!previousLocationPathname) {
      return '/'
    }

    return previousLocationPathname
  }, [previousLocationPathname])

  const navigateBack = useCallback(() => {
    navigate(previousUrl, { state: { refreshSearchResults: false } })
  }, [previousLocationPathname])

  const joinedPrescriptions = useMemo(() => {
    return joinPrescriptionsWithConsultations(prescriptions, consultations.data?.consultations || [])
  }, [prescriptions, consultations.data?.consultations])

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item xs={12} container justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              onClick={navigateBack}
              startIcon={<SvgIcon component={CaretLeft} inheritViewBox size={24} />}
              sx={{ color: 'gray.900', p: 0, fontWeight: 700, fontSize: '1.5rem', mr: 3 }}
            >
              {patient.legalName}
            </Button>

            {showWatchlistButton ? (
              isWatchlisted ? (
                <Button sx={{ color: 'gray.900' }} onClick={onRemoveWatchlist}>
                  <SvgIcon
                    htmlColor="gray.800"
                    component={BookmarkSimple}
                    weight="fill"
                    inheritViewBox
                    sx={{ color: 'gray.900', mr: 1 }}
                  />
                  {t('pages.watchlist.remove')}
                </Button>
              ) : (
                <Button sx={{ color: 'gray.900' }} onClick={onAddWatchlist}>
                  <SvgIcon component={BookmarkSimple} inheritViewBox sx={{ color: 'gray.900', mr: 1 }} />
                  {t('pages.watchlist.add')}
                </Button>
              )
            ) : null}
          </Stack>

          <Box>
            <Button
              variant="contained"
              onClick={() => navigate(`/patients/${patient.emrId}/consultation`)}
              data-test="addUpdate-button"
            >
              <SvgIcon component={PencilSimpleLine} inheritViewBox sx={{ mr: 1 }} />
              {t('pages.emr.addUpdate')}
            </Button>
          </Box>
        </Grid>

        {patient.disabled ? (
          <Grid item xs={12} container>
            <Alert
              sx={{
                alignItems: 'center',
                width: '100%',
                borderLeftWidth: '3px',
                borderLeftColor: palette.error.main,
                borderLeftStyle: 'solid',
              }}
              severity="error"
              icon={<Trash color={palette.error.main} size={24} />}
            >
              <Stack>
                <Typography color="gray.900" fontSize={14} fontWeight="bold">
                  {t('pages.emr.disabled.title')}
                </Typography>
                <Typography color="gray.900" variant="body2" mt={1}>
                  {t('pages.emr.disabled.description')}
                </Typography>
              </Stack>
            </Alert>
          </Grid>
        ) : null}

        <BlockedUserAlert blocked={patient.blocked} />

        <Grid component="section" item xs={12} sx={{ pl: 3, py: 2, mt: 1 }}>
          <PatientDetailsReadOnly additionalInformation={additionalInformation} patient={patient} />
        </Grid>

        <Grid component="section" item xs={5}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1" component="h2">
              {t('pages.emr.medicalHistory.title')}
            </Typography>
            <Button>
              {t('common.seeAll')}
              <SvgIcon component={CaretRight} inheritViewBox sx={{ ml: 1 }} />
            </Button>
          </Stack>

          <Card sx={{ px: 3, py: 2, mt: 1 }}>
            <MedicalHistoryDetails medicalHistory={medicalHistory} prescriptions={joinedPrescriptions} />
          </Card>
        </Grid>

        <EMRClinicianInteractions patientId={patient.id} />
      </Grid>
    </>
  )
}
