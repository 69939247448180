import { Stack } from '@mui/material'
import React from 'react'

import { WarningTriangleIcon } from '@app/components/icons/WarningTriangleIcon'

interface MedicalHistoryTitleProps {
  showAlert?: boolean
}

export const MedicalHistoryTitle: React.FC<MedicalHistoryTitleProps> = ({ children, showAlert }) => {
  return (
    <Stack direction="row" alignItems="center" sx={{ color: showAlert ? 'error.main' : 'initial' }}>
      {showAlert && <WarningTriangleIcon />}
      {children}
    </Stack>
  )
}
