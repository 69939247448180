import * as yup from 'yup'

import { getToken } from '@app/lib/auth'
import { apiRequest, BackendError } from '@app/lib/request'

export const lock = (patientId: number) =>
  apiRequest<{ message: string }, BackendError>(`/patients/${patientId}/lock`, {
    method: 'POST',
    getToken,
  })

export const unlock = (patientId: number) =>
  apiRequest<{ message: string }, BackendError>(`/patients/${patientId}/unlock`, {
    method: 'POST',
    getToken,
  })

// Origin: https://cardohealth.atlassian.net/browse/CAR-3568
//
// Mainly used to filter out unprocessable search results. This data is so bad that
// we do not even add it to the list of search results.
const patientSearchResultSchema = yup.object({
  emrId: yup.string().required(),
})

export const isPatientSearchResultValid = (emrPatient: unknown): boolean => {
  try {
    patientSearchResultSchema.validateSync(emrPatient)
    return true
  } catch (error) {
    console.debug('Error validating EMR patient search result data:', error)
    return false
  }
}
