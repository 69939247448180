import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import EmptyQueueImage from '@app/assets/images/empty-queue.svg'

export const QueueEmpty: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
      <img src={EmptyQueueImage} alt={t('pages.queue.emptyQueue')} />
      <Typography variant="h3" sx={{ color: 'gray.700', mt: 5 }}>
        {t('pages.queue.noPatients')}
      </Typography>
    </Stack>
  )
}
