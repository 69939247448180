import { proxy } from 'valtio'

import { Allergies } from '@app/types'

interface State {
  allergies?: Allergies
  formStatus: {
    isValid: boolean
  }
}

const state = proxy<State>({
  allergies: {
    drugAllergies: [],
    hypersensitivities: undefined,
    otherWarning: undefined,
  },
  formStatus: {
    isValid: true,
  },
})

const setAllergiesState = (newState?: Allergies) => {
  state.allergies = { ...state.allergies, ...newState }
}

const setFormIsValid = (isValid: boolean) => {
  state.formStatus.isValid = isValid
}

export const useConsultationMedicalHistoryAllergiesState = () => {
  return {
    state,
    setAllergiesState,
    setFormIsValid,
  }
}
