import { Button, Divider, Grid, List, ListItem, Pagination, Stack, SvgIcon, Typography } from '@mui/material'
import { CaretRight } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AppLoading } from '@app/components/AppLoading'
import { PastConsultationModal } from '@app/components/Consultation/common/PastConsultationModal'
import { ConsultationCard } from '@app/components/EMR/EMRHistoryView/ConsultationCard'
import { ConsultationFeedbackProvider } from '@app/context/consultation-feedback'
import { useConsultations } from '@app/hooks/use-consultations'
import { getClosedAction, getConsultationDate } from '@app/lib/consultation'
import { Consultation, ConsultationSortBy, ConsultationStatus, ConsultationType } from '@app/types'

interface EMRClinicianInteractionsProps {
  patientId: number
}

function getMonth(consultation: Consultation) {
  return new Date(getConsultationDate(consultation)).getMonth()
}

function sortByConsultationDate(a: Consultation, b: Consultation) {
  return new Date(getConsultationDate(b)).getTime() - new Date(getConsultationDate(a)).getTime()
}

export function EMRClinicianInteractions(props: EMRClinicianInteractionsProps) {
  const { t } = useTranslation()
  const [pastConsultationId, setPastConsultationId] = React.useState<number | null>(null)
  const [page, setPage] = React.useState(1)
  const consultations = useConsultations({
    patientId: props.patientId,
    status: [ConsultationStatus.CLOSED, ConsultationStatus.CLOSED_WITH_ERROR, ConsultationStatus.CANCELLED],
    sortBy: ConsultationSortBy.CREATED_AT_DESC,
    page,
  })

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Grid component="section" item xs={7}>
      {pastConsultationId && (
        <ConsultationFeedbackProvider consultationId={pastConsultationId}>
          <PastConsultationModal
            isOpen={Boolean(pastConsultationId)}
            onClose={() => setPastConsultationId(null)}
            consultationId={pastConsultationId}
          />
        </ConsultationFeedbackProvider>
      )}

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1" component="h2">
          {t('pages.emr.cliniciansInteractions')}
        </Typography>
        <Button>
          {t('common.seeAll')}
          <SvgIcon component={CaretRight} inheritViewBox sx={{ ml: 1 }} />
        </Button>
      </Stack>

      {consultations.isLoading && <AppLoading />}

      {!consultations.isLoading && consultations.data && (
        <Stack component={List} spacing={2}>
          {consultations.data.consultations.sort(sortByConsultationDate).map((consultation, i, arr) => {
            const previousConsultation = arr[i - 1]
            const monthChanged = previousConsultation && getMonth(previousConsultation) !== getMonth(consultation)
            const consultationDate =
              consultation.type === ConsultationType.SCHEDULED && consultation.startTime
                ? consultation.startTime
                : consultation.createdAt

            return (
              <React.Fragment key={consultation.id}>
                {(!previousConsultation || monthChanged) && (
                  <ListItem disablePadding>
                    <Divider textAlign="left">{t('dates.month', { date: consultationDate })}</Divider>
                  </ListItem>
                )}

                <ListItem disablePadding>
                  <ConsultationCard
                    consultation={{
                      consultationDate: getConsultationDate(consultation),
                      category: consultation.category.name,
                      status: consultation.status,
                      prescriptionId: consultation.prescriptionId,
                      type: consultation.type,
                      closedAction: getClosedAction(consultation),
                    }}
                    clinician={{
                      firstName: consultation.clinician?.firstName ?? '',
                      lastName: consultation.clinician?.lastName ?? '',
                      title: consultation.clinician?.title ?? '',
                      category: consultation.clinician?.specialtyAndSubSpecialtyTitle ?? '',
                    }}
                    onClick={() => setPastConsultationId(consultation.id)}
                  />
                </ListItem>
              </React.Fragment>
            )
          })}
        </Stack>
      )}

      {consultations.pageCount > 0 && (
        <Pagination
          sx={{ mt: 2 }}
          count={consultations.pageCount}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
      )}
    </Grid>
  )
}
