import CloseIcon from '@mui/icons-material/Close'
import { Button, Stack, Dialog, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useERecetaPrescription } from '@app/hooks/use-ereceta-prescription'
import { Consultation, EmptyConsultation } from '@app/types'

type IframeDialogProps = {
  patientId: number
  consultationId: number | null
  isDialogOpen: boolean
  setIsDialogOpen: (status: boolean) => void
  onSign: () => void
}

const IframeDialog: React.FC<IframeDialogProps> = ({
  consultationId,
  patientId,
  isDialogOpen,
  setIsDialogOpen,
  onSign,
}) => {
  const { t } = useTranslation()
  const { iframeUrl, error } = useERecetaPrescription(patientId, consultationId)

  useEffect(() => {
    const handleMessageFromIframe = (event: { origin: string; data: unknown }) => {
      // TODO: Pass this via ENV variable
      if (event.origin === 'https://test.ereceta.es' && event.data === 'ereceta-prescripcion-emitida') {
        onSign()
        setIsDialogOpen(false)
      }
    }

    window.addEventListener('message', handleMessageFromIframe)

    return () => {
      window.removeEventListener('message', handleMessageFromIframe)
    }
  }, [])

  return (
    (!error && iframeUrl && (
      <Dialog
        aria-label={t('pages.emr.consultationDetail')}
        maxWidth="md"
        fullScreen
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setIsDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <iframe src={iframeUrl} title="eReceta" style={{ width: '100%', height: '100%' }} />
      </Dialog>
    )) ||
    null
  )
}

type ERecetaPrescriptionCTAProps = {
  consultation: Consultation | EmptyConsultation
  isConfirmationChecked: boolean
  onSign: () => void
}

export const ERecetaPrescriptionCTA: React.FC<ERecetaPrescriptionCTAProps> = ({
  isConfirmationChecked,
  onSign,
  consultation,
}) => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const onOpen = () => {
    setIsDialogOpen(true)
  }

  return (
    <>
      <IframeDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onSign={onSign}
        patientId={consultation?.patientId}
        consultationId={consultation?.id}
      />
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isConfirmationChecked}
          onClick={onOpen}
          data-test="modal-signAndShareWithEReceta-button"
        >
          {t('pages.emr.signandShareWithEReceta')}
        </Button>

        <Button
          variant={isConfirmationChecked ? 'outlined' : 'contained'}
          disabled={!isConfirmationChecked}
          onClick={onSign}
          color="primary"
          data-test="modal-signAndShareWithoutEReceta-button"
        >
          {t('pages.emr.signandShareWithoutEReceta')}
        </Button>
      </Stack>
    </>
  )
}
