import i18n from 'i18next'

export const nameHeader = {
  id: 'name',
  label: i18n.t('pages.monitoring.tables.clinicians.name'),
  numeric: false,
  disablePadding: false,
}
export const specialityHeader = {
  id: 'specialty',
  label: i18n.t('pages.monitoring.tables.clinicians.specialty'),
  numeric: false,
  disablePadding: false,
}
export const patientHeader = {
  id: 'patient',
  label: i18n.t('pages.monitoring.tables.patients.patient'),
  numeric: false,
  disablePadding: false,
}
export const phoneHeader = {
  id: 'phone',
  label: i18n.t('pages.monitoring.tables.patients.phone'),
  numeric: false,
  disablePadding: false,
}
export const ageHeader = {
  id: 'age',
  label: i18n.t('pages.monitoring.tables.patients.age'),
  numeric: true,
  disablePadding: false,
}
export const sexHeader = {
  id: 'sex',
  label: i18n.t('pages.monitoring.tables.patients.sex'),
  numeric: false,
  disablePadding: false,
}
export const statusHeader = {
  id: 'status',
  label: i18n.t('pages.monitoring.tables.patients.status'),
  numeric: false,
  disablePadding: false,
}
export const categoryHeader = {
  id: 'category',
  label: i18n.t('pages.monitoring.tables.patients.category'),
  numeric: false,
  disablePadding: false,
}
export const productHeader = {
  id: 'product',
  label: i18n.t('pages.monitoring.tables.patients.product'),
  numeric: false,
  disablePadding: false,
}
export const assignedToHeader = {
  id: 'assignedTo',
  label: i18n.t('pages.monitoring.tables.patients.assignedTo'),
  numeric: false,
  disablePadding: false,
}
export const timeInServiceHeader = {
  id: 'timeInService',
  label: i18n.t('pages.monitoring.tables.patients.timeInService'),
  numeric: false,
  disablePadding: false,
}
export const bookingTimeHeader = {
  id: 'bookingTime',
  label: i18n.t('pages.monitoring.tables.patients.bookingTime'),
  numeric: false,
  disablePadding: false,
}
