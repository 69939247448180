export const typography = {
  fontFamily: ['Inter', 'sans-serif', 'Arial'].join(','),
  h1: {
    fontSize: '2rem',
    fontWeight: '700',
  },
  h2: {
    fontSize: '1.75rem',
    fontWeight: '700',
  },
  h3: {
    fontSize: '1.5rem',
    fontWeight: '700',
  },
  subtitle1: {
    fontSize: '1.25rem',
    fontWeight: '600',
  },
  subtitle2: {
    fontSize: '1.25rem',
    fontWeight: '500',
  },
  body1: {
    fontSize: '1rem',
    fontWeight: '400',
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: '400',
  },
  button: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: '400',
  },
}
