import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { v4 } from 'uuid'

import { Prescription } from '@app/types'

type ContextType = {
  newPrescriptions: Prescription[]
  initNewPrescriptions: (prescriptions: Prescription[]) => void
  addNewPrescription: (prescription: Prescription) => void
  removeNewPrescription: (index: number) => void
  removeNewPrescriptionByPrescriptionId: (prescriptionId: string) => void
}

export const NewPrescriptionsContext = createContext<ContextType | undefined>(undefined)

export const NewPrescriptionProvider: React.FC = ({ children }) => {
  const [newPrescriptions, setNewPrescriptions] = useState<Prescription[]>([])

  const initNewPrescriptions = useCallback((prescriptions: Prescription[]) => {
    setNewPrescriptions(prescriptions)
  }, [])

  const addNewPrescription = useCallback((prescription: Prescription) => {
    setNewPrescriptions(prev => [{ ...prescription, medication: { ...prescription.medication, uuid: v4() } }, ...prev])
  }, [])

  const removeNewPrescription = useCallback(
    (index: number) => {
      setNewPrescriptions(newPrescriptions.filter((_, i) => i !== index))
    },
    [newPrescriptions]
  )

  const removeNewPrescriptionByPrescriptionId = useCallback(
    (prescriptionId: string) => {
      const updatedPrescriptions = newPrescriptions.filter(
        prescription => prescription.prescriptionId !== prescriptionId
      )
      setNewPrescriptions(updatedPrescriptions)
    },
    [newPrescriptions, setNewPrescriptions]
  )

  const value = useMemo(
    () => ({
      newPrescriptions,
      initNewPrescriptions,
      addNewPrescription,
      removeNewPrescription,
      removeNewPrescriptionByPrescriptionId,
    }),
    [
      newPrescriptions,
      initNewPrescriptions,
      addNewPrescription,
      removeNewPrescription,
      removeNewPrescriptionByPrescriptionId,
    ]
  )

  return <NewPrescriptionsContext.Provider value={value}>{children}</NewPrescriptionsContext.Provider>
}

export const useNewPrescriptionsContext = () => {
  const context = useContext(NewPrescriptionsContext)

  if (!context) {
    throw new Error('useNewPrescriptions() must be wrapped inside NewPrescriptionsProvider.')
  }

  return context
}
