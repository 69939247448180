import { Alert, Button, SvgIcon } from '@mui/material'
import { CaretLeft } from 'phosphor-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { AppLoading } from '@app/components/AppLoading'
import { NewConsultation } from '@app/components/Consultation/NewConsultation'
import { Page } from '@app/components/Page'
import { useAuth } from '@app/context/auth'
import { ConsultationFeedbackProvider } from '@app/context/consultation-feedback'
import { PatientFileStatus, PatientFileStatusProvider } from '@app/context/patient-file-status'
import { NewPrescriptionProvider } from '@app/context/use-new-prescriptions'
import { useHistoryPush } from '@app/hooks/use-history-push'
import { useHistoryReplace } from '@app/hooks/use-history-replace'
import { usePatient } from '@app/hooks/use-patient'
import { useUnloadDialog } from '@app/hooks/use-unload-dialog'
import { lock, unlock } from '@app/lib/patients'
import { ErrorCodes } from '@app/lib/request'
import { ConsultationType, EmptyConsultation } from '@app/types'
import { assertIsDefined } from '@app/util/asserts'

/**
 * Consultation update.
 */
export const NewConsultationPage: React.FC = () => {
  const { emrId } = useParams()
  const { profile } = useAuth()
  assertIsDefined(emrId)
  assertIsDefined(profile)
  const { data, hasError, isLoading } = usePatient(emrId)
  const { t } = useTranslation()
  const [consultation, setConsultation] = useState<EmptyConsultation | null>(null)
  const [isLocked, setIsLocked] = useState(true)
  const [blockHistoryWarning, setBlockHistoryWarning] = useState(true)

  const handleUnlock = useCallback(() => {
    if (!data) return

    unlock(data.patient.id)
  }, [data?.patient.id])

  useEffect(() => {
    if (!data) return

    setConsultation({
      id: null,
      clinician: {
        title: profile.clinicianData?.professionTitle ?? '',
        firstName: profile.firstName,
        lastName: profile.lastName,
        cardoId: profile.cardoId,
      },
      patient: data.patient,
      patientId: data.patient.id,
      medicalHistory: data.medicalHistory,
      pastPrescriptions: data.pastPrescriptions,
      additionalInformation: data.additionalInformation,
      type: ConsultationType.MEDICAL_UPDATE,
    })
  }, [data?.patient.lockedById])

  useUnloadDialog(t('errorScreen.newConsultation.unloadConfirmation'), !!consultation)

  useHistoryPush(t('errorScreen.newConsultation.unloadConfirmation'), blockHistoryWarning)

  useHistoryReplace(t('errorScreen.newConsultation.unloadConfirmation'))

  useEffect(() => {
    if (!data) return

    lock(data.patient.id).then(resp => {
      const isLockedByMe = resp.kind === 'success' || resp.error?.code === ErrorCodes.ALREADY_LOCKED
      return setIsLocked(!isLockedByMe)
    })

    return () => {
      handleUnlock()
    }
  }, [data?.patient.id])

  return (
    <NewPrescriptionProvider>
      <Page>
        {data?.patient.emrId ? (
          <Button
            component={Link}
            to={`/patients/${data.patient.emrId}`}
            startIcon={<SvgIcon component={CaretLeft} inheritViewBox size={24} />}
            sx={{ color: 'gray.900', p: 0, mb: 2, fontWeight: 700, fontSize: '1.25rem' }}
          >
            {consultation?.patient.legalName}
          </Button>
        ) : null}
        {hasError ? <Alert severity="error">{t('pages.consultation.error')}</Alert> : null}
        {isLoading ? <AppLoading /> : null}
        {!isLoading && consultation && profile ? (
          <PatientFileStatusProvider value={isLocked ? PatientFileStatus.LOCKED : PatientFileStatus.UNLOCKED}>
            <ConsultationFeedbackProvider consultationId={consultation.id}>
              <NewConsultation
                consultation={consultation}
                handleUnlock={handleUnlock}
                isLocked={isLocked}
                onBlockHistoryWarning={setBlockHistoryWarning}
              />
            </ConsultationFeedbackProvider>
          </PatientFileStatusProvider>
        ) : null}
      </Page>
    </NewPrescriptionProvider>
  )
}
