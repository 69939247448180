import { proxy } from 'valtio'

import { PathologicalPersonalHistory } from '@app/types'

interface State {
  pathologicalPersonalHistory?: PathologicalPersonalHistory
  formStatus: {
    isValid: boolean
  }
}

const state = proxy<State>({
  pathologicalPersonalHistory: {
    previousDiseases: [],
    previousMedication: [],
    previousSurgeries: [],
    currentDiseases: [],
    currentMedication: [],
    currentSurgeries: [],
  },
  formStatus: {
    isValid: true,
  },
})

const setPathologicalState = (newState?: PathologicalPersonalHistory) => {
  state.pathologicalPersonalHistory = { ...state.pathologicalPersonalHistory, ...newState }
}

const setFormIsValid = (isValid: boolean) => {
  state.formStatus.isValid = isValid
}

export const useConsultationMedicalHistoryPathologicalState = () => {
  return {
    state,
    setPathologicalState,
    setFormIsValid,
  }
}
